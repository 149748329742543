.fc-timegrid-slot {
  padding: 20px 4px !important;
}
.disbaled-event {
  /* background-color: #00000015 !important; */
  background-color: #00000015 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.appointmentcalender-table .fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px;
}
.appointmentcalender-table .fc-toolbar.fc-header-toolbar h2 {
  font-family: "Gotham Medium";
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
}
button.ant-btn.full-screen-button {
  /* margin-right: 35px !important; */
}
.rl-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.appointmentcalender-table .ant-select.doctors-multi-dropdown {
  width: 100%;
  min-width: unset;
}
/* .appointmentcalender-table .table-responsive .fc-right {
    margin-right: 46px;
  } */
.appointmentcalender-table .table-responsive .date-arrow-wrapper {
  position: absolute;
  right: 18px;
  top: 80px;
}
.appointmentcalender-table .fc-left div {
  display: flex;
  align-items: center;
}
.appointmentcalender-table .fc-button-primary {
  background-color: #1445b7;
  border-color: #1445b7;
  border-radius: 0.25em !important;
}
.appointmentcalender-table .fc-button-primary:not(:last-child) {
  margin-right: 3px;
}
.appointmentcalender-table .fc-button-primary.fc-button-active,
.appointmentcalender-table .fc-button-primary:hover {
  background: rgb(226, 244, 255);
  color: rgb(32, 114, 187);
  border-color: rgb(32, 114, 187);
}
.appointmentcalender-table .fc-button-primary.fc-next-button {
  margin-right: 28px;
}
.appointmentcalender-table .fc-left div .fc-button-primary {
  display: none;
}
.appointmentcalender-table {
  border-radius: 3px;
  border: none;
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  position: relative;
}
.full-screen-wrapper {
  position: relative;
}
.appointmentcalender-table .full-screen-btn {
  position: absolute;
  width: 24px;
  right: 380px;
  top: 13px;
  cursor: pointer;
}
.appointmentcalender-table .fullscreen-enabled .full-screen-btn {
  top: 11px;
}
.fc .fc-timegrid-slot {
  border-bottom: 8px;
  height: 4.5em !important;
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: unset;
}
.fc-theme-standard td:not(.fc-timegrid-slot):not(.fc-timegrid-axis-frame) {
  border-right: 1px solid transparent !important;
}
.calendar-title {
  font-weight: 700;
  font-size: 18px;
  display: flex;
  padding-top: 4px;
}
.calendar-icon {
  font-size: 16px;
  color: #818181;
  fill: currentColor;
}
.full-screen-wrapper .ant-radio-group .ant-radio-button-wrapper {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
.full-screen-wrapper
  .ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #1445b7;
}
.full-screen-wrapper .ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}
a.fc-timegrid-event.fc-v-event.fc-event.fc-event-draggable.fc-event-start.fc-event-end.fc-event-today.fc-event-future.empty-event {
  background: transparent !important;
}
.hover-class {
  background: #1890ff82;
  height: inherit;
  cursor: alias;
}
.empty-slot-event {
  height: inherit;
}
.empty-slot-event:hover {
  background: rgba(188, 232, 241, 0.3);
  cursor: alias;
}

.empty-slot-event-week:hover {
  cursor: alias;
}
.empty-slot-event-week {
  height: inherit;
}

th.fc-col-header-cell.fc-day.fc-day-today.today-date-header {
  color: #1445b7;
  position: relative;
}
th.fc-col-header-cell.fc-day.fc-day-today.today-date-header::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #1445b7;
}
