body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  #layoutContainerV {
    background-color: #DDD;
    /* position: relative; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DDD;
  }
  #layoutContainerV > * {
    background-color: #000;
    display: inline-block;
    border: 1px solid #fff;
    /* transition-property: all;
    transition-duration: 0.5s; */
  }

  #layoutContainerV> * {
    width: 0;
    height: 0;
    opacity: 0;
  }

  #layoutContainerV > *.ot-layout {
    opacity: 1;
  }
