.__questionnaire_page_wrapper {
  min-height: 100vh;
  color: #231f20;
  background: #f0f4f5;
}
.questionnaire_page .questionare_header {
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #fff;
}
.questionnaire_page .questionare_header .healthyaLogoImage {
  width: 150px;
}

.questionnaire_page .questioanire_fill_Wrapper {
  background: #f0f4f5;
  min-height: calc(100vh - 60px - 62px);
  padding: 30px 0 80px;
}
.questionnaire_page .questioanire_fill_Wrapper .questioanire_fill_wrap_inner {
  max-width: 1000px;
  margin: auto;
}
.questionnaire_page .date-selector {
  max-width: 500px;
  margin: auto;
}
.child-questions {
  padding-left: 30px;
}

/* color , button , font size , ant reset */

.questionnaire_page .btn-blue {
  font-size: 18px;
  height: 44px;
}
.questionnaire_page .btn-blue,
.questionnaire_page .btn-blue:active {
  color: #fff;
  background-color: #007f3b;
  border-color: #007f3b;
}
.questionnaire_page .btn-blue:hover {
  background: #fff;
  color: #007f3b;
}
.questionnaire_page .text-dark {
  color: #231f20 !important;
}
.questionnaire_page .ant-input,
.questionnaire_page .ant-picker {
  background: #eefbff !important;
}
._questions_wrapper .ant-input:focus,
._questions_wrapper .ant-input:hover {
  border-color: #007f3b !important;
}
._questions_wrapper .ant-radio-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
}
._questions_wrapper .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
._questions_wrapper .ant-checkbox-wrapper {
  margin: 0 !important;
}
._questions_wrapper .ant-checkbox-inner,
._questions_wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
}
._questions_wrapper .ant-radio-inner::after {
  width: 16px;
  height: 16px;
}
._questions_wrapper .ant-checkbox-inner {
  border-radius: 4px;
}
._questions_wrapper .ant-checkbox-inner::after {
  left: 25%;
}
._questions_wrapper .ant-radio-checked::after,
._questions_wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #231f20 !important;
}
._questions_wrapper .ant-radio-checked .ant-radio-inner::after {
  background: #231f20 !important;
  width: 14px;
  height: 14px;
  top: 4px;
  left: 4px;
}
._questions_wrapper .ant-alert-info {
  border-radius: 6px;
}
.__questionnaire_page_wrapper .ant-picker:hover,
._questions_wrapper .ant-picker-focused,
._questions_wrapper .ant-checkbox-inner {
  border-color: #231f20 !important;
}
._questions_wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background: #231f20 !important;
  border-color: #231f20 !important;
}

/* questions content */
._question_wrapper {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e9e9e9;
}
._question_wrapper:last-child {
  border: none;
  margin: 0;
}
._question_wrapper ._question {
  gap: 8px;
  margin-bottom: 4px;
}
._question_wrapper ._question .dot {
  width: 8px;
  height: 8px;
  margin-top: 10px;
  min-width: 8px;
  display: inline-block;
  border: 1px solid #ec613a;
  background: #ec613a !important;
}
._question_wrapper ._question .Questions {
  flex-grow: 1;
  display: inline-block;
  line-height: 37px;
  font-size: 16px;
  font-weight: 600;
}
._question_input {
  padding: 0 0 16px 16px;
}
._question_input * {
  font-size: 18px !important;
}
.questionnaire_answer_wrapper {
  border: 1px solid #d9d9d9;
  padding: 12px;
  margin-top: 8px;
  border-radius: 8px;
}
.questionnaire_answer_wrapper .ant-checkbox {
  top: 2px;
}
.questionnaire_answer_wrapper .full_box .ant-checkbox {
  top: 4px;
}
.question_wrapper {
  padding-bottom: 8px;
}
.questionnaire_answer_wrapper:not(:last-child) {
  margin-bottom: 8px;
}
.questionnaire_answer_child_wrapper {
  margin: 24px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.questionnaire_answer_child_wrapper > .questionnaire_answer:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-top: 8px;
}
.questionnaire_answer:not(:last-child) {
  margin-bottom: 8px;
}

.__questionnaire_page_wrapper .footer_custom {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 62px !important;
}
.__questionnaire_page_wrapper .nhsLogo_questionnaire {
  position: fixed;
  bottom: 5px;
  right: 15px;
  max-height: 48px;
  z-index: 90;
}
.__questionnaire_page_wrapper .footer_custom .poweredBy {
  left: 15px !important;
  top: 50%;
  transform: translateY(-50%);
}
.__questionnaire_page_wrapper .footer_custom .poweredBy h3 {
  line-height: 22px !important;
}
/* new session questionnaire */

.modal-dialog.create_questionaire {
  max-width: 1000px;
}
.drprofilebtns .ant-btn:hover,
.drprofilebtns .ant-btn:focus {
  color: #fff !important;
}
.dot {
  position: relative;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #000;
  display: block;
  padding-left: 5px;
  top: 4px;
}
.add-scroll-to-answereview {
  max-height: 424px;
  overflow: auto;
}
.full_box .radiopbtn .ant-radio-group {
  flex-wrap: wrap;
}
.full_box .radiopbtn .ant-radio-group label.ant-radio-wrapper {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.Questions ul {
  margin-left: 2px;
  margin-bottom: 10px;
}
.Questions ul li {
  margin-bottom: 3px;
  position: relative;
  padding-left: 20px;
}
.Questions ul li::before,
.questionnaire_answer_wrapper .question_wrapper ul li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 7px;
  left: 0;
  /* bottom: 0; */
  margin: auto;
  background: #000;
}
.quetionanirewrap .mainquestionnairewrap .Questions ul li {
  font-size: 16px !important;
}
.child-questions .margin-b-15 {
  margin-bottom: 15px !important;
}
