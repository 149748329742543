.pro-pic-left {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.profile__img {
  max-width: 95px;
  width: 100%;
  border-radius: 50%;
  max-height: 100px;
  object-fit: cover;
  height: 95px;
}
.carousel-inner {
  height: 300px;
}

.breadcrumb__item {
  margin-right: 5px;
  padding: 0;
  font-size: 13px;
  color: black;
}

.breadcrumb__link {
  color: blue;
}
.breadcrumb__link--current {
  color: black;
}

.chathistory__item {
  word-wrap: break-word;
}

.chathistory__user {
  display: flex;
  font-size: xx-small;
}

.chathistory__user--left {
  justify-content: flex-start;
}

.chathistory__user--right {
  justify-content: flex-end;
}

.screenshot__img {
  width: inherit;
  border-radius: 6px;
  overflow: hidden;
  display: table;
}

.tabs__link {
  margin-right: 30px !important;
}

.gallery-container {
  display: flex;
  justify-content: space-evenly;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.sidebar-container .nav {
  overflow-y: auto;
}

.sidebar-container .nav .nav-item {
  width: 100%;
}
.overlay {
  position: absolute;
  z-index: 1;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-eclipse {
  position: relative;
}
.lds-eclipse div {
  position: absolute;
  -webkit-animation: lds-eclipse 1s linear infinite;
  animation: lds-eclipse 1s linear infinite;
  width: 50px;
  height: 50px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #1445b7;
  -webkit-transform-origin: 25px 27px;
  transform-origin: 25px 27px;
}
.lds-eclipse {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* style.css*/
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

/* Not needed with the cssTransition helper */
/* New Chat CSS Starts */
/* New chat CSS Starts */

.border-right {
  border-right: 1px solid #e9e9e9;
}
.height-100 {
  height: 100%;
}
.message_box .message_heading {
  min-height: 80px;
  background-color: #f9f9f9;
  box-shadow: inset 0 -1px 0 0 #e9e9e9;
  padding: 18px 24px 16px 24px;
  margin: 0;
  border-left: 4px solid #f9f9f9;
}
.message_box .message_heading.active {
  border-left: 4px solid #1445b7;
  background-color: #fff;
}
.message_box .custom_label {
  width: auto;
  height: 20px;
  border-radius: 3px;
  background-color: #1445b7;
  text-align: center;
  font-size: 10px;
  color: #fff;
  padding: 2px 10px;
}
.message_box .custom_label.sky {
  background-color: #1445b7;
  border-color: #1445b7;
}
.message_box .custom_label.blue {
  background-color: #1445b7;
  border-color: #1445b7;
}
.message_box .notification {
  width: 20px;
  height: 20px;
  background-color: #43dc91;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 2px;
}
.message_box .customTab md-tabs .md-tab {
  font-size: 12px;
  color: #757575;
}
.message_box .customTab md-tabs .md-tab.md-active {
  font-size: 12px;
  color: #404040;
}
.message_box .message_send_box {
  height: 48px;
  background-color: rgba(249, 249, 249, 0.3);
  box-shadow: 0 -1px 0 0 #e9e9e9;
  position: relative;
}
.message_box .message_send_box .custom-input,
.message_box .message_send_box .custom-input:focus,
.message_box .message_send_box .custom-input:active {
  border: none !important;
  padding-right: 60px;
  background-color: rgba(249, 249, 249, 0.3);
}
.message_box .message_send_box .send-msg-img {
  position: absolute;
  right: 15px;
  top: 8px;
}
.message_box .message_detail {
  padding: 15px 84px;
}
.message_box .message_detail .msg_chat_body {
  min-height: 41px;
  border-radius: 2px;
  background-color: #1445b7;
  padding: 10px 15px;
  max-width: 315px;
  width: auto;
  margin-top: 24px;
  position: relative;
}
.message_box .message_detail .msg_chat_body p {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.2px;
  text-align: left;
  color: #121212;
  margin: 0;
  word-break: break-word;
}
.message_detail .msg_chat_body.sender {
  background-color: #1445b7;
  float: right;
}
.message_detail .msg_chat_body.receiver {
  background-color: #e8efff;
  float: left;
}
.message_detail .msg_chat_body.sender p {
  color: #fff;
}
.message_detail .msg_chat_body:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #e8efff;
  border-bottom: 0;
  border-left: 0;
  left: 0px;
  bottom: -7px;
}
.message_detail .msg_chat_body.sender:after {
  border-top-color: #1445b7;
  left: inherit;
  right: -5px;
  transform: rotate(316deg);
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  bottom: -5px;
}
.block-row {
  width: 100%;
  display: block;
}
.nav-link {
  cursor: pointer;
}
.customTab {
  width: 100%;
  align-items: center;
  height: 80px;
  display: flex;
  justify-content: center;
}
.date {
  width: 100%;
}
.orders-chat-time {
  padding-left: 15px;
  font-size: 10px;
}
.doctor-date {
  width: 80% !important;
  padding: 20px;
  border: 1px solid #e9e9e9;
  color: #121212;
}
.doctor-date.active {
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #1445b7;
}
.slick-slider,
.slick-initialized {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.slick-list {
  text-align: center;
}
.rotate180 {
  transform: rotate(180deg);
}
.rota-docs-list {
  min-height: 200px;
}
.prescription-switch-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.prescription-filter-input {
  height: 2rem;
  font-size: 0.76rem;
  width: 110px;
}
.prescription-date-picker > div {
  display: inline;
}
.prescription-date-picker {
  z-index: 199;
}

.collapse-investigation {
  font-size: 16px;
  width: auto;
  float: right;
  text-align: left;
  font-family: regularfont;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.collapse-investigation .fa {
  color: #192a3e;
  font-weight: 800;
}
/*
.slick-list,
.slick-slider {
  height: 100px;
  width: 100%;
} */
.tab-content {
  display: block;
  min-height: 300px;
  min-width: 300px;
}

.slick-next:before,
.slick-prev:before {
  color: #1445b7;
}
/* .slick-slide {
  width: 263px !important;
} */

.patient-clinical-tab {
  width: 100%;
  height: 59px;
}

.patient-clinical-div {
  width: 100%;
  height: 55px;
}
/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  height: 63px;
} */
.slider-patient {
  display: flex;
  align-items: center;
}

/* .prescription_drugs */
.prescription_drugs
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 4px 10px !important;
  background: #1445b7 !important;
}
.prescription_drugs .ant-collapse-content > .ant-collapse-content-box {
  padding: 10px !important;
}
.prescription_drugs .ant-collapse-header svg {
  color: #ffffff !important;
}
.prescription_drugs .ant-collapse,
.prescription_drugs .ant-collapse-content {
  border: none !important;
}
/* .prescription_drugs */
.ant-modal-body
  .bookaptcalenderwrap
  .ant-radio-group
  .ant-radio-button-wrapper:nth-child(3) {
  border-left: 1px solid #d0d0da !important;
}
.ant-modal-body
  .bookaptcalenderwrap
  .ant-radio-group
  .ant-radio-button-wrapper:nth-child(3).ant-radio-button-wrapper-checked {
  border-color: #1445b7 !important;
}
.ellipsis-1-line {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-2-line {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-3-line {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pre_questionairepopup .fc-event-main b,
.appointment-calendar_wrapper .fc-event-main b {
  padding: 5px 8px 1px;
  display: inline-block;
}
.fc-timegrid-slot-label-cushion {
  font-size: 12px;
  font-weight: 700;
  color: #6a6e73;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif; */
}
.fc {
  --fc-border-color: #ddd;
}
/* .fc-theme-standard .fc-scrollgrid{border:1px solid #ddd;} */
.fc-timegrid-event .fc-event-main > p,
.fc-timegrid-event .fc-event-main .tag-parent {
  padding-inline: 8px;
  line-height: normal;
}
/* .disbaled-event{background-color:rgba(0,0,0,.02) !important;} */
.fc-theme-standard th[role="columnheader"],
.fc-theme-standard .fc-timegrid-axis {
  border: 1px solid #ddd;
}
.fc-theme-standard .fc-col-header th[role="columnheader"] {
  border-bottom: 0 !important;
}
.fc-timegrid-col a.disbaled-event {
  border-color: transparent !important;
}
.fc-theme-standard th {
  vertical-align: middle;
}
.fc-theme-standard th[role="presentation"] {
  border-bottom: 1px solid #ddd;
}
.fc-theme-standard .fc-timegrid-axis:not(:first-child),
.fc-theme-standard th[role="columnheader"] {
  border: 1px solid #ddd !important;
}
.fc-theme-standard .fc-timegrid-axis:first-child {
  border-bottom: 0 !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 10px 2px;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0;
}
/* .fc-timegrid-axis-chunk,.fc thead .fc-scrollgrid-section{background-color:rgba(20,69,183,.05);} */
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
}
.fc .fc-timegrid-slot-label-cushion {
  white-space: nowrap;
}
.BookAppointment_Flow
  .available_rota_list
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #ffffff !important;
}
.calendercontrols button.ant-btn:hover {
  position: relative;
  z-index: 1;
}
.fc-v-event:not(.disbaled-event) {
  border-radius: 0;
}
.fc-scrollgrid thead .fc-scrollgrid-section td:first-child {
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.fc-theme-standard td {
  border: 1px solid #ddd;
}
.ant-modal-content .ant-modal-body .ant-btn > span {
  line-height: normal;
}
.bookappoinmentsection .ant-spin-nested-loading,
.bookappoinmentsection .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.bookappoinmentsection .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-direction: column;
}
/* .bookappoinmentsection .noslotdiv {background-color: #fff1f2;} */
.ant-modal-body
  .bookappoinmentsection
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  margin-left: 0;
}
.fc-col-header.fc-scrollgrid-sync-table tr:last-child th, .fc-col-header tr:last-child th {
  padding: 8px 2px;
}
.fc-col-header tr:last-child th {
  border-bottom: 0;
}
.flex-1 {
  flex: 1;
}
.text-black-70 {
  color: rgba(0, 0, 0, 0.7) !important;
}
.text-danger-70 {
  color: rgba(220, 53, 69, 0.7) !important;
}
.border-box-danger {
  border: solid 1px #ffd9db !important;
  padding: 5px 10px !important;
}
.fullscreen.fullscreen-enabled {
  background: #fff;
  overflow: auto;
  padding: 15px;
}
.bookappoinmentsection .full-loader-div {
  background: transparent;
  top: 0;
  left: 0;
}
.bookappoinmentsection .lds-facebook {
  background-color: transparent;
}
.appointment-calendar_wrapper .fc.fc-media-screen {
  height: calc(100vh - 160px) !important;
}
.fullscreen-enabled .fc.fc-media-screen,
.appointment-calendar_wrapper .fullscreen-enabled .fc.fc-media-screen {
  height: calc(100vh - 82px) !important;
}
.appointment-calendar_wrapper .full-loader-div {
  background: transparent;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 0;
}
.appointment-calendar_wrapper .lds-facebook {
  background-color: transparent;
}
.appointment-calendar_wrapper .full-screen-parent {
  right: 333px;
}
.full-screen-wrapper .refresh-btn {
  position: absolute;
  right: 387px;
  top: 0;
}
.fullscreen-enabled .refresh-btn {
  right: 402px;
  top: 15px;
}
.refresh-btn .ant-btn {
  padding-block: 5px;
}
.refresh-btn svg {
  color: #808396;
  font-size: 18px;
}
.appointment-calendar_wrapper
  .full-screen-wrapper
  .fullscreen-enabled
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  top: 19px;
}
.appointment-calendar_wrapper .fc .fc-toolbar-title {
  color: #142d38;
  font-size: 20px !important;
}
.appointment-calendar_wrapper .fullscreen-enabled .full-screen-parent {
  right: 348px;
}
.appointment-calendar_wrapper .calendercontrols button.ant-btn {
  min-height: 32px;
}
.appointment-calendar_wrapper .ant-radio-group {
  display: flex;
  gap: 4px;
}
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #fff;
  border-color: #1445b7;
  background-color: #1445b7;
}
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #1445b7;
}
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:last-child {
  border-left-color: #1445b7;
}
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before,
.appointment-calendar_wrapper
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: #1445b7;
}
.appointment-calendar_wrapper .ant-radio-button-wrapper {
  font-size: 12px;
  color: #808396;
}
.appointment-calendar_wrapper .ant-radio-button-wrapper:hover {
  color: #808396;
}
.calendar-menu-icon .ant-menu-item-icon {
  margin-inline: 0;
}
.rta .tox-tinymce {
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #f9f9f9;
}
.rta:focus .tox-tinymce {
  border-color: #1445b7;
}
.tox .tox-edit-area__iframe,
.tox .tox-statusbar {
  background-color: transparent !important;
}
.consultantion-info-table-wrapper .ant-select-selector {
  border-radius: 2px !important;
}
.highlightedtext .ant-input-number {
  border-radius: 2px;
}
.religon_drop .walkingquestions {
  margin-bottom: 4px;
}
.consultantion-info-table-wrapper .religon_drop .form-control,
.consultantion-info-table-wrapper
  .religon_drop
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 27px !important;
}
.religon_drop
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 26px;
}
.consultantion-info-table-wrapper
  .religon_drop
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 27px !important;
}
.walkin_appnt_wrapper .table-wrapper.maintable {
  margin-top: 22px;
}
body#tinymce {
  color: #404040;
  padding: 6px 8px !important;
  font-family: "Gotham Book" !important;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}
.\[w-25\] {
  width: 25px;
}
.\[w-34\] {
  width: 34px;
}
.\[w-80\] {
  width: 80px;
}
.\[w-90\] {
  width: 90px;
}
.\[w-100\] {
  width: 100px;
}
.\[w-110\] {
  width: 110px;
}
.\[w-140\] {
  width: 140px;
}
.\[w-150\] {
  width: 150px;
}
.\[w-160\] {
  width: 160px;
}
.\[w-180\] {
  width: 180px;
}
.\[w-200\] {
  width: 200px;
}
.\[w-220\] {
  width: 220px;
}
.\[w-250\] {
  width: 250px;
}
.\[w-260\] {
  width: 260px;
}
.\[w-290\] {
  width: 290px;
}
.\[w-11p\] {
  width: 11%;
}
.\[w-15p\] {
  width: 15%;
}

.\[mt-2\] {
  margin-top: 2px;
}
.table-responsive-1100 {
  min-width: 1100px;
}
.tags {
  background: #1445b7;
  padding: 5px 10px 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.patient-email-calc {
  max-width: calc(100% - 29px);
}
.font-weight-normal {
  font-family: "Gotham Book";
}
.inline-calendar .ant-picker-calendar-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.inline-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: auto;
  display: flex;
}
.ant-modal-body
  .inline-calendar
  .ant-radio-group
  .ant-radio-button-wrapper:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-modal-body
  .inline-calendar
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ant-modal-body
  .inline-calendar
  .ant-radio-group
  label.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px !important;
}

.ant-modal-body
  .inline-calendar
  .ant-radio-group
  label.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0 !important;
}
.inline-calendar
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector::after,
.inline-calendar
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.inline-calendar
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 26px;
}
.inline-calendar.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 0;
  padding-left: 0;
}
.bookappoinmentsection .inline-calendar .ant-picker-calendar-header {
  padding: 4px 0 12px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #1445b7;
}
.inline-calendar.ant-picker-calendar-mini .ant-picker-panel {
  width: 100%;
}
.inline-calendar .ant-picker-content th,
.inline-calendar .ant-picker-content td {
  line-height: 40px;
}
.inline-calendar .ant-picker-cell::before {
  height: 40px;
}
.inline-calendar .ant-picker-cell .ant-picker-cell-inner {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}
.inline-calendar
  .ant-picker-month-panel
  .ant-picker-cell
  .ant-picker-cell-inner {
  min-width: 100px;
}
.inline-calendar
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}
.inline-calendar.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 4px;
}
.inline-calendar .ant-picker-month-panel .ant-picker-content {
  height: 224px;
}
.ant-modal-body .inline-calendar .ant-radio-group .ant-radio-button-wrapper {
  padding-block: 5px 2px !important;
  font-size: 13px !important;
}
.appointment-cal-header .ant-drawer-header {
  padding: 12px 15px;
}
.appointment-cal-header .ant-drawer-body {
  padding: 15px;
}

.select-slot-group .ant-radio-group {
  border: 1px solid #e9e9e9;
  padding: 8px 4px;
  margin: 0;
  border-radius: 2px;
}
.popup-text-overflow {
  display: inline-block;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.parent-emain-copy {
  padding-right: 20px;
  position: relative;
}
.parent-emain-copy .ant-typography-copy {
  position: absolute;
  right: 0;
  top: 0;
}
.popup-text-overflow {
  display: inline-block;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.morden-cl-wrapper.inline-calendar {
  width: 100% !important;
  min-height: 352px !important;
}
.inline-calendar.Calendar .Calendar__weekDays {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 13px;
  background-color: transparent;
}
.inline-calendar .Calendar__day {
  font-size: 14px;
  line-height: 38px;
  margin-bottom: 0;
}

.inline-calendar .Calendar__weekRow {
  gap: 4px;
}
.inline-calendar .Calendar__monthSelector,
.inline-calendar .Calendar__yearSelector {
  row-gap: 4px;
}

.inline-calendar .Calendar__monthSelectorAnimationWrapper,
.inline-calendar .Calendar__yearSelectorAnimationWrapper {
  height: calc(100% - 56px);
}
.inline-calendar .Calendar__monthSelectorItemText {
  font-size: 14px;
  line-height: 40px;
  /* border-radius: 2px; */
}
.inline-calendar .Calendar__monthSelectorItem {
  margin-top: 0;
}
.inline-calendar.Calendar .Calendar__yearSelectorItem {
  margin-top: 0;
}
.inline-calendar.Calendar .Calendar__yearSelectorItem button {
  padding: 0;
  line-height: 40px;
  font-size: 14px;
  /* border-radius: 2px; */
}
.inline-calendar .Calendar__monthText,
.inline-calendar .Calendar__yearText {
  margin: 0 2px;
  font-size: 18px;
}
.inline-calendar .Calendar__monthArrow {
  width: 20px;
  height: 20px;
}
.inline-calendar .Calendar__header {
  padding-block: 15px;
}
.inline-calendar .Calendar__sectionWrapper {
  padding-bottom: 20px;
  min-height: 230px !important;
  margin-inline: 5px !important;
  padding-inline: 0 !important;
}
.inline-calendar .Calendar__monthYear button {
  color: #fff;
}
.inline-calendar .Calendar__section {
  padding: 0 !important;
}
.inline-calendar .Calendar__section.-hiddenPrevious {
  transform: translateX(-100%);
}
.inline-calendar .Calendar__section.-hiddenNext {
  transform: translateX(100%);
}
.newpatientdetails .patientnewlistwrap,
.newpatientdetails .newpatientlistdata {
  min-width: 1480px;
}
.custom-tag-dropdown .react-select__control {
  height: auto !important;
  min-height: 28px !important;
  background-color: #f9f9f9 !important;
}
.custom-tag-dropdown
  .react-select__control
  .react-select__value-container.react-select__value-container--is-multi {
  height: auto;
  background-color: transparent !important;
}
.custom-tag-dropdown .react-select__clear-indicator {
  padding: 3px;
}
.custom-tag-dropdown .react-select__multi-value {
  background-color: #1445b7;
  min-height: 22px;
  align-items: center;
  padding-top: 2px;
}
.custom-tag-dropdown .react-select__multi-value__label {
  color: #fff;
}
.custom-tag-dropdown .react-select__multi-value__remove,
.custom-tag-dropdown .react-select__multi-value__remove:hover {
  background-color: transparent;
  color: #fff;
  display: flex;
  margin-top: -2px;
  cursor: pointer;
}
.custom-tag-dropdown .react-select__placeholder {
  color: #bfbfbf;
}
.react-select__control.react-select__control--is-focused,
.react-select__control.react-select__control--menu-is-open {
  box-shadow: 0 0 0 1px #1445b7;
}
.appointment-calendar-popover {
  max-width: 600px;
  width: calc(100% - 30px);
}
.patient-search-min-height {
  min-height: calc(100vh - 140px);
}
.dropdown-title {
  background-color: #e9e9e9;
  padding: 8px 15px !important;
  line-height: 1.42857143;
  font-weight: 500;
  font-family: "Gotham Medium";
  color: #000;
  text-transform: capitalize;
}
.group-title .react-select__group-heading {
  font-size: 100%;
  padding: 0;
}
.group-title .react-select__group  {
  padding-block: 0;
}
.tag-text-wrap .react-select__multi-value__label {
  white-space: normal;
}