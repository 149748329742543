/* Style for the suggestion box */
.rta__autocomplete {
  z-index: 9999;
  font-size: 10px;
}

.rta_loading {
  z-index: 9999;
  font-size: 10px;
}


/* .rta   {
  display: grid;
}
.rta  ::after {
  content: attr(data-replicated-value) " ";

  white-space: pre-wrap;

  visibility: hidden;
}
.rta   > textarea {
  resize: none;

  overflow: hidden;
}
.rta   > textarea,
.rta    a::after {
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  grid-area: 1 / 1 / 2 / 2;
} */

