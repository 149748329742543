.login-wrapper {
  width: 100%;
  min-height: 100vh;
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
   justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f7f7f7; */
}
.login-wrapper-logo {
  /* margin-bottom: 40px; */
  /* margin-bottom: 15px;
  margin-top: 15px; */
  margin-right: 30px;
  margin-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.login-wrapper-logo a {
  display: inline-block;
}
.login-wrapper-logo img {
  max-width: 204px;
}
.login-form-wrapper {
  max-width: 350px;
  width: 100%;
  /* background-color: #ffffff;
  padding: 28px 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,.13); */
  /* box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 18px 8px rgba(0, 0, 0, 0.05); */
}
.password_show {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
}
/* .form-control.is-valid + img {
  right: 32px;
} */
.login-form-wrapper button.btn {
  padding-right: 28px;
  padding-left: 30px;
}
.login-title-wrapper {
  background-color: #f7f7f7;
  margin-bottom: 40px;
  padding-right: 30px;
  padding-left: 30px;
}
.login-title-wrapper .login-title-h1 {
  text-transform: uppercase;
  font-size: 24px;
  margin: 0;
  padding: 30px 0;
  font-family: "Gotham Medium";
}
.login-form-wrapper {
  margin-left: 30px;
}
.signup-form-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 30px;
}
.photo_pic button.ant-btn {
  border: none;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: auto;
  color: #1445b7;
  font-size: 14px;
}
.photo_pic button.ant-btn span {
  text-decoration: underline;
  line-height: 1;
  letter-spacing: -0.2px;
  font-family: Gotham Medium;
}
.ant-select.country-code-dropdown .ant-select-selector {
  width: 60px !important;
  padding: 0 !important;
  border: none !important;
  height: 22px !important;
}
.ant-select.country-code-dropdown
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 22px !important;
  padding-right: 0 !important;
}
.custom-phone-input .seperator {
  display: block;
  height: 100%;
  width: 1px;
  background: #808396;
}
.custom-phone-input input {
  margin-left: 10px;
  outline-style: none;
}
.pwd-input input.ant-input {
  background-color: #f9f9f9;
  padding: 0;
}
.pwd-input input.ant-input:focus,
.pwd-input input.ant-input:hover {
  border: none !important;
  outline: none !important;
}
svg.searchicon {
  left: 14px;
  width: 12px;
}
.postcode-input .ant-select-selector {
  left: -8px;
  padding-left: 8px !important;
  height: 22px !important;
  border: none !important;
}
.personaldetailsmain_wrapper .postcode-input.form-control {
  height: 30px;
}
.personaldetailsmain_wrapper .custom-profile-postcode .ant-select-selector {
  margin-top: 0;
}
.personaldetailsmain_wrapper .postcode-input .ant-select-selector {
  height: 28px !important;
}
.SignupContainer_warpper .postcode-input .ant-select-selector {
  width: calc(100% + 16px) !important;
}
.personaldetailsmain_wrapper .postcode-input .ant-select-selector .ant-select-selection-search-input {
  height: 28px !important;
  padding-top: 2px !important;
}
.personaldetailsmain_wrapper .custom-profile-postcode .ant-select-selector {
  margin-left: 0;
}
.personaldetailsmain_wrapper .postcode-input .ant-select-selector {
  left: 0;
}

.personaldetailsmain_wrapper .postcode-input .ant-select-selector span.ant-select-selection-placeholder {
  padding-top: 2px;
}
.ant-select.postcode-input {
  padding: 0px 8px !important;
}
.postcode-input .ant-select-selector .ant-select-selection-search-input {
  height: 22px !important;
  padding-left: 22px !important;
}
.postcode-input .ant-select-selector span.ant-select-selection-placeholder {
  font-family: Gotham Book;
  line-height: 26px !important;
  padding-left: 24px !important;
}
.postcode-input .ant-select-selector {
  box-shadow: none !important;
  border: none !important;
}
span.ant-select-selection-placeholder {
  font-family: "Gotham Book";
  line-height: 34px !important;
}
.custom-arrow.form-control .ant-select-selector {
  border: none !important;
}
.postcode-input span.ant-select-selection-item {
  padding-left: 24px !important;
  padding-right: 0 !important;
  line-height: 1.6 !important;
}
.add-pateint-form-container .postcode-input span.ant-select-selection-item {
  line-height: 26px !important;
}
.pwd-input input.ant-input,
.country-code-dropdown,
.custom-phone-input input {
  font-size: 11px;
}
.input-sm-28 span.ant-select-selection-placeholder {
  line-height: 26px !important;
}
.ant-input-password.form-control input {
  border: none !important;
  background-color: transparent;
  font-family: "Gotham Book";
  font-size: 11px;
}
.ant-input-password.form-control input.ant-input:focus,
.ant-input-password.form-control input.ant-input:hover {
  background-color: transparent !important;
}
.login-wrapper-logo .row{
  align-items: center;
}