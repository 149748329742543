/* .messages-wrapper {
  padding: 6%;
}
.message-conversations-wrapper {
  padding: 6%;
  width: 100%;
} */
.messages-inner-wrapper {
  display: flex;
}
.messages-inner-wrapper .ant-layout-sider .chat-user-item {
  height: auto !important;
  line-height: 18px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.message-conversations-wrapper {
  width: 100%;
}
.messages-search .ant-input-affix-wrapper input.ant-input {
  border: none !important;
}
.messages-inner-wrapper .ant-layout-sider {
  /* height: calc(100vh - 205px) !important; */
  height: calc(100vh - 216px) !important;
  top: 0;
  border: none;
  position: relative !important;
}
.messages-inner-wrapper {
  margin-top: 15px;
  border: 1px solid #d0d0da;
}
.message-conversations-wrapper .chat-bubble-container {
  /* height: calc(100vh - 240px) !important; */
  height: calc(100vh - 244px) !important;
  /* overflow-y: auto; */
  padding: 15px 10px 15px;
}
.message-conversations-wrapper .chat-bubble-container .chat-left,
.message-conversations-wrapper .chat-bubble-container .chat-right {
  padding: 0px 10px 0px 10px;
  font-size: 10px;
  /* line-height: 1.67; */
  max-width: 70%;
  font-family: "Gotham Book";
  /* border-radius: 8px; 
  display: block;*/
  background-color: transparent;
  margin-top: 10px;
  color: #808396;
  flex-direction: column;
}
.message-conversations-wrapper .chat-bubble-container .chat-left .chat-msg {
  border: solid 1px #d0d0da;
  background-color: #ffffff;
  color: #1d2943;
}
/* .message-conversations-wrapper .chat-bubble-container .chat-right {
  color: #ffffff;
} */
.messages-inner-wrapper .ant-layout-sider .chat-user-item .last-message {
  font-size: 12px;
  color: #015d9b;
  font-style: italic;
  display: block;
  margin-top: 5px;
}
.chat_bottom_part .ant-input-wrapper .ant-input-affix-wrapper {
  padding: 0;
  font-size: 14px;
  border: none !important;
  /* border-top: 1px solid #e9e9e9 !important;
  border-bottom: none;
  border-right: none;
  border-left: none; */
}
.chat_bottom_part .ant-input-wrapper .ant-input-affix-wrapper .ant-input-lg {
  font-size: 14px;
  padding: 8px 8px 8px 0px;
  border: none !important;
}
.chat_bottom_part
  .ant-input-wrapper
  .ant-input-affix-wrapper
  .ant-input-suffix {
  margin-right: 4px;
}
.chat_bottom_part .ant-btn-dangerous {
  padding: 8px 8px;
  height: auto;
}
.chat_bottom_part .ant-input-affix-wrapper-lg .ant-input-suffix label {
  margin: 0 8px 0 0;
  cursor: pointer;
  padding: 6px 13px;
  border-radius: 3px;
  border: solid 1px #d0d0da;
  height: 38px;
}
.messages-wrapper {
  padding-right: 70px;
  padding-left: 70px;
  margin-top: 90px;
}
.chat-user-item .message-user-wrapper img.chat-user-img {
  -webkit-filter: none;
  filter: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.user-groups-wrapper {
  background-color: #f6f6f6 !important;
  border-right: 1px solid #d0d0da;
}
.messages-search {
  padding: 10px 6px 12px;
}
.messages-search span.ant-input-affix-wrapper {
  border-radius: 3px;
  border: 1px solid #d0d0da !important;
}
.message-user-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.message-user-wrapper .message-user-wrapper-right {
  width: 60px;
  text-align: right;
}
.message-user-wrapper .message-user-wrapper-left {
  width: calc(100% - 60px);
}
.message-user-wrapper .message-user-wrapper-right .chat-user-unreadmsgs {
  height: 20px;
  min-width: 20px;
  background-color: #e52c2c;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  font-size: 10px;
  font-family: "Gotham Medium";
}
.message-user-wrapper .message-user-wrapper-right .chat-user-time {
  font-size: 10px;
  margin-left: 8px;
  font-family: "Gotham Book";
}
.message-conversations-userdata img.chat-user-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.message-conversations-userdata {
  padding: 9px 17px;
  border-bottom: 1px solid #d0d0da;
}
.message-conversations-userdata .chat-user-name {
  font-size: 14px;
  color: #1d2943;
  font-family: "Gotham Book";
}
.ant-layout-sider .ant-menu-item.chat-user-item.ant-menu-item-selected,
.messages-inner-wrapper .ant-layout-sider .chat-user-item:hover {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  color: inherit;
  font-family: "Gotham Book";
}
.ant-layout-sider .ant-menu-item.chat-user-item.ant-menu-item-selected:after {
  content: none !important;
}
.messages-inner-wrapper .ant-layout-sider .chat-user-item::before {
  content: "";
  width: calc(100% - 60px);
  display: block;
  height: 1px;
  bottom: 0;
  position: absolute;
  right: 10px;
  border-bottom: 1px solid #d0d0da;
}
.messages-inner-wrapper
  .ant-layout-sider
  .chat-user-item.ant-menu-item-selected::before {
  border: none;
}
.chat_bottom_part {
  padding: 5px 17px;
  border-top: 1px solid #e9e9e9;
}
.chat_bottom_part {
  padding: 5px 17px;
  border-top: 1px solid #e9e9e9;
}
.chat_bottom_part .ant-input-group-addon button.ant-input-search-button {
  width: 135px;
  height: 38px;
}
.chat_bottom_part .ant-input-group-addon {
  left: 0 !important;
}
.message-conversations-wrapper .chat-bubble-container .chat-right::after,
.message-conversations-wrapper .chat-bubble-container .chat-left::after {
  content: none;
}
.message-conversations-wrapper .chat-bubble-container .chat-right span.chat-msg,
.message-conversations-wrapper .chat-bubble-container .chat-left span.chat-msg {
  padding: 6px 10px 6px 10px;
  font-size: 12px;
  line-height: 14px;
  display: block;
  border-radius: 8px;
  word-break: break-word;
}
.message-conversations-wrapper
  .chat-bubble-container
  .chat-right
  span.chat-msg {
  background-color: #1445b7;
  color: #fff;
}
.message-conversations-wrapper .chat-bubble-container .chat-left .chat-date,
.message-conversations-wrapper .chat-bubble-container .chat-right .chat-date {
  margin-top: 5px;
}
.message-conversations-wrapper .chat-bubble-container .chat-right .chat-date {
  display: block;
}
.message-conversations-wrapper .chat-bubble-container .chat-right {
  align-items: flex-end;
}
.msgs_chat_scrolling {
  /* height: calc(100vh - 250px) !important; */
  height: calc(100vh - 260px) !important;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}
.message-user-wrapper .message-user-wrapper-left .chat-user-name {
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.message-user-wrapper .message-user-wrapper-left .chat-user-name .ant-image {
  vertical-align: middle;
}
.message-conversations-wrapper
  .chat-bubble-container
  .chat-rightmspan.chat-msg
  a {
  color: #ffffff;
}
.message-conversations-wrapper
  .chat-bubble-container
  .chat-left
  span.chat-msg
  a {
  color: #1d2943;
}
.message-conversations-wrapper .chat-bubble-container .chat-left a svg,
.message-conversations-wrapper .chat-bubble-container .chat-right a svg {
  border-radius: 8px;
  height: 76px;
  width: 76px;
  padding-bottom: 6px;
}
.message-conversations-wrapper .chat-bubble-container .chat-right a svg {
  background-color: #1445b7;
}
.message-conversations-wrapper .chat-bubble-container .chat-left a svg {
  border: solid 1px #d0d0da;
}
.msgs_chat_scrolling > div {
  overflow-x: hidden !important;
}

.chat_ended_div {
  font-style: italic;
  text-align: center;
  padding-top: 14px;
  background: #80839630;
  height: 50px;
  color: #0000008f;
  font-size: 15px;
}
.no_message {
  text-align: center;
  font-style: italic;
  color: #80839682;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: calc(100vh - 240px) !important;
}
.chat-user-name-side {
  display: -webkit-flex !important;
  display: flex !important;
}

.chat_dropdown {
  max-width: 250px;
  text-align: left;
}

.notification_loader_section {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
.noti-loader-wrapper {
  height: 60px;
  width: 60px;
  z-index: 10;
  -webkit-transition: all 0.3s ease-in-out 0;
  -o-transition: all 0.3s ease-in-out 0;
  transition: all 0.3s ease-in-out 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.chat_bottom_part .ant-input-affix-wrapper:focus,
.chat_bottom_part .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1445b7;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
  z-index: 1;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.chat_popup.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-title {
  max-width: 350px;
  margin-top: 10px !important;
}
.chat_dropdown.custom-arrow {
  height: 40px;
  padding-top: 4px;
}
.chat_status .ant-modal-confirm-title {
  max-width: 100% !important;
}
.height-40-imp {
  height: 40px !important;
}
.messages-inner-wrapper .ant-tag.ant-tag-has-color {
  background: #1445b7 !important;
}
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
