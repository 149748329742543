:root {
  --columns: 4;
}
@media print {
  @page {
    scale: 200;
    height: max-content;
    width: max-content;
  }
}
/* @media print {
  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
} */

/* Embeded fonts */
/* @font-face {
  font-family: bookfont;
  src: url(../fonts/Gotham-Book.otf);
}

@font-face {
  font-family: boldfont;
  src: url(../fonts/Gotham-Bold.otf);
}

@font-face {
  font-family: mediumfont;
  src: url(../fonts/Gotham-Medium.otf);
} */

@import "./antd-overried.css";
@import "./pages/rota.css";
@import "./components/mordernCalendar.css";
@import "~react-modern-calendar-datepicker/lib/DatePicker.css";

@font-face {
  font-family: "Gotham Medium";
  /* font-family: mediumfont; */
  src: url("../fonts/Gotham-Medium.otf");
  /* src: url("../fonts/Gotham-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gotham-Medium.woff2") format("woff2"),
    url("../fonts/Gotham-Medium.woff") format("woff"),
    url("../fonts/Gotham-Medium.svg#Gotham-Medium") format("svg");
   font-weight: 350; */
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "Gotham Book";
  /* font-family: bookfont; */
  src: url("../fonts/Gotham-Book.otf");
  /* src: url("../fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gotham-Book.woff2") format("woff2"),
    url("../fonts/Gotham-Book.woff") format("woff"),
    url("../fonts/Gotham-Book.svg#Gotham-Book") format("svg");
   font-weight: 325; */
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "Gotham Bold";
  /* font-family: boldfont; */
  src: url("../fonts/Gotham-Bold.otf");
  /* src: url("../fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/Gotham-Bold.woff") format("woff"),
    url("../fonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
   font-weight: 400; */
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
/* Embeded fonts */

/* Common CSS Start*/
body {
  font-family: "Gotham Book";
  /* font-family: bookfont !important; */
  color: #5d5d5d;
  line-height: 1.4 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham Book";
  color: #5d5d5d;
}
input,
textarea,
button,
select {
  font-family: "Gotham Book";
  color: #5d5d5d;
}
ul,
li {
  margin: 0;
  padding: 0;
}
a {
  color: #5d5d5d;
}
a:hover {
  text-decoration: none;
  color: #5d5d5d;
}
p {
  margin: 0;
}
::selection {
  background: #29abe1;
  color: #fff;
}
::-moz-selection {
  background: #29abe1;
  color: #fff;
}
.form-control::-webkit-input-placeholder {
  /* Chrome */
  color: #adb2b4;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #adb2b4;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #adb2b4;
  opacity: 1;
}
.form-control:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #adb2b4;
  opacity: 1;
}

/*Font weight classes*/
.font-weight-400,
.weight-400 {
  font-family: "Gotham Book";
}
.font-weight-500,
.weight-500 {
  font-family: "Gotham Medium";
}
.font-weight-700,
.weight-700 {
  font-family: "Gotham Bold";
}

/*Font size classes*/
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px;
}
.font-12-imp {
  font-size: 12px !important;
}
.font-13-imp {
  font-size: 12px !important;
}
.font-13 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-14-imp {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-34 {
  font-size: 34px;
}
.font-36 {
  font-size: 36px;
}
.font-38 {
  font-size: 38px;
}
.font-40 {
  font-size: 40px;
}
.font-42 {
  font-size: 42px;
}
.font-44 {
  font-size: 44px;
}
.font-46 {
  font-size: 46px;
}
.font-48 {
  font-size: 48px;
}
.font-50 {
  font-size: 50px;
}
.font-54 {
  font-size: 54px;
}

/* Font color clsses */
.text-fff {
  color: #fff;
}
.text-sky {
  color: #29abe1;
}
.text-light-green {
  color: #4cdb93;
}
.text-green {
  color: #009a22;
}
.text-red {
  color: #ea5353;
}
.text-light-gray {
  color: #f4f6f8;
}
.text-gray {
  color: #adadad;
}
.text-black {
  color: #121212;
}
.text-dark-gray {
  color: #757575;
}
.text-light-black {
  color: #404040;
}
.text-light-black-imp {
  color: #404040 !important;
}
.text-blue {
  color: #1445b7;
}
.text-blue-imp {
  color: #1445b7 !important;
}
.text-dark-blue {
  color: #1445b7;
}
.text-light-blue {
  color: #29abe2 !important;
}
.text-light-red {
  color: #ff5964;
}
.pink-text {
  color: #fe8d8d;
}
.text-link-blue {
  color: #1445b7 !important;
}
.text-underline {
  text-decoration: underline;
}
.text-high-dark-blue {
  color: #1445b7;
}
.text-high-dark-blue-imp {
  color: #1445b7 !important;
}

/* Bg color classes */
.bg-gray {
  background-color: #f9f9f9;
}
.bg-lightblue {
  background-color: #29abe2;
}
.bg-blue {
  background-color: #1445b7;
}
.bg-yellow {
  background-color: #ffaf1b;
}
.bg-green {
  background-color: #43dc91;
}
.bg-dark-blue {
  background-color: #1445b7;
}
.no-background {
  background: none;
}

/* Line height classes */
.normal-lineheight {
  line-height: normal;
}
.line-1 {
  line-height: 1;
}
.line-1-imp {
  line-height: 1 !important;
}
.line-1-2 {
  line-height: 1.2;
}
.line-1-33 {
  line-height: 1.33;
}
.line-1-43 {
  line-height: 1.43;
}
.line-1-5 {
  line-height: 1.5;
}
.line-1-75 {
  line-height: 1.71;
}
.line-2 {
  line-height: 2;
}
.line-height-30px-imp {
  line-height: 30px !important;
}

/*margin classes*/
.margin-0 {
  margin: 0 !important;
}
.margin-rl-auto {
  margin-right: auto;
  margin-left: auto;
}
/* top classes */
.top--1 {
  top: -1px;
}

/* margin-top classes*/
.margin-top--1 {
  margin-top: -1px;
}
.margin-top--2 {
  margin-top: -2px;
}
.margin-top--4 {
  margin-top: -4px;
}
.margin-top--5 {
  margin-top: -5px;
}
.margin-top--5-imp {
  margin-top: -5px !important;
}
.margin-top--10 {
  margin-top: -10px;
}
.margin-top--10-imp {
  margin-top: -10px !important;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-top-2 {
  margin-top: 2px;
}
.margin-top-3 {
  margin-top: 3px;
}
.push-5-t {
  margin-top: 5px;
}
.margin-top-6 {
  margin-top: 6px;
}
.margin-top-8 {
  margin-top: 8px;
}
.push-10-t {
  margin-top: 10px;
}
.margin-top-13 {
  margin-top: 13px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-16 {
  margin-top: 16px;
}
.push-20-t {
  margin-top: 20px;
}
.margin-top-25 {
  margin-top: 25px;
}
.push-30-t {
  margin-top: 30px;
}
.margin-top-35 {
  margin-top: 35px;
}
.push-36-t {
  margin-top: 36px;
}
.push-40-t {
  margin-top: 40px;
}
.push-45-t {
  margin-top: 45px;
}
.push-50-t {
  margin-top: 50px;
}
.push-55-t {
  margin-top: 55px;
}
.push-60-t {
  margin-top: 60px;
}
.push-55-t {
  margin-top: 55px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-top-75 {
  margin-top: 75px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-top-95 {
  margin-top: 95px;
}
.margin-top-105 {
  margin-top: 105px;
}

/*margin-bottom classes*/
.push--30-b-imp {
  margin-bottom: -30px !important;
}
.push--5-b {
  margin-bottom: -5px;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.push-3-b {
  margin-bottom: 3px;
}
.push-5-b {
  margin-bottom: 5px;
}
.margin-bottom-6 {
  margin-bottom: 6px !important;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.push-10-b,
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.push-20-b {
  margin-bottom: 20px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.push-30-b {
  margin-bottom: 30px;
}
.push-40-b {
  margin-bottom: 40px;
}
.push-45-b {
  margin-bottom: 45px;
}
.push-50-b {
  margin-bottom: 50px;
}
.push-60-b {
  margin-bottom: 60px;
}
.margin-bottom-65 {
  margin-bottom: 65px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-bottom-95 {
  margin-bottom: 95px;
}

/*margin right classes*/
.margin-right-0-imp {
  margin-right: 0px !important;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-12 {
  margin-right: 12px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-right-45 {
  margin-right: 45px;
}
.margin-right-60 {
  margin-right: 60px;
}
.push-3-r {
  margin-right: 3px;
}
.push-5-r {
  margin-right: 5px;
}
.push-10-r {
  margin-right: 10px;
}

/*margin left classes*/
.margin-left-auto {
  margin-left: auto;
}
.push-2-l {
  margin-left: 2px;
}
.push-3-l {
  margin-left: 3px;
}
.push-n-5-l {
  margin-left: -5px;
}
.push-5-l {
  margin-left: 5px;
}
.push-8-l {
  margin-left: 8px;
}
.push-10-l {
  margin-left: 10px;
}
.push-12-l {
  margin-left: 12px;
}
.margin-left-15 {
  margin-left: 15px;
}
.push-20-l {
  margin-left: 20px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-35 {
  margin-left: 35px;
}
.margin-left-45 {
  margin-left: 45px;
}
.push-10-lp {
  margin-left: 10%;
}

/* Padding classes */
.padding-right-left-10 {
  padding-right: 10px;
  padding-left: 10px;
}
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-5-imp {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px;
}

/*Padding top Classes*/
.padding-3-t {
  padding-top: 3px;
}
.padding-10-t {
  padding-top: 10px;
}
.padding-3-t {
  padding-top: 3px;
}
.padding-5-t {
  padding-top: 5px;
}
.padding-top-12,
.padding-12-t {
  padding-top: 12px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-20-t {
  padding-top: 20px;
}
.padding-top-25 {
  padding-top: 25px;
}
.padding-30-t {
  padding-top: 30px;
}
.padding-37-t {
  padding-top: 37px;
}
.padding-40-t {
  padding-top: 40px;
}
.padding-44-t {
  padding-top: 44px;
}
.padding-45-t {
  padding-top: 45px;
}
.padding-50-t {
  padding-top: 50px;
}
.padding-55-t {
  padding-top: 55px;
}
.padding-60-t {
  padding-top: 60px;
}
.padding-70-t {
  padding-top: 70px;
}
.padding-top-75 {
  padding-top: 75px;
}
.padding-top-90 {
  padding-top: 90px;
}
.padding-top-100 {
  padding-top: 100px;
}
.padding-top-120 {
  padding-top: 120px;
}
.padding-top-130 {
  padding-top: 130px;
}
.padding-top-140 {
  padding-top: 140px;
}

/* Padding bottom classes */
.padding-7-5-r-imp {
  padding-right: 7.5px !important;
}
.padding-1-b {
  padding-bottom: 1px;
}
.padding-3-b {
  padding-bottom: 3px;
}
.padding-5-b {
  padding-bottom: 5px;
}
.padding-10-b {
  padding-bottom: 10px;
}
.padding-15-b {
  padding-bottom: 15px;
}
.padding-20-b {
  padding-bottom: 20px;
}
.padding-30-b {
  padding-bottom: 30px;
}
.padding-40-b {
  padding-bottom: 40px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-60 {
  padding-bottom: 60px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}
.padding-bottom-80 {
  padding-bottom: 80px;
}
.padding-bottom-90 {
  padding-bottom: 90px;
}
.padding-bottom-120 {
  padding-bottom: 120px;
}
.padding-bottom-130 {
  padding-bottom: 130px;
}
.padding-bottom-140 {
  padding-bottom: 140px;
}
.padding-bottom-144 {
  padding-bottom: 144px;
}
.padding-bottom-160 {
  padding-bottom: 160px;
}
.padding-bottom-210 {
  padding-bottom: 210px;
}
.padding-bottom-230 {
  padding-bottom: 230px;
}
.padding-bottom-300 {
  padding-bottom: 300px;
}
.padding-bottom-307 {
  padding-bottom: 307px;
}
.padding-bottom-400 {
  padding-bottom: 400px;
}

/*Padding right classes*/
.padding-right-0 {
  padding-right: 0px;
}
.padding-0-r-imp {
  padding-right: 0 !important;
}
.padding-7-5-l-imp {
  padding-left: 7.5px !important;
}
.padding-5-r {
  padding-right: 5px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-20-r,
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-25 {
  padding-right: 25px;
}
.padding-right-30 {
  padding-right: 30px;
}
.padding-right-32 {
  padding-right: 32px !important;
}
.padding-right-40 {
  padding-right: 40px;
}
.padding-right-55 {
  padding-right: 55px;
}
.padding-right-75 {
  padding-right: 75px;
}

/*Padding left classes*/
.padding-left-0-imp {
  padding-left: 0 !important;
}
.padding-7-5-l-imp {
  padding-left: 7.5px !important;
}
.padding-left-0 {
  padding-left: 0px;
}
.padding-0-l-imp {
  padding-left: 0 !important;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-left-10 {
  padding-left: 10px;
}
.padding-left-15 {
  padding-left: 15px;
}
.padding-left-18 {
  padding-left: 18px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-30 {
  padding-left: 30px;
}
.padding-left-35 {
  padding-left: 35px;
}
.padding-left-40 {
  padding-left: 40px;
}
.padding-left-63 {
  padding-left: 63px;
}

/* Alignment classes */
.vertical-top {
  vertical-align: top !important;
}
.vertical-sub {
  vertical-align: sub;
}
.vertical-text-bottom {
  vertical-align: text-bottom;
}
.vertical-bottom {
  vertical-align: bottom;
}
.vertical-middle {
  vertical-align: middle;
}
.vertical-top {
  vertical-align: top !important;
}

/* Width classes */
.width-18px {
  width: 18px;
}
.width-0_5 {
  width: 0.5%;
}
.width-1 {
  width: 1%;
}
.width-2 {
  width: 2%;
}
.width-4 {
  width: 4%;
}
.width-5 {
  width: 5%;
}
.width-7 {
  width: 7%;
}
.width-8 {
  width: 8%;
}
.width-9 {
  width: 9%;
}
.width-10 {
  width: 10%;
}
.width-12 {
  width: 12%;
}
.width-11 {
  width: 11%;
}
.width-13 {
  width: 13%;
}
.width-14 {
  width: 14%;
}
.width-15 {
  width: 15%;
}
.width-16 {
  width: 16%;
}
.width-17 {
  width: 17%;
}
.width-18 {
  width: 18%;
}
.width-19 {
  width: 19%;
}
.width-20 {
  width: 20%;
}
.width-21 {
  width: 21%;
}
.width-22 {
  width: 22%;
}
.width-24 {
  width: 24%;
}
.width-25 {
  width: 25%;
}
.width-26 {
  width: 26%;
}
.width-27 {
  width: 27%;
}
.width-28 {
  width: 28%;
}
.width-29 {
  width: 29%;
}
.width-30 {
  width: 30%;
}
.min-width-30 {
  min-width: 30%;
}
.min-width-180px {
  min-width: 180px;
}
.min-table-w-1280 {
  min-width: 1280px;
}
.min-width-200px {
  min-width: 200px !important;
}
.\!min-width-300px {
  min-width: 300px !important;
}
.width-31 {
  width: 31%;
}
.width-32 {
  width: 32%;
}
.width-33 {
  width: 33%;
}
.width-35 {
  width: 35%;
}
.width-37 {
  width: 37%;
}
.width-40 {
  width: 40%;
}
.width-42 {
  width: 42%;
}
.width-45 {
  width: 45%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-61 {
  width: 61%;
}
.width-68 {
  width: 68%;
}
.width-70 {
  width: 70%;
}
.width-73 {
  width: 73%;
}
.width-75 {
  width: 75%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-80px {
  width: 80px;
}
.width-70px {
  width: 70px;
}
.width-100px {
  width: 100px;
}
.width-105 {
  width: 105px;
}
.width-100-p {
  width: 100%;
}
.width-124 {
  width: 124px;
}
.width-130 {
  width: 130px;
}
.width-180 {
  width: 180px;
}
.width-190 {
  width: 190px;
}
.width-324 {
  width: 324px;
}
.width-220px {
  width: 220px;
}
.width-255px {
  width: 255px;
}
.width-30px {
  width: 30px;
}
.width-60px {
  width: 60px;
}
.width-115px {
  min-width: 115px;
}
.width-136px {
  width: 136px;
}
.width-161px {
  width: 161px;
}
.width-30-minus-5 {
  width: calc(30% - 5px);
}
.width-391px {
  width: 391px;
}
.width-850px {
  width: 850px !important;
}
.max-width-630px {
  max-width: 630px;
}
.width-1000 {
  width: 1000px !important;
}

.word-break {
  word-break: break-word;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-0 {
  opacity: 0;
}
.bg_gray_opacity {
  background: rgba(247, 247, 247, 0.6);
}
.bg-f9f9f9 {
  background-color: #f9f9f9;
}
.input-bg-f9f9f9,
.input-bg-f9f9f9 .ant-select-selector {
  background-color: #f9f9f9 !important;
}
.form-control.input-bg-f9f9f9.custom-input[readonly] {
  background-color: #f9f9f9 !important;
}
.medical_deselected {
  opacity: 0.5;
}

/* height css */
.height-15 {
  height: 15px;
}
.height-36-imp {
  height: 36px !important;
}
.height-68 {
  height: 68px;
}
.height-auto {
  height: auto;
}
.height-auto-imp {
  height: auto !important;
}
.input-sm-30 {
  height: 30px;
}
.min-height-26-imp {
  min-height: 26px !important;
}
.min-height-28px-imp {
  min-height: 28px !important;
}
.height-180 {
  height: 180px;
}
.height-40px-imp {
  height: 40px !important;
}
.input-sm-30.ant-select-multiple .ant-select-selector,
.input-sm-30.ant-select-multiple {
  height: auto !important;
  min-height: 30px;
  line-height: 1.4;
  padding-top: 0;
  padding-bottom: 0;
}

/* Button css */
/* Radio button Css */
md-radio-group.md-default-theme.md-focused:not(:empty)
  .md-checked.md-primary
  .md-container:before,
md-radio-group.md-focused:not(:empty)
  .md-checked.md-primary
  .md-container:before,
md-radio-group.md-default-theme.md-focused:not(:empty).md-primary
  .md-checked
  .md-container:before,
md-radio-group.md-focused:not(:empty).md-primary
  .md-checked
  .md-container:before {
  background-color: transparent;
}
.normal-radio md-radio-button {
  display: inline-block;
  margin-right: 20px;
}
.normal-radio md-radio-button .md-label {
  font-size: 14px;
  font-family: "Gotham Book";
}
.normal-radio md-radio-button .md-off {
  width: 18px;
  height: 18px;
  border-width: 1px;
  top: 1px;
}
.normal-radio md-radio-button .md-on {
  width: 18px;
  height: 18px;
  top: 1px;
  background-color: #1445b7 !important;
}
.normal-radio md-radio-button.md-checked .md-off {
  border-color: #1445b7 !important;
}
.custom_radio .btn-custom {
  border-radius: 2px;
}
.custom_radio .btn-custom:first-child {
  border-radius: 2px 0px 0px 2px;
}
.custom_radio .btn-custom:last-child {
  border-radius: 0px 2px 2px 0px;
}
.custom_radio .btn-custom:second-child {
  border-radius: 0px;
}

/* Button css */
.go-back-icon {
  position: absolute;
  left: -13%;
  top: -2px;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
}
.btn-custom {
  height: 40px;
  border-radius: 2px !important;
  font-size: 12px;
  line-height: 1.43;
  padding: 10px;
  font-family: "Gotham Medium";
  letter-spacing: -0.2px;
}
a.btn-custom.height-big {
  padding: 15px;
}
.custom-alert p.margin-0 {
  margin: 0px !important;
}
.btn-custom:hover,
.btn-custom:active,
.btn-custom:focus {
  outline: none !important;
  box-shadow: none;
}
.btn-custom.btn-white {
  background-color: #ffffff;
  border: solid 1px #e9e9e9;
  color: #404040;
}
.btn-custom.btn-white:hover,
.btn-custom.btn-white:active,
.btn-custom.btn-white:focus {
  background-color: #ffffff;
  color: #404040;
  box-shadow: none;
}
.btn-custom.btn-blue {
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
  line-height: normal;
}
.btn-custom.btn-blue[disabled] {
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
  /* opacity: 1; */
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.btn-custom.btn-blue[disabled]:hover {
  background-color: #1445b7;
  color: #fff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #1445b7;
}
.ant-btn.btn-custom.btn-blue:hover[disabled] span {
  color: #fff !important;
}
.btn-custom.sm-commonbtn {
  width: 100px;
  height: 30px;
  padding: 0;
}
.btn-custom.btn-light-blue {
  background-color: #e8efff;
  border: solid 1px #1445b7;
  color: #1445b7;
}
.btn-custom.btn-light-blue.show-more-btn {
  width: 125px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(226, 244, 255, 0.4);
  border: solid 1px #1445b7;
  color: #1445b7;
  font-family: "Gotham Book";
}
.ant-btn.btn-green, .btn-custom.btn-green {
  background-color: #43dc91;
  border: solid 1px #43dc91;
  color: #fff;
}
.btn-custom.btn-gray {
  background-color: #f9f9f9;
  border: solid 1px #f9f9f9;
  color: #404040;
}
.btn-custom.btn-gray {
  background-color: #f9f9f9;
  border: solid 1px #f9f9f9;
  color: #404040;
}
.btn-custom.btn-darkgray {
  background-color: #e9e9e9;
  border: solid 1px #e9e9e9;
  color: #757575;
}
.btn-custom.btn-darkgray:hover,
.btn-custom.btn-darkgray:focus {
  background-color: #b4b4b4;
  border: solid 1px #b4b4b4;
  color: #747474;
}
.btn-custom.pick_btn {
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
}
.btn-custom.ship_btn {
  background-color: #ffaf1b;
  border: solid 1px #ffaf1b;
  color: #fff;
}
.btn-custom.dispense_btn {
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
}
.btn-custom.shipped_btn {
  background-color: #43dc91;
  border: solid 1px #43dc91;
  color: #fff;
}
.btn-custom.btn-danger {
  background-color: #ff5964;
  border: solid 1px #ff5964;
  color: #fff;
}
.btn-custom.btn-dark-gray {
  background-color: #e9e9e9;
  border: solid 1px #e9e9e9;
  color: #404040;
}
.btn-custom.btn-danger:hover,
.btn-custom.btn-danger:focus,
.btn-custom.btn-danger:active {
  background-color: #f8404c;
  border: 1px solid #f8404c;
  color: #fff;
}
.btn-green.ant-btn[disabled] {
  background-color: #43dc91 !important;
  border: solid 1px #43dc91 !important;
  color: #fff;
}
/* .btn-custom.btn-blue:hover,
.btn-custom.btn-blue:active,
.btn-custom.btn-blue:focus {
  background-color: #1445B7;
  color: #fff;
  box-shadow: none;
} */
.btn-custom.btn-blue:hover,
.btn-custom.btn-blue:active,
.btn-custom.btn-blue:focus {
  background-color: transparent;
  color: #1445b7 !important;
  box-shadow: none;
  border-color: #1445b7;
}
.ant-btn.btn-green:hover,
.ant-btn.btn-green:active,
.ant-btn.btn-green:focus,
.btn-custom.btn-green:hover,
.btn-custom.btn-green:active,
.btn-custom.btn-green:focus {
  background-color: #0fa538 !important;
  color: #fff !important;
  box-shadow: none !important;
  border-color: #0fa538 !important;
}
.btn-custom.width-100 {
  width: 100%;
}
.btn-custom.width-255 {
  width: 255px;
}
.btn-custom.width-200 {
  width: 200px;
}
.btn-custom.width-193 {
  width: 193px;
}
.btn-custom.width-104 {
  width: 104px;
}
.btn-custom.width-136 {
  width: 136px;
}
.btn-custom.width-120 {
  width: 120px;
}
.btn-custom.width-115 {
  width: 115px;
}
.btn-custom.max-width-124 {
  max-width: 124px;
}
.btn-custom.height-big {
  margin: 0;
  height: 48px;
}
.no-focus:focus {
  outline: none;
  box-shadow: none;
  border: solid 1px #e9e9e9;
}
.small-btn {
  padding-right: 8px;
  padding-left: 8px;
}

/* Hover css */
.hover-blue:hover {
  color: #1445b7;
}
.hover-dark-blue:hover {
  color: #1445b7;
}
.text-decoration-hover:hover {
  text-decoration: underline;
}

/* Border css */
.border {
  border: 1px solid #eeeeee !important;
}
.border-top {
  border-top: 1px solid #eeeeee;
}
.border-bottom {
  border-bottom: 1px solid #eeeeee !important;
}
.border-right {
  border-right: 1px solid #eeeeee;
}
.border-left {
  border-left: 1px solid #eeeeee;
}
.border-bottom-dash {
  border-bottom: 2px dashed #eeeeee;
}
.border-bottom-none-imp {
  border-bottom: none !important;
}
.noborder {
  border: none !important;
}

/* position css */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

/* display css */
.inline {
  display: inline;
}
.in-block {
  display: inline-block;
}
.dis-block {
  display: block;
}
.dis-block-imp {
  display: block !important;
}
.dis-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.dis-table {
  display: table;
  width: 100%;
}
.cell {
  display: table-cell;
  vertical-align: middle;
}
.prelinetext {
  white-space: pre-line;
}

/* pagination css */
.pagination {
  margin-top: 30px;
  flex-wrap: wrap;
}
.pagination .page-item {
  margin-right: 12px;
  margin-bottom: 8px;
}
.pagination .page-item:last-child {
  margin-right: 0;
}
.pagination .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  border-radius: 3px;
  background-color: #eceff1;
  border: 1px solid #eceff1;
  color: #404040;
  font-size: 12px;
  font-family: "Gotham Medium";
}
.pagination .page-item.active .page-link {
  z-index: 1;
}
.pagination .page-item.active .page-link,
.pagination .page-item .page-link:hover {
  background-color: #1445b7;
  border: 1px solid #1445b7;
  color: #ffffff;
}
.pagination li.page-item:last-child .page-link,
.pagination li.page-item:first-child .page-link {
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: 0;
}
.pagination li.page-item:first-child .page-link {
  background-image: url(../images/common/left-gray.png);
}
.pagination li.page-item:last-child .page-link {
  background-image: url(../images/common/left-gray.png);
  transform: rotate(180deg);
}

/* container css starts */
.container-850 {
  width: 850px;
  margin: 0 auto;
}
/* container css ends */

/* form css */
form {
  margin: 0;
}
.form-control {
  font-size: 14px;
  height: 48px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e9e9e9 !important;
  box-shadow: none;
  color: #404040;
  padding: 6px 8px;
  font-family: "Gotham Book";
}
.form-group {
  margin-bottom: 20px;
}
.input-sm-28 {
  height: 28px !important;
}
.input-sm-32 {
  height: 32px;
}
.input-sm-30 {
  height: 30px;
}
.form-control.custom-input[readonly] {
  background-color: #fff !important;
}
.border-box {
  border: solid 1px #e9e9e9 !important;
  padding: 5px 10px !important;
}
.form-control:focus {
  outline: none !important;
  border: solid 1px #1445b7 !important;
  box-shadow: none;
}
.form-control:disabled {
  background-color: #f9f9f9 !important;
  border: 1px solid #e9e9e9 !important;
}

/* breadcrumb css */
.ant-breadcrumb * {
  line-height: 1;
  vertical-align: middle;
  font-size: 13px;
}
.ant-breadcrumb .anticon {
  font-size: 16px;
  color: #1445b7;
}
nav.breadcrub_wrapper {
  margin-top: 10px;
  padding: 6px 10px 12px;
  margin-bottom: 10px;
  background-color: rgba(22, 164, 250, 0.2);
  border-radius: 3px;
}
nav.breadcrub_wrapper .ant-breadcrumb .ant-breadcrumb-link {
  cursor: pointer;
}
.padding-25-t {
  padding-top: 25px;
}
.search-highlighter {
  color: #1445b7;
  background: #e3f5ff;
  font-family: "Gotham Bold";
}
.font-size-mini {
  font-size: 10px;
}
.appointment-btn {
  background: #43dc91 !important;
  margin-right: 5px !important;
  color: #fff !important;
}
.validate-danger {
  color: #a94442;
  margin-bottom: 0px;
  display: block;
  margin-top: 5px;
  font-size: 10px;
  font-family: "Gotham Book";
}
.gray-tag {
  color: #757575;
  background-color: #e9e9e9;
  display: inline-block;
  padding: 0px 8px;
}
.blue-tag {
  background: #29abe2;
  color: #ffffff;
  padding: 0px 8px;
}
.white-tag {
  color: #fff !important;
  border: 1px solid #fff !important;
  background-color: transparent !important;
}
.lightblue-tag,
.ant-modal-body label.lightblue-tag {
  background-color: #e8efff;
  color: #1445b7;
  padding: 0 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-normal {
  text-transform: none;
}

span.ant-select-selection-item > span > mark {
  background: none;
  font-weight: 300;
}

.report-user img {
  /* max-width: 40px;
  height: 40px; */
  width: 100%;
  border-radius: 100%;
}
/* .ant-upload-list {
  display: none;
} */

.display-none {
  display: none;
}
@import "./antd-overried.css";
@import "./pages/rota.css";
@import "./components/mordernCalendar.css";
@import "~react-modern-calendar-datepicker/lib/DatePicker.css";

.buttonSeperator.cancelButton {
  display: none;
}
.user-profile-image {
  border-radius: 100%;
}
.border-radius-50 {
  border-radius: 50%;
}
.profile-head {
  display: flex;
  align-items: center;
}

.logout-link {
  font-size: 20px;
  margin-top: 4px;
}

.user-profile-image {
  border-radius: 100%;
}

.profile-head .ant-upload-list-item {
  display: none;
}

.document-container {
  border: 1px solid #d9d9d9;
  padding: 10px 3px 10px 10px;
  min-height: 120px;
  display: flex;
  flex-wrap: wrap;
}

.input-textarea {
  min-height: 100px !important;
}

.field-status {
  display: flex;
  align-items: center;
}

.form-error {
  font-size: 12px;
  color: #ff000088;
  font-weight: 400;
}

.form-success {
  font-size: 12px;
  color: #00ff0088;
  font-weight: 400;
}

.verification-badge {
  margin-left: auto;
}

.doc-single img {
  max-width: 100%;
  height: 60px;
}

.doc-badge {
  padding: 2px 5px;
  text-align: center;
  font-weight: bold;
  margin: 0;
  background-color: #43dc91;
  color: #fff;
  font-size: 10px;
}
/* placeholer css starts */
::-webkit-input-placeholder {
  color: #adb2b4 !important;
  font-family: "Gotham Book";
  opacity: 1;
}
.placeholder-10::-webkit-input-placeholder,
.placeholder-10 ::-webkit-input-placeholder {
  font-size: 10px;
}
::-moz-placeholder {
  color: #adb2b4 !important;
  font-family: "Gotham Book";
  opacity: 1;
}
:-ms-input-placeholder {
  color: #adb2b4 !important;
  font-family: "Gotham Book";
  opacity: 1;
}
:-moz-placeholder {
  color: #adb2b4 !important;
  font-family: "Gotham Book";
  opacity: 1;
}
.custom-dropdown-spinner {
  position: absolute;
  right: 17px;
}
.color-red {
  font-size: 12px;
}
.danger-border {
  border: solid 1px #a94442 !important;
}

.chat-bubble-container {
  display: flex;
  flex-direction: column;
}

.chat-bubble-right {
  display: flex;
  align-self: flex-end;
}
/* .indication-dropdown > .ant-select-arrow {
    display: none;
} */
/* placeholer css ends */

.form-error {
  font-size: 12px;
  color: #ff000088;
  font-weight: 400;
}

.verification-badge {
  margin-left: auto;
}

.doc-single {
  padding: 4px;
  width: 33%;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
}
.validate-danger.font-14 {
  font-size: 14px !important;
}
.prescription-modal-container {
  display: flex;
}
.prescription-form {
  width: 62%;
}

#report_table .ant-table-row,
#questionnaire_table .ant-table-row {
  cursor: pointer;
}
label.font-12 {
  font-size: 12px !important;
}
.custom-input[disabled] {
  background-color: #f9f9f9 !important;
  border: 1px solid #d9d9d9 !important;
}
.personaldetailsmain .profile-info-box {
  min-height: 120px;
  max-height: 120px;
  overflow-y: auto;
}
.doc-single.upload_img_box {
  border: 1px solid #e9e9e9;
  padding: 7px 7px 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  width: calc(25% - 7px);
  position: relative;
  /* height: 102px; */
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 13px;
}
.doc-single.upload_img_box img {
  width: 100%;
  height: 100%;
  max-height: 102px;
  object-fit: cover;
}
.input-sm-28 .ant-select-selector,
.input-sm-28 .ant-select-selection-search-input {
  height: 26px !important;
}
.input-sm-30 .ant-select-selector,
.input-sm-30 .ant-select-selection-search-input {
  height: 30px !important;
}
.font-11 .ant-picker-input > input {
  font-size: 11px;
}
.input-sm-30 .ant-picker-input > input {
  height: 20px;
}
.lb-container {
  left: 354px !important;
  top: 153px !important;
  width: 60% !important;
  height: 60% !important;
}
.document-container .certificate-single img {
  max-width: 100%;
  margin-bottom: 15px;
}

@import "./antd-overried.css";
@import "./pages/rota.css";
@import "./pages/videoCallSetup.css";
@import "./components/mordernCalendar.css";
@import "~react-modern-calendar-datepicker/lib/DatePicker.css";
@import "~react-awesome-lightbox/build/style.css";

/* //video call css÷ */
.border-none {
  border: none !important;
}
.ant-result-icon {
  margin-top: 10%;
}
.d-inline-flex {
  display: inline-flex;
}

.custom-label.warning {
  background-color: rgba(252, 248, 227, 0.08);
  border: 1px solid #8a6d3b;
  color: #8a6d3b;
}
.custom-label.danger {
  background-color: rgba(255, 89, 100, 0.08);
  border: 1px solid #ff5964;
  color: #ff5964;
}
.custom-label {
  height: 20px;
  border-radius: 3px;
  padding: 2px 8px;
  align-items: center;
  font-size: 10px;
  text-align: center;
  font-family: "Gotham Medium";
}
.custom-label.private_medication {
  background-color: #43dc91;
  border: 1px solid #43dc91;
  color: #fff;
}
.Calendar .Calendar__section.-shown,
.Calendar .Calendar__weekDays {
  position: relative;
  z-index: 1;
}
/* search with filter css starts */
.search-filter-downdown-input .input-suffix {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 30px;
  text-align: center;
  top: 5px;
}
.search-with-filterdropdown {
  position: relative;
  font-family: "Gotham Book" !important;
}
.search-filter-downdown-input {
  position: relative;
}
.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input {
  width: calc(100% - 50px);
}
.search-filter-downdown-input .input-suffix .anticon {
  font-size: 12px;
  color: #757575;
}
.search-filter-downdown-input .ant-input-prefix {
  margin-right: 10px;
}
.search-filter-downdown-input .ant-input-prefix {
  font-size: 15px;
  color: #c4c4c4;
}
.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input:focus,
.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input:hover {
  border: none !important;
}

.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input {
  line-height: 23px;
}
.search-filter-downdown-content {
  margin-top: 10px;
  padding: 20px;
}
.search-filter-downdown-content .ant-input-number {
  width: 100%;
}
.font-13-imp .ant-select-item-option-content {
  font-size: 13px;
}
.search-filter-downdown-content {
  -webkit-box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  border-radius: 0.25rem;
  z-index: 1;
  position: absolute;
  top: 100%;
  display: none;
  bottom: auto;
  opacity: 0;
  width: 120%;
  right: 0;
  left: auto;
}
.search-filter-downdown-content.search-show {
  display: block;
  opacity: 1;
  z-index: 2;
}
.refresh_wrapper, .refresh_wrapper:hover {
  color: #1445b7;
}
.refresh_wrapper:hover img {
  /* filter: brightness(4); */
}
.refresh_wrapper img,
.refresh_wrapper:hover img {
  transition: filter 0.3s;
}

.slick-slider,
.slick-initialized {
  display: block !important;
}
.search-filter-downdown-content .optionContainer li {
  padding: 5px;
  font-size: 12px;
}
/* search with filter css ends */

button.clear_btn {
  padding: 0;
  position: absolute;
  z-index: 2;
  right: 13px;
  top: 9px;
  color: #1445b7;
  border: none;
  background-color: transparent;
  margin-right: 10px;
  font-size: 12px;
}
button.clear_btn:focus {
  outline: none !important;
}

.search-filter-downdown-input br {
  display: none;
}
.search-filter-downdown-input .ant-input-prefix {
  padding-top: 24px;
  margin: 0;
  width: 100%;
}
.search-filter-downdown-input .ant-input-prefix div {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
.search-filter-downdown-input .ant-input-prefix div span.ant-tag {
  margin: 0;
  white-space: break-spaces;
  word-break: break-word;
}
.search-filter-downdown-input .ant-input-prefix,
.search-filter-downdown-input .ant-input-affix-wrapper {
  flex-wrap: wrap;
  flex: unset;
  display: flex;
}
.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input,
.search-filter-downdown-input .ant-input-prefix .anticon-search {
  position: absolute;
  top: 4px;
}
.search-filter-downdown-input .ant-input-prefix .anticon-search {
  left: 8px;
  top: 8px;
  color: #757575;
  display: flex;
}
.search-filter-downdown-input .ant-input-affix-wrapper input.ant-input {
  right: 22px;
  padding-right: 35px;
  color: #757575;
  font-size: 12px;
  top: 5px;
}
.search-filter-downdown-content {
  max-width: 600px;
  min-width: 550px;
}
.search-filter-downdown-input {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.search-filter-downdown-input .ant-input-affix-wrapper {
  width: auto;
  min-width: 500px;
  border: 1px solid #d0d0da;
}
.search-filter-downdown-content select,
.search-filter-downdown-content .react-daterange-picker__wrapper {
  background-color: #f9f9f9 !important;
}
.search-filter-downdown-content select,
.search-filter-downdown-content .react-datepicker-wrapper .custom-input,
.search-filter-downdown-content .react-daterange-picker__wrapper,
.search-filter-downdown-content .ant-input {
  border-radius: 2px;
  border: solid 1px #ececec;
  padding: 4px 11px;
  height: 30px;
  background-color: #f7f7f7 !important;
}
.search-filter-downdown-content .react-daterange-picker,
.search-filter-downdown-content .react-datepicker-wrapper {
  width: 100%;
}
.search-filter-downdown-content .react-datepicker-wrapper .custom-input:focus,
.search-filter-downdown-content select:focus {
  outline: none;
  border: 1px solid #1445b7;
}
#multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {
  border-radius: 2px;
  border: solid 1px #e9e9e9;
  padding: 1px 11px;
  min-height: 30px;
  background-color: #f9f9f9 !important;
  font-size: 13px;
}
#multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV .chip {
  margin-top: 5px;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__button
  svg {
  width: 18px;
  height: 16px;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__button,
.rota_modal
  .ant-modal-body
  .react-daterange-picker__wrapper
  .react-daterange-picker__button {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__range-divider
  + .react-daterange-picker__inputGroup {
  display: flex;
  justify-content: center;
  padding-right: 10px;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup {
  font-size: 13px;
}
/* .search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  input {
  min-width: 18px !important;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  input.react-daterange-picker__inputGroup__year {
  min-width: 32px !important;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  .react-daterange-picker__inputGroup__month,
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  .react-daterange-picker__inputGroup__day {
  min-width: 24px !important;
  text-align: center;
} */
.search-filter-downdown-content
  .react-calendar__month-view__days
  .react-calendar__month-view__days__day {
  height: 34px;
  border-radius: 50%;
  width: 34px;
  max-width: none !important;
  flex-basis: unset !important;
  margin: 2px;
  font-size: 12px;
}
.search-with-filterdropdown .react-calendar__month-view__weekdays,
.search-with-filterdropdown .react-calendar__month-view__days {
  padding: 0 0.3em;
}
.search-with-filterdropdown .react-calendar__month-view__days__day--weekend {
  color: #5d5d5d;
}
.search-filter-downdown-content .react-calendar__tile--now:enabled:hover,
.search-filter-downdown-content .react-calendar__tile--now:enabled:focus {
  background: #1445b7;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #1445b7;
}
.ant-slider-track {
  background-color: #1445b7;
}
.ant-slider-handle {
  border-color: #1445b7;
}
.age-min-max-container {
  font-size: 12px;
}
.search-filter-downdown-content
  .react-daterange-picker__inputGroup__input:invalid,
.rota_modal .ant-modal-body .react-daterange-picker__inputGroup__input:invalid {
  background: transparent;
}
.search-filter-downdown-content .react-calendar,
.rota_modal .ant-modal-body .react-calendar {
  font-family: "Gotham Book" !important;
  border: none;
  -webkit-box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
}
.search-filter-downdown-content
  .react-calendar
  .react-calendar__month-view__weekdays__weekday
  abbr[title],
.rota_modal
  .ant-modal-body
  .react-calendar
  .react-calendar__month-view__weekdays__weekday
  abbr[title] {
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
.search-filter-downdown-content .react-calendar__navigation,
.rota_modal .ant-modal-body .react-calendar__navigation {
  background-color: #1445b7;
  font-size: 14px;
}
.search-filter-downdown-content .react-calendar__navigation button,
.rota_modal .ant-modal-body .react-calendar__navigation button {
  color: #ffffff;
}
.search-filter-downdown-content button.react-calendar__tile--now,
.rota_modal .ant-modal-body button.react-calendar__tile--now {
  background: #1445b7;
  color: #ffffff;
}
.search-filter-downdown-content .react-calendar__tile--hasActive,
.rota_modal .ant-modal-body .react-calendar__tile--hasActive {
  background: #1445b7;
  color: #ffffff;
}
.search-filter-downdown-content
  .react-calendar__navigation
  button:enabled:hover,
.search-filter-downdown-content
  .react-calendar__navigation
  button:enabled:focus,
.rota_modal .ant-modal-body .react-calendar__navigation button:enabled:focus,
.rota_modal .ant-modal-body .react-calendar__navigation button:enabled:hover {
  background-color: #1445b7;
}
.react-calendar__tile--active, .react-calendar__tile--hasActive, .react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-datepicker .react-datepicker__day--selected {
  background: #1445b7 !important;
  color: #fff !important;
}
.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #1445b7;
}
.search-filter-downdown-content .react-calendar__tile--active:enabled:hover,
.search-filter-downdown-content .react-calendar__tile--active:enabled:focus,
.search-filter-downdown-content
  .react-calendar__navigation
  button:enabled:hover,
.search-filter-downdown-content
  .react-calendar__navigation
  button:enabled:focus,
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  input:focus,
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup:focus,
.rota_modal .ant-modal-body .react-calendar__tile--active:enabled:hover,
.rota_modal .ant-modal-body .react-calendar__tile--active:enabled:focus,
.rota_modal .ant-modal-body .react-calendar__navigation button:enabled:hover,
.rota_modal .ant-modal-body .react-calendar__navigation button:enabled:focus,
.rota_modal
  .ant-modal-body
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup
  input:focus,
.rota_modal
  .ant-modal-body
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup:focus {
  border: none;
  outline: none;
}
.rota_modal .ant-modal-body .react-calendar__navigation button[disabled],
.search-filter-downdown-content .react-calendar__navigation button[disabled] {
  background-color: #1445b7;
}
.Calendar .Calendar__weekDays abbr[title],
.Calendar .Calendar__weekDays abbr[data-original-title] {
  cursor: pointer;
  text-decoration: none;
}
.search-filter-downdown-content .react-daterange-picker__wrapper,
.search-filter-downdown-content .react-datepicker__input-container input,
.react-daterange-picker__wrapper {
  background-image: url(../images/common/ic-cal.svg);
  background-position: 97.6%;
  background-repeat: no-repeat;
}
.ant-modal-content
  .ant-modal-body
  .ant-picker-input:not(.RangePickerItem_Wrapper .ant-picker-input) {
  background-image: url(../images/common/ic-cal.svg);
  background-position: 100%;
  background-repeat: no-repeat;
}
.RangePickerItem_Wrapper .ant-picker-range {
  display: flex;
}
.RangePickerItem_Wrapper .ant-picker-range .ant-picker-active-bar {
  background: #1445b7;
}
.ant-modal-content .ant-modal-body .ant-picker-input span.ant-picker-suffix {
  display: none;
}
.ant-modal-content .ant-modal-body .ant-picker-input span.ant-picker-clear {
  margin-right: 20px;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  button.react-daterange-picker__calendar-button.react-daterange-picker__button,
.react-daterange-picker__wrapper
  button.react-daterange-picker__calendar-button.react-daterange-picker__button {
  visibility: hidden;
  padding: 0 0 0 2px;
}
.ant-modal-content .ant-modal-body .ant-select-clear {
  background: transparent;
}
.search-filter-downdown-content.search-show
  button.react-datepicker__close-icon {
  margin-right: 25px;
}
button.react-daterange-picker__clear-button.react-daterange-picker__button {
  margin-right: 10px;
}
.search-filter-downdown-content.search-show
  .react-datepicker__close-icon::after,
.react-datepicker__close-icon::after {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 2px 0 0;
  line-height: 12px;
  font-size: 14px;
}
.search-filter-downdown-content
  .react-daterange-picker__wrapper
  .react-daterange-picker__button
  .anticon-close-circle,
.react-daterange-picker
  .react-daterange-picker__wrapper
  .react-daterange-picker__clear-button
  .anticon-close-circle,
.react-daterange-picker__clear-button .anticon-close-circle {
  width: 16px;
  height: 16px;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.search-filter-downdown-content
  .react-calendar
  .react-calendar__month-view__weekdays__weekday {
  padding: 0.3em;
  width: 34px;
  max-width: none !important;
  flex-basis: unset !important;
  margin: 0px 2px;
}
.react-daterange-picker__calendar {
  width: 280px;
}
span.react-daterange-picker__inputGroup__leadingZero {
  line-height: 1.4;
}
.react-daterange-picker
  .react-daterange-picker__wrapper
  .react-daterange-picker__inputGroup__day {
  text-align: center;
}
.react-daterange-picker
  .react-daterange-picker__wrapper
  .react-daterange-picker__clear-button
  .anticon-close-circle
  svg {
  height: 18px !important;
}
.react-daterange-picker
  .react-daterange-picker__wrapper
  button.react-daterange-picker__clear-button.react-daterange-picker__button
  .anticon-close-circle {
  margin-top: -1px !important;
}
#rota-range-picker:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__year,
#filter-date-range-pickerdate_period:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__year {
  min-width: 38px !important;
}
#rota-range-picker:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__month,
#filter-date-range-pickerdate_period:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__month {
  min-width: 25px !important;
}
#rota-range-picker:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__day,
#filter-date-range-pickerdate_period:not(.datepicker-custom)
  .react-daterange-picker__inputGroup__day {
  min-width: 23px !important;
}
/* search with filter css ends */

/* questionair css starts */
.script_review_que .script_review_inner {
  float: none;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 5px);
  margin-right: 10px;
  padding: 0;
}
.script_review_que .script_review_inner > .col-md-12.col-sm-12.col-xs-12 {
  padding: 0;
}
.script_review_que .script_review_inner:nth-child(2n + 0) {
  margin-right: 0;
}
.blue-bg-alert {
  background-color: transparent;
  padding: 0;
  border: none;
}
.script_review_inner .medication_table {
  min-height: 28px;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 0;
  border: 1px solid #e9e9e9;
  line-height: 1.42857143;
}
.script_review_inner .medication_table:not(:last-child) {
  border-bottom: none;
}
.script_review_inner .border_div {
  padding: 5px;
  border: 1px solid #e9e9e9;
}
.script_review_inner ul li {
  list-style: disc;
}
.script_review_inner ul {
  padding-left: 15px;
}
.script_review_inner .text-dark-blue {
  height: auto;
  border-radius: 4px;
  background-color: #e3f5ff;
  min-height: 34px;
  display: inline-block;
  padding: 8px 10px 4px;
  border: 1px solid #1445b7;
}
.script_review_inner .text-dark-blue:empty {
  display: none;
}

.custom-tags {
  color: #1445b7;
  background: #e6f7ff;
  border-color: #91d5ff;
}
button.ant-btn.ant-btn-ghost.rota-ok-btn {
  background-color: #f5365a !important ;
  color: #fff !important;
  border: none !important;
}
.rota-cancel-btn {
  background-color: #e9e9e9 !important ;
  color: black !important;
  border: none !important;
}
.user-select-none {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -moz-user-select: none;
}
/*  */
/* questionair css ends */

span.text-fff a,
.text-fff .ant-upload {
  color: #fff;
}
.font-12-imp.text-fff .ant-upload {
  font-size: 12px !important;
}
.upload_btn span.ant-upload {
  color: #1445b7;
  font-family: "Gotham Medium";
  font-size: 13px;
}

span.blue-bold {
  font-family: "Gotham Medium";
  color: #1445b7;
}

/* .react-daterange-picker__inputGroup__day,
.react-daterange-picker__inputGroup__month {
  width: 20px !important;
} */
.sort-applied {
  transform: rotate(360deg) !important;
  /* filter: sepia(1); */
}
.questionare_radio .ant-radio-group .ant-radio-wrapper,
.reports_radio label.ant-radio-wrapper,
.bookappointment_radio label.ant-radio-wrapper,
.gender_wrapper label.ant-radio-wrapper,
.weight_wrappers .ant-radio-button-wrapper,
.height_wrappers .ant-radio-button-wrapper {
  display: inline-block;
}
.bookappointment_radio label.ant-radio-wrapper,
.gender_wrapper label.ant-radio-wrapper {
  margin-top: 0 !important;
}
/* .questionare_radio .ant-radio-group .ant-radio-wrapper span,
.reports_radio label.ant-radio-wrapper span,
.bookappointment_radio label.ant-radio-wrapper span,
.gender_wrapper label.ant-radio-wrapper span {
  vertical-align: middle;
} */
.reasontext {
  background: #e6f7ff;
  padding: 10px;
  min-height: 50px;
  border-radius: 4px;
}
.reasonimg img {
  border-radius: 4px;
  height: 50px;
  width: 50px;
  background-color: #f9f9f9;
  object-fit: cover;
}
._3vt7_Mh4hRCFbp__dFqBCI input {
  width: 100%;
}
.patient-call-conatainer .dynamic-component-container .reason_wrapper {
  padding-top: 8px;
  margin-bottom: 0px !important;
}
.gx-layout-sider-scrollbar div:first-child {
  overflow-x: hidden !important;
}
.forgot_wrapper {
  margin: -5px;
}

/* calender css starts */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #1445b7;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #1445b7;
}
.react-datepicker__day--selected {
  background-color: #2373bb !important;
  border-radius: 50%;
  font-family: "Gotham Book";
  font-weight: 400;
}
.react-datepicker__header {
  background-color: #1445b7;
}
.react-datepicker__month-read-view--selected-month,
.react-datepicker__header .react-datepicker__current-month,
.react-datepicker__year-read-view--selected-year {
  font-family: "Gotham Book";
}
.react-datepicker__header .react-datepicker__current-month {
  color: #ffffff;
  font-weight: 400;
}
.react-datepicker__navigation--next {
  border-left-color: #fff;
}
.react-datepicker__navigation--previous {
  border-right-color: #fff;
}
.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: none;
  color: #ffffff;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ffffff;
  border-width: 0.35rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 34px;
}
.react-datepicker {
  background: #fff;
  -webkit-box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  border: none;
  border-radius: 0 !important;
}
.react-datepicker__day-names {
  margin-top: 6px;
  margin-bottom: -2px;
  background-color: #f1f1f1;
}
.react-datepicker__header__dropdown {
  margin-top: 5px;
}
.react-datepicker__day {
  border-radius: 50% !important;
  font-size: 12px;
  width: 34px;
  line-height: 34px;
}
.react-datepicker__today-button {
  background: #f1f1f1 !important;
  border-top: none;
}
.is_active_sorting {
  filter: saturate(38) brightness(1);
}
.sort-icon {
  transform: rotate(180deg);
}
.prescription_drugs_wrap .custom-alert {
  white-space: normal;
  display: flex;
  height: auto;
  text-align: left;
  min-height: 20px;
  line-height: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.prescription_drugs_wrap .custom-alert .anticon-info-circle {
  margin-top: 2px;
}
.searchicon {
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 1;
  transform: translateY(-59%);
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.full-open-chat {
  display: block !important;
  position: absolute;
  right: 93px;
  bottom: 45px !important;
}
.full-close-chat {
  display: none !important;
  position: absolute;
  right: 93px;
  bottom: 45px !important;
}
.chat-unread-full {
  color: #fff;
  position: absolute;
  height: 19px !important;
  width: 14px !important;
  min-width: 19px !important;
  font-size: 10px !important;
  margin-left: 4px;
  margin-top: -8px;
}
.react-resizable-handle-sw {
  background-image: url(../images/common/ic_expand.svg);
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-position: bottom right;
  padding: 0 0px 2px 0px;
  background-size: contain;
  z-index: 999;
}
.pre_quitionare
  .ant-collapse-item
  .ant-collapse-header
  span.ant-collapse-arrow {
  background-image: url(../images/common/expand-button.svg);
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow {
  background-image: url(../images/common/expand-button-black.svg);
}
tr.selected-tr {
  background: #e8efff;
  color: #1445b7 !important;
}
tr.selected-tr > td {
  color: #1445b7 !important;
  font-weight: 900;
}
.width-1050 {
  width: 1050;
}
.width-950 {
  width: 950 !important;
}
.prescription-alert .ant-alert-description {
  font-size: 10px !important;
  font-family: "Gotham Medium";
  font-weight: 500;
}
.form-control {
  height: 36px;
}
.custom-arrow.form-control {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.ant-btn.btn-green, .btn-custom.btn-green {
  background-color: #04b333 !important;
  border: 1px solid #04b333 !important;
  color: #fff !important;
}
.met-refresh {
  color: #d9d9d9 !important;
  padding: 5px !important;
  font-size: 11px !important;
}
.custom-profile-postcode .ant-select-selector {
  margin-left: 8px;
  margin-top: 4px;
}
/* dummy comment goes here for testing*/
.help-guide-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.pd5 {
  padding-bottom: 5px;
  padding-top: 2px;
}
.font-white {
  color: #fff;
  cursor: pointer;
}
.appointment_nodata {
  margin-top: 10px;
}
/* .appointment_nodata {
  border: 1px solid #eeeeee;
  padding: 5px 12px;
  font-family: "Gotham Book";
  border-top: none;
}
.ant-table-cell .appointment_nodata {
  padding: 0;
  border: none;
} */

.Calendar__monthArrow,
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next,
.react-calendar__navigation__arrow {
  background-image: url(../images/common/expand-button.svg) !important;
}
.react-calendar__navigation__arrow {
  background-repeat: no-repeat !important;
  background-position: center !important;
  font-size: 0;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  border: none;
  padding: 3px;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.Calendar__monthArrowWrapper.-right,
.react-datepicker__navigation--previous,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__prev-button {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.Calendar__monthArrowWrapper.-left,
.react-datepicker__navigation--next {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.react-calendar__navigation button {
  background-color: transparent;
  min-width: 40px;
}
.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: #1445b7;
  color: #fff;
  font-family: "Gotham Book";
  font-weight: 400;
}
.Calendar {
  margin-left: auto;
  margin-right: auto;
}
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}
.messages-wrapper .ant-result-icon.ant-result-image {
  margin-top: 5px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
span.parent-auth {
  margin-bottom: 10px !important;
  display: flex;
  justify-content: center;
  font-size: revert;
  font-weight: 500;
  color: #424242d4;
}

.rota-appointment-btns .btn-primary {
  background-color: #1445b7;
  border-color: #1445b7;
}
.rota-appointment-btns .btn-blue {
  border-radius: 15px;
  margin-right: 15px;
  font-family: "Gotham Book";
}
.rota-appointment-btns .btn-secondary {
  border: 1px solid #d0d0da;
  background-color: transparent;
  color: #d0d0da;
}

.session_btn .btn-custom.btn-blue {
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
}
/* calender css starts */
.search-filter-downdown-content .session_btn .btn-custom {
  font-family: "Gotham Book";
  padding: 7px 10px !important;
  border-color: #ececec;
  margin-right: 14px;
}

.session_btn .btn-custom {
  border: 1px solid #d0d0da;
  background-color: transparent;
  color: #808396;
  margin-right: 16px;
  border-radius: 50px !important;
  padding: 10px 16px !important;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1;
  height: auto;
}

.custom-ul-prescription > li {
  text-transform: uppercase;
}

.video-call-chat-panel .ant-input-group-addon [type="button"] span {
  font-size: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../images/common/chat_send.svg);
  background-size: 22px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
}
.btn-height-32 {
  height: 32px;
  line-height: 1;
}
.top-2 {
  top: 2px !important;
}

/* //health assessmen css */
.healthassement_tabs .ant-tabs-ink-bar {
  display: none !important;
}
.healthassesment-list
  .searchbar_dropdown
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 44px !important;
}
.ant-tabs-tab-active .icons_wrapper_img img {
  filter: brightness(0) invert(1);
}
.icons_wrapper_img {
  /* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2); */
  background-color: #e8efff;
  display: inline-block;
  height: 60px;
  min-width: 60px;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  margin-right: 14px;
}
.ant-tabs-tab-active .icons_wrapper_img {
  background-color: #1445b7;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.ant-tabs-tab-active .icons_wrapper_title {
  color: #1445b7;
  font-family: "Gotham Medium";
}
.healthassement_tabs .ant-tabs-tab {
  margin-right: 0px !important;
  margin-bottom: 15px !important;
  flex: 0 0 20%;
  max-width: 20%;
}
.healthassement_tabs_health .ant-tabs-tab {
  flex: 0 0 14.28%;
  margin-left: 0 !important;
  margin-top: 7px !important;
  max-width: 14.28%;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 7px;
  padding: 0 !important;
}
.healthassement_tabs .ant-tabs-nav-list {
  flex-wrap: wrap;
}
.healthassement_tabs .ant-tabs-nav-wrap {
  white-space: normal !important;
  width: 100%;
  flex-wrap: wrap !important;
  flex-direction: column;
}
.healthassement_tabs > .ant-tabs-nav:before {
  border-color: #d0d0da !important;
}
.healthassement_box .icons_wrapper,
.healthassement_box .icons_wrapper_data {
  flex: 0 0 100%;
  text-align: center;
}
.healthassement_box .icons_wrapper .icons_wrapper_img_box {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  box-shadow: none;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_img_box
  img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.healthassement_box .icons_wrapper .icons_wrapper_title {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #1445b7;
  font-family: "Gotham Book";
  max-width: calc(100% - 32px);
  margin-left: 8px;
}
.healthassement_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #1445b7;
  border-radius: 5px;
  padding: 5px 6px;
}
.healthassement_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 0 !important;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_img
  img {
  filter: brightness(0) invert(1);
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_title,
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_data {
  color: #fff;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_data {
  border-top: solid 0.2px #f6f8fc;
}
.healthassement_tabs .ant-tabs-tab-btn .healthassement_box .icons_wrapper_data {
  border-top: solid 0.2px #1445b7;
}
.healthassement_box .icons_wrapper_data {
  padding-top: 7px;
  display: block;
  text-align: center;
  font-family: "Gotham Medium";
  font-size: 13px;
}
.healthassement_box .icons_wrapper {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map_data_wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.map_data_wrapper .map_data + .map_data {
  margin-left: 36px;
}
.healthassement_tabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
  /* display: block !important; */
  flex-wrap: wrap;
  /* justify-content: center; */
}
.healthassement_tabs .ant-tabs-nav::before {
  border-bottom: 1px solid #e9e9e9 !important;
}
/* .healthassement_tabs .ant-tabs-nav {
  padding-bottom: 24px;
  margin-right: -7px !important;
  margin-left: -7px !important;
} */
.healthassement_tabs .ant-tabs-tab .ant-tabs-tab-btn {
  padding: 0 7px;
  width: 100%;
}
/* health assessment css end */

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1445b7 !important;
}
.health-green {
  color: #44dc91 !important;
}

.health-orange {
  color: #f48918 !important;
}

.health-red {
  color: #fe5965 !important;
}
.health-at_risk {
  color: #f5eb1a !important;
}
.health-severe {
  color: #c22026 !important;
}
.health-normal {
  color: #16b250 !important;
}
.path.highcharts-scrollbar-arrow {
  display: none;
}
path.highcharts-scrollbar-rifles {
  display: none;
}
rect.highcharts-scrollbar-button {
  display: none;
}
g.highcharts-range-selector-buttons {
  /* display: none; */
  fill: #ebf1ff;
  y: 11px;
}

g.highcharts-input-group {
  display: none;
}

g.highcharts-exporting-group {
  display: none;
}

rect.highcharts-scrollbar-track {
  y: 2%;
}
.scroll-custom {
  y: 0% !important;
}

rect.highcharts-scrollbar-thumb {
  y: 2%;
}
path.highcharts-scrollbar-arrow {
  display: none;
}

g.highcharts-range-selector-group {
  display: none;
}
.spinner-container > div > svg > circle {
  stroke: #1445b7 !important;
}
.spinner-container {
  text-align: center;
  /* margin-top: 100px; */
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 450px;
  position: relative;
}
/* div#HeightGraph {
  max-width: 800px;
  min-width: 320px;
  height: 400px;
  width: 400px;
} */
.message-conversations-wrapper .ant-result-title {
  font-size: 16px;
  font-family: "Gotham Book";
  color: #1d2943;
}
.footer_wrapper {
  padding: 20px 0;
  background-color: #f6f8fc;
}
/* .healthassement_box {
  width: 100%;
  min-height: 80px;
  border-radius: 6px;
  background-color: #f6f8fc;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Gotham Book";
  padding: 0 12px;
}
.healthassement_tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 14px;
} */
.healthassement_box .icons_wrapper,
.healthassement_box .icons_wrapper_data {
  flex: 0 0 100%;
  text-align: center;
}
.healthassement_box .icons_wrapper .icons_wrapper_title {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #1445b7;
  font-family: "Gotham Book";
  max-width: calc(100% - 32px);
  margin-left: 8px;
}
.healthassement_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none !important;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #1445b7;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_img
  img {
  filter: brightness(0) invert(1);
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_title,
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_data {
  color: #fff;
}
.healthassement_tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .healthassement_box
  .icons_wrapper_data {
  border-top: solid 0.2px #f6f8fc;
}
.healthassement_tabs .ant-tabs-tab-btn .healthassement_box .icons_wrapper_data {
  border-top: solid 0.2px #1445b7;
}
.healthassement_box .icons_wrapper_data {
  padding-top: 7px;
  font-family: "Gotham Medium";
  font-size: 12px;
}
.consultleft .ant-btn-danger span {
  font-size: 12px !important;
}
.healthassement_box .icons_wrapper {
  padding-top: 5px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map_data_wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.map_data_wrapper .map_data + .map_data {
  margin-left: 36px;
}
.healthassement_tabs .ant-tabs-nav::before {
  border-bottom: 1px solid #e9e9e9 !important;
}
.healthassement_tabs .icons_wrapper {
  display: flex;
  align-items: center;
  /* max-width: 249px; */
  max-width: 213px;
  padding: 0 12px 0 5px;
}
/* .healthassement_tabs .ant-tabs-tab-active .icons_wrapper span:first-child {
  margin-left: -10px;
}
.healthassement_tabs
  .ant-tabs-tab:not(.ant-tabs-tab-active)
  .icons_wrapper_title {
  padding-left: 10px;
} */
/* health assesment css ends */

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1445b7 !important;
}

.bmi_wrapper {
  width: 100%;
  /* margin-left: 15%; */
}
span.icons_wrapper_data.health-green {
  color: #35bc35;
}

/* calender CSS ends */
.ant-switch .anticon {
  vertical-align: middle;
}
.dynamic-msg-div {
  width: 278px;
  position: absolute;
  right: -300px;
  background-color: #fff;
  top: 0;
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
/* .mobile_frame {
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 26px;
  overflow: hidden;
  z-index: 2;
  padding: 28px 12px 100% 13px;
  position: relative;
  height: 445px;
  background: url("../images/sidebar/mobile_frame.png") #f7f7f7;
  background-size: cover;
} */

.mobile_header {
  background-color: #1445b7;
  padding: 7px 10px;
  width: 100%;
  font-family: "Gotham Medium";
  color: #fff;
  font-size: 12px;
}

.mobile_pushdata li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: inherit;
  align-items: flex-start;
  cursor: pointer;
  margin: 0;
  padding: 12px 5px;
}
.mobile_pushdata li .pushpreview_note_img {
  width: 16px;
}
/* .pushpreview_note_img_sprite {
  background: url("../images/sidebar/questionnaire.svg") no-repeat 0 0;
  width: 12px;
  margin-right: 3px;
  height: 16px;
} */
.mobile_pushdata li .pushpreview_note_data {
  position: relative;
  width: calc(100% - 16px);
}
.mobile_pushdata li .pushpreview_note-title {
  font-size: 11px;
  padding-right: 80px;
  margin-bottom: 5px;
  line-height: 1;
  color: #1d2943;
}
.pushpreview_note-date-time {
  color: #808396;
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 8px;
}
.mobile_date {
  color: #808396;
  font-size: 10px;
  text-align: right;
  padding: 10px;
}
.mobile_data {
  margin: 0 10px;
  background-color: #1445b7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 8px;
  padding: 10px;
}
.header_pre,
.footer_pre {
  max-width: 100%;
}
button.green-btn.text-white.font-13.weight-400.prl-15.btn.padrl-6.push-5-t.push-5-b.width-80p.dltbtn {
  background: rgb(135, 208, 104);
}
/* 5-1-2023 css start */
.min-width-18px {
  min-width: 18px;
}
.upcoming_appt_data .custom-label.danger {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 30px;
  line-height: 1;
}
.Calendar__day.-ltr.-today:not(.-selectedStart):not(.-selectedEnd):not(
    .-selectedBetween
  )::after {
  display: none;
}
.ant-layout-sider-collapsed .dashboard_btns {
  display: none;
}

/* new patient list css start */
.padding-left-right-20px {
  padding: 0 20px;
}
.patientnewlistwrap table {
  width: 100%;
}
.padding-6-t {
  padding-top: 6px;
}
.padding-6-b {
  padding-bottom: 6px;
}
.padding-left-12 {
  padding-left: 12px;
}
.padding-top-12 {
  padding-top: 12px;
}
.cheboxpopover {
  min-width: 198px;
}
/* script review css start */
.scriptreview_collapse .CollapsiableSubHeader svg path {
  fill: #1445b7;
}
.push-15-b {
  margin-bottom: 15px;
}
.ant-modal-content .ant-modal-body .form-control::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
}

.ant-modal-content .ant-modal-body .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
}

.ant-modal-content .ant-modal-body .form-control::placeholder {
  font-size: 12px;
}
.patientnewlistwrap th {
  font-family: "Gotham Book";
  /* font-family: "Gotham Medium"; */
}
.newpatientdetails td {
  font-family: "Gotham Book";
}
/* Questionnaire review css start */
.field_spacing .form-row {
  margin-bottom: 12px;
  align-items: center;
}
.field_spacing p.dis-block.push-5-b,
.questionare_review_wrapper .push-10-b p.text-light-black.text-left {
  margin-top: 10px;
}
/* update signature pad css*/
.update_signature .signcontainermain {
  height: 100% !important;
}
.update_signature .signcontainer {
  height: 50vh;
  width: 100%;
}
.update_signature .clearbtn button {
  -webkit-appearance: none;
  background-color: #1445b7;
  border: solid 1px #1445b7;
  color: #fff;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  padding: 4px 30px;
  font-size: 14px;
  height: 35px !important;
  margin-top: 10px;
  width: auto;
  border-radius: 4px !important;
  margin-bottom: 20px;
}
.update_signature .clearbtn button:hover {
  background-color: #1445b7;
  color: #fff;
  box-shadow: none;
  border-color: #1445b7;
}
.update_signature .clearbtn {
  text-align: center;
}
.prev_btn.btn-white.text-blue {
  border-color: #1445b7;
  color: #1445b7;
  background: #fff;
}
.prev_btn.btn-white.text-blue:hover {
  border-color: #1445b7;
  color: #fff;
  background: #1445b7;
}
.ant-modal-body label {
  font-size: 10px !important;
}
.doc-single .ant-image-mask-info {
  display: flex;
  flex-direction: column;
}
.margin-left-10 {
  margin-left: 10px;
}
.personaldetailsmain_wrapper span.ant-select-selection-placeholder {
  line-height: 30px !important;
}
.update_signature .signcontainer canvas {
  width: 100% !important;
  height: 100% !important;
}

/* for phone number hover issue */

/*theme color changes css start*/
.ant-modal-content .react-tel-input .selected-flag:hover,
.ant-modal-content .react-tel-input .selected-flag:focus {
  background: transparent !important;
}
.ant-menu-item svg g,
.ant-menu-submenu svg g {
  fill: #757575;
}
.ant-menu-item > span,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  margin-left: 10px;
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
    color 0.3s;
}
.ant-menu-item > svg {
  margin-right: 10px;
  min-width: 14px;
  width: 14px !important;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item > svg {
  margin-right: 0px;
}
.ant-menu-inline-collapsed .ant-menu-item > span {
  margin-left: 0px;
  display: inline-block;
  opacity: 0;
  margin-left: 0px;
  position: absolute;
}
.ant-menu-submenu-selected {
  color: #1445b7 !important;
}
.ant-menu-item:hover .referral_code g,
li.ant-menu-item.ant-menu-item-selected .referral_code g {
  stroke: #1445b7;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1445b7 !important;
}
.ant-tabs-ink-bar,
.ant-radio-inner::after {
  background: #1445b7 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #e8efff !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1445b7 !important;
}
.ant-menu-submenu-selected .healthassicon path,
.ant-menu-item:hover .healthassicon path {
  fill: #1445b7;
  stroke: #1445b7;
}
.ant-menu-submenu.ant-menu-submenu-selected svg g {
  fill: #1445b7;
}
a:hover,
.ant-tabs-tab:hover {
  color: #1445b7 !important;
}
a[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
}
.main_menu li a:hover {
  color: #fff !important;
}
.prescription_drugs_wrap span.anticon.anticon-info-circle svg path:first-child {
  fill: #1445b7 !important;
}
.prescription_drugs_wrap span.anticon.anticon-info-circle svg path:last-child {
  fill: #1445b7 !important;
}
.btn-custom.btn-blue.text-fff:hover .ant-upload {
  color: #1445b7;
}
.userdropdown .ant-dropdown-trigger:hover {
  color: #fff !important;
}
.ant-spin-dot-item {
  background-color: #1445b7 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #1445b7 !important;
}
.pagination .page-link:hover {
  color: #fff !important;
}
.ant-modal-content .react-tel-input .selected-flag:hover,
.ant-modal-content .react-tel-input .selected-flag:focus,
.signup-form-wrapper .selected-flag:hover,
.signup-form-wrapper .react-tel-input .selected-flag:focus {
  background: transparent !important;
}
/* sign up phone field css*/
.react-tel-input .form-control {
  width: 100%;
  font-size: 11px;
}
.ant-progress-steps-item-active {
  background: #1445b7 !important;
}
.profile-details-form a.ant-dropdown-link,
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #1445b7 !important;
}
.patient_backarrow svg {
  cursor: pointer;
}
/* search pharmacy ui css */
.new_prescription .form-group .rcvd_code_checkbox label.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.new_prescription
  .form-group
  .rcvd_code_checkbox
  label.ant-checkbox-wrapper
  .ant-checkbox {
  top: -1px;
}
.new_prescription .form-group .live-talk2-table th label.ant-checkbox-wrapper .ant-checkbox {
  top: 0px;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-picker-focused {
  border-color: #1445b7 !important;
  box-shadow: 0 0 6px rgba(20, 69, 231, 0.2) !important;
}
.searchbar_dropdown .ant-input-affix-wrapper:focus,
.searchbar_dropdown .ant-input-affix-wrapper-focused {
  border-color: #1445b7 !important;
  box-shadow: 0 0 6px rgba(20, 69, 231, 0.2) !important;
}
.ant-picker-today-btn {
  color: #1445b7 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #1445b7 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #1445b7 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #1445b7 !important;
  box-shadow: 0 0 6px rgba(20, 69, 231, 0.2) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #4670d4 !important;
  color: #fff !important;
}
.commnspace .padding-55-t {
  padding-top: 32px;
}
/* priscriber role css start */
.walkinheaderleft .padding-right-15.corporate_data {
  padding-right: 0px;
}
.walkinheaderleft .ant-collapse-header {
  line-height: 1 !important;
  vertical-align: top;
}
.walkinheaderleft .padding-right-15 {
  padding-right: 10px;
}
/* doctor portal ui css start 29-5 */
textarea.form-control-textarea.min-height-92 {
  min-height: 92px !important;
}
textarea.form-control-textarea.min-height-180 {
  min-height: 180px !important;
}
.consultantion-info-table-wrapper {
  padding-top: 25px;
}
.consultantion-info-table-wrapper .table-wrapper {
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  align-items: flex-start;
  grid-gap: 5px;
}
.consultantion-info-table-wrapper table thead th:first-child {
  width: 23%;
}
.consultantion-info-table-wrapper table thead th:nth-child(2) {
  width: 15%;
  padding-right: 0;
}
.consultantion-info-table-wrapper table thead th:last-child {
  width: 62%;
}
.consultantion-info-table-wrapper th,
.consultantion-info-table-wrapper td {
  padding: 4px 7px;
  font-size: 12px;
  font-family: "Gotham Book";
}
.consultantion-info-table-wrapper table td:last-child {
  padding-left: 0;
  padding-right: 3px;
}

.consultantion-info-table-wrapper input {
  width: 100px;
  outline: none;
  border-radius: 4px;
  font-size: 12px;
  border: solid 1px #e9e9e9;
  line-height: 26px;
  padding: 0 4px;
}
.consultantion-info-table-wrapper input:focus {
  border-color: #1445b7 !important;
}
.consultantion-info-table-wrapper
  .input-wrapper
  input:not(.ant-select-selection-search-input) {
  width: 45%;
}
.width-4 {
  width: 4%;
}
.address_pop img,
.address_popover img {
  width: 16px;
}
.table-dropdown {
  height: 30px;
  border: 1px solid #d9d9d9;
  padding: 5px;
  background: #d9d9d905;
  border-radius: 3px;
  font-weight: 700;
}
.address_pop .anticon-info-circle {
  font-size: 20px;
}
.newpatientlistdata .dropdown-menu .dropdown-item:hover {
  background: #1445b7 !important;
  color: #fff !important;
}
.newpatientlistdata .view_option_drop span {
  color: #1445b7;
  text-decoration: underline;
  cursor: pointer;
}
.mt-8 {
  margin-top: 8px;
}
.newpatientdetails table td span,
.newpatientdetails table td {
  word-break: break-all;
}
.sort-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sort-applied {
  -webkit-transform: rotate(360deg) !important;
  -ms-transform: rotate(360deg) !important;
  transform: rotate(360deg) !important;
}
.newpatientlistdata .view_option_drop span.nullfield {
  text-decoration: none !important;
}
.dynamic-component-container:has(~ .video-call-panelinit)
  .consultantion-info-table-wrapper {
  max-width: 40%;
  flex: 0 0 40%;
}
.dynamic-component-container .col-md-8.maincol {
  max-width: 60%;
  flex: 0 0 60%;
}
.react-daterange-picker__inputGroup__input {
  font-size: 12px;
}
.optionListContainer ul li:hover,
.optionListContainer ul li.highlight {
  background: #1445b7 !important;
}
.divider {
  border-width: 3px !important;
}
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  font-size: 12px !important;
}
.coding_modal_table .font-10 {
  font-size: 12px !important;
}
.mainappoinmentwrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px !important;
}
.mainappoinmentwrap ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px !important;
}
.mainappoinmentwrap :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px !important;
}
.mainappoinmentwrap :-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px !important;
}
.checkboxgroup .col {
  padding: 0 5px;
  /* max-width: inherit !important;
  flex: 0 0 auto;
  width: auto; */
  max-width: 23.3% !important;
  flex: 0 0 23.3%;
}
.dot {
  position: relative;
}
.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #000;
  display: block;
  padding-left: 5px;
  top: 4px;
  margin-right: 8px;
}
.single-choice-question_wrapper .dot,
.TextBoxInputItem_Wrapper .dot,
.checkbox-group-question_wrapper .dot,
.check-agree-question_wrapper .dot {
  top: 7px;
}

.single-choice-question_wrapper:not(:last-child),
.TextBoxInputItem_Wrapper:not(:last-child),
.checkbox-group-question_wrapper:not(:last-child),
.check-agree-question_wrapper:not(:last-child),
.quastionanairepopup .NumericalQuestion_Wrapper:not(:last-child),
.quastionanairepopup .CustomDropDownItem_Wrapper:not(:last-child) {
  margin-bottom: 12px;
}

@media (max-width: 1400px) {
  .corporate_data .overtext_hidden {
    max-width: 244px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .corporate_data {
    vertical-align: middle;
  }
  .dynamic-component-container:has(~ .video-call-panelinit) .col-md-6.maincol {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper {
    max-width: 70%;
    flex: 0 0 70%;
  }
  .checkboxgroup .col {
    max-width: 24.85% !important;
    flex: 0 0 24.85%;
  }
  .checkboxgroup .col.highlighted_dvt,
  .checkboxgroup .col.highlighted_throat {
    max-width: 20.3% !important;
    flex: 0 0 20.3%;
  }
}

@media screen and (max-width: 1200px) {
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultant_review:has(+ .consultantion-info-table-wrapper) {
    max-width: 35%;
    flex: 0 0 35%;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper {
    max-width: 65%;
    flex: 0 0 65%;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child {
    border-top: none !important;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child
    thead {
    display: none;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child
    tbody
    tr:first-child {
    border-top: none !important;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child
    tbody
    tr
    td:first-child {
    width: 23% !important;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child
    tbody
    tr
    td:nth-child(2) {
    width: 18% !important;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .table-wrapper
    table:last-child
    tbody
    tr
    td:last-child {
    width: 55% !important;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper
    .input-wrapper
    .ant-select {
    width: 40% !important;
  }
}

div:has(.reason_wrapper) > .reason_wrapper + div {
  padding-top: 10px !important;
}

div .reason_wrapper {
  padding-top: 45px !important;
  margin-bottom: 10px !important;
}
/* hardik's given code start*/
div:not(.dynamic-component-container):has(.reason_wrapper)
  > .reason_wrapper
  + div {
  padding-top: 40px !important;
}
div:not(.dynamic-component-container):has(.reason_wrapper)
  > .reason_wrapper
  + div.showreasonfield,
div:not(.dynamic-component-container):has(.reason_wrapper)
  > .reason_wrapper
  + div {
  padding-top: 0px !important;
}
.dynamic-component-container:has(.reason_wrapper) > .reason_wrapper + div {
  padding-top: 10px !important;
}

div .reason_wrapper {
  padding-top: 45px !important;
}
/* hardik's given code end*/

.previous_appt_list .ant-tabs {
  overflow: visible;
}

/* date picker ui update for add/edit patient form */
.react-date-picker-wrapper .MuiInputBase-input {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 28px;
  font-family: "Gotham Book";
}
.react-date-picker-wrapper .MuiFormControl-root {
  width: 100%;
  background: #f9f9f9;
}
.react-date-picker-wrapper fieldset {
  border-radius: 2px;
  border: 1px solid #e9e9e9 !important;
  outline: none !important;
}
.react-date-picker-wrapper .MuiOutlinedInput-root:hover fieldset {
  border: 1px solid #1445b7 !important;
}
.react-date-picker-wrapper input {
  padding: 0 10px;
  font-size: 12px;
  color: #1d2943;
  line-height: 28px;
}
.react-date-picker-wrapper .MuiIconButton-root svg {
  font-size: 1rem !important;
}
.react-date-picker-wrapper .MuiButtonBase-root.Mui-selected {
  background: #1445b7;
}
.MuiPickersDay-today:not(.Mui-selected) {
  border-color: #1445b7 !important;
}
/* date picker ui update for add/edit patient form */

/* 15-6-2023 search patient ui changes  */
.overflow_class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 105px;
}
p.overflow_class {
  margin-bottom: 0;
}
.address_txt p.d-flex {
  margin-bottom: 0;
}
.btn-custom.btn-blue:hover span,
.btn-custom.btn-blue:active span,
.btn-custom.btn-blue:focus span {
  color: #1445b7 !important;
}
.MuiButtonBase-root,
.MuiDayCalendar-header,
.MuiPickersCalendarHeader-label {
  font-family: "Gotham Book";
}
/* walking appointment scroll css start*/

/* .sectionscroll {
  height: calc(100vh - 63vh);
} */
.selection_container {
  width: 100%;
}
.dynamic-component-container .mainappoinmentwrap {
  padding-top: 50px !important;
}
.dynamic-component-container .mainappoinmentwrap {
  height: 100%;
}
/* .dynamic-component-container .sectionscroll {
  height: calc(100vh - 63vh) !important;
} */
.dynamic-component-container .medicalhistory_wrapper {
  padding-right: 10px !important;
}
.screenshort_tabs {
  margin-top: 0px !important;
}
.sicknote_cotent.push-t-45 {
  margin-top: 45px !important;
}
.sicknotenodata {
  margin-top: 55px !important;
}
.scriptreviewmain_wrapper .nospace {
  margin-top: 20px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.divider {
  margin-bottom: 5px !important;
}
.questionare_review_wrapper .mainspace,
.reportreviewpage .mainspace {
  padding-top: 55px;
}
.questionare_review_wrapper .reportscont_wrapper,
.reportreviewpage .reportscont_wrapper,
.patientconsultation .reportscont_wrapper,
.patientdetailpage .reportscont_wrapper,
.walkin_appointment .reportscont_wrapper {
  padding-top: 55px;
}
.patientconsultation .mainspace,
.dynamicvideocall .mainspace,
.patientdetailpage .mainspace,
.walkin_appointment .mainspace {
  padding-top: 45px;
}
.testinvest_wrapper,
.walkin_appointment .reportscont_wrapper,
.walkin_appointment .splnotes_wrapper,
.walkin_appointment .mainspace,
.patientconsultation .mainspace,
.patientconsultation .reportscont_wrapper,
.patientconsultation .splnotes_wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.pt-15 {
  padding-top: 15px !important;
}
.allergies_wrapper,
.barcode_wrapper,
.medicalhistory_wrapper,
.familyrecords_wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.bg-red {
  background: #ff4a37 !important;
  border-color: #ff4a37 !important;
}
.hidereasonfield {
  padding-top: 55px !important;
}
.walkin_appointment .hidereasonfield {
  padding-top: 30px !important;
}
/* .hidereasonfield .sectionscroll {
  height: calc(100vh - 60vh);
}
.patientconsultation .sectionscroll {
  height: calc(100vh - 60vh);
} */
.patientconsultation .consultant_header {
  margin: 0 10px;
}
.patientconsultation .anticon-plus-square {
  margin-right: 3px;
}
/* .showreasonfield .sectionscroll {
  height: calc(100vh - 67vh) !important;
  overflow: auto;
} */
.dynamic-component-container:has(~ .video-call-panelinit)
  .consultantion-info-table-wrapper {
  max-width: 40%;
  flex: 0 0 40%;
}
.dynamic-component-container:has(~ .video-call-panelinit) .col-md-6.maincol {
  max-width: 80%;
  flex: 0 0 80%;
}
.consultantion-info-table-wrapper .table-wrapper .border {
  margin-bottom: 10px;
  width: 100%;
  max-width: 100%;
}
.css-1pahdxg-control {
  border-color: #1445b7 !important;
}
.appointmentpage .pre_quitionare {
  padding-top: 40px;
}
.appointmentpage .sectionscroll .pre_quitionare {
  padding-top: 0px;
}

.appointmentpage .pastorders_wrapper .pre_quitionare {
  padding-top: 0px;
}
.chathistorymain,
.patientconsultation .screenshot-container {
  padding: 0 10px;
}
.disselectfield.css-2b097c-container,
.css-yk16xz-control,
.css-1pahdxg-control,
.react-select__control {
  height: 26px !important;
  min-height: inherit !important;
  border-color: #e9e9e9 !important;
  border-radius: 2px !important;
}
.disselectfield {
  position: relative;
  z-index: 100;
}
.css-g1d714-ValueContainer,
.react-select__value-container {
  background-color: #f9f9f9 !important;
}

.walkingappointmentsecscroll .sectionsubscroll .prescription-array,
.sectionsubscroll .prescription-array {
  padding: 0 10px;
}
.showreasonfield .sectionscroll.walkingappointmentsecscroll {
  /* overflow: hidden; */
}

.healthassesment-list {
  padding: 0 10px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background: #1445b7 !important;
  border-color: #1445b7 !important;
}
.ant-tabs-tab {
  font-size: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.sectionsubscroll .pagination {
  margin-top: 5px;
  padding-bottom: 0px;
}
.walkingappointmentsecscroll .search-filter-downdown-content {
  height: 120px;
  overflow: auto;
}
.walkingappointmentsecscroll .padding-left-10.padding-right-10 {
  padding: 0 !important;
}
.walkingappointmentsecscroll .ant-tabs-nav {
  padding: 0 10px;
}
.walkingappointmentsecscroll .left-right-space {
  padding: 0 10px;
}
.patient_detail_left {
  flex: 1;
}

.css-1wa3eu0-placeholder {
  font-size: 12px;
  font-family: "Gotham Book";
  color: rgba(0, 0, 0, 0.85);
}
.css-g1d714-ValueContainer,
.react-select__value-container {
  height: 24px;
}
.css-b8ldur-Input,
.css-1g6gooi {
  padding: 0 !important;
  margin: 0 !important;
}
.ant-input.textarea.font-10::-webkit-input-placeholder {
  font-size: 10px !important;
}
.prescriptiondetailpopup .css-1wa3eu0-placeholder {
  font-size: 10px;
}

.appointmentpage .screenshort_tabs .pre_quitionare {
  padding-top: 0px;
}

.dynamicvideocall .consultant_review {
  margin-top: 0px;
}
.bmi_wrapper .font-24 {
  font-size: 14px !important;
}
.ant-modal-content .ant-modal-body textarea.form-control {
  line-height: 18px !important;
}

.selection_container .font-14 {
  font-size: 12px !important;
}
.selection_container .ant-tabs-tab-btn {
  font-size: 12px !important;
  font-family: "Gotham Book";
}
.new_prescription
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 10px;
}
.new_prescription .ant-modal-content .ant-modal-body .form-control {
  font-size: 10px !important;
}
.testinvitigationpopup .ant-modal-body label {
  font-size: 12px !important;
}
.mainappoinmentwrap.showreasonfield .walkin_appnt_wrapper {
  padding-top: 10px !important;
}
div .reason_wrapper {
  padding-top: 45px !important;
  margin-bottom: 10px !important;
}
.mainappoinmentwrap.showreasonfield .walkin_appnt_wrapper {
  padding-top: 10px !important;
}

.dynamicvideocall .walkingappointmentsecscroll .search-filter-downdown-content {
  height: 98px;
  overflow: auto;
}
.patientdetailsmain.padding-15-lr,
.padding-15-lr {
  padding-left: 10px;
  padding-right: 10px;
}
.patientdetailsmain.padding-15-lr.consultaiondetail-main {
  padding-left: 0px;
  padding-right: 0px;
}
.prequitiorepage {
  padding-top: 30px;
}
.reportscont_wrapper {
  padding-top: 70px;
  padding-left: 10px;
  padding-right: 10px;
}
.splnotes_wrapper {
  padding-left: 10px;
}
.padding-left-0.padding-right-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.video_inner_content_scroll .mainspace {
  padding-left: 10px;
}
.patientconsultation .sicknotepage {
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
}
.scriptreviewmain_wrapper .reportscont_wrapper {
  padding-top: 0px;
}
.patientconsultation .sicknotenodata {
  margin-top: 0px !important;
}
.healthassesmentpage .healthassesment-list {
  padding: 0;
}
.questionare_review_wrapper .padding-15-lr.prequitiorepage,
.questionare_review_wrapper .mainappoinmentwrap .selection_container {
  padding-left: 0px;
  padding-right: 0px;
}
.questionare_review_wrapper .scriptreview_detail_header + .prequitiorepage {
  padding-top: 15px;
}
.questionare_review_wrapper .scriptreview_detail_header + .mainappoinmentwrap {
  padding-top: 15px;
}
.questionare_review_wrapper
  .walkingappointmentsecscroll
  .sectionsubscroll
  .prescription-array,
.questionare_review_wrapper .sectionsubscroll .prescription-array {
  padding-left: 0;
  padding-right: 0;
}
.questionare_review_wrapper .testinvest_wrapper,
.questionare_review_wrapper .walkin_appointment .reportscont_wrapper,
.questionare_review_wrapper .walkin_appointment .splnotes_wrapper,
.questionare_review_wrapper .walkin_appointment .mainspace,
.questionare_review_wrapper .patientconsultation .mainspace,
.questionare_review_wrapper .patientconsultation .reportscont_wrapper,
.questionare_review_wrapper .patientconsultation .splnotes_wrapper {
  padding-left: 0;
  padding-right: 0;
}
.questionare_review_wrapper .reportscont_wrapper {
  padding-left: 0;
  padding-right: 0;
}
.splnotes_wrapper {
  padding-left: 0;
}
.reportreviewpage .testinvest_wrapper,
.reportreviewpage .walkin_appointment .reportscont_wrapper,
.reportreviewpage .walkin_appointment .splnotes_wrapper,
.reportreviewpage .walkin_appointment .mainspace,
.reportreviewpage .patientconsultation .mainspace,
.reportreviewpage .patientconsultation .reportscont_wrapper,
.reportreviewpage .patientconsultation .splnotes_wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.reportreviewpage .reportscont_wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.reportreviewpage .mainappoinmentwrap > .selection_container,
.scriptreviewmain_wrapper
  .mainappoinmentwrap
  .sectionsubscroll
  .prescription-array,
.questionare_review_wrapper
  .mainappoinmentwrap
  .sectionsubscroll
  .prescription-array,
.reportreviewpage .mainappoinmentwrap .sectionsubscroll .prescription-array {
  padding-left: 0px;
  padding-right: 0px;
}
.patient_detail_header + .medicalhistory_wrapper,
.patient_detail_header + .familyrecords_wrapper,
.patient_detail_header + .allergies_wrapper,
.patient_detail_header + .testinvest_wrapper,
.patient_detail_header + .reportscont_wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.patientconsultation .patient_detail_header + .reportscont_wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.scriptreviewmain_wrapper
  .scriptreview_bottomheader
  + .healthassesmentpage
  .padding-55-t {
  padding-top: 15px !important;
}
.scriptreviewmain_wrapper
  .scriptreview_bottomheader
  + .push-50-t.prequitiorepage {
  margin-top: 15px !important;
  padding: 0px !important;
}
.questionare_review_wrapper .allergies_wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.css-26l3qy-menu {
  font-size: 12px;
  font-family: "Gotham Book";
}

.reason_wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px !important;
}
.reason_wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px !important;
}
.reason_wrapper :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px !important;
}
.reason_wrapper :-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px !important;
}
.mainappoinmentwrap .btn-custom.btn-blue {
  font-size: 12px !important;
}
.react-select__menu-list {
  min-height: 26px !important;
  font-size: 12px !important;
  font-family: "Gotham Book";
}
.dynamicvideocall .patient_detailswrapper {
  padding-left: 10px;
}
.prescription-form .coding_modal_table .font-10 {
  font-size: 10px !important;
}
.dynamic-component-container .healthassesment-list {
  padding-left: 10px;
}
.dynamicvideocall .splnotes_wrapper {
  padding-left: 10px;
}
.reason_wrapper .customblock .font-11 p {
  font-size: 12px !important;
}
.mainappoinmentwrap .btn-custom.btn-blue {
  min-width: 100px !important;
  width: auto !important;
  padding: 0 10px !important;
}
.doc-single.upload_img_box {
  width: auto;
  max-width: 100%;
  min-height: 102px;
  padding-bottom: 7px !important;
}
.doc-single.upload_img_box .ant-image {
  width: 60px;
  height: 60px;
}
.ant-collapse-content-box .ant-image {
  width: 100%;
}
.patientdetailpage .sectionsubscroll .prescription-array {
  padding-left: 0;
  padding-right: 0;
}
.checkboxgroup .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.checkboxgroup .ant-checkbox + span {
  padding-right: 5px !important;
  word-break: break-word;
}
.shortscodepanel {
  display: flex;
  align-items: center;
  height: 100%;
}
.shortcodewrap
  .pre_quitionare
  .ant-collapse-item
  .ant-collapse-header
  span.anticon {
  top: 0;
  bottom: 0;
  margin: auto;
}
.shortcodewrap .padding-5 {
  padding: 3px 5px !important;
}
.shortcodewrap .font-12 span {
  font-size: 11px !important;
}
.shortcodewrap button.btn.btn-sm.btn-link.text-white {
  font-size: 11px !important;
}
.font-11.in-block {
  font-size: 13px !important;
  word-break: break-all;
}
.testinvest_wrapper.shortcodewrap {
  padding: 0 !important;
  position: relative;
}
.h-auto.form-control {
  height: auto !important;
}
.shortcodeloader > div {
  margin-top: 50px !important;
  padding-bottom: 50px !important;
}
.shortcodetable td {
  border-bottom: solid 1px #eeeeee;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.shortcodetable {
  border: solid 1px #eeeeee;
}
.width-20 {
  width: 20%;
}
.width-50 {
  width: 50%;
}
.stickybtn {
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;
}
.shortcodetable .btn-custom {
  height: 30px;
  padding: 2px !important;
}
.sidebarmenuwrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.btnswrap {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-height: 768px) {
  /* .stickybtn{
    position: relative;
    bottom: auto;
    margin-bottom: 15px;
  } */
}
/* splitpanel css start */
.mainappoinmentwrap.showreasonfield {
  height: calc(100vh - 166px);
}
.mainappoinmentwrap.showreasonfield.previous-unfinished-view {
  height: calc(100vh - 97px);
}
.mainappoinmentwrap.showreasonfield.previous-finished-view {
  height: calc(100vh - 167px);
}
.mainappoinmentwrap.showreasonfield .SplitPane {
  top: 140px !important;
}
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 1px;
  margin: -1px 0;
  border-top: 0.5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
/* .Resizer.horizontal {
  height: 1px;
  margin: -1px 0;
  border-top: 0.5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  height: 11px;
  margin: 5px 0;
} */
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.walkingappointmentsecscroll {
  width: 100%;
  background: #fff;
  position: relative;
}
.sectionscroll.pre_encountersecscroll {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.mainappoinmentwrap .scrollbar-container {
  width: 100%;
}
.mainappoinmentwrap.hidereasonfield {
  height: calc(100vh - 60px);
}
/* .showreasonfield .walkingappointmentsecscroll .sectionsubscroll {
    height: auto;
  } */
.checkboxgroup .ant-radio-group.ant-radio-group-outline {
  display: flex;
}
.checkboxgroup .ant-radio-group label.ant-radio-wrapper {
  margin-top: 0px;
}
.checkboxmaintitle {
  font-size: 12px;
}
.search_symtoms {
  text-decoration: underline !important;
}
.checkboxgroup span.ant-radio + * {
  font-size: 11px;
}
.reason_wrapper .react-select-container {
  width: 100%;
  flex: 1;
}
.reason_wrapper .react-select__control {
  height: auto !important;
  align-items: center;
  background: #f9f9f9 !important;
  min-height: 28px !important;
}
.react-select-container .react-select__multi-value {
  background-color: #1445b7;
}
.react-select-container .react-select__multi-value__label {
  color: #fff;
  font-size: 12px;
  padding-block: 2px;
}
.react-select-container  .react-select__value-container--is-multi {
  padding-block: 0;
}
.react-select-container .react-select__multi-value__remove {
  cursor: pointer;
  color: #fff;
}
.react-select-container .react-select__multi-value__remove:hover {
  background-color: transparent;
  color: #fff;
}
.react-select-container .react-select__indicator {
  padding: 3px;
}
.reason_wrapper .css-g1d714-ValueContainer,
.reason_wrapper .react-select__value-container {
  height: auto;
}
.tabledata .ant-table-thead > tr > th,
.tabledata .ant-table-tbody > tr > td {
  padding: 5px 10px;
}
.mainappoinmentwrap {
  height: calc(100vh - 56px);
  padding-top: 40px;
}
.mainappoinmentwrap .reason_wrapper {
  padding-top: 14px !important;
}
.mainappoinmentwrap .consultantion-info-table-wrapper {
  padding-top: 0px;
}
.mainappoinmentwrap.showreasonfield {
  padding-top: 0px;
}
.walkin_appointment .mainappoinmentwrap.showreasonfield {
  padding-top: 30px;
}
[data-attribute="horizontal"][data-type="Resizer"]{
  height: 11px;
  margin: -5px 0px;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background: padding-box rgb(0, 0, 0);
}
.shortcodewrap .search-filter-downdown-input .input-suffix {
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  align-items: center;
}
.shortcodewrap
  .search-filter-downdown-input
  .ant-input-affix-wrapper
  input.ant-input {
  top: 0px;
  bottom: 0px;
}
.editicon {
  width: 30px;
  height: 30px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.shortcodewrap button.clear_btn {
  right: 30px;
  top: 0px;
  bottom: 0px;
}
.shortcodewrap .font-11.in-block {
  line-height: 1.3;
  font-size: 12px !important;
}
.width-88 {
  width: 88% !important;
}
.width-82 {
  width: 88% !important;
}

.shortcodewrap .shortscodepanel {
  font-size: 12px !important;
}
.commnspace.nospace {
  margin-top: 0px !important;
}
.SplitPane.horizontal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  outline: none;
  overflow: hidden;
  user-select: text;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-flex: 1;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  position: inherit !important;
}

.walkin_appointment .walkin_detail_header + .patient_detailswrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.reason_wrapper .react-select-container {
  /* min-height: 60px; */
  background: #f9f9f9 !important;
}
.reason_wrapper .react-select__value-container {
  background: transparent !important;
}
.walkin_appointment .healthassesment-list {
  padding-left: 10px;
  padding-right: 10px;
}
/* walking appoinement new UI css start */
.questionary_select .ant-select {
  width: 100%;
}
.highlightedtext .numberinput .ant-input-number {
  /* width: 50px; */
  width: 100%;
}
.checkboxgroup .highlightedtext {
  padding: 4px 5px;
  border-left: 1px solid #e9e9e9;
  /* border-right: 1px solid #e9e9e9; */
  /* border-bottom: 1px solid #e9e9e9; */
  /* margin-bottom: 10px; */
  border-radius: 2px;
  height: 100%;
}
.checkboxgroup .form-row {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.checkboxgroup {
  margin-bottom: 10px;
}
.checkboxgroup .highlightedtext input.ant-input-number-input {
  width: 100% !important;
  padding: 0 5px !important;
  height: 26px;
  font-size: 12px;
}
.inputbox a {
  width: auto;
  margin-right: 6px;
  display: inline-block;
  flex: 1;
  font-size: 12px;
  font-family: "Gotham Book" !important;
  width: 60%;
  word-break: break-all;
  line-height: 1.2;
}
.infoshortcodebox textarea {
  resize: none;
}
.tabledata .ant-table-body {
  border-bottom: 1px solid #f0f0f0;
}
/* questionaire css start */
.radiopbtn .ant-radio-group {
  display: flex;
  align-items: center;
}
.single-choice-question_wrapper .radiopbtn .ant-radio-group {
  align-items: flex-start;
}
.radiopbtn .ant-radio-group label.ant-radio-wrapper {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  min-width: 100px;
}
.radiopbtn .ant-radio {
  top: 0 !important;
}
.quastionanairepopup .btn-container {
  margin-top: 20px;
}
.quastionanairepopup .font-14 {
  font-size: 12px !important;
  line-height: 1.3;
}
.questionnaire_answer_wrapper .Questions {
  line-height: 1.3;
}
.check-agree-question_wrapper .ant-checkbox-wrapper {
  line-height: normal;
}
.quastionanairepopup
  .ant-modal-body
  label.ant-checkbox-wrapper
  .ant-checkbox
  + span {
  font-size: 10px !important;
}
.quastionanairepopup .text-danger,
.quastionanairepopup .text-primary {
  margin-top: 8px !important;
  display: block;
  font-size: 12px !important;
}
.quastionanairepopup .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
  margin-right: 8px !important;
  margin-bottom: 0px !important;
}
.quastionanairepopup .ant-modal-body label.ant-checkbox-wrapper {
  margin-bottom: 0px !important;
}
.quastionanairepopup .form-row.child-questions {
  margin: 0px;
  /* margin-top: 15px; */
  padding-left: 0px !important;
  width: 100%;
}
.quastionanairepopup .form-row.child-questions {
  padding-left: 14px !important;
}
.child-questions .margin-t-15 {
  margin-top: 15px;
}
.quastionanairepopup .form-row.child-questions .col-md-12 {
  padding: 0;
}
.quastionanairepopup .form-row.child-questions {
  padding-left: 0px !important;
}
.quastionanairepopup .form-row.child-questions .margin-top-10 {
  margin-top: 15px;
}
.quastionanairepopup .form-row.child-questions label.ant-checkbox-wrapper {
  width: 16.66%;
}
.quastionanairepopup .form-row.child-questions .ant-checkbox-wrapper {
  margin-right: 8px !important;
}
.quastionanairepopup .ant-checkbox-wrapper {
  /* width: 16.66%; */
  margin-right: 8px !important;
}
.quastionanairepopup .ant-checkbox-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ant-modal-body .quastionanairepopup label.ant-checkbox-wrapper {
  margin-bottom: 0px;
}
/* walking apt new changes css start */
button.ant-btn.imagebtn {
  padding: 0px;
  height: auto;
  border: none;
  line-height: inherit;
}
button.ant-btn.imagebtn > .anticon {
  line-height: inherit !important;
}
.imagebtn svg {
  fill: #1445b7;
}
.numberinput {
  width: 32px;
}
.preg_input .inputbox a,
.preg_input .numberinput {
  width: 50%;
}
.padding-t-0 {
  padding-top: 0px !important;
}
.maintable .highlightedtext {
  width: 40%;
  align-items: center;
  padding: 5px 5px;
}
.maintable .highlightedtext input.ant-input-number-input {
  width: 100% !important;
}

.maintable .highlightedtext .ant-input-number {
  flex-grow: 1;
}
.padding-5 {
  padding: 5px;
}
.consultantion-info-table .ant-collapse-content-box {
  padding: 0 12px;
}
.consultantion-info-table .tablebodymain {
  font-size: 12px !important;
}
.consultantion-info-table .tablebodymain .tablerow:first-child {
  border: none !important;
}
.keytd {
  width: 40%;
  padding-right: 10px;
}
.keytd.w-60 {
  width: 60%;
}
.valuetd {
  width: 20%;
}

.inputtd {
  width: 40%;
}
.inputtd .ant-input-number:not(.two_input .ant-input-number) + span {
  display: inline-block;
  width: 32px;
  min-width: 32px;
  text-align: left;
}
/* .highlightedtext.d-flex.bigfields {
    width: 60%;
} */
/* .bigfields .valuetd {
  width: 13.33%;
}
.bigfields .inputtd{
  width: 63.33%;
} */
.maintable {
  font-size: 12px !important;
}
.inputtd + div {
  justify-content: flex-end;
}
.maintable .highlightedtext {
  border: none;
}
.tablerow {
  border-top: 1px solid #e9e9e9;
}
.checkboxgroup .col.highlighted-stroke,
.checkboxgroup .col.highlighted-phq9,
.checkboxgroup .col.highlighted-sepsis,
.checkboxgroup .col.highlighted-gad7 {
  max-width: 15% !important;
  flex: 0 0 15%;
}
.checkboxgroup .col.highlighted-stroke .numberinput,
.checkboxgroup .col.highlighted-phq9 .numberinput,
.checkboxgroup .col.highlighted-sepsis .numberinput,
.checkboxgroup .col.highlighted-gad7 .numberinput {
  width: 32px;
}
.checkboxgroup .col.highlighted-stroke .inputbox a,
.checkboxgroup .col.highlighted-phq9 .inputbox a,
.checkboxgroup .col.highlighted-sepsis .inputbox a,
.checkboxgroup .col.highlighted-gad7 .inputbox a {
  width: 40%;
}
.consultantion-info-table .tablebodymain .highlightedtext,
.consultantion-info-table-wrapper .maintable .highlightedtext {
  border-right: 1px solid #e9e9e9 !important;
}
.consultantion-info-table .tablebodymain .highlightedtext + .highlightedtext,
.consultantion-info-table .tablebodymain .highlightedtext.bigfields {
  border-right: none !important;
}
.maintable .tablebodymain .highlightedtext {
  width: 50%;
}
.maintable .tablebodymain .highlightedtext.bpfields {
  width: 100%;
}
.maintable .tablebodymain .highlightedtext.bpfields .keytd {
  width: calc(20% - 2px);
}
.maintable .tablebodymain .highlightedtext.bpfields .valuetd {
  width: calc(10% - 2px);
}
.maintable .tablebodymain .highlightedtext.bpfields .inputtd {
  width: 70%;
}
.maintable .highlightedtext.bpfields input.ant-input-number-input {
  width: 80px !important;
}
.tablehead .w-43 {
  width: 20.5%;
}
.tablehead .w-23 {
  width: 23.33%;
}
.tablehead .w-40 {
  width: 40%;
}
.tablehead .w-20 {
  width: 20%;
}
.dragbtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d0d0da;
  position: absolute;
  z-index: 999;
  top: -15px;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  text-align: center;
  cursor: pointer;
}
.btn-updown {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.uprrow {
  margin-top: -2px;
}
/* .dragbtn {
  margin-right: 20%;
} */
span.downarrow,
span.uprrow {
  font-size: 13px;
  line-height: 0;
}
span.downarrow {
  position: relative;
  display: flex;
}
span.uprrow {
  position: relative;
  display: flex;
}
.uprrow .anticon-caret-up, .downarrow .anticon-caret-down {
  display: flex;
}
.stickfilter {
  position: absolute;
  top: -49px;
  right: 0;
}
.mainappoinmentwrap .walkingappointmentsecscroll .ant-tabs-nav {
  padding-top: 18px;
  padding-bottom: 10px;
  margin-bottom: 6px !important;
}
.mainappoinmentwrap .walkingappointmentsecscroll .ant-tabs-tab {
  padding-top: 0px !important;
}
.mainappoinmentwrap .search-filter-downdown-input .ant-input-affix-wrapper {
  min-width: 400px;
}
.mainappoinmentwrap .searchbar_dropdown.medical_Search {
  min-width: 400px !important;
}
.mainappoinmentwrap
  .walkingappointmentsecscroll
  .screenshort_tabs
  .ant-tabs-nav {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.consultant_review .rta__autocomplete {
  word-break: break-all;
  top: 16px !important;
  left: 4px !important;
  font-size: 12px;
  width: 100%;
  font-family: "Gotham Book";
}
.consultant_review .rta__list {
  width: 99%;
  max-height: 200px;
  overflow: auto;
}
/* .consultant_review .rta__item:not(:last-child){
  border:none !important;
} */
.consultant_review .rta__entity--selected {
  background-color: #deebff;
  /* color: #404040; */
  color: rgba(64, 64, 64, 0.86);
}
.consultant_review .rta__entity > * {
  padding: 8px;
  white-space: pre-line;
  word-break: break-all;
  color: rgba(64, 64, 64, 0.86);
}
.mainappoinmentwrap .consultantion-info-table-wrapper {
  padding-top: 15px;
}
.patientdetailpage .stickfilter {
  top: -58px;
  right: 7px;
}
.patientdetailpage .commnspace .ant-tabs-tab {
  padding-top: 13px !important;
  padding-bottom: 18px !important;
}
.shortcodefiltermain {
  position: absolute;
  right: 0px;
  z-index: 1;
}
.shortaddbtn {
  padding-right: 0px !important;
}
.shortcodewrap .ant-tabs-tab {
  padding-bottom: 14px !important;
}
.shortcodewrap .ant-tabs {
  font-family: "Gotham Book";
}
.addshortcodepopup .font-10 span {
  font-size: 10px !important;
}
.addshortcodepopup .ant-modal-body label {
  font-size: 10px !important;
}
.shortcodefiltermain .search-filter-downdown-input .ant-input-affix-wrapper {
  min-width: 300px;
}
.shortcodetable th {
  padding-right: 10px;
}
.shortcodewrap .appointment_nodata {
  margin-bottom: 10px !important;
}
.quastionanairepopup b {
  margin-left: 5px;
  font-family: "Gotham Bold";
}
.quastionanairepopup .child-questions .col-md-4 > div:not(.not-p-l) {
  padding-left: 20px;
}
.quastionanairepopup .font-14.margin-bottom-10 {
  margin-bottom: 0px !important;
}
.quastionanairepopup .form-row {
  align-items: center;
}
.full_box .col-md-2 {
  flex: 0 0 100%;
  max-width: 100%;
}
.full_box .ant-radio-group,
.full_box .ant-checkbox-group {
  width: 100%;
  display: grid;
  margin: 0 !important;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
}
.full_box .ant-radio-group .ant-radio-wrapper,
.full_box .ant-checkbox-group .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start !important;
  margin: 0 !important;
}
.full_box .ant-radio-group .ant-radio-wrapper span:not(.ant-radio),
.full_box .ant-checkbox-group .ant-checkbox-wrapper span:not(.ant-checkbox) {
  flex-grow: 1;
}
.quetionanirewrap .col-md-2 input,
.quastionanairepopup .col-md-2 input {
  border-color: rgb(176 176 176) !important;
}
.TextAreaQuestion_Wrapper textarea {
  min-height: 80px !important;
  border-color: rgb(176 176 176) !important;
}
.quastionanairepopup .full_box .font-14.margin-bottom-10,
.quastionanairepopup .font-14.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.check-agree-question_wrapper .ant-checkbox {
  top: 1px;
}
.single-choice-question_wrapper .full_box .ant-radio {
  top: 4px !important;
}
.TextBoxInputItem_Wrapper .Questions > p:not(:last-child) {
  margin-bottom: 8px !important;
}
.quastionanairepopup .margin-t-15 {
  margin-bottom: 15px;
  margin-top: 0px;
}
.radiobtnrow .label.ant-radio-wrapper {
  width: 33.33%;
}
.ant-modal-body .quastionanairepopup .full_box label.ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.ant-modal-body
  .quastionanairepopup
  .margin-t-15.full_box
  label.ant-checkbox-wrapper {
  margin-bottom: 10px;
}
/* .quastionanairepopup b.required{
  color:#ff4a37;
} */
.roleselect .custom-arrow.form-control {
  padding: 0 !important;
}

.genderselect
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.roleselect .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 26px !important;
}
.patientconsultation .stickfilter {
  top: -49px;
}
.gptitle span {
  color: #404040;
  margin-bottom: 14px;
  font-family: "Gotham Book";
  font-size: 12px !important;
  display: block;
}
.mt-14 {
  margin-top: 14px;
}
.presentingcomp_popup img {
  max-width: 100%;
}
.down-arrow .uprrow {
  display: none;
}
.up-arrow .downarrow {
  display: none;
}
.header_wrapper_right.pl-0 {
  padding-left: 0px !important;
}
.header_wrapper_left {
  margin-left: auto;
}
.tabledata {
  position: relative;
  z-index: 999;
  pointer-events: auto !important;
}
.walkinheaderrt .timer_counter {
  min-width: 85px !important;
}
.font-10_5 {
  font-size: 10.5px !important;
}
.pharmacymainbox {
  padding: 8px 10px;
  border: 1px solid #1445b7;
}
.flex-1 {
  flex: 1;
}
.notetxt p {
  font-size: 10px !important;
  font-family: "Gotham Medium";
  font-weight: 500;
  color: #ff5964 !important;
  margin-top: 8px;
  margin-bottom: 5px;
}
.newcodetable th label {
  margin-bottom: 0px !important;
  display: flex !important;
  align-items: center;
}
.w-33 {
  width: 33.3%;
}
.w-53 {
  width: 46.3%;
}
.w-13 {
  width: 20.3%;
}
.practicename,
span.address {
  text-transform: capitalize !important;
  display: inline-block;
}
/* .ant-select-item-option,
.ant-select-item-option *,
.ant-select-dropdown {
  text-transform: capitalize !important;
} */
b {
  font-family: "Gotham Medium";
}
.autotextshortcodetable p {
  margin-bottom: 0px;
}
.tableHeader {
  background: #1445b7 !important;
  color: white !important;
  /* margin-bottom: 3px; */
  /* border-bottom: 0px white solid; */
  font-weight: bolder !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}
.autotextshortcodetable .tabledata {
  display: flex;
  align-items: center;
}
.autotextshortcodetable .tabledata p.description {
  flex: 1;
  margin-left: 10px;
}
.autotextshortcodetable .tabledata p.code {
  min-width: 52px;
  max-width: 52px;
}
.ant-select-selector .ant-select-selection-item b {
  font-family: "Gotham Book" !important;
  font-weight: 400;
}
.ant-select-item-option.full_txt .ant-select-item-option-content {
  overflow: visible !important;
  text-emphasis: none !important;
  white-space: normal !important;
}
.drop_name .ant-btn-primary,
.drop_name .ant-btn-primary span {
  font-size: 10px;
}
.drop_name .ant-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-select__menu-list {
  color: #404040 !important;
}
.infoicon .infobtn {
  padding: 0px;
  border: none !important;
  display: flex;
  align-items: center;
}
.infoicon .infobtn span {
  display: flex;
  align-items: center;
  color: #1445b7;
  font-size: 12px;
}
.infobodymain ul.mainlist > li {
  position: relative;
  padding-left: 20px;
  list-style-type: none;
  font-size: 12px;
  font-family: "Gotham Book";
  margin-bottom: 10px;
  line-height: 1.5;
}
.infobodymain ul.mainlist > li::before {
  content: "";
  position: absolute;
  background: url(../images/common/bookmark-check_blue.svg) no-repeat 0 0;
  width: 14px;
  height: 19px;
  top: 3px;
  left: 0;
  background-size: 12px;
}
.infobodymain,
.checklistinfomain {
  max-width: 600px;
}
.featureslist {
  margin-top: 10px;
}
.featureslist ul li {
  list-style-type: none;
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
  padding: 4px 10px;
  border: 1px solid #e9e9e9;
  border-bottom: none;
}
.featureslist ul li:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.infobodymain ul.mainlist {
  margin-bottom: 0px;
  border-bottom: 1px solid #e9e9e9;
}
.plaintext {
  padding: 10px 0 0;
}
.plaintext p {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
}
.checklistinfomain p {
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
}
.checklistinfomain span.smalltext {
  font-size: 11px;
  font-family: "Gotham Book";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
}
.checklistinfomain b {
  font-weight: 500;
}
.rankuingchart {
  text-align: center;
  margin-bottom: 5px;
}
.rankuingchart ul {
  display: inline-block;
  margin: auto;
  margin-bottom: 0px;
}
.rankuingchart ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Gotham Book";
  line-height: 1.5;
  font-size: 12px;
}
.rankuingchart ul li span {
  width: 40px;
  height: 25px;
  margin-right: 10px;
}
.rankuingchart ul li span.more_likely {
  background: #ff7f27;
}
.rankuingchart ul li span.likely {
  background: #fdc400;
}
.rankuingchart ul li span.less_likely {
  background: #eadb95;
}

.rankuingchart ul li {
  display: flex;
  align-items: center;
}

/* checklist css start */
.panelrow {
  display: flex;
  align-items: center;
}
.panelmainbox .icon {
  width: 18px;
  margin-right: 10px;
}
.symtomsquestion h3 {
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
  margin-bottom: 0px;
}
.symtomscode p {
  border: 1px solid rgba(0, 0, 0, 0.85);
  padding: 2px 10px;
  border-radius: 25px;
  margin: 0px;
  font-size: 11px;
}
.tabledata .ant-table-tbody > tr.ant-table-row:hover > td {
  color: rgba(0, 0, 0, 0.85) !important;
}
.symtomscode,
.symtomsquestion {
  margin-right: 10px;
}
.symtomsquestion,
.leftheading {
  flex: 1;
}
.symtomsinfoicon .ant-btn {
  padding: 0;
  margin: 0;
  border: none;
}
.panelrow_child .symtomsquestion {
  display: flex;
  align-items: center;
}
.questionbox {
  border: 1px dashed #1445b7;
  margin: 5px 0;
}
.questionhead {
  padding: 4px 8px;
  border-bottom: 1px dashed #1445b7;
}
.questionboxcontent {
  padding: 4px 8px;
}
.questionhead {
  position: relative;
}
.questionhead .ant-modal-close-x {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
}
.questionhead .ant-modal-close {
  height: 100%;
  right: 8px;
}
.questionhead h3 {
  font-size: 12px;
  font-family: "Gotham Medium";
  line-height: 1.5;
  margin-bottom: 0px;
}
.questionboxcontent p {
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
}
.panelmainbox .show {
  display: block !important;
}
.panelmainbox .hide {
  display: none !important;
}
.rightheading .ant-btn {
  padding: 0px !important;
}
.questionpopover p {
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85) !important;
}
.ispregnentbox .checkboxgroup {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-wrap: wrap;
  justify-content: center;
}
.ispregnentbox .checkboxmaintitle {
  margin-right: 8px;
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85) !important;
  display: block;
}
.checkboxgroup span.ant-radio + * {
  font-family: "Gotham Book";
}
.ispregnentbox {
  flex: 1;
}
.labelicon {
  margin-right: 10px;
}
.consultantion-info-table-wrapper .religon_drop .form-control {
  height: 32px;
  border: none !important;
}
.consultantion-info-table-wrapper .religon_drop .ant-select-selector {
  padding: 0 11px !important;
}
.consultantion-info-table-wrapper .religon_drop .ant-select {
  width: 100%;
  padding: 0px !important;
}
.religon_drop label {
  font-size: 12px;
  font-family: "Gotham Book";
  line-height: 1.5;
  color: #1445b7 !important;
}
.walkingquestions {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.walkingquestions label {
  margin-bottom: 0px !important;
}
.ant-popover-title {
  font-family: "Gotham Book";
}
.numberinput input.custom-input-number {
  width: 100%;
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 5px !important;
  font-size: 12px;
  height: 26px;
  font-family: "Gotham Book";
}
.numberinput input.custom-input-number:focus-visible {
  outline: none !important;
}
.newpatientdetails table td .contact_info > span {
  max-width: 96%;
}
.newp_name {
  width: 11.5%;
}
.text-underline {
  text-decoration: underline;
}
a.text-underline:hover {
  text-decoration: underline;
}
/* .usermenu_dropdown._renderQRItems_Wrapper.ant-dropdown-menu .ant-dropdown-menu-item, .usermenu_dropdown.width-262.ant-dropdown-menu .ant-dropdown-menu-item { */
.usermenu_dropdown.width-262.ant-dropdown-menu .ant-dropdown-menu-item, .usermenu_dropdown._renderQRItems_Wrapper.ant-dropdown-menu .ant-dropdown-menu-item {
  border-bottom: 1px solid #eee;
}
.width-262 {
  width: 262px;
}
.abbervationpopup .shortcodefiltermain {
  position: relative;
  margin-right: 35px;
}
.abbervationpopup
  .search-filter-downdown-input
  .ant-input-affix-wrapper
  input.ant-input {
  top: 7px !important;
}
.abbervationpopup .ant-modal-content button.ant-modal-close {
  top: 14px;
}
.abbervationpopup button.clear_btn {
  top: 7px;
}

.preconsulttabwrap .ant-tabs-tab-active .icons_wrapper_title {
  color: #fff !important;
}
.preconsulttabwrap .ant-tabs-tab .icons_wrapper_title {
  /* padding: 4px 20px !important; */
  width: 100%;
}
.preconsulttabwrap .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px;
}
.preconsulttabwrap .ant-tabs-tab-btn {
  min-width: 120px;
  text-align: center;
  border-radius: 2px;
}
.preconsulttabwrap .ant-tabs-tab.ant-tabs-tab-active .firsttab {
  background: #1445b7;
}
.preconsulttabwrap .ant-tabs-tab .firsttab {
  color: #1445b7;
}
.preconsulttabwrap .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.preconsulttabwrap .ant-tabs-tab.ant-tabs-tab-active,
.preconsulttabwrap .ant-tabs-tab {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/* optional css */
.preconsulttabwrap .ant-tabs-tab {
  border-radius: 4px;
  border-bottom: none !important;
}
.preconsulttabwrap .ant-tabs-tab-btn {
  border: 1px solid #eeeeee;
  border-bottom: none !important;
  border-left: 0px;
}
.preconsulttabwrap .ant-tabs-nav-list > .ant-tabs-tab:first-child {
  border-left: 1px solid #eeeeee;
}
.react-calendar__month-view__weekdays {
  font-size: 0.75em !important;
}
@media (min-width: 1920px) {
  .overflow_class {
    max-width: 220px;
  }
  .newp_name {
    width: 11%;
  }
}
@media (max-width: 1920px) and (min-width: 1600px) {
  .overflow_class.address_one {
    max-width: 120px;
  }
  span.overflow_class.gp_addressname,
  p.overflow_class.gp_address {
    max-width: 127px;
  }
  p.overflow_class.pharmacy_name,
  p.overflow_class.pharmacy_address {
    max-width: 167px;
  }
  .overflow_class.adress_one,
  .overflow_class.address_two {
    /* max-width: 148px; */
    max-width: 100px;
  }
  .contact_info {
    width: 88%;
  }
  p.overflow_class.pharmacy_name,
  p.overflow_class.pharmacy_address {
    max-width: 150px;
  }
  .newp_name {
    width: 11%;
  }
}
@media (max-width: 1550px) and (min-width: 1400px) {
  .checkboxgroup .col.highlighted-stroke,
  .checkboxgroup .col.highlighted-phq9,
  .checkboxgroup .col.highlighted-sepsis,
  .checkboxgroup .col.highlighted-gad7 {
    max-width: 14% !important;
    flex: 0 0 14%;
  }
  .checkboxgroup .col {
    max-width: 24% !important;
    flex: 0 0 24%;
  }
  .overflow_class.address_two {
    max-width: 95px;
  }
  .overflow_class.pharmacy_address {
    max-width: 100px;
  }
  .overflow_class {
    max-width: 100px;
  }
  .contact_info {
    width: 88%;
  }
  .newp_name {
    width: 9%;
  }
}
@media (max-width: 1400px) {
  span.overflow_class.gp_addressname {
    max-width: 90px;
  }

  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultant_review:has(+ .consultantion-info-table-wrapper) {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .dynamic-component-container .col-md-8.maincol {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .preg_input .inputbox a {
    width: 47%;
  }
  .preg_input .numberinput {
    width: 53%;
  }
  .newp_name {
    width: 9%;
  }
}

@media (max-width: 1199px) {
  .dynamic-component-container:has(~ .video-call-panelinit)
    .consultantion-info-table-wrapper {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .dynamic-component-container .col-md-8.maincol {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
/* 15-6-2023 search patient ui changes  */
/* sso css start */
.csbtn a {
  background: #fff;
  padding: 0px 6px;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
  border-radius: 2px;
  font-weight: 500;
  color: #1445b7;
  min-height: 30px;
  min-width: 104px;
}
.csbtn {
  margin: 0 4px;
}
.header_wrapper_left {
  padding-left: 0 !important;
}
/* .header_wrapper_left {
  flex: 0 0 43.333333%;
  max-width: 43.333333%;
}
.header_wrapper_right {
  flex: 0 0 56.666667%;
  max-width: 56.666667%;
} */
/* examination table css start */
.highlightedtext .ant-input-number {
  width: 70%;
  border-radius: 4px;
}
.highlightedtext input.ant-input-number-input {
  width: 67% !important;
  border: none;
}
.ant-input-number:hover,
.highlightedtext .ant-input-number:focus,
.highlightedtext .ant-input-number-focused {
  border-color: #1445b7 !important;
}
.ant-input-number.bg-danger {
  background: #dc3545 !important;
}
.highlightedtext .ant-input-number-focused,
.highlightedtext .ant-input-number:focus {
  box-shadow: none !important;
}
.highlightedtext
  .ant-input-number-handler-wrap:hover
  .ant-input-number-handler {
  height: 50%;
}
.consultantion-info-table-wrapper
  .input-wrapper
  .ant-input-number-handler-wrap
  span.ant-input-number-handler
  .anticon,
.consultantion-info-table-wrapper
  .input-wrapper
  .ant-input-number-handler-wrap
  span.ant-input-number-handler {
  font-size: 7px;
  transform: none;
  left: 0;
  top: 0;
  font-weight: 400;
  position: relative;
  height: 50%;
}
.highlightedtext .ant-input-number-handler-up-inner {
  top: 0;
  margin-top: 0;
  text-align: center;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #1445b7 !important;
}
.highlightedtext .ant-input-number-handler-up:hover,
.highlightedtext .ant-input-number-handler-down:hover {
  height: 50% !important;
}
.highlightedtext .two_input .input-wrapper > span {
  margin: 0 6px;
}
.highlightedtext .two_input .ant-input-number {
  width: 44%;
}
/* .createtableform {
  width: 50%;
} */
.createtableform {
  width: 100%;
}
.width-100px.new_btnwidth {
  width: auto !important;
  padding: 0 10px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background: #1445b7 !important;
}
.savebtn {
  margin-left: 20px;
}
.rightmainsection a,
.rota-wrapper .link-text {
  color: #1445b7;
}
.rightmainsection a.text-underline {
  text-decoration: underline !important;
}
.commoncheckbox .ant-checkbox-wrapper {
  align-items: inherit;
}
.rota_wrapper_data .btn-custom.btn-blue:active,
.rota_wrapper_data .btn-custom.btn-blue:focus span {
  color: #fff !important;
}
.session_btn .btn-custom.btn-blue:hover {
  color: #fff !important;
}
.consultant_review textarea.form-control-textarea.min-height-92,
.consultant_review textarea.form-control-textarea {
  resize: vertical !important;
}
.appointmentpage .patient-call-conatainer .dynamic-component-container .pre_quitionare:not(.walkingappointmentsecscroll .pre_quitionare) {
  padding-top: 10px;
}
/* .walkin_appointment .commnspace {
  border-top: 2px solid #eeeeee;
  margin-top: 22px;
} */
.patientconsultation .padding-55-t {
  padding-left: 10px;
  padding-right: 10px;
}
/* inputnumer without updown arrow in ha table */
.custom-input-number .ant-input-number-handler-wrap {
  display: none !important;
}

.text-black {
  color: black !important;
}
.form-row.child-questions {
  padding-left: 30px;
  margin-top: 13px;
}
/* comment */
/* vertical color fill */
.vertical-color-fill {
  background-color: red; /* Replace 'your-color' with your desired color */
  width: 5px;
  height: 100%; /* This will fill the entire height of the container */
  position: absolute;
  left: 0; /* This will position the fill from the left side */
}

.header-links {
  display: flex;
  justify-content: center;
  padding: 5px;
  background: #1445b7;
  color: white;
  font-weight: 500;
  font-size: small;
}

.header-sub-links {
  border-bottom: 2px solid #1445b7;
  padding: 5px;
}

/* .links-main-container {
  padding: 4px;
} */
.s-header-link-item {
  background: rgb(233 233 233);
  padding: 4px;
  margin-top: 2px;
  color: black !important;
}
.s-header-link-item > a {
  color: #1445b7;
  font-size: 13px;
  font-weight: 600;
}
span.header-sub-links-content {
  color: #404040;
  /* font-size: 12px; */
  font-weight: 600;
  font-size: 10px;
}
.links-loader {
  display: flex;
  align-items: normal;
  /* height: 200px; */
  justify-content: center;
  margin-top: -45px;
}
.dignosis-name > span {
  font-size: 15px;
  font-weight: 700;
  color: #252525;
}

ul.sub-diagnosis {
  padding-left: 30px;
}

li.sub-diagnosis-content {
  list-style: decimal;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  /* text-decoration: underline; */
  cursor: pointer;
  color: black;
}

li.sub-diagnosis-content.active {
  color: #1445b7;
  width: 100%;
  text-decoration: underline;
}
.signup-alert-box {
  font-weight: 700 !important;
  font-size: 12px !important;
  margin-bottom: 17px !important;
}
.mt-18 {
  margin-top: 18px !important;
}
.abbreviaiondrop {
  padding: 12px 15px 12px;
}
.abbreviaiondrop h6 {
  color: #1445b7;
  font-family: "Gotham Book";
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.abbreviaiondrop label {
  font-weight: 400;
  color: #404040;
  font-size: 10px;
  font-family: "Gotham Book";
  display: block;
  margin-bottom: 5px;
}
.abbreviaiondrop .form-control,
.abbreviaiondrop .ant-select {
  background-color: #f9f9f9;
  height: 28px;
  line-height: 27px !important;
  padding: 5px 11px;
  border-radius: 2px;
  width: 100%;
}
.abbreviaiondrop .ant-btn {
  min-width: 90px;
  border-radius: 4px !important;
  font-size: 12px !important;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
}
.abbreviaiondrop .ant-btn-primary {
  background: #1445b7 !important;
  border: 1px solid #1445b7 !important;
}
.mt-2.abberbtn {
  margin-top: 13px !important;
}
.abbreviaiondrop .ant-btn:hover {
  color: #1445b7;
  border-color: #1445b7;
}
.appointmentpage .mainappoinmentwrap .reason_wrapper {
  padding-top: 0px !important;
}
.appointmentpage .mainappoinmentwrap.showreasonfield .walkin_appnt_wrapper {
  padding-top: 0px !important;
}
.appointmentpage
  .dynamic-component-container:has(~ .video-call-panelinit)
  .consultantion-info-table-wrapper {
  padding-top: 0px !important;
}
.ant-btn.infobtn {
  height: auto;
}
.appointmentpage .mainappoinmentwrap.showreasonfield {
  height: calc(100vh - 56px);
}
.appointmentpage .dragbtn a {
  width: 26px;
  height: 26px;
  span {
    &.downarrow,
    &.uprrow {
      font-size: 12px;
    }
    &.downarrow {
      top: 1px;
    }
  }
}

.patient-call-conatainer
  .dynamic-component-container
  .search-filter-downdown-input
  .ant-input-affix-wrapper {
  min-width: 239px;
}
.dynamic-component-container
  .mainappoinmentwrap
  .selection_container
  .form-row {
  justify-content: space-between;
}
.appointmentpage .mainappoinmentwrap .reason_wrapper {
  margin-bottom: 10px !important;
}
.coding_modal_table th .ant-checkbox-wrapper {
  margin-bottom: 0px !important;
}
.csbtn a {
  font-family: "Gotham Book" !important;
  font-size: 10px;
}
/* diasble class for patient listing if pharmacy not matched start */

.disable-listing {
  position: relative;
}
.disable-listing:before {
  content: "";
  position: absolute;
  background: rgba(253, 253, 253, 0.1);
  backdrop-filter: blur(3px);
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: not-allowed;
}
/* diasble class for patient listing if pharmacy not matched end */
.ant-btn-primary.request_otp span {
  color: #fff !important;
  font-size: 10px !important;
}
.ant-btn-primary.request_otp {
  padding: 4px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  margin: auto;
}
.veriftotp_popup .otp-content-wrapper {
  margin-left: 0px !important;
  margin-top: 20px;
}
.veriftotp_popup
  .otp-content-wrapper
  .body-content
  .body-verify-content
  .body-verify-text
  p {
  font-size: 14px !important;
}
.veriftotp_popup.ant-modal .ant-modal-content .ant-modal-title {
  font-size: 16px !important;
}
.veriftotp_popup .ant-modal-body label.font-12 {
  font-size: 14px !important;
}
.icons .anticon {
  font-size: 12px !important;
}
.icons .form-control {
  background-color: #f9f9f9;
}
.ant-tag-processing {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.mx-width-74 {
  max-width: 74px !important;
}
.dropdownfield {
  flex: 1;
  margin-right: 10px;
}
.viewbtn .ant-btn {
  height: 28px;
  line-height: 16px;
}
.full-width {
  width: 100%;
}
/* .ant-modal-content .ant-modal-body .ant-btn:hover {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
} */
.ant-tag-processing {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.shortcodetable .font-11.in-block {
  font-size: 12px !important;
  font-family: "Gotham Book" !important;
}
.disabled-view {
  pointer-events: none;
  opacity: 0.7;
}
.managmentplan .rta {
  height: auto !important;
}
.word-break {
  word-break: break-all;
}
.diagnosisdropwn .font-11.in-block {
  font-size: 11px !important;
}
.patientdetailsmain .font-11.in-block {
  font-size: 11px !important;
}
.inreviewconsultion .mainappoinmentwrap .consultantion-info-table-wrapper {
  padding-top: 0px;
}
.inreviewconsultion .mainappoinmentwrap .reason_wrapper {
  padding-top: 0px !important;
}
.submitBtn {
  position: relative !important;
  z-index: 2;
}
.quastionanairepopup sup.text-danger {
  font-size: 20px !important;
  top: -0.1rem !important;
  left: 2px;
}
.profomaquestionaire .ant-modal-content .ant-modal-body {
  padding-top: 36px !important;
}

/* QR COde */
.qrcodeinner > span {
  display: block;
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  color: #0a2f3a;
  margin-bottom: 30px;
}
.qrcodeinner > span.anticon.anticon-arrow-down {
  color: #ff5426;
  margin-bottom: 10px;
}
.qrcodeinner {
  text-align: center;
}
.gap-4px {
  gap: 4px;
}
.gap-6px {
  gap: 6px;
}
.gap-8 {
  gap: 8px;
}
.logo_w img {
  width: 106px !important;
}
.user_role .ant-tag-processing {
  font-size: 10px !important;
  margin-right: 0px !important;
}
.userdropdown .ant-dropdown-trigger {
  font-size: 10px;
}
.userdropdown .ant-tag {
  font-size: 10px;
}
.margin-left-4 {
  margin-left: 4px;
}
.margin-right-4 {
  margin-right: 4px;
}
.preconsultview {
  background: #f6f6f6 !important;
}
.preconsultview ul li a {
  border-top: 1px solid #d9d9d9 !important;
  min-height: 34px !important;
  padding: 2px 13px !important;
  display: inline-block;
  width: 100%;
  color: #757575;
  line-height: 31px;
}
.preconsultview ul li:last-child a {
  border-bottom: 1px solid #d9d9d9 !important;
}
.pre_questionairepopup b,
.appointment-calendar_wrapper .fc-event-main b {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}
.pre_questionairepopup span.ant-divider-inner-text b {
  color: #1445b7;
  font-family: "Gotham Book" !important;
  font-weight: 400;
  font-size: 14px;
}
.pre_questionairepopup
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  background: none;
  background: #1445b7;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.pre_questionairepopup .dot {
  top: 7px;
}
.Questions,
.Questions * {
  font-size: 16px;
  word-break: break-word;
}
.pre_questionairepopup .radiopbtn .ant-radio-group label.ant-radio-wrapper {
  min-width: 80px;
}
.pre_questionairepopup
  .ant-divider-horizontal.ant-divider-with-text-left::before {
  display: none;
}
.pre_questionairepopup
  .ant-divider-horizontal.ant-divider-with-text-left
  .ant-divider-inner-text {
  padding-left: 0px;
}
.pre_questionairepopup
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding-top: 6px;
  padding-bottom: 6px;
}
.pre_questionairepopup sup {
  top: 7px;
}
.quetionanirewrap .ant-divider-with-text-left {
  margin-top: 5px !important;
}
.questionairesubmit {
  margin-top: 15px;
  display: inline-block;
}
.questionanspart .ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 0%;
}
.questionanspart
  .ant-divider-horizontal.ant-divider-with-text-right
  .ant-divider-inner-text {
  padding-right: 0px;
}
/* .questionanspart .ant-divider-horizontal.ant-divider-with-text-right {
  margin-top: 5px !important;
} */
.questionanspart
  .ant-divider-horizontal.ant-divider-with-text-right
  .ant-divider-inner-text
  .anticon {
  font-size: 16px !important;
}
.question_wrapper .font-14 {
  font-size: 14px;
  line-height: 1.5;
}
.questionanspart .add-scroll-to-answereview {
  padding-bottom: 10px;
  padding-right: 10px;
}
.outcomepart {
  padding: 13px 15px;
  border: 3px solid #1445b7;
  margin-bottom: 20px;
  border-radius: 4px;
}
.outcomediv h3 {
  font-size: 18px;
  color: #1445b7;
  font-family: "Gotham Medium";
}
.outcomediv p {
  font-size: 14px;
  font-family: "Gotham Book";
  line-height: 1.5;
  white-space: pre-line;
}
/* .quetionanirewrap label.ant-checkbox-wrapper:first-child {
  margin-left: 9px;
} */
.quetionanirewrap .full_box .ant-checkbox-group {
  margin: 0 -10px;
}
.ant-modal-body label.ant-checkbox-wrapper:last-child {
  margin-bottom: 0;
}
.questionnaire_answer_wrapper:empty {
  display: none;
}
.questionnaire_answer_wrapper .question_wrapper {
  padding-bottom: 0px;
}
.full_box span.Questions {
  margin-bottom: 3px;
}
.questionnaire_answer_child_wrapper {
  margin-top: 10px;
}
.quetionanirewrap input.ant-input.form-control.fileupload {
  height: auto !important;
}
.quetionanirewrap p a {
  color: #1445b7;
}
.form-row.child-questions {
  margin-top: 0px !important;
  margin-left: 0px !important;
  padding-left: 20px;
}
.color_box {
  padding: 5px 6px !important;
  border-radius: 2px;
}
.text-right.in-block.ml-1.dropdown {
  margin-right: 4px;
}
.questionnaire_answer_wrapper .question_wrapper ul {
  margin-left: 17px;
  margin-top: 15px;
}
.questionnaire_answer_wrapper .question_wrapper ul li {
  margin-bottom: 8px;
  padding-left: 17px;
  position: relative;
}
.search-with-filterdropdown .ant-select.ant-select-multiple {
  padding: 0 !important;
  border: none !important;
}
/* questionaire popup radiobutton style */

h5.questionnairename {
  font-size: 16px !important;
  font-family: "Gotham Medium";
  margin-bottom: 20px;
  text-transform: capitalize;
}
input.form-control.fileupload {
  height: auto !important;
}
.ant-modal-body
  .ant-radio-group.radiogroupbtn
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  margin-left: 0px;
}
.ant-btn-primary.h_28 {
  padding-top: 0;
  padding-bottom: 0;
  height: 28px !important;
}
.mt-20 {
  margin-top: 20px;
}
.Questions img,
.mainquestionnairewrap img {
  max-width: 100%;
  height: auto;
}
.questionnaire_answer_wrapper .question_wrapper p span > p {
  display: inline;
}
.questionnaire_answer_wrapper .question_wrapper pre {
  margin-top: 1em;
}
.usertabledropdown {
  display: flex;
  align-items: center;
  margin-top: 22px;
  font-family: "Gotham Book" !important;
}
.usertabledropdown
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  font-family: "Gotham Book" !important;
}
.newpatientdetails .pagination {
  margin-bottom: 0px !important;
}
.consultquestion .col-md-10 {
  flex: 0 0 72.333333%;
  max-width: 72.333333%;
}
.consultquestion .col-md-2 {
  flex: 0 0 27.666667%;
  max-width: 27.666667%;
}
.quastionanairepopup.consultquestion {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  padding: 10px;
  margin-top: 6px;
}

.consultquestion .margin-t-15.margin-bottom-10,
.quastionanairepopup.consultquestion .margin-t-15 {
  border-bottom: 1px solid #e9e9e9;
}
.form-row.radiobtnrow {
  margin-bottom: 10px;
}
.sendbtns .ant-btn {
  height: 28px !important;
  line-height: 1.3715 !important;
}
.userdropdown.qrcodedropbtn .ant-dropdown-trigger {
  padding: 0px 5px;
  height: 22px;
  line-height: 20px;
}
.userdropdown.qrcodedropbtn .ant-dropdown-trigger:hover {
  color: #404040 !important;
}
span.anticon.anticon-copy svg {
  color: #1445b7 !important;
}
.addpatientmodal .react-date-picker-wrapper .MuiIconButton-root {
  background: url(../images/common/newcalendar_icon.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  margin-right: 0px;
  border-radius: 0px;
}
.addpatientmodal .ant-modal-content .ant-modal-body .ant-btn {
  padding: 10px 16px;
  border-radius: 2px !important;
  font-size: 14px !important;
  height: auto !important;
  min-width: 180px !important;
}
.addpatientmodal .ant-modal-content .ant-modal-body .ant-btn span {
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  opacity: 1;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control::-moz-placeholder {
  /* Firefox 19+ */

  opacity: 1;
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control:-ms-input-placeholder {
  /* IE 10+ */

  opacity: 1;
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  font-size: 14px !important;
  position: relative;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector {
  height: 39px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .thirdrow
  .form-control.ant-select
  .ant-select-selector {
  padding-left: 46px !important;
}

.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .thirdrow
  .form-control.ant-select-show-search:before {
  background: url(../images/common/search_ico.svg) no-repeat 0 0;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
  width: 19px;
  height: 18px;
}
.addpatientmodal .ant-modal-content .ant-modal-close-x .anticon {
  background: url(../images/common/popup_close.svg) no-repeat 0 0;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .thirdrow
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  padding-left: 36px !important;
}
/* span.ellipsis-text,
.ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 115px;
} */
.infoicon svg {
  min-height: 14px;
  min-width: 15px;
}
.min-w-100 {
  min-width: 100px;
}
.text_col {
  margin-right: 2px;
}
.preconsulttable .ellipsis-text {
  display: inline-block;
  max-width: 161px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 100%;
}
.preconsulttable .ellipsis-text.email-ellipsis {
  max-width: 240px;
}
td.overflow_td {
  overflow: hidden;
}
.overflow_td p {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.user_role .ant-tag-processing {
  line-height: 21px;
  margin-top: 2px;
}
@media (max-width: 1550px) {
  .preconsulttable span.ellipsis-text,
  .preconsulttable .ellipsis-text {
    max-width: 135px;
  }
}
@media (max-width: 1366px) {
  .preconsulttable span.ellipsis-text,
  .preconsulttable .ellipsis-text {
    max-width: 115px;
    width: 100%;
    min-width: 100%;
  }
}
.newpre_questionairepopup .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  max-height: 190px;
  overflow-y: auto;
}
.newpre_questionairepopup label.ant-radio-button-wrapper {
  margin: 0 3px 8px !important;
}
.newpre_questionairepopup {
  width: 100% !important;
  max-width: 400px;
}
.newpre_questionairepopup
  .ant-radio-group.radiogroupbtn
  label.ant-radio-button-wrapper {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  text-align: center;
}
button.ant-btn.cancelbtn {
  height: 30px !important;
}
.ant-btn.cancelbtn:hover {
  border-color: #1445b7;
  color: #1445b7;
}
.newpre_questionairepopup .ant-radio-group.radiogroupbtn {
  margin: 0px !important;
}
.newpre_questionairepopup
  .ant-radio-group.radiogroupbtn
  label.ant-radio-button-wrapper:last-child {
  border-left: 1px solid #d0d0da !important;
}
.answertxt {
  margin-top: 10px;
}
.cheboxrow label.ant-checkbox-wrapper {
  margin-left: 0px !important;
  margin-right: 10px;
}
.questionnaire_answer_wrapper .question_wrapper > p > span > p > div {
  margin-top: 10px;
  margin-bottom: 10px;
}
.patient_contentbox {
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
}
.patient_contentbox .secondrowdiv .dragbtn a {
  background: #e1eefb;
  border-color: #e1eefb;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.max-height-550 {
  max-height: 550px;
}
.add-pateint-form-container .ant-input-affix-wrapper {
  display: flex;
  align-items: center;
}
.anticon-eye {
  color: #1445b7;
}
.ha-lowBP {
  background: #16a4fa;
}
.ha-lowBP-text {
  color: #16a4fa !important;
}
.ha-normalBP {
  background: #16b250;
}
.ha-normalBP-text {
  color: #16b250 !important;
}
.ha-preHyperBP {
  background: #d4ca01;
}
.ha-preHyperBP-text {
  color: #d4ca01 !important;
}
.ha-stage1BP {
  background: #f48918;
}
.ha-stage1BP-text {
  color: #f48918 !important;
}
.ha-stage2BP {
  background: #ed1e24;
}
.ha-stage2BP-text {
  color: #ed1e24 !important;
}
.ha-stage3BP {
  background: #c22026;
}
.ha-stage3BP-text {
  color: #c22026 !important;
}
.ha-lowBP input.ant-input-number-input,
.ha-normalBP input.ant-input-number-input,
.ha-preHyperBP input.ant-input-number-input,
.ha-stage1BP input.ant-input-number-input,
.ha-stage2BP input.ant-input-number-input,
.ha-stage3BP input.ant-input-number-input {
  color: #fff !important;
}

.second-sidebar-wrap {
  border-top: 1px solid #eeeeee;
  /* margin-top: 50px; */
  margin-top: 15px;
  padding-top: 15px;
}
.mainquestionnairewrap .tablehead .w-43 {
  width: 25.5%;
}
.pre_questionairepopup .questionanspart .ant-spin-container .mt-3 {
  margin-top: 0px !important;
}
.pre_questionairepopup .quetionanirewrap .ant-divider-with-text-left {
  margin-bottom: 10px;
}
.inlineradiogroup .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.inlineradiogroup .ant-radio-wrapper span.ant-radio {
  top: 0px !important;
}
.inlineradiogroup label {
  font-size: 12px !important;
}
.ant-modal-body label.label-12 {
  font-size: 12px !important;
}
.tabsbox .icons_wrapper {
  line-height: 24px;
}
/* new questionnaire font changes css */
.Questions h1,
.Questions h2,
.Questions h3,
.Questions h4,
.Questions h5,
.Questions h6,
.Questions h1 *,
.Questions h2 *,
.Questions h3 *,
.Questions h4 *,
.Questions h5 *,
.Questions h6 *,
.question h1,
.question h2,
.question h3,
.question h4,
.question h5,
.question h6,
.question h1 *,
.question h2 *,
.question h3 *,
.question h4 *,
.question h5 *,
.question h6 * {
  font-size: 20px !important;
  text-align: left !important;
}
.quastionanairepopup .ant-radio-wrapper span:not(.ant-radio),
.mainquestionnairewrap .ant-radio-wrapper span:not(.ant-radio),
.quastionanairepopup .ant-checkbox-wrapper span:not(.ant-checkbox),
.mainquestionnairewrap .ant-checkbox-wrapper span:not(.ant-checkbox),
.Questions h2,
.question h2,
.Questions h3,
.question h3,
.Questions p,
.question p {
  color: rgba(0, 0, 0, 0.85) !important;
  margin: 0 !important;
  font-size: 16px !important;
  text-align: left !important;
}
.Questions h2,
.question h2,
.Questions h3,
.question h3 {
  font-family: "Gotham Medium";
}
.quetionanirewrap .mainquestionnairewrap .Questions ul li,
.questionnaire_answer_wrapper .question_wrapper ul li {
  font-size: 16px !important;
  list-style-type: none;
}

.Questions p:empty:before {
  content: none;
}
.radiopbtn {
  margin-top: 20px;
}
.line-height-1 {
  line-height: 1.5;
}
.mainquestionnairewrap label {
  font-size: 14px !important;
}
.mainquestionnairewrap .ant-btn span {
  font-size: 13px;
}
.border-blue {
  border: 1px solid #1445b7 !important;
}

.disabled-screnshot_icon {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}
.black-txt {
  color: #404040 !important;
}
.border-box-d9d9d9 {
  border: 1px solid #d9d9d9 !important;
}

.textbtn a {
  font-size: 12px;
  /* padding: 8px 12px; */
  padding: 12px;
}
.textbtn a svg {
  margin-left: 8px;
  position: relative;
  top: -2px;
}
.preconsulttable .ant-timeline {
  margin-top: 20px;
}
.preconsulttable .ant-timeline-item.ant-timeline-item-last {
  padding-bottom: 0px;
}
.preconsulttable .ant-timeline-item {
  padding-bottom: 10px;
}

.preconsulttable .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto;
}
.preconsulttable .infoicon {
  display: flex;
  align-items: center;
}
.ant-timeline-item-head-blue {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.preconsulttable .ant-timeline-item b {
  text-transform: none;
}

.after-care-email-modal {
  width: 800px !important;
}

/* template-preview-modal */
.template-preview-modal .template-preview-header {
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: center;
  background: #d3ebe6;
}
.template-preview-modal .template-preview-title {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: bold;
  font-family: Poppins, sans-serif, Arial, Helvetica;
  padding: 0;
  margin: 40px;
  text-align: center;
  letter-spacing: normal;
  line-height: 26px;
  font-style: normal;
}
.template-preview-modal .template-preview-content {
  padding: 0 10px;
}
.template-preview-modal .template-preview-content p:not(table p) {
  font-size: 16px;
  line-height: 22px;
}
.template-preview-modal .template-preview-footer {
  background: #d3ebe6;
  padding: 25px 10px;
  margin-top: 40px;
}
.template-preview-inner {
  border: 1px solid #cccccc;
}
/* template-preview-modal */

/* saved-toast */
.react-hot-toast-container {
  padding: 10px 12px;
  box-shadow: 0px 2px 6px 0px #00000029;
}
.react-hot-toast-container > div {
  margin: 0;
}
.react-hot-toast-container .saved-toast,
.react-hot-toast-container .saving-toast {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #1445b7;
}
.react-hot-toast-container .saved-toast span,
.react-hot-toast-container .saving-toast span {
  font-family: "Gotham Book";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1445b7;
}

.react-hot-toast-container .saving-toast svg {
  animation: rotateFull 1s linear infinite;
}
@keyframes rotateFull {
  100% {
    transform: rotate(360deg);
  }
}
/* saved-toast */

/* tinymce override */
.tox-editor-header .tox-mbtn {
  margin: 0 !important;
  font-size: 12px !important;
  height: 22px !important;
}
/* tinymce override */

/* pre consult review */
.address_contentbox_popover .ant-popover-inner-content,
.patient_contentbox_popover .ant-popover-inner-content {
  padding: 0 !important;
}
.address_contentbox_popover .ant-popover-inner,
.patient_contentbox_popover .ant-popover-inner {
  border-radius: 6px !important;
}
.patient_contentbox_popover .ant-popover-inner-content .patient_contentbox {
  margin: 0 !important;
}
.address_contentbox_popover .ant-popover-inner-content .address_contentbox {
  padding: 10px 15px;
}
.KioskPreConsultReviewTable_Wrapper .patient_name_info .ant-typography-copy,
.PreConsultationReviewTable_Wrapper .patient_name_info .ant-typography-copy {
  display: inline-flex !important;
  line-height: 22px !important;
  height: 22px;
  align-items: center;
}
.KioskPreConsultReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span,
.PreConsultationReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span {
  margin-top: -4px;
}
/* pre consult review */

.KioskPreConsultReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span,
.KioskPreConsultReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span
  svg,
.PreConsultationReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span,
.PreConsultationReviewTable_Wrapper
  .patient_name_info
  .ant-typography-copy
  span
  svg {
  display: inline-flex !important;
}

.filter_with_btn > .btn-primary {
  background-color: #1445b7;
  border-color: #1445b7;
  box-shadow: none !important;
  border-radius: 2px !important;
}
.filter_with_btn > .btn-primary:not(:disabled):hover {
  background-color: #fff;
  border-color: #1445b7;
  color: #1445b7;
}

#after_care_email_template_table .buttons-wrapper .ant-btn {
  min-width: auto !important;
}
#after_care_email_template_table .buttons-wrapper .ant-btn:hover {
  background: #fff !important;
  color: #1445b7 !important;
}

/* SortCodesContainer_Modal */
.SortCodesContainer_Modal {
  height: calc(100% - 200px);
  display: grid;
  place-items: center;
}
.SortCodesContainer_Modal .ant-modal-content {
  width: 100%;
}
/* SortCodesContainer_Modal */
/* Header tag qr code */
.ant-tag-has-color {
  background: #e8efff !important;
  color: #1445b7 !important;
  border: 1px solid #1445b7;
}
/* Header tag qr code */

.full-loader-div {
  background: #21252938;
  position: absolute;
  width: 96.5%;
  position: absolute;
  top: 84px;
  left: 15px;
  bottom: 0;
  right: 0;
  /* margin-top: -68%; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  padding-top: 20%;
  z-index: 10;
}
.lds-facebook {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 330px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
}
.lds-facebook-wrapper {
  display: flex;
  align-items: center;
}
.lds-facebook .lds-facebook-wrapper div {
  display: inline-block;
  width: 12px;
  margin: 0 4px;
  background: #1445b7;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook .lds-facebook-wrapper div:nth-child(1) {
  animation-delay: -0.24s;
}
.lds-facebook .lds-facebook-wrapper div:nth-child(2) {
  animation-delay: -0.12s;
}
.lds-facebook .lds-facebook-wrapper div:nth-child(3) {
  animation-delay: 0;
}
.rota-docs-list {
  position: relative;
}
.feedback_loader {
  position: relative;
  min-height: 370px;
}
.feedback_loader .lds-facebook-wrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  position: absolute;
}
.fullScreenChart .full-screen-btn {
  position: absolute;
  top: -49px;
  right: 220px;
  width: 20px !important;
}
.full-screen-btn {
  position: absolute;
  left: 50%;
  top: 4px;
  cursor: pointer;
  transform: translateX(-50%);
  width: 24px;
}
.full-screen-parent {
  /* position: absolute; */
  top: 0;
  right: 311px;
}
.full-screen-button .anticon-fullscreen,
.full-screen-button svg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-screen-button svg {
  color: #808396;
  font-size: 18px;
}
.fullscreen-enabled .full-screen-btn {
  top: 0px;
}
.fc-license-message {
  display: none;
}
a.disbaled-event > div {
  display: none;
}

/* pre_questionairepopup */
.pre_questionairepopup .patient_details .gender, .info-parent .gender, .patient_name_info .gender_span, .newpatientdetails span.gender  {
  padding: 6px 4px 3px 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: #fff;
  width: 25px;
  height: 25px;
  font-weight: 700;
  font-size: 14px;
}
.newpatientdetails span.gender {
  padding: 8px 4px 3px;
}
.patient_name_info .gender_span.male {
  padding-inline-end: 5px;
}
.pre_questionairepopup .patient_details .gender.male, .info-parent .gender.male, .patient_name_info .gender_span.male, .newpatientdetails .male{
  background: #1445b7;
  color: #fff;
}
.ant-select-selection-item .gender, .ant-select-item .gender{
  font-size: 14px;
  width: 22px;
  height: 22px;
  padding: 2px 2px 1px;
}
.ant-select-selection-item .gender{
  margin-top: 3px;
}
.textarea-auto-height {
  height: auto !important;
}
.pre_questionairepopup .patient_details .gender.female, .info-parent .gender.female, .patient_name_info .gender_span.female , .newpatientdetails .female {
  background: #ff69b4;
  color: #fff;
}
.pre_questionairepopup .patient_details .gender.other, .info-parent .gender.other, .patient_name_info .gender_span.other , .newpatientdetails .other {
  background: #919191;
  color: #fff;
}
.pre_questionairepopup .expandicon {
  width: 16px;
  min-width: 16px !important;
  height: 16px;
}
.pre_questionairepopup .expandicon svg {
  width: 100%;
  height: 100%;
}
/* pre_questionairepopup */
.gapx-1 {
  column-gap: 4px;
}
.gapx-2 {
  column-gap: 8px;
}
.gapx-3 {
  column-gap: 12px;
}
.gapx-4 {
  column-gap: 16px;
}
.gapy-1 {
  row-gap: 4px;
}
.gapy-2 {
  row-gap: 8px;
}
.gapy-3 {
  row-gap: 12px;
}
.gapy-4 {
  row-gap: 16px;
}
.calendercontrols button.ant-btn {
  padding-block: 4px;
  min-width: auto !important;
  min-height: 29px;
}
.ant-modal-body
  .ant-radio-group.change_calendar_view_btns
  .ant-radio-button-wrapper {
  border-radius: 2px!important;
  min-width: auto !important;
  min-height: 29px;
}
.full-screen-parent .full-screen-button {
  padding-bottom: 4px;
}
.full-exit-screen-parent {
  position: absolute;
  top: 15px;
  right: 326px;
}
.BookAppointment_Flow .change_calendar_view_btns,
.BookAppointment_Flow .bookingoptionradiogrup .ant-radio-group {
  gap: 4px;
}
.ant-modal-body
  .bookingoptionradiogrup
  .ant-radio-group
  .ant-radio-button-wrapper {
  border-radius: 2px !important;
  line-height: 1.5 !important;
  border-color: #d9d9d9 !important;
}
.ant-modal-body
  .bookingoptionradiogrup
  .ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #1445b7 !important;
}
.full-screen-wrapper .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  margin: 0 !important;
  position: absolute;
  left: 0;
  top: 4px;
}
/* .ant-modal-content .ant-modal-body .bookappoinmentsection .ant-picker.form-control input{font-size:12px;line-height:3.1;}
.ant-modal-content .ant-modal-body .bookappoinmentsection .ant-picker.form-control {min-height: 39px;}
.ant-modal-body .bookappoinmentsection .appointment-type .ant-radio-group .ant-radio-button-wrapper {padding: 10px 15px !important;font-size: 12px !important;} */
.ant-modal-body
  .bookappoinmentsection
  .ant-radio-group.radiogroupbtn
  label.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d0d0da !important;
}
.full-screen-wrapper
  .fullscreen-enabled
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  left: 15px;
  top: 17px;
}
.text-white .ant-input-number-input {
  color: #fff;
}
.ant-divider-dark {
  border-top-color: #c6cbcd;
}
.blue-border-box {
  border-color: #1445b7;
  background-color: transparent;
}
.upcoming_booking_table td {
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.isLoading-table .pre_consultation_review_table .upcoming_booking_table {
  /* min-height: calc(100vh - 145px); */
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}
.isLoading-table .upcoming_booking_tableheader th:first-child {
  border-left: solid 1px #eeeeee;
}
.isLoading-table .upcoming_booking_tableheader th:last-child {
  border-right: solid 1px #eeeeee;
}
.ant-tabs-nav + .ant-tabs-content-holder .isLoading-table .pre_consultation_review_table ,
.ant-tabs-nav + .ant-tabs-content-holder .isLoading-table .pre_consultation_review_table .upcoming_booking_table{
  /* min-height: calc(100vh - 200px); */
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}
.isLoading-table .pre_consultation_review_table .upcoming_booking_table td > div{
  min-height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tabs-nav + .ant-tabs-content-holder .isLoading-table .pre_consultation_review_table td > div {
  min-height: calc(100vh - 450px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre_consultation_review_table .upcoming_booking_table td {
  /* border-bottom: 0; */
}
.pre-encounter-item-header .custom-bluelabel {
  padding-block: 2px 1px;
}
.patient_detail_left .CollapsiableSubHeader {
  display: flex;
  align-items: center;
}
.patient_detail_left .CollapsiableSubHeader .ant-collapse-header {
  display: flex;
  align-items: center;
}
.cheboxrow .ant-checkbox {
  top: 0;
}
.answer-review_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  border-radius: 10px;
}
.questionanspart .answer-review_wrapper .add-scroll-to-answereview {
  margin-bottom: 10px;
  padding-bottom: 1px;
}
.csbtn-1 {
  background: #fff;
  /* padding: 0px 6px; */
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
  border-radius: 2px;
  font-weight: 500;
  color: #1445b7;
  min-height: 30px;
  min-width: 104px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
/*
.ytp-chrome-top .ytp-button:hover,
.ytp-chrome-controls
  .ytp-button:not([aria-disabled="true"]):not([disabled]):hover,
.ytp-chrome-controls .ytp-button[aria-expanded="true"],
.ytp-chrome-controls .ytp-button[aria-pressed="true"],
.ytp-replay-button:hover {
  opacity: 1;
  -webkit-transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
}
.ytp-chrome-controls .ytp-button,
.ytp-replay-button {
  opacity: 0.9;
  display: inline-block;
  width: 48px;
  padding: 0 2px;
  -webkit-transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  overflow: hidden;
}
.ytp-button:not([aria-disabled="true"]):not([disabled]):not(
    [aria-hidden="true"]
  ) {
  cursor: pointer;
}
.ytp-chrome-controls .ytp-button {
  height: 100%;
}
.ytp-chrome-controls .ytp-button.ytp-miniplayer-button,
.ytp-chrome-controls .ytp-button.ytp-remote-button {
  padding: 0 4px;
}
.ytp-button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font-size: 100%;
  font-family: inherit;
  cursor: default;
  line-height: inherit;
} */
.appointment_arrows {
  text-align: right;
  position: absolute;
  z-index: 11111;
  right: 39%;
  top: -2px;
}
.appointment_arrows button {
  border: 0;
  margin: 2px 0 0 10px;
  cursor: pointer;
  background-color: #1445b7;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}
.appointment_arrows button img {
  filter: brightness(0) invert(1);
  width: 20px;
  margin-top: -3px;
  position: relative;
  left: -1px;
}
.sectionscroll .sectionsubscroll {
  height: 418px;
}
.sectionscroll .screenshort_tabs .sectionsubscroll {
  height: 408px;
}
.walkingappointmentsecscroll .sectionsubscroll {
  height: 404px;
}
.right-video-panel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.icon-three-dots-vertical {
  color: #1445b7;
  fill: currentColor;
  display: inline-flex;
}
.ant-dropdown-trigger .icon-three-dots-vertical, .dropdown .icon-three-dots-vertical{
  max-height: 16px;
}
.upcoming_appt_data .upcoming_booking_table:not(:first-child) {
  margin-top: -1px;
}
.min-width-\[34px\] {
  min-width: 34px;
}
.ant-select-item.multi-data:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
}
.react-select-container .react-select__input {
  display: flex !important;
  align-items: center;
}
.react-select-container .react-select__input input {
  font-size: 12px !important;
}
.ant-modal.pre_questionairepopup.full-height-modal {
  position: static;
}
.after_care_emails_edit, .after_care_emails_preview {
  top: 0;
  margin-block: 30px;
  padding-bottom: 0;
}
.aftercare-editor .tox.tox-tinymce {
  min-height: 350px;
}
.info-parent .gender, .newpatientdetails span.gender, .patient_name_info .gender_span, .pre_questionairepopup .patient_details .gender {
  padding: 6px 4px 3px 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: #fff;
  width: 25px;
  height: 25px;
  font-weight: 700;
  font-size: 14px;
}
.ant-select-item .gender, .ant-select-selection-item .gender {
  font-size: 14px;
  width: 22px;
  height: 22px;
  padding: 2px 2px 1px;
}