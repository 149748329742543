.opentok-hardware-setup .opentok-hardware-setup-camera {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.opentok-hardware-setup {
  box-sizing: border-box;
  border: 0;
  background-color: unset !important;
  padding: unset !important;
  width: unset !important;
  height: unset !important;
  display: flex !important;
}

.opentok-hardware-setup .opentok-hardware-setup-mic {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.opentok-hardware-setup-label {
  display: none;
}
.audio-video-setup-container {
  display: flex;
  justify-content: space-evenly;
}
.audio-visulizer > .ant-progress-steps-outer > .ant-progress-steps-item {
  width: 6px !important;
  height: 50px !important;
  border-radius: 10px !important;
  margin-right: 5px !important;
}

.container,
.container .OT_subscriber,
.container .OT_publisher {
  overflow: hidden;
}
.OT_subscriber {
  position: unset !important;
}

/* .container .OT_subscriber,
.container .OT_publisher {
  float: left;
  width: 2px !important;
  height: 2px !important;
  min-width: 2px !important;
  max-width: 2px !important;
  min-height: 2px !important;
  max-height: 2px !important;
}
#graphContainer {
  display: none;
} */

.prettyBox h1 {
  margin-top: 0;
  padding-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  display: block;
  font-weight: 200;
  padding: 0.6rem;
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(237, 237, 237, 1)),
    color-stop(53%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
}

.prettyBox h1 small {
  color: #999;
}

.prettyBox {
  box-sizing: border-box;
  padding: 1rem;
  display: none;

  margin: 0 auto;
  margin-top: 1rem;
  max-width: 90%;
  min-width: 60%;
  width: 600px;
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(237, 237, 237, 1)),
    color-stop(53%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.prettyBox h2 {
  margin-top: 0;
  font-size: 20px;
}

div.prettyBox p {
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}

/* div.prettyBox .results {
  display: none;
} */

h3 {
  font-size: 16px;
  margin-bottom: 6px;
}
div.prettyBox .results :last-child {
  margin-bottom: 8px;
}

div.prettyBox img {
  width: 50px;
}

#precall {
  display: block;
}

#precall .options span {
  margin-left: 24px;
  font-size: 14px;
}

#precall .options {
  margin: 20px;
}

#precall button {
  font-family: "Helvetica Neu", Arial;
  font-size: 12px;
}

#quality_status_container {
  display: none;
  flex-direction: column;
  align-items: center;
}

#stop_test {
  margin-top: 8px;
  display: none;
}
#quality_status_container > div {
  display: flex;
  justify-content: center;
}

#quality_header {
  flex-direction: column;
  align-items: center;
}

#quality_status_container #audio,
#quality_status_container #video {
  width: 50%;
}

.graph {
  height: 240px;
}

/* #quality_status_container .results {
  display: none;
}

#video-unsupported-reason {
  display: none;
} */

@media screen and (max-width: 380px) {
  h1 {
    font-size: 24px;
    padding: 0.5rem;
  }
  h2 {
    font-size: 18px;
  }
  div.prettyBox p {
    font-size: 20px;
  }
  div.prettyBox {
    padding: 1rem;
  }
}
.call-quality-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
}

/* #hardware-setup {
  display: flex;
  flex-direction: row-reverse;
  width: 70%;
  margin-top: 10%;
} */

.option-bg {
  /* width: 96px;
  height: 96px;
  padding-top: 14px; */
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #f9f9f9;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
}
.option-bg .counter {
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #1445b7;
  /* border: 3px solid #fff; */
  position: absolute;
  top: -12px;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%);
  font-family: "Gotham Book";
}
#hardware-setup {
  height: 180px;
  object-fit: contain;
  border-radius: 4px;
}
#hardware-setup .OT_publisher {
  width: 100% !important;
  height: 100% !important;
}
.calling-status-container {
  background-color: #d9f8e9;
  border: 1px solid #43dc91;
  color: #404040;
  display: inline-block;
  min-width: 75px;
  border-radius: 3px;
  text-align: center;
  font-size: 11px;
  padding: 4px 6px 4px;
  font-family: "Gotham Medium";
  line-height: 1;
}
.calling-timer-container {
  /* text-align: right; */
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #808396;
}
/* .calling-timer-container span.calling-timer-text {
  display: inline-block;
  width: 98px;
} */
.calling-uper-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: center;
  /* padding: 12px; */
  margin-top: 15px;
  margin-bottom: 15px;
}
.video-call-chat-panel {
  /* padding-top: 37px; */
  padding-top: 1px;
}
.selecti3on_container
  .patient_detail_left
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  /* top: 52px; */
  top: 40px;
}
.patient-call-conatainer .video-call-chat-panel .progress {
  bottom: 0;
  top: 0;
  z-index: 1;
  height: 3px;
}
.patient-call-conatainer .dynamic-component-container {
  /* width: calc(100% - 312px); */
  /* padding-left: 10px !important; */
}

.patient-call-conatainer .video_inner_content_scroll {
  /* height: calc(100vh - 45px) !important; */
  height: calc(100vh - 86px) !important;
  padding-right: 10px !important;
  /* overflow-y: auto; */
  /* margin-top: 41px; */
}
.patient-call-conatainer .video-call-chat-panel {
  /* width: 400px; */
  /* height: calc(100vh - 66px); */
  border-left: 1px solid #e9e9e9;
  /* margin-top: 37px; */
  /* width: 312px; */
  /* padding: 0 15px !important; */
}
/* .patient-call-conatainer .dynamic-component-container .padding-50-t {
  padding-top: 15px;
}
.patient-call-conatainer .dynamic-component-container .padding-55-t {
  padding-top: 20px;
} */
.patient-call-conatainer .dynamic-component-container .push-50-t,
.patient-call-conatainer .dynamic-component-container .push-45-t {
  margin-top: 15px;
}
.patient-call-conatainer .dynamic-component-container .push-55-t {
  margin-top: 20px;
}
.patient-call-conatainer .video-call-chat-panel {
  /* height: calc(100vh - 118px); */
  /* height: calc(100vh - 116px); */
  margin-top: 52px;
}
.patient-call-conatainer {
  display: flex;
  flex-wrap: unset;
  /* width: calc(100% + 40px); */
  height: 100%;
  margin-right: -10px;
  margin-left: -10px;
}
.default_video_screen {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #e8efff;

  min-height: 330px;
  border-radius: 10px;
  /* -webkit-box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%); */
}
/* .video-screen {
  height: 280px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
  background-color: #E8EFFF;
} */
.default_video_screen {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.default_video_screen button.ant-btn {
  background: transparent;
  border: none;
  height: auto;
  box-shadow: none;
  margin-top: 20px;
}
.default_video_screen button.ant-btn img {
  width: 46px;
}
/* .OTSubscriberContainer {
	width: 100% !important;
	height: 420px !important;
} */
.video_widget {
  position: relative;
  /* border-radius: 2px; */
  /* background: black !important; */
  border-radius: 10px;
  /* height: 370px; */
  /* height: 330px; */
}
.OT_publisher {
  border-radius: 2px;
}
.publisher-video-controls {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 999;
  /* left:-6% */
}
.icon-wrapper.volume_slider {transition: right 0.6s ease-in-out;}
.publisher-video-controls .video_btns_wrapper {transform: translateY(100%);transition: all 0.6s ease-in-out;}
.video-only-call:hover .publisher-video-controls .video_btns_wrapper {transform: translateY(0);}
.OTPublisherContainer {
  height: 80px !important;
  width: 130px !important;
  position: absolute !important;
  /* right: 10% !important; */
  right: 25px !important;
  /* top: 20% !important; */
  z-index: 1 !important;
  bottom: 5% !important;
}
.OTPublisherContainer,
.video_widget:hover .OTPublisherContainer {
  /* -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out; */
}
.video_widget:hover .OTPublisherContainer {
  /* bottom: 23% !important; */
  bottom: 80px !important;
}
.publisher-video-controls .screenshot-icon,
.publisher-video-controls .video-icon,
.publisher-video-controls .audio-icon {
  padding: 6px 12px;
}
.OTSubscriberContainer {
  /* height: 370px !important; */
  width: unset !important;
  /* height: 330px !important; */
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%); */
}
/* .video-call-chat-panel .chat-bubble-container {
  overflow-y: scroll;
  overflow-x: hidden;
   height: calc(100vh - 492px);
  position: relative;
  list-style: none;
  padding: 10px 5px 10px;
  margin: 0;
} */
.video_chat_scrolling {
  /* height: calc(100vh - 576px) !important; */
  height: calc(100vh - 450px) !important;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}
/* .video_chat_scrolling > div {
  overflow-x: hidden !important;
  height: 100%;
} */
.video-call-chat-panel .chat-right {
  color: #fff;
}
.video-call-chat-panel .chat-right::after,
.video-call-chat-panel .chat-left::after {
  content: none;
}
.video-call-chat-panel .chat-right,
.video-call-chat-panel .chat-left {
  display: inline-block;
  font-size: 12px;
  word-break: break-word;
  line-height: 1.43;
  letter-spacing: -0.2px;
  margin-bottom: 5px;
  padding: 5px 10px;
  margin-top: 0;
  border-radius: 5px;
}
.video-call-chat-panel .chat-left {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  color: #121212;
}
.video-call-chat-panel .ant-input-group-wrapper {
  box-shadow: 0 -1px 0 0 #e9e9e9;
  /* padding-top: 3px; */
  padding-top: 5px;
  padding-bottom: 5px;
}
.video-call-chat-panel input {
  height: 36px;
  font-size: 12px;
  padding-left: 10px !important;
  color: #1d2943;
}
.video-call-chat-panel span.ant-input-affix-wrapper {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.video-call-chat-panel .ant-upload img {
  height: 26px;
}
.video-call-chat-panel input:focus,
.video-call-chat-panel input:hover {
  border: none !important;
}
.video-call-chat-panel .ant-input-group-addon [type="button"] {
  height: 34px;
  background: transparent !important;
  border: none !important;
  margin-top: 0px !important;
  padding: 0;
  margin-left: 5px;
  position: relative;
  width: 30px;
}

.video-call-chat-panel span.ant-input-affix-wrapper:focus,
.video-call-chat-panel span.ant-input-affix-wrapper:hover {
  box-shadow: none;
  border: none !important;
}
.video-call-chat-panel .ant-input-group-addon {
  background-color: transparent !important;
}
.pic_desc_scroll {
  border: 1px solid #e9e9e9;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
}
.pic_desc_scroll p {
  color: #1d2943;
  font-weight: 400;
  font-size: 14px;
  font-family: "Gotham Book";
  line-height: 1.4;
}
.pic_section {
  width: 300px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  margin: 0;
  overflow: hidden;
}
.pic_section img {
  width: 300px;
  height: 100%;
  object-fit: cover;
}
.publisher-video-controls ul.rtf {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 4px !important;
}
.publisher-video-controls ul.rtf li.rtf--mb__c {
  margin: 0;
  padding: 0;
}
.publisher-video-controls ul.rtf li.rtf--mb__c button.rtf--mb {
  height: 38px;
  width: 38px;
  background-color: #1445b7;
}
.publisher-video-controls ul.rtf li.rtf--mb__c button.rtf--mb:focus {
  outline: none;
  border: none;
}
.publisher-video-controls ul.rtf li.rtf--mb__c ul button.rtf--ab {
  margin: 0;
  height: 38px;
  width: 38px;
  background-color: #1445b7;
}
.publisher-video-controls ul.rtf li.rtf--mb__c ul button.rtf--ab img {
  width: 24px;
}
.publisher-video-controls ul.rtf li.rtf--mb__c ul li {
  padding: 7px 0;
}
.video_wrapper {
  position: absolute;
  top: 20%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.video-call-chat-panel button.OT_edge-bar-item.OT_mute.OT_active,
.video-call-chat-panel .OT_bar.OT_edge-bar-item.OT_mode-auto {
  display: none;
}
.video-screen {
  /* margin-top: 15px; */
  padding-top: 15px;
  overflow: hidden;
}
.audio-icon {
  position: absolute;
  right: 10px;
  padding: 0;
}
.audio-icon img {
  width: 28px;
}
span.veye_icon img {
  width: 30px;
}
/* .icon-wrapper.volume_slider {
  transform: rotate(-90deg);
  left: -48px;
  bottom: 75px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} */
.icon-wrapper.volume_slider {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 13px 6px 26px 6px;
  border-radius: 25px;
  /* bottom: 72px; */
  bottom: 56px;
  position: absolute;
  /* left: 15px; */
}
.icon-wrapper.volume_slider .ant-slider {
  margin: 0;
}
.icon-wrapper.volume_slider .anticon {
  /* -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg); */
  position: absolute;
  bottom: 7px;
  /* right: 5px;
  top: 10px; */
}
.end_call-icon {
  /* position: absolute;
  left: 48.5%; */
  /*bottom: 0;
  transform: translateX(-55.5%); */
}

span.vscreen_shot_icon,
span.vno_video_icon,
span.vmute_icon {
  cursor: pointer;
}
.icon-wrapper.volume_slider .ant-slider-handle {
  border-color: #1445b7 !important;
}
.icon-wrapper.volume_slider .ant-slider-track {
  background-color: #1445b7 !important;
}
.icon-wrapper.volume_slider .anticon svg path:first-child {
  fill: #1445b7;
}
span.vno_video_icon img {
  width: 30px;
  height: 30px;
}
.audio_pulse {
  position: absolute;
  /* top: 48px; */
  top: 8px;
}
.audio_pulse {
  border-radius: 50%;
  margin: 10px;
  /* height: 98px;
  width: 98px;
  transform: scale(1);
  animation: pulse 0.1s infinite; */
}
.icon-wrapper.volume_slider .ant-slider-rail {
  background-color: #e9e9e9;
}
/* .ant-layout-sider-collapsed
  + .rightmainsection
  .patient-call-conatainer
  .dynamic-component-container {
  width: calc(100% - 412px);
}
.ant-layout-sider-collapsed
  + .rightmainsection
  .patient-call-conatainer
  .video-call-chat-panel {
  width: 412px;
} */
/* .dynamic-component-container,
.video-call-chat-panel,
.ant-layout-sider-collapsed
  + .rightmainsection
  .patient-call-conatainer
  .dynamic-component-container,
.ant-layout-sider-collapsed
  + .rightmainsection
  .patient-call-conatainer
  .video-call-chat-panel {
  -webkit-transition: all 0.6s !important;
  -o-transition: all 0.6s !important;
  transition: all 0.6s !important;
} */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(1, 140, 231, 0.6);
  }
  40% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(1, 140, 231, 0.3);
  }
  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(1, 140, 231, 0.1);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(1, 140, 231, 0.4);
  }
}
.audio_pulse::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 150%;
  height: 150%;
  box-sizing: border-box;
  margin-left: -25%;
  margin-top: -25%;
  border-radius: 100%;
  background-color: #1445b7;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 0.1)
    infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 0.1) infinite;
  z-index: 1;
}
.audio_pulse::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 125%;
  height: 125%;
  margin-left: -12.5%;
  margin-top: -12.5%;
  background-color: #1445b7;
  border-radius: 100%;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 0.1)
    infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 0.1) infinite;
  z-index: 1;
}
.audio_pulse {
  width: 160px;
  height: 160px;
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.2);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}
/* .OTPublisherContainer {
	width: 36% !important;
	height: 104px !important;
	position: absolute;
	margin-top: 47%;
	z-index: 1;
	margin-left: 56%;
}
.publisher-video-controls {
	color: red;
	position: absolute;
	z-index: 2;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	margin-top: 66%;
}*/

/* Audio Call Background*/
.video-screen.custom-video-screen {
  /* width: 100%;
    position: absolute;
    margin-top: -260px;
    z-index: -1; */
  width: 100%;
  z-index: -1;
  top: 0px;
  position: relative;
  height: 330px;
  box-shadow: none;
  background: #e8efff;
}
.video-screen {
  /* margin-right: 15px;
  margin-left: 15px; */
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}
.audio-call-avatar-image {
  border-radius: 50px;
  z-index: 1;
  border: solid white;
}
.custom-pulsator {
  position: absolute !important;
  margin-top: -49px;
  z-index: 0;
}
.audio-container .ant-progress-steps {
  width: 100%;
  overflow: hidden;
}
/* .OT_subscriber:hover .OT_edge-bar-item.OT_mode-auto {
  display: none;
} */

/* animation caa */
/* span.vscreen_shot_icon,
span.veye_icon,
.video_widget:hover span.veye_icon,
.video_widget:hover span.vscreen_shot_icon,
span.vno_video_icon,
span.vmute_icon,
.end_call-icon,
.video_widget:hover span.vno_video_icon,
.video_widget:hover span.vmute_icon,
.video_widget:hover .end_call-icon,
.icon-wrapper.volume_slider,
.video_widget:hover .icon-wrapper.volume_slider,
.publisher-video-controls,
.publisher-video-controls:hover,
.video_widget:hover .publisher-video-controls::before,
.video_widget .publisher-video-controls::before,
span.screenshare_icon,
.video_widget:hover span.screenshare_icon,
.video_widget:hover span.fullscreen_icon {
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
} */
/* span.vscreen_shot_icon, span.veye_icon{
    top: -324px;
} */
span.vno_video_icon,
span.vmute_icon,
.end_call-icon,
span.vscreen_shot_icon,
span.veye_icon {
  pointer-events: none;
}
.video_widget:hover span.veye_icon,
.video_widget:hover span.vscreen_shot_icon,
.video_widget:hover span.vno_video_icon,
.video_widget:hover span.vmute_icon,
.video_widget:hover .end_call-icon,
.video_widget:hover .icon-wrapper.volume_slider {
  pointer-events: auto;
}
/* .video_widget:hover span.veye_icon {
  bottom: 15px;
}
.video_widget:hover span.vscreen_shot_icon {
  bottom: 9px;
}
.video_widget:hover span.vno_video_icon {
  bottom: 18px;
}
.video_widget:hover span.vmute_icon {
  bottom: 10px;
}*/
.video_widget:hover .end_call-icon {
  bottom: 5px;
}
/* span.vno_video_icon,
span.vmute_icon,
.end_call-icon,
span.veye_icon,
span.vscreen_shot_icon,
span.screenshare_icon,
span.fullscreen_icon {
  bottom: -64px;
} */
.video_widget {
  overflow: hidden;
  min-height: 100%;
  /* padding: 0 15px; */
  margin: 0;
  /* height: 100%; */
  -webkit-box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}
.full-screen-video-call{
  min-height: unset !important;
  /* width: ; */
}
span.screenshare_icon {
  position: absolute;
  display: inline-block;
  left: 10.5%;
  cursor: pointer;
}
.end_call-icon img {
  height: 40px;
}
.icon-wrapper.volume_slider {
  right: -36px;
}
.video_widget:hover .icon-wrapper.volume_slider {
  right: 10px;
}
.video_resizable {
  min-height: 300px;
  max-height: calc(100vh - 160px);
  min-width: 300px;
}
.OT_widget-container {
  border-radius: 2px !important;
}
/* .OTSubscriberContainer {
  margin: 0 15px !important;
} */
/* .video_widget:hover .publisher-video-controls::before {
  content: "";
  bottom: 0;
}
.video_widget .publisher-video-controls::before {
  bottom: -66px;
  background-color: #1445b7;
  width: calc(100% - 26px);
  border-top-left-radius: 68px;
  border-top-right-radius: 68px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 56px;
  content: "";
  position: absolute;
} */
.patient-call-conatainer .video-call-chat-panel {
  min-width: 346px;
  /* width: 100%; */
}
/* svg.no_vdo {
  margin-bottom: -8px;
} */
.audio-only-call .OTSubscriberContainer {
  display: none !important;
}
.video-call-chat-panel .chat-right a {
  color: #fff;
}
.video-call-chat-panel .chat-left a {
  color: #1445b7 !important;
}
/* .video_widget.video-only-call {
  position: relative;
  width: calc(100% - 20px);
  padding-top: calc(48% - 20px);
  margin-left: auto;
  margin-right: auto;
  min-height: 320px;
}
.video_widget.video-only-call .react-draggable {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: auto !important;
  max-height: none !important;
}
.video_widget.video-only-call .resizable-intract {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
} */
.video-chatmainsection {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.parent-div {
  position: relative;
  /* height: calc(100vh - 124px); */
}
.resizable-intract {
  max-height: calc(100vh - 166px) !important;
  /* transform: none !important; */
  min-width: 330px !important;
}
.patient-call-conatainer .video-call-chat-panel {
  width: auto;
}
.patient-call-conatainer .dynamic-component-container {
  /* max-width: calc(100% - 330px); */
  /* width: auto; */
  width: 100%;
}
.resize-drag {
  background-color: #29e;
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 8px;
  margin: 30px 20px;
  touch-action: none;
  padding: 5px;
  width: 120px;

  /* This makes things *much* easier */
  box-sizing: border-box;
}

.resize-container {
  display: inline-block;
  width: 100%;
  height: 240px;
}
/* JS Demo only */

.custom-resize {
  background: lime;
  height: 200px;
  width: 300px;
}
.custom-resize.resizable {
  background: cyan;
  position: relative;
}
.custom-resize.resizer {
  width: 10px;
  height: 10px;
  background: blue;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}
/* .patient-call-conatainer .video-call-chat-panel {
  max-width: calc(100vw - 481px);
}
.patient-call-conatainer .video-call-chat-panel .video_widget.video-only-call {
  max-width: calc(100vw - 491px);
} */
.patient-call-conatainer
  .video-call-chat-panel
  .video_widget.video-only-call
  .react-resizable {
  /* max-width: calc(100vw - 511px); */
  max-height: 100%;
  z-index: 5;
}
.video-call-panelinit {
  min-width: 346px;
  margin-top: 52px;
  border-left: 1px solid #e9e9e9;
}
div#publisher {
  width: 150px;
  height: 100px;
  /* margin-top: 174px; */
  /* margin-left: 63%; */
  /* position: absolute;
  top: 49%;
  left: 61%; */
  position: fixed;
  right: 0;
}
div#subscriber > div > .OT_bar {
  /* display: block!im; */
  display: none;
}
div#subscriber > div > .OT_widget-container {
  /* width: 27% !important;
  margin-top: 8%; */
  height: inherit !important;
}
/* .react-draggable.react-draggable-dragged {
  bottom: 15px !important;
  top: unset !important;
  right: 15px !important;
  left: unset !important;
  transform: translate(-15px, 0px) !important;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.video_widget:hover .react-draggable.react-draggable-dragged {
  bottom: 70px !important;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
} */
.default_video_screen {
  -webkit-box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
}
/* .publisher-hover{
  bottom: 70px !important;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
} */
.audio-only-call .default_video_screen {
  box-shadow: none;
  min-height: auto;
}
.audio-only-call .video-screen.custom-video-screen,
.audio-only-call .react-resizable {
  height: 280px !important;
}
.video_widget.audio-only-call {
  min-height: 280px;
}
.audio-only-call + .video-chatmainsection .video_chat_scrolling {
  height: calc(100vh - 490px) !important;
}
.audio-call-avatar-image {
  object-fit: cover;
}
span.vscreen_shot_icon,
span.vno_video_icon,
span.vmute_icon,
span.veye_icon,
span.fullscreen_icon,
span.screenshare_icon {
  width: 39px;
  height: 39px;
  text-align: center;
  line-height: 39px;
  background-color: #ffffff;
  border-radius: 50%;
}
span.vscreen_shot_icon svg g,
span.vno_video_icon svg g,
span.vmute_icon svg g,
span.veye_icon svg g,
span.vscreen_shot_icon svg g path,
span.vno_video_icon svg path,
span.vmute_icon svg path,
span.veye_icon svg path {
  fill: #1445b7;
}
.video_widget:hover span.veye_icon,
.video_widget:hover span.vscreen_shot_icon,
.video_widget:hover span.vno_video_icon,
.video_widget:hover span.vmute_icon,
.video_widget:hover span.screenshare_icon,
.video_widget:hover span.fullscreen_icon {
  /* bottom: 7px; */
}
span.veye_icon {
  /* position: absolute; */
  display: inline-block;
  /* right: 13.5%; */
  cursor: pointer;
}
/* span.vscreen_shot_icon {
  position: absolute;
  display: inline-block;
  left: 23%;
  cursor: pointer;
} */
span.vno_video_icon {
  /* position: absolute;
  left: 35.5%; */
  /*bottom: 14px;*/
}
span.vmute_icon {
  /* position: absolute;
  right: 26%; */
  /*bottom: 10px;*/
}
span.vno_video_icon svg.no_vdo path {
  fill: #1445b7;
}
span.vscreen_shot_icon svg,
span.vno_video_icon svg,
span.vmute_icon svg,
span.vmute_icon img,
span.veye_icon svg {
  /* height: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
span.vno_video_icon svg {
  max-width: 16px;
}
.react-draggable {
  border: none !important;
}
span.fullscreen_icon {
  /* position: absolute; */
  /* right: 4px; */
  /* bottom: 7px; */
  /* left: 23.5%; */
  transition: all 0.6s ease-in-out;
}
span.fullscreen_icon .anticon {
  /* font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.video_btns_wrapper {
  max-width: 400px;
  width: 400px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 4px;
  padding-block: 8px;
}
.veye_icon svg{width: 20px;height: 20px;}
.vscreen_shot_icon svg{width: 24px;height: 24px;}
.vno_video_icon svg{}
.vmute_icon img{width: 18px;height: 18px;}
.vmute_icon svg{width: 22px;height: 22px;}
.ytp-svg-shadow svg{width: 36px;height: 36px;}
.message_icon svg {color: #1445b7;font-size: 22px;}
/* .end_call-icon{background-color: #E52C2C;border-radius: 2px;} */
body.full-screen-active .header_wrapper,
body.full-screen-active .ant-layout-sider,
body.full-screen-active .rightmainsection .patient_detail_header,
body.full-screen-active .dynamic-component-container,
#blank_div.full-screen-active .video-chatmainsection,
#blank_div.full-screen-active
  #v_call_panel
  .react-resizable
  .react-resizable-handle-sw {
  display: none;
}
body.full-screen-active .rightmainsection {
  width: 100%;
  top: 0;
}
#blank_div.full-screen-active {
  width: 100%;
}
#blank_div.full-screen-active #v_call_panel {
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}
#blank_div.full-screen-active #v_call_panel .react-resizable {
  max-width: 100%;
  width: 100% !important;
  /* height: calc(100vh - 60px) !important; */
  height: calc(100vh - 22px) !important;
  max-height: unset;
}
#blank_div.full-screen-active #v_call_panel .video_widget.video-only-call {
  margin: 0;
}
#blank_div.full-screen-active
  #v_call_panel
  .video_widget
  .icon-wrapper.volume_slider {
  bottom: 35vh;
}
.video-call-chat-panel > .react-draggable {
  z-index: 999;
  min-width: 423px;
  min-height: 355px;
  left: auto !important;
}
.video-chatmainsection {
  /* position: fixed;
  width: 100%;
  bottom: 35px;
  background-color: #fff;
  z-index: 9;
  max-width: 420px;
  right: 35px;
  top: auto;
  border: 4px;
  box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 68%); */
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  max-width: 420px;
  right: 10px;
  top: auto;
  border: 4px;
  -webkit-box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 68%);
  box-shadow: 0px 0px 30px -10px rgb(0 0 0 / 68%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
}
.chatbot_header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1445b7;
  height: 42px;
  padding: 0 10px;
  width: 100%;
  transition: background-color 0.2s ease;
}
.chat_trigger {
  position: absolute;
  bottom: 0;
  background-color: #1445b7;
  width: calc(100% - 20px);
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 10px;
}

#chat-circle {
  /* position: fixed;
  bottom: 15px;
  right: 15px;
  background: #1445b7;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: white;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 60%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
  position: fixed;
  bottom: -4px;
  right: 10px;
  background: #1445b7;
  width: 100%;
  max-width: 420px;
  height: 46px;
  color: white;
  padding: 12px;
  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin: 0;
}
span.close_chat {
  display: inline-flex;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  margin-top: 0;
  line-height: 1;
}
div#chat-circle {
  display: flex;
  justify-content: space-between;
}
span.chatbox_unread {
  background-color: #e52c2c;
  min-width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  line-height: 2;
  font-size: 12px;
  padding: 0px 2px;
}
.video-chatmainsection {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.video-chatmainsection.closed-chat {
  /* bottom: -790px; */
  /* bottom: 0; */
  transform: translateY(calc(100% - 42px));
}
#chat-circle.chat-icon-opened {
  display: none;
}
.video-chatmainsection.opened-chat {
  /* bottom: 2px; */
}
.video_widget.audio-only-call .video_btns_wrapper {
  position: absolute;
  bottom: 0;
}
.patient-call-conatainer .dynamic-component-container {
  min-width: 280px;
}
.patient-call-conatainer
  .video-call-chat-panel
  .video_widget.video-only-call
  .react-resizable {
  max-width: calc(100vw - 549px);
}
.calltype_wrapper span {
  display: inline-block;
  line-height: 1;
  color: #1445b7;
  border: 1px solid #1445b7;
  border-radius: 15px;
  background-color: #e8efff;
  padding: 4px 8px;
  font-family: "Gotham Medium";
  font-size: 12px;
}
.calltype_wrapper {
  margin-right: 10px;
}
.bottom_btns {
  margin-bottom: 15px;
}
.patient-call-conatainer
  .dynamic-component-container
  .search-filter-downdown-input
  .ant-input-affix-wrapper {
  min-width: 262px;
}
.patient-call-conatainer
  .dynamic-component-container
  .searchbar_dropdown.medical_Search {
  max-width: 262px;
}

.session-timer-wrapper {
  text-align: center;
}
.session_popuptitle {
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #1c2745;
  font-size: 16px;
  font-family: "Gotham Medium";
  margin: 16px 0;
}
.session_img {
  padding-top: 38px;
}
.timer_min_inner {
  width: 133px;
  height: 84px;
  border-radius: 8px;
  background-color: #ffefef;
  line-height: 84px;
  font-size: 72px;
  color: rgba(229, 44, 44, 0.89);
  display: inline-block;
  font-family: "Gotham Medium";
}
.session-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
}
.timer_min,
.timer_sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.timer_min {
  margin-right: 10px;
}
.timer_sec {
  margin-left: 10px;
}
.timer_min span,
.timer_sec span {
  color: #808396;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: "Gotham Book";
}
.session-timer-btn-container button.continue_btn,
.session-timer-btn-container button.signoff_bn {
  width: 124px;
  height: 42px;
  padding: 8px 12px;
  border-radius: 4px;
  font-family: "Gotham Book";
}
.session-timer-btn-container button.continue_btn {
  background-color: #1445b7;
  color: #ffffff;
  border: 1px solid #1445b7;
  margin-right: 20px;
}
.session-timer-btn-container button.signoff_bn {
  border: solid 1px #808396;
  background-color: transparent;
  color: #1c2745;
}
.session-timer-btn-container {
  margin-top: 24px;
}
span.timerdot {
  color: rgba(229, 44, 44, 0.89);
  font-size: 72px;
  font-weight: 500;
}
span.timerdot {
  color: rgba(229, 44, 44, 0.89);
  font-size: 72px;
  font-weight: 500;
}
.session_modal span.ant-modal-close-x {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid rgba(229, 44, 44, 0.89);
  line-height: 1.6;
}
.session_modal svg {
  color: rgba(229, 44, 44, 0.89);
}
.session_modal button.ant-modal-close {
  right: 14px;
  top: 14px;
}
.ant-modal.precalltestmodal {
  top: 40px;
}
.precalltestmodal .ant-modal-content .ant-modal-body {
  padding-bottom: 20px;
}
.btnsection {
  margin-top: 25px;
}
/* .video_widget #layoutContainerV .ot-layout {
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
} */
@media (max-width: 1400px) {
  .patient-call-conatainer .video-call-chat-panel {
    /* height: calc(100vh - 118px); */
    /* height: calc(100vh - 116px); */
    /* margin-top: 52px; */
  }
}
@media only screen and (max-width: 1280px) {
  .ant-modal.precalltestmodal {
    top: 20px;
  }
  .precalltestmodal .ant-modal-content .ant-modal-body {
    padding-bottom: 15px;
  }
  #quality_status_container .results {
    margin-top: 10px;
  }
  div.prettyBox .results p {
    font-size: 12px;
  }
  div.prettyBox .results :last-child {
    margin-bottom: 6px;
  }
  div#quality_header {
    margin-bottom: 0 !important;
  }
}
.members_drawer .ant-list-item-meta-avatar .ant-avatar-image {
  width: 48px;
  height: 48px;
}
.members_drawer .ant-drawer-body .ant-list-item {
  padding-top: 0;
  border-bottom: solid 1px #e9e9e9 !important;
}
.members_drawer
  .ant-drawer-body
  .ant-list-item
  .ant-list-item-meta-content
  .ant-list-item-meta-title {
  color: #1445b7;
  font-size: 14px;
  line-height: 1.6;
  font-family: "Poppins Medium";
  text-transform: capitalize;
}
.members_drawer .ant-list-item-meta-avatar {
  margin-right: 14px;
}
.drawer_icons .drawer_icons_inner {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;
  padding: 0;
  background-color: rgba(51, 105, 231, 0.2);
  text-align: center;
  border-radius: 50%;
}
.drawer_icons .drawer_icons_inner img,
.drawer_icons_inner_active img {
  height: 12px;
}
.drawer_icons_inner_active {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;
  padding: 0;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(29, 41, 67, 0.1);
}
#blank_div.full-screen-active .video-chatmainsection.full-open-chat {
  display: block !important;
  position: absolute;
  right: 93px;
  bottom: 45px !important;
}
.full-screen-active span.chatbox_unread,
.chatbox_unread.chat-unread-full {
  position: absolute;
  top: -12px;
  color: #fff;
  right: -8px;
}
/* #layoutContainerV .ot-layout {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  left: unset !important;
  top: unset !important;
}
#layoutContainerV {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1px;
}
#blank_div.full-screen-active #layoutContainerV {
  grid-template-columns: auto auto;
}*/
.patient-call-conatainer .familyrecords_wrapper {
  padding-top: 62px;
  padding-right: 0;
  padding-left: 10px;
}
.show_member_btn {
  margin: 0 15px 10px;
  text-align: right;
}
.show_member_btn .custom_ok_btn {
  text-shadow: none;
  font-family: "Gotham Medium" !important;
  font-size: 14px !important;
  padding: 4px 6px;
  letter-spacing: -0.2px;
}
.full-screen-active .calling-uper-container {
  margin-top: 3px;
  margin-bottom: 0px;
}
.full-screen-active .show_member_btn {
  margin-bottom: 5px;
}
.full-screen-active .show_member_btn .custom_ok_btn {
  height: 28px;
  line-height: 1;
}
.full-screen-active #layoutContainerV .ot-layout:only-child {
  width: 78% !important;
  left: 50% !important;
  /* right: 0 !important; */
  transform: translateX(-50%);
  height: 100% !important;
}
/* .selecti3on_container .patient_detail_header {
  z-index: 6;
} */
.call_bg_wrapper {
  display: flex;
  margin-top: 15px;
}
.call_bg_wrapper .call_bg_inner {
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.call_bg_wrapper .call_bg_inner:last-child {
  margin-right: 0;
}
.call_bg_wrapper .call_bg_inner label {
  margin-bottom: 0;
  padding-left: 3px;
  font-size: 12px;
  color: #808396;
  font-family: "Gotham Book";
  line-height: 1;
  display: block;
}
.call_bg_wrapper .call_bg_inner .ant-switch {
  min-width: 36px;
}
.blur_bg_wrapper {
  margin-top: 15px;
}
.blur_bg_wrapper label.ant-radio-wrapper {
  display: block;
  margin-top: 0px;
  line-height: 1;
}
.blur_bg_wrapper label.ant-radio-wrapper .ant-radio {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  background-color: #d8d8d8;
  padding: 4px;
  display: block;
  overflow: hidden;
  top: 0;
}
.blur_bg_wrapper label.ant-radio-wrapper .ant-radio + span {
  display: block;
  padding: 0;
  margin-top: 5px;
  color: #1c2745;
  font-size: 11px;
  font-family: "Gotham Medium";
}
.blur_bg_wrapper label.ant-radio-wrapper .ant-radio .ant-radio-inner {
  display: inline-block;
  width: 10px;
  height: 10px;
  top: -5px;
}
.blur_bg_wrapper
  label.ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner::after {
  top: 0px;
  left: 2px;
  background-color: transparent;
  transform: scale(1) rotate(45deg);
  border: 2px solid #1445b7 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 3.5px !important;
  height: 6.142857px !important;
  /* transition: 0.2s opacity; */
}
.blur_bg_wrapper label.ant-radio-wrapper .ant-radio-checked::after {
  content: none;
}
.blur_bg_wrapper .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.setting_drawer .ant-drawer-body {
  padding: 12px;
}
.setting_drawer .ant-drawer-body #publisher2 {
  border: none !important;
  height: 150px;
}
.setting_drawer .ant-drawer-body {
  padding: 12px;
}
.setting_drawer .ant-drawer-body #publisher2 {
  border: none !important;
  height: 150px;
}
.setting_drawer .ant-drawer-body .ant-space .ant-btn {
  width: 100%;
  height: 36px;
  font-family: "Gotham Medium";
  font-weight: 500;
}
.setting_drawer .ant-drawer-body .ant-space .ant-btn.reset_btn {
  border: solid 1px #1445b7 !important;
  color: #1445b7;
}
.setting_drawer .ant-drawer-body .ant-space {
  display: flex;
  padding-right: 8px;
}
.setting_drawer .ant-drawer-body .ant-space .ant-space-item {
  flex: 0 0 50%;
  max-width: 50%;
}
.setting_drawer .ant-drawer-header {
  padding-left: 12px;
  padding-right: 12px;
}
.setting_drawer .ant-drawer-header .ant-drawer-title {
  font-family: "Gotham Medium";
  font-size: 16px;
  color: #1c2745;
}
.call_bg_wrapper .ant-radio-group .ant-radio-wrapper {
  color: #808396;
  font-size: 12px;
  font-family: "Gotham Book";
}
.call_bg_wrapper .ant-radio-group {
  flex-wrap: wrap;
}
.call_bg_wrapper .ant-radio-group .ant-radio-wrapper:last-child {
  margin-right: 0;
}
.call_bg_wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  width: 14px;
  height: 14px;
}
.call_bg_wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner::after {
  width: 6px;
  height: 6px;
}
.call_bg_wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  padding-right: 0;
  padding-left: 5px;
}
.btn.click-text-btn {
  width: 169px;
  height: 30px;
  padding: 5px;
}
.volume_slider .anticon-sound svg path:nth-child(2) {
  fill: #1445b7;
}
.text-dark-blue {
  color: #1445b7 !important;
}
.unset-width {
  min-width: unset !important;
}

.resize-handle-wrapper {
  position: absolute;
  left: 0;
  bottom: -25px;
  z-index: 99;
  width: 35px;
  height: 35px;
}
.resize-handle-wrapper .resize-handle-bottom-left {
  position: static !important;
  width: 100% !important;
  height: 100% !important;
}
.button-resize {
  background-color: transparent;
  color: #fff;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  cursor: ne-resize !important;
  display: grid;
  place-items: center;
  border-radius: 2px;
}
.button-resize .icon-resize {color: #fff;fill: currentColor;width: 100%;
  height: 100%;filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 1));}
