.browser_setup {
  margin: auto;
}
.crome_setup,
.edge_setup{
  max-width: 720px;
}
.firefox_setup{
  max-width: 648px;
}
.safari_setup{
  max-width: 706px;
}
.browser_setup ol {
  padding-left: 15px;
}
.setup-img-container {
  margin-top: 10px;
}
.setup-img-container img{
  max-width: 100%;
}
.mt-40{
  margin-top: 40px;
}
.pt-45{
  padding-top: 45px;
}


div#quality_status_container {
  background: unset;
  width: 100% !important;
  box-shadow: unset !important;
  border: unset !important;
  margin-top: 0 !important;
}

/* div#audioGraph {
  margin-top: 9%;
  margin-left: 19%;
} */
div.prettyBox .results p {
  color: #333;
  font-size: 14px;
  /* margin: 0; */
  font-family: "Gotham Medium";
}
div.prettyBox .results p span {
  font-family: "Gotham Book";
}
/* div#videoGraph {
  margin-top: 9%;
  margin-left: 15%;
} */
.highcharts-title tspan {
  text-transform: capitalize;
}

/* div#quality_status_container {
  border-top: solid 1px #e9e9e9 !important;
} */
div.prettyBox .results .excellent_status,
div.prettyBox .results .good_status,
div.prettyBox .results .bed_status,
div.prettyBox .results .fair_status,
div.prettyBox .results .poor_status {
  display: inline-block;
  padding: 6px;
}
div.prettyBox .results .excellent_status {
  /* box-shadow: 1px 1px 2px 2px #b6fac8; */
  background-color: #04b333;
  color: #ffffff;
}
div.prettyBox .results .bed_status {
  background-color: #e52c2c;
  color: #ffffff;
}
div.prettyBox .results .good_status {
  background-color: #43dc91;
  color: #ffffff;
}
div.prettyBox .results .fair_status {
  background-color: #ffaf1b;
  color: #ffffff;
}
div.prettyBox .results .fair_status {
  background-color: #ffaf1b;
  color: #ffffff;
}
div.prettyBox .results .poor_status {
  background-color: #fa6d6d;
  color: #ffffff;
}
.re-run-btn {
  margin-left: 10px !important;
  background-color: rgb(233, 233, 233) !important;
  color: #0000008c !important;
  border: none !important;
}

#graphContainer {
  display: none;
}

.prettyBox h1 {
  margin-top: 0;
  padding-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  display: block;
  font-weight: 200;
  padding: 0.6rem;
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(237, 237, 237, 1)),
    color-stop(53%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
}

.prettyBox h1 small {
  color: #999;
}

.prettyBox {
  box-sizing: border-box;
  padding: 1rem;
  display: none;

  margin: 0 auto;
  margin-top: 1rem;
  max-width: 90%;
  min-width: 60%;
  width: 600px;
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(237, 237, 237, 1)),
    color-stop(53%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(246, 246, 246, 1) 53%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.prettyBox h2 {
  margin-top: 0;
  font-size: 20px;
}

div.prettyBox p {
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}

/* div.prettyBox .results {
  display: none;
} */

h3 {
  font-size: 16px;
  margin-bottom: 6px;
}
div.prettyBox .results :last-child {
  margin-bottom: 8px;
}

div.prettyBox img {
  width: 50px;
}

#precall {
  display: block;
}

#precall .options span {
  margin-left: 24px;
  font-size: 14px;
}

#precall .options {
  margin: 20px;
}

#precall button {
  font-family: "Helvetica Neu", Arial;
  font-size: 12px;
}

#quality_status_container {
  display: none;
  flex-direction: column;
  align-items: center;
}

#stop_test {
  margin-top: 8px;
  display: none;
}
#quality_status_container > div {
  display: flex;
  justify-content: center;
}

#quality_header {
  flex-direction: column;
  align-items: center;
}

#quality_status_container #audio,
#quality_status_container #video {
  width: 50%;
}

.graph {
  height: 240px;
}

/* #quality_status_container .results {
  display: none;
}

#video-unsupported-reason {
  display: none;
} */

@media screen and (max-width: 380px) {
  h1 {
    font-size: 24px;
    padding: 0.5rem;
  }
  h2 {
    font-size: 18px;
  }
  div.prettyBox p {
    font-size: 20px;
  }
  div.prettyBox {
    padding: 1rem;
  }
}
.call-quality-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
}