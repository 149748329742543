/* .pre-encounter-item-header {
  height: 21px;
  line-height: 21px;
  padding: 0 8px;
} */

/* .encounter_wrap {
  padding-right: 15px;
  padding-left: 15px;
} */
.prescription-array .ant-row {
  margin-left: -15px;
  margin-right: -15px;
}
.searchbar_dropdown.medical_Search {
  margin: 0;
  max-width: 384px;
  margin-left: auto;
}
.searchbar_dropdown.medical_Search .anticon-search {
  color: #757575;
  font-size: 14px;
  margin-right: 5px;
}
.searchbar_dropdown.medical_Search input.ant-input {
  /* background-color: #f7f7f7; */
  border: 1px solid #e9e9e9;
  height: 28px;
  font-size: 11px;
}
.searchbar_dropdown.medical_Search img {
  width: 18px;
  z-index: 1;
}
.searchbar_dropdown.medical_Search .ant-input-affix-wrapper-lg {
  font-size: 11px;
  /* border: 0; background-color: #f7f7f7;
  padding: 0 15px 0 35px!important; */
  padding: 0 15px 0 10px !important;
  border: 1px solid #e9e9e9;
}
.searchbar_dropdown.medical_Search .ant-input-affix-wrapper-lg .ant-input.ant-input-lg {
  border: 0 !important;
}
.searchbar_dropdown.medical_Search input.ant-input::-webkit-input-placeholder {/* Chrome/Opera/Safari */
  color: #9f9b9b;
  font-family: "Gotham Book";
}
.searchbar_dropdown.medical_Search input.ant-input::-moz-placeholder {/* Firefox 19+ */
  color: #9f9b9b;
  font-family: "Gotham Book";
}
.searchbar_dropdown.medical_Search input.ant-input:-ms-input-placeholder {/* IE 10+ */
  color: #9f9b9b;
  font-family: "Gotham Book";
}
.searchbar_dropdown.medical_Search input.ant-input:-moz-placeholder {/* Firefox 18- */
  color: #9f9b9b;
  font-family: "Gotham Book";
}