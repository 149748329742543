.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.rota-wrapper {
  padding-top: 57px;
  padding-left: 10px;
  padding-right: 10px;
}
.rota-wrapper.patient-search-container {
  height: calc(100vh - 28px);
}
.rota-wrapper.patient-search-container .rota-wrapper-row {
  height: unset !important;
}
.add-rota-wrapper {
  padding-left: 22px;
}
.add-rota-inner {
  text-align: center;
}
.rota_modal .Calendar {
  width: 224px !important;
  margin-top: 0 !important;
}
.rota_modal .ant-modal-body .Calendar__footer .ant-btn {
  background: #1445b7 !important;
  color: #fff;
  border: 1px solid #1445b7 !important;
}
.rota_modal .ant-modal-body .Calendar__footer .ant-btn span {
  font-size: 12px;
}
.rota_modal .ant-modal-body .Calendar__footer > div {
  padding: 10px !important;
  border-top: 1px solid #f1f1f1;
}
/* .rota_modal .Calendar .Calendar__day.-ltr.-today {
  background: #2373bb;
  color: #ffffff !important;
} */
/*
.btn-item-wrapper {
  background: red;
  width: 43%;
  display: flex;
  justify-content: space-around;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
}
 */
.rota-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}
.Calendar {
  width: 201px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  margin-top: 10px !important;
  font-family: "Gotham Book";
  min-height: auto !important;
}
.Calendar .Calendar__section.-shown,
.Calendar .Calendar__weekDays {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.Calendar__header {
  background: #1445b7;
  color: #ffffff;
  padding: 10px !important;
}
.Calendar .Calendar__monthYear button {
  color: #ffffff;
  transform: none;
}
.Calendar .Calendar__monthYear.-shown button:hover {
  background: #1445b7;
}
.Calendar .Calendar__monthArrow {
  filter: brightness(0) invert(1);
}
.Calendar .Calendar__weekDays {
  font-size: 10px;
  background-color: #f1f1f1;
  color: #404040;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Calendar__sectionWrapper {
  min-height: 20.8em !important;
}
.rota-wrapper-row .rota-wrapper-col.width-25 {
  width: 221px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #f6f6f6;
}
.rota-wrapper-row .rota-wrapper-col.width-75 {
  width: calc(100% - 221px);
  padding-right: 10px;
  padding-left: 10px;
}
.rota-wrapper-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  height: calc(100vh - 28px);
}
.ant-btn *,
.ant-btn {
  height: auto;
  line-height: 1.43;
  letter-spacing: -0.2px;
  font-family: "Gotham Book";
}
.ant-btn .anticon {
  vertical-align: middle;
}
.ant-btn .anticon svg {
  vertical-align: middle;
}
.ant-btn-primary span {
  text-shadow: none;
}
.rota_wrapper_data .ant-spin-container .ant-list-items {
  margin-right: -10px;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}
.rota_wrapper_data .ant-spin-container {
  padding: 0 10px;
}
.rota_wrapper_data .ant-spin-container .ant-list-items .ant-list-item {
  width: calc(50% - 5px);
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  border: solid 1px #e9e9e9;
  margin-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  line-height: 1.1;
  margin-bottom: 10px;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item:nth-child(odd) {
  margin-right: 5px;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item:nth-child(even) {
  margin-left: 5px;
  margin-right: 0;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta-title {
  margin: 0 0 5px 0;
  font-size: 12px;
  line-height: 1.1;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta {
  align-items: center;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-content {
  text-align: left;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-avatar
  * {
  vertical-align: middle;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-avatar {
  margin-right: 10px;
  margin-top: -3px;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-action
  li {
  padding: 0;
}
.small-btn {
  padding-right: 8px;
  padding-left: 8px;
}
.font-12 span {
  font-size: 12px;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-avatar
  .anticon-clock-circle,
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-action
  .ant-dropdown-link
  img {
  margin-top: -5px;
}
.Calendar__section.-hiddenNext {
  display: none;
}
.Calendar__section.-hiddenNext.-shownAnimated {
  display: flex;
}
.rota_wrapper_data
  .ant-spin-container
  .ant-list-items
  .ant-list-item
  .ant-list-item-action
  li
  .ant-dropdown-trigger
  img {
  transform: rotate(90deg);
  height: 6px;
}
.rota-wrapper-row .rota-wrapper-col .Calendar .Calendar__weekDays abbr[title],
.rota-wrapper-row
  .rota-wrapper-col
  .Calendar
  .Calendar__weekDays
  abbr[data-original-title] {
  cursor: pointer;
  text-decoration: none;
}
.rota-wrapper-row .rota-wrapper-col .Calendar .Calendar__weekDays,
.Calendar__sectionWrapper {
  padding-right: 6px !important;
  padding-left: 6px !important;
}
.rota-wrapper-row .rota-wrapper-col .Calendar__day.-ltr {
  height: 2.3em;
  min-height: auto;
}
.rota_modal .react-calendar__tile.react-calendar__month-view__days__day {
  width: 34px;
  border-radius: 50%;
  padding: 4px;
  max-width: none !important;
  flex-basis: unset !important;
  height: 34px;
  margin: 2px;
  font-size: 12px;
}
.rota_modal
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday {
  width: 34px;
  max-width: none !important;
  flex-basis: unset !important;
  margin: 0 2px;
  padding-right: 0;
  padding-left: 0;
}
.rota_modal .react-daterange-picker__calendar {
  width: 286px;
}
.rota_modal .react-calendar__month-view__weekdays,
.rota_modal .react-calendar__month-view__days {
  padding: 0 10px;
}
.rota_modal .react-daterange-picker__calendar {
  font-family: "Gotham Book" !important;
}
.rota_modal
  .ant-modal-body
  .react-calendar
  .react-calendar__month-view__weekdays__weekday
  abbr[title] {
  font-family: "Gotham Bold";
}
.rota_modal .react-calendar__month-view__days__day--weekend {
  color: #5d5d5d;
}
.rota_modal .Calendar .Calendar__weekDays .Calendar__weekDay {
  width: 34px;
}
.rota_modal .Calendar__day.-ltr {
  width: 32px;
  height: 30px;
  min-height: unset;
  font-size: 12px;
}
.rota_modal .Calendar .Calendar__monthYear.-shown button {
  font-size: 13px;
}
.rota_modal .Calendar__header {
  padding: 6px 6px !important;
}
.rota_modal .ant-modal-body .Calendar__footer .ant-btn {
  min-width: 70px;
  height: 28px;
  padding: 3px 8px 1px !important;
}
.rota_modal .ant-modal-body .Calendar__footer .ant-btn:first-child {
  background-color: #e9e9e9 !important;
  border: solid 1px #e9e9e9 !important;
  color: #404040 !important;
}
/* .rota_modal .ant-modal-body .react-daterange-picker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup__day.react-daterange-picker__inputGroup__input--hasLeadingZero,
.rota_modal .ant-modal-body .react-daterange-picker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup__month.react-daterange-picker__inputGroup__input--hasLeadingZero {
    min-width: 18px;
} */

/* 5-1-2023 css start */
.rota-wrapper-row .rota-wrapper-col.width-100 {
  width: 100%;
}

.rota-select-option.ant-select-item-option-active{
  background-color: #1445b7 !important;
  color: #fff !important;
}
.remove-rota-issue .rota-wrapper.patient-search-container {
  display: none;
}