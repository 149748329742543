.up_doctor_profile img {
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
}
.up_doctor_profile {
  background-color: #f7f7f7;
  border-radius: 50%;
  overflow: hidden;
  height: 30px;
  width: 30px;
}
.upcoming_booking_table {
  border: solid 1px #eeeeee;
  min-height: 40px;
}
.upcoming_booking_table .arrive_btn {
  height: 30px;
  padding: 0 10px;
}
.upcoming_booking_table .autoheightbtn .arrive_btn {
  height: auto;
  padding: 6px 8px;
}

.upcoming_booking_table p {
  word-break: break-word;
}
.upcoming_appt_list
  .upcoming_appt_data
  .upcoming_booking_table:not(:last-child) {
  border-bottom: none;
}
.upcoming_appt_list .upcoming_appt_data .aprove_rejecttable {
  border-bottom: solid 1px #e9e9e9 !important;
}
.upcoming_appt_list .upcoming_appt_data .aprove_rejecttable:not(:first-child) {
  border-bottom: solid 1px #e9e9e9;
  border-top: none;
}
.anticon.font-16 svg {
  font-size: 16px;
}
.text-ellipsis-55 {
  max-width: 55%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.custom-text-width {
  max-width: calc(100% - 83px);
}
.aprove_reject_btns .aprove_btn,
.aprove_reject_btns .reject_btn {
  width: calc(50% - 3px);
  margin-right: 6px;
  display: inline-block;
  vertical-align: middle;
}
.aprove_reject_btns .reject_btn {
  margin-right: 0;
}
.upcoming_booking_tableheader th {
  font-family: "Gotham Medium";
  font-weight: 500;
}
/* .Calendar .Calendar__day.-ltr.-today {
  background: #1445B7!important;
  color: #fff!important;
} */

.Calendar .Calendar__day.-ltr.-today {
  /* background: #1445B7 !important;
	color: #ffffff !important; */

  /* background: #212529 !important; */
  color: #212529 !important;
  font-weight: bolder !important;
  /* font-size: 15px; */
  border: 1px solid #1445b7;
}
.Calendar__day.-ltr {
  min-height: 2.4em !important;
}
/* .calender_box .Calendar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
} */
.calender_box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.calender_box .Calendar__day {
  line-height: normal;
  padding-top: 4px !important;
}
.height-30px {
  height: 30px;
}
.object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.border-radius-4 {
  border-radius: 4px;
}
.dynamic_header_ques .patient_detail_left {
  width: 145px;
}
.dynamic_header_ques .patient_detail_left .timer_div {
  display: inline-block;
}
.dynamic_header_ques .patient_detail_left .timer_div + .CollapsiableSubHeader {
  margin-top: 8px;
}
.questionaire_detailswrapper {
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
}
.prl-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.questionaireinner_wrapper_table .ant-collapse-header,
.onlineconsultation_wrapper_table .ant-collapse-header {
  padding: 0 !important;
}
.questionaireinner_wrapper_table .ant-collapse-item,
.onlineconsultation_wrapper_table .ant-collapse-item {
  border-bottom: solid 1px #eeeeee !important;
}
.questionaireinner_wrapper_table .ant-collapse,
.onlineconsultation_wrapper_table .ant-collapse {
  border: solid 1px #eeeeee !important;
  background-color: #fff;
}
.border.questionaireinner_wrapper_subtable,
.border.onlineconsultation_wrapper_subtable {
  border-bottom: none !important;
}
.questionaireinner_wrapper_table .ant-collapse-content-box,
.onlineconsultation_wrapper_table .ant-collapse-content-box {
  padding: 10px;
}
.questionaireinner_wrapper_table .ant-collapse-item.ant-collapse-item-active,
.onlineconsultation_wrapper_table .ant-collapse-item.ant-collapse-item-active {
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
}
.questionaireinner_wrapper_table
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header,
.onlineconsultation_wrapper_table
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header {
  background-color: #1445b7;
  color: #fff;
}
.questionaireinner_wrapper_table
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  td
  p,
.onlineconsultation_wrapper_table
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  td
  p {
  color: #fff !important;
}
