.fc-timegrid-slot {
  padding: 20px 4px !important;
}
.disbaled-event {
  /* background-color: #00000015 !important; */
  background-color: #00000015 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.appointmentcalender-table .fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px;
}
.appointmentcalender-table .fc-toolbar.fc-header-toolbar h2 {
  font-family: "Gotham Medium";
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
}
.rl-5 {
  padding-right: 5px;
  padding-left: 5px;
}
.appointmentcalender-table .ant-select.doctors-multi-dropdown {
  width: 100%;
  min-width: unset;
}
/* .appointmentcalender-table .table-responsive .fc-right {
  margin-right: 46px;
} */
.appointmentcalender-table .table-responsive .date-arrow-wrapper {
  position: absolute;
  right: 18px;
  top: 80px;
}
.appointmentcalender-table .fc-left div {
  display: flex;
  align-items: center;
}
.appointmentcalender-table .fc-button-primary {
  background-color: #1445b7;
  border-color: #1445b7;
  border-radius: 0.25em !important;
}
.appointmentcalender-table .fc-button-primary:not(:last-child) {
  margin-right: 3px;
}
.appointmentcalender-table .fc-button-primary.fc-button-active,
.appointmentcalender-table .fc-button-primary:hover {
  background: rgb(226, 244, 255);
  color: rgb(32, 114, 187);
  border-color: rgb(32, 114, 187);
}
.appointmentcalender-table .fc-button-primary.fc-next-button {
  margin-right: 28px;
}
.appointmentcalender-table .fc-left div .fc-button-primary {
  display: none;
}
.appointmentcalender-table {
  border-radius: 3px;
  border: none;
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  position: relative;
}
.full-screen-wrapper {
  position: relative;
}
.appointmentcalender-table .full-screen-btn {
  position: absolute;
  width: 24px;
  right: 380px;
  top: 13px;
  cursor: pointer;
}
.appointmentcalender-table .fullscreen-enabled .full-screen-btn {
  top: 11px;
}
.fc .fc-timegrid-slot {
  border-bottom: 8px;
  height: 4.5em !important;
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: unset;
}
.fc-theme-standard td:not(.fc-timegrid-slot):not(.fc-timegrid-axis-frame) {
  border-right: 1px solid transparent !important;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  margin-bottom: -1px !important;
  border-color: #ddd !important;
  border-left: none;
}

.empty-slot-event:hover {
  cursor: url('../../assets/images/common/cursor-hand.svg'), auto;
}
.empty-slot-event-week:hover {
cursor: url('../../assets/images/common/cursor-hand.svg'), auto;
}

.event-slote-title {
  max-width: calc(100% - 94px);
  min-width: 5px;
}
.event-slote-title.booked {
  max-width: calc(100% - 77px);
}
.event-slote-title.finished {
max-width: calc(100% - 64px);
}
.event-slote-title.finished-oos {
  max-width: calc(100% - 94px);
}
.event-slote-title.not-attended {
  max-width: calc(100% - 96px);
}
.event-slote-title.patient-arrived {
  max-width: calc(100% - 103px);
}
.event-slote-title.ready-for-call {
  max-width: calc(100% - 102px);
}
.event-slote-title.on-call {
  max-width: calc(100% - 58px);
}
.event-slote-title.in-review {
  max-width: calc(100% - 71px);
}
.event-slote-title.cancelled {
  max-width: calc(100% - 74px);
}
.event-slote-title.patient-not-arrived {
  max-width: calc(100% - 131px);
}
.event-slote-title.call-ended {
  max-width: calc(100% - 78px);
}
.event-slote-status {
  min-width: 0;
  max-width: calc(100% - 18px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.BookAppointment_Flow .fc-col-header.fc-scrollgrid-sync-table {
border-bottom: 1px solid #ddd !important;
}