.otp-wrapper {
  width: 100%;
  min-height: 100vh;
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f7f7f7; */
}
.otp-content-wrapper {
  font-family: "Gotham Book";
}
.otp-wrapper-logo {
  /* margin-bottom: 40px; */
  /* margin-bottom: 15px;
  margin-top: 15px; */
  margin-right: 30px;
  margin-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.otp-wrapper-logo a {
  display: inline-block;
}
.otp-wrapper-logo img {
  max-width: 204px;
}
.otp-content-wrapper {
  max-width: 350px;
  width: 100%;
  margin-left: 30px;
  /* background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,.13); */
}
/* .form-control.is-valid + img {
  right: 32px;
} */
.otp-content-wrapper button.btn {
  padding-right: 20px;
  padding-left: 20px;
}
.otp-content-wrapper .header-content {
  padding: 25px 30px;
  border-bottom: 1px solid #ececec;
}
.otp-content-wrapper .header-content h3 {
  font-size: 20px;
  font-family: "Gotham Medium";
  margin: 0;
  padding: 0;
}
.otp-content-wrapper .body-content .body-verify-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}
/* .body-content {
  padding: 30px;
} */
.otp-content-wrapper .body-content .body-verify-content .body-verify-text {
  padding-left: 20px;
  line-height: 1.71;
}
.otp-content-wrapper .body-content .body-verify-content .body-verify-text p {
  margin: 0;
  color: #868585;
}
.otp-content-wrapper .body-content .body-verify-content .body-verify-text b {
  margin: 0;
  color: #202020;
}
.otp-content-wrapper .body-content .otp-form p a b,
.otp-content-wrapper .body-content .body-verify-content .body-verify-text b {
  font-family: "Gotham Medium";
  font-weight: normal;
}
.otp-content-wrapper .body-content .otp-form p {
  margin: 0;
  /* color: #1445b7; */
}
.ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 13px;
  color: #757575;
}
.otp-content-wrapper .body-content .otp-form p.rcvd_code {
  margin-top: 25px;
  margin-bottom: 25px;
}
.otp-content-wrapper .body-content .otp-form p a b,
.otp-content-wrapper .body-content .otp-form p.rcvd_code b {
  /* text-decoration: underline; */
  color: #1445b7;
  font-family: "Gotham Medium";
  font-weight: normal;
}
.otp-text-input-form .ant-checkbox-wrapper .ant-checkbox {
  /* top: 0; */
  position: absolute;
  left: 0;
}
.otp-text-input-form .ant-checkbox-wrapper {
  position: relative;
  line-height: 1.42857143;
}
.otp-text-input-form .ant-checkbox-wrapper .ant-checkbox + span {
  padding-left: 25px;
  float: left;
  padding-right: 0;
  font-size: 12px;
}
.otp-form .form-group {
  margin-bottom: 16px;
}
.rcvd_code_checkbox div {
  line-height: 1;
}
.push-30-b.form-group {
  margin-bottom: 30px;
}
.otp-content-wrapper button.forget_btn {
  padding-right: 24px;
  padding-left: 24px;
}
