.ant-drawer-title {
  color: white;
  font-weight: 600;
}
button.ant-drawer-close {
  color: #fff;
}
.ant-drawer-header {
  background-color: rgb(20, 69, 183);
  color: rgb(255, 255, 255);
}
