.patient-searchbar {
  display: flex;
  margin-top: 12px;
}
.patient-results {
  margin-top: 30px;
}
.searchbar_dropdown .ant-input {
  height: 40px;
  font-size: 12px;
  color: #404040;
  padding-left: 41px;
  font-family: "Gotham Book";
}
.searchbar_dropdown img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.searchbar_dropdown {
  position: relative;
  margin-right: 15px;
}
.searchdropdowninputmain .ant-btn-primary {
  height: 40px !important;
  padding: 0px 20px !important;
  margin-left: 0px;
  width: 123px;
}
.patient-results .row {
  margin-right: -10px;
  margin-left: -10px;
}
.patient-results table.w-100 {
  background-color: #ffffff;
  border: solid 1px #e9e9e9;
  /* font-family: "Gotham Book"; */
}
.patient-results .newpatientlistdata table:not(:first-child) {
  margin-top: -1px;
}
.appointment-booking-table button.ant-btn {
  height: 30px;
  border-radius: 2px;
  margin: 5px 0px;
  font-size: 14px;
  line-height: 1.43;
  padding: 0 20px;
}
.appointment-booking-table button.ant-btn span {
  font-family: "Gotham Medium";
}
.appointment-booking-table .ant-tag-green {
  background-color: rgba(67, 220, 145, 0.1);
  border: solid 1px #43dc91;
  color: #43dc91;
}
.appointment-booking-table .ant-tag {
  font-size: 10px;
  font-family: "Gotham Medium";
  line-height: 1.4;
  height: 20px;
  border-radius: 3px;
  padding: 2px 8px;
}
/* .prescription-array {
  display: flex;
  flex-direction: column-reverse;
} */
.pre-encounter-item-header {
  width: 100% !important;
  display: flex !important;
  /* justify-content: flex-start !important; */
  /* align-items: flex-start !important; */
  /* background: #42abe2 !important; */
  color: #fff !important;
}
.pre-encounter-item-header span:first-child {
  flex-grow: 1;
}
.pre-encounter-item-header .styled-link:last-child {
  padding: 0 10px 0 10px;
  min-width: 50px;
  display: block;
}
.startendtime{
  padding-left:5px;
  padding-right: 5px;
  display: block;
}
.reported-by {
  display: flex;
  justify-content: flex-end;
}
.appointment-booking-table button.ant-btn.appointment-btn:hover {
  background-color: #37b577 !important;
  border-color: #37b577 !important;
}
.ant-modal-content .ant-modal-footer .ant-btn:hover {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  color: #404040;
}
.ant-collapse.ant-collapse-icon-position-right.collapse-reverse-array {
  display: flex;
  flex-direction: column-reverse;
}
.patient_dropdown_data {
  display: flex;
  flex-wrap: wrap;
}
.patient_dropdown_data .patient_dropdown_inner {
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 5px;
  display: flex;
  padding-right: 10px;
}
.patient_dropdown_data .patient_dropdown_inner:nth-last-child(-n + 4) {
  margin-bottom: 0;
}
.collapseprofiledetail .patient_dropdown_data {
  flex: 0 0 85%;
  max-width: 85%;
}
.collapseprofiledetail .patient_dropdown_dataright {
  flex: 0 0 15%;
  max-width: 15%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.collapseprofiledetail {
  display: flex;
  flex-wrap: wrap;
}
.CollapsiableSubHeader {
  float: right;
  width: 15px;
}
.search_filter .ant-input-affix-wrapper {
  height: 30px;
}
.search_filter .ant-input-affix-wrapper input.ant-input:focus,
.search_filter .ant-input-affix-wrapper input.ant-input {
  border: none !important;
  outline: none !important;
}
.patient-searchbar span.ant-input-affix-wrapper {
  padding: 0 10px 0px 0;
}
.patient-searchbar span.ant-input-affix-wrapper input.ant-input {
  padding-left: 41px;
}
.patient-searchbar img {
  z-index: 1;
}
.patient-searchbar span.ant-input-affix-wrapper input.ant-input:hover,
.patient-searchbar span.ant-input-affix-wrapper input.ant-input:focus {
  border: 0 !important;
  outline: none !important;
}
.adress_popup b {
  font-size: 12px;
  font-weight: 700;
  color: #404040;
}
.adress_popup span {
  font-size: 12px;
  color: #404040;
}
.margin-right-5 {
  margin-right: 5px;
}
.actions_btns button {
  max-width: 59px;
  font-weight: 400;
}
.actions_btns button.height-30.add_appointmentbtn {
  max-width: inherit;
  height: auto;
  padding: 5px 10px;
}
.actions_btns .btn-custom.btn-white {
  border-color: #1445b7;
}
.actions_btns .btn-custom.btn-white.text-blue,
.actions_btns .ant-btn.btn-custom.btn-white.text-blue span {
  color: #1445b7;
  transition: none !important;
}

.newpatientlistdata span {
  font-size: 12px;
  color: #404040;
}
.mrgin-right-8 {
  margin-right: 8px;
}
.width-11 {
  width: 11.11%;
}
.width-10 {
  width: 10%;
}
.width-13 {
  width: 13%;
}
.width-6 {
  width: 6%;
}
.width-8 {
  width: 8%;
}
.width-14 {
  width: 14%;
}
.width-9 {
  width: 9%;
}
.font-12 {
  font-size: 12px !important;
}
.text-center {
  text-align: center;
}
.view_option_drop span {
  color: #1445b7;
  text-decoration: underline;
}
.checkbox_main {
  margin-bottom: 8px;
}
.adress_popup b {
  margin-bottom: 10px;
}
.saveoptionsbtn button.w-100 {
  width: auto !important;
  min-width: 100px;
  margin-top: 6px;
}
.ant-btn.btn-custom.btn-blue span {
  color: #fff !important;
}
.padding-right-12 {
  padding-right: 12px;
}
.biopopover {
  min-width: 120px;
}
.width-10 {
  width: 10%;
}
.checkbox_main .ant-checkbox-wrapper .ant-checkbox + span {
  color: #404040;
}
.actions_btns .btn-custom.btn-white:hover,
.actions_btns .btn-custom.btn-white.text-blue:hover span {
  background: #1445b7;
  color: #fff;
}
.font-12 span {
  word-break: break-all;
}
.ant-btn.btn-custom.btn-blue:hover span {
  color: #1445b7 !important;
}
.patient_profile .doctor_profile_text {
  padding-left: 0;
  width: auto;
  display: flex;
}
.rota_wrapper_data .ant-btn.btn-custom.btn-blue:hover span {
  color: #fff !important;
}
a#patientSearch {
  text-align: center;
}