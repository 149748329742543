.profile_status.active {
  background-color: #1445b7;
}
.profile_status {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-bottom: 3px;
  margin-right: 6px;
}
.patient_detail_left
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  top: 0;
  font-size: 15px;
  color: #1445b7;
}
.patient_detail_left
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  /* padding: 0 0 17px 0; */
  padding: 0;
  position: relative;
  left: unset;
  top: unset;
}
.patient_detail_left
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box,
.patient_detail_left .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
/* .patient_detail_left .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 9px 18px;
} */
.patient_detail_left
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 40px;
  background-color: #ffffff;
  padding: 8px 0;
  border-bottom: solid 1px #f6f6f6;
}
.patient_detail_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
.patient_detail_header {
  position: fixed;
  width: calc(100% - 241px);
  background-color: #ffffff;
  z-index: 3;
  /* top: 43px; */
  top: 57px;
}
.customblock {
  width: calc(100% - 65px);
}
.photoidimages {
  padding: 5px 0px;
}
.photoidimages li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  background: #e9e9e9;
  margin-right: 5px;
}
.photoidimages li .patient-detail-img img,
.photoidimages li img.patient-detail-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}
.prescription_drugs_wrap {
  line-height: 22px;
  padding: 0;
  border-top: none;
}
button.custom-alert {
  height: 20px;
  padding: 0px 10px;
  line-height: 1;
  border-radius: 4px !important;
  background-color: #e3f5ff !important;
  vertical-align: middle;
  color: #1445b7 !important;
  font-size: 11px !important;
}
button.custom-alert span {
  font-size: 11px;
  color: #1445b7;
  vertical-align: middle !important;
}
button.custom-alert:hover {
  background-color: #e3f5ff !important;
}
.custom-alert.quantitytag {
  height: 25px;
  border-radius: 3px;
  border: solid 1px #1445b7;
  background-color: rgba(22, 164, 250, 0.08);
  font-family: "Gotham Book";
  font-size: 10px;
  font-weight: 500;
  color: #1445b7;
  text-align: center;
  line-height: 25px;
  font-weight: 500;
  display: inline-block;
  padding: 0px 10px;
}
.prescription_drugs_wrap:not(:first-child) {
  border-top: solid 1px #e9e9e9;
  padding: 5px 0 0px;
  margin-top: 10px;
}
/* .family_record_content .border.custom_table:not(:last-child){
  border-bottom: none !important;
} */
.font-12 .ant-table .ant-table-content .ant-table-tbody > tr > td.text-black {
  color: #121212;
}
.font-12 .ant-table .ant-table-content .ant-table-tbody > tr > td.text-gray {
  color: #adadad;
}
.custom-bluelabel {
  height: 20px;
  border-radius: 3px;
  padding: 0px 8px;
  align-items: center;
  font-size: 10px !important;
  text-align: center;
  background-color: #e8efff;
  border: solid 1px #1445b7;
  color: #1445b7;
  display: inline-block;
  line-height: 1.9;
  font-family: "Gotham Book";
  /* font-family: "Gotham Medium"; */
}
.custom-danger {
  height: 20px;
  border-radius: 3px;
  padding: 0px 8px;
  align-items: center;
  font-size: 10px !important;
  text-align: center;
  background-color: #ffe7e7;
  border: solid 1px #ed3336;
  color: #ed3336;
  display: inline-block;
  line-height: 1.9;
  font-family: "Gotham Book";
}
.custom-rounded-tag {
  padding: 4px 8px 3px;
  border-radius: 15px;
  font-size: 11px !important;
  font-weight: 700;
  display: inline-flex;
  line-height: normal;
  height: auto;
}
.test_investigation_content .ant-collapse {
  background-color: #ffffff;
  font-family: "Gotham Book";
  font-size: 12px;
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header {
  min-height: 25px;
  padding: 5px 58px 5px 10px;
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow {
  top: 8px;
  right: 12px;
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 10px;
}
.test_investigation_content .ant-collapse-item {
  margin-bottom: 5px;
  border: 1px solid #d9d9d9;
  background-color: #f9f9f9;
}
.test_investigation_content .ant-collapse {
  border: none;
}
.test_investigation_content .ant-collapse-item.ant-collapse-item-active {
  background-color: #fff;
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  .test_investigation_detail
  p {
  line-height: 1.4;
  color: #757575;
  font-size: 12px;
  margin-bottom: 10px;
}
.ant-modal-content .ant-modal-body .form-control.height-68 {
  height: 68px;
}
textarea.form-control {
  line-height: 1.4 !important;
}
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.patient_detail_left
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.patient_detail_left
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  width: calc(100% + 20px);
  left: -10px;
}
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  width: calc(100% - 222px);
  right: 0;
}
.patient_detail_header .patient_detail_right,
.walkin_detail_header .walkinheaderleft {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  min-width: 700px;
}
@media screen and (max-width: 1200px) {
  .patient_detail_header .patient_detail_right,
  .walkin_detail_header .walkinheaderleft {
    min-width: unset;
  }
}
.border-box.p-0 {
  padding: 0 !important;
}

/* consultation css starts */
.consultant_header
  .patient_detail_left
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  /* padding-right: 10px;padding-left: 10px; */
  padding: 0;
}
/* .consultant_header .patient_detail_left .ant-collapse > .ant-collapse-item > .ant-collapse-header .anticon {
  left: 4px;
  padding-bottom: 0;
  top: 3px;
} */
.consultant_profile {
  width: 30px;
  height: 30px;
}
.consultant_profile img {
  border-radius: 50%;
  height: 30px;
  background-color: #f7f7f7;
}
.div-border {
  line-height: 22px;
  border: 1px solid #e9e9e9;
}
.consultant_data {
  padding: 12px 12px;
}
.add-coding {
  position: absolute;
  right: 5px;
}
.consultant_review .add-coding {
  top: 0;
}
.actionicons {
  position: absolute;
  top: 5px;
  right: 0;
}
.addtextdiv .form-control {
  padding: 6px 50px 6px 12px;
}
.highlightedtext {
  border-top: 1px solid #e9e9e9;
}
.consultant_data:empty {
  display: none;
}
.consultaiondetail-main .coding_table {
  padding: 3px 15px;
  min-height: 25px;
  display: table;
  width: 100%;
  border-bottom: none;
  border: 1px solid #e9e9e9;
}
.consultaiondetail-main .coding_table:not(:last-child) {
  border-bottom: 0;
}
.consultaiondetail-main .coding_table *,
.consultaiondetail-main .coding_table {
  line-height: 1;
}
.consultaiondetail-main *,
.consultaiondetail-main {
  line-height: 1.4;
}
.consultaiondetail-main .consultant_data {
  padding: 5px 10px;
}
.live-talk2-table th {
  background-color: #e9e9e9;
  padding: 4px 10px !important;
  line-height: 1.42857143;
  border: 0;
  font-weight: 500;
}
.coding_modal_table {
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 0;
}
.default-padding {
  padding: 8px !important;
}
.ant-modal-content
  .ant-modal-body
  .form-control.addcomment.ant-input-affix-wrapper {
  padding: 0;
}
.ant-modal-content
  .ant-modal-body
  .form-control.addcomment.ant-input-affix-wrapper
  input {
  font-size: 11px;
  padding: 5px 16px;
  border: 0 !important;
}
.ant-modal-content
  .ant-modal-body
  .form-control.addcomment.ant-input-affix-wrapper
  .ant-input-suffix {
  margin-right: 12px;
}
.table.live-talk2-table {
  margin: 0;
}
.prescription-array.no-column-reverse {
  flex-direction: column;
}
.text-underline span.ant-upload {
  text-decoration: underline;
  color: #1445b7 !important;
}
.prescription-summary {
  background: #f7f7f7;
  margin-left: 8px;
}
.prescription-summary {
  padding: 10px;
  width: 50%;
  margin-bottom: 10px;
}
.screen_shot_section {
  width: calc(16.66% - 9px);
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.screen_shot_section .ant-image-img {
  height: 200px;
  object-fit: cover;
  object-position: top;
  background: #f9f9f9;
}
.screen_shot_section .ant-image {
  width: 100%;
  cursor: pointer;
}
.screen_shot_section:nth-child(6n + 0) {
  margin-right: 0;
}
.ant-modal-body
  .referral-modal-container
  label.ant-checkbox-wrapper
  .ant-checkbox
  + span {
  font-size: 10px;
}
.address_box {
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  padding: 5px 10px;
  line-height: 1.42857143;
}
.address_box p {
  margin: 0;
  color: #404040;
  font-family: "Gotham Book";
}
.custom_check span {
  font-size: 10px !important;
  color: #757575 !important;
}
.custom_check {
  margin-top: 10px;
}
.font-10 span {
  font-size: 10px !important;
}
.ant-modal-content
  .ant-modal-body
  .ant-picker.form-control
  .ant-picker-suffix
  .anticon {
  font-size: 14px !important;
}
.ant-alert-with-description {
  background-color: rgba(255, 89, 100, 0.08) !important;
  border: 1px solid #ff5964 !important;
  color: #ff5964 !important;
  font-family: "Gotham Medium";
  border-radius: 3px !important;
  padding: 2px 8px !important;
}
.ant-alert-with-description .ant-alert-description {
  font-size: 10px;
  text-align: center;
  line-height: 1.42857143;
}
.summary_panel .ant-collapse-header {
  font-size: 10px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
}
.summary_panel .anticon {
  top: 50% !important;
  transform: translateY(-50%);
  font-size: 10px !important;
}
.summary_panel .ant-collapse-content-box {
  padding: 10px !important;
}
.summary_panel {
  margin-bottom: 5px !important;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
}
.prescription-summary .ant-collapse {
  border: none;
  background-color: transparent;
}
.summary_panel .ant-collapse-content {
  border-top: 1px solid #e9e9e9;
}
.dlt_prescp {
  position: absolute;
  right: 5px;
}
.chatbox_search input {
  background-color: #f7f7f7;
  border: #f7f7f7;
  height: 30px;
  font-size: 11px;
  padding-left: 40px;
}
.chat-bubble-right,
.chat-bubble-left {
  display: -ms-flexbox;
  display: flex;
}
.chat-bubble-right,
.chat-bubble-left {
  max-width: 83.33333333%;
  margin-top: 15px;
  border-radius: 2px;
  position: relative;
}
.chat-bubble-right {
  -ms-flex-item-align: end;
  align-self: flex-end;
  background-color: #1445b7;
}
.chat-bubble-left {
  -ms-flex-item-align: start;
  align-self: flex-start;
  background-color: #e8efff;
}
.chat-bubble-right p,
.chat-bubble-left p {
  display: inline-block;
  font-size: 12px;
  word-break: break-word;
  line-height: 1.43;
  letter-spacing: -0.2px;
  margin: 0;
  padding: 5px 10px;
}
.chat-bubble-right p {
  text-align: right;
  color: #fff;
}
.chat-bubble-left p {
  text-align: left;
  color: #121212;
}
.chat-bubble-right::after,
.chat-bubble-left::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}
.chat-bubble-right::after {
  left: inherit;
  right: -4px;
  transform: rotate(316deg);
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  border-bottom: 0;
  border-left: 0;
  border-top-color: #1445b7;
  bottom: -4px;
}
.chat-bubble-left::after {
  border-top-color: #e8efff;
  border-bottom: 0;
  border-left: 0;
  left: 0;
  bottom: -7px;
}
.chat-bubble-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.chat-bubble-content {
  /* overflow-y: auto;
  height: calc(100vh - 108px)!important;
  overflow-x: hidden; */
  padding: 0 0 20px;
}
.inner_chathistory {
  height: calc(100vh - 125px) !important;
}
/* consultation css ends */

/* chat history css starts */
.chathistorymain .chathistoryleftsection {
  width: calc(100% - 320px);
  display: inline-block;
  vertical-align: top;
}
.chathistorymain .chathistoryrightsection {
  width: 315px;
  display: inline-block;
  vertical-align: top;
  padding: 10px 0px 10px 10px;
}
.chathistorymain .chathistoryleftsection {
  height: calc(100vh - 80px) !important;
}
.video-div-wrapper {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.video-div-wrapper .video_image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.video-play-button {
  position: absolute !important;
  background-color: transparent !important;
  border: none !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto !important;
  padding: 0 !important;
}
.ant-modal.video_popup {
  width: 710px !important;
}
.ant-modal.video_popup .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}
/* chat history css ends */

/* scrpt review css start */
.scriptreview_detail_header .questionarereviewheaderleft,
.scriptreview_detail_header .scriptreviewheaderleft {
  width: calc(100% - 312px);
  padding-right: 5px;
  /* padding-left: 5px; */
}
.scriptreview_detail_header .questionarereviewheaderleft {
  padding-top: 4px;
  padding-bottom: 4px;
}
.scriptreview_detail_header .questionarereviewheaderright,
.scriptreview_detail_header .scriptreviewheaderright {
  width: 312px;
  padding-right: 5px;
  padding-left: 5px;
}
.walkin_detail_header,
.scriptreview_detail_header,
.reportreview_detail_header {
  position: fixed;
  width: calc(100% - 236px);
  background-color: #ffffff;
  z-index: 1;
  top: 57px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}
.questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  top: 86.5px;
}
.walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  top: 90px;
}
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  top: 96.5px;
}
.questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content {
  position: fixed;
  /* width: calc(100% - 240px);
  right: 10px; */
  background-color: #ffffff;
  padding: 8px 0px 10px 8px;
  border-bottom: solid 1px #f6f6f6;
}
.ant-layout-sider-collapsed
  + .rightmainsection
  .questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.ant-layout-sider-collapsed
  + .rightmainsection
  .reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.ant-layout-sider-collapsed
  + .rightmainsection
  .walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.ant-layout-sider-collapsed
  + .rightmainsection
  .scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content,
.ant-layout-sider-collapsed + .rightmainsection .walkin_detail_header,
.ant-layout-sider-collapsed + .rightmainsection .scriptreview_detail_header,
.ant-layout-sider-collapsed + .rightmainsection .reportreview_detail_header {
  width: calc(100% - 50px);
}
.questionarereview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  .ant-collapse-content-box,
.reportreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  .ant-collapse-content-box,
.walkin_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  .ant-collapse-content-box,
.scriptreview_collapse
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  .ant-collapse-content-box {
  padding: 0;
}
.patient_detail_right,
.reportreviewheaderleft,
.walkinheaderleft {
  border-right: 1px solid #e9e9e9;
}
.questionarereviewheaderright,
.scriptreviewheaderright {
  border-left: 1px solid #e9e9e9;
}
.questionarereview_collapse .ant-collapse-header .ant-collapse-arrow,
.reportreview_collapse .ant-collapse-header .ant-collapse-arrow,
.walkin_collapse .ant-collapse-header .ant-collapse-arrow,
.scriptreview_collapse .ant-collapse-header .ant-collapse-arrow {
  position: relative !important;
  top: unset !important;
  left: unset !important;
}
.questionarereview_collapse .ant-collapse-header,
.reportreview_collapse .ant-collapse-header,
.walkin_collapse .ant-collapse-header,
.scriptreview_collapse .ant-collapse-header {
  /* padding: 0px 22px 0 0 !important; */
  padding: 0px !important;
}
.timer-notification {
  width: 9px;
  height: 9px;
  border: 8px solid rgba(255, 89, 100, 0.14);
  display: inline-block;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  top: -2px;
}
.timer_counter {
  position: relative;
  display: inline-block;
  border-radius: 33px;
  background-color: #f7f7f7;
  padding: 9px 10px 6px;
  min-width: 110px;
  font-family: "Gotham Medium";
  font-size: 13px;
}
.timer_div {
  position: relative;
  line-height: 1;
  vertical-align: middle;
}
.timer-notification::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #ff5964;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.check_identity_modal .slick-slider {
  padding-right: 30px;
  padding-left: 30px;
}
.check_identity_modal .slick-prev {
  left: 0;
}
.check_identity_modal .slick-next {
  right: 0;
}
.check_identity_modal .slick-slider .walkin_in_img img {
  max-width: 100%;
  height: 420px;
  object-fit: cover;
}
.scriptreview_bottomheader {
  margin-top: 65px;
}
.scriptreview_bottomheader .ant-collapse-header {
  padding: 4.5px 10px !important;
}
.scriptreview_bottomheader .ant-collapse {
  background-color: transparent;
}
.scriptreview_bottomheader span.anticon {
  display: none !important;
}
.scriptreview_bottomheader .ant-collapse-item-active .plus_icon,
.scriptreview_bottomheader .minus_icon {
  display: none;
}
.scriptreview_bottomheader .ant-collapse-item-active .minus_icon {
  display: inline-block;
}
.scriptreview_bottomheader .plus_icon,
.scriptreview_bottomheader .minus_icon {
  margin-top: -5px;
}
.scriptreview_bottomheader .ant-collapse-content-box {
  padding: 10px 12px;
  overflow-y: auto;
  max-height: 315px;
}
.scriptreviewmain_wrapper .scriptreview_bottomheader + .patient_detailswrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .medicalhistory_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .allergies_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .acute_medicate_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .nhscon_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .familyrecords_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .testinvest_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .splnotes_wrapper,
.scriptreviewmain_wrapper .scriptreview_bottomheader + .pastorders_wrapper {
  padding-top: 15px;
  padding-left: 0;
  padding-right: 0;
}

.scriptreviewmain_wrapper .scriptreview_bottomheader + .reportscont_wrapper {
  margin-top: 25px;
  padding-left: 0;
  padding-right: 0;
}
.scriptreviewmain_wrapper .scriptreview_bottomheader + .reportscont_wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.scriptreviewmain_wrapper .scriptreview_bottomheader + .preencounter_wrapper {
  margin-top: 5px;
}
.scriptreviewmain_wrapper .mainappoinmentwrap .selection_container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pre_quitionare .ant-collapse-item {
  margin-bottom: 10px;
  /* border: solid 1px #e9e9e9 !important; */
  border: none !important;
}
.pre_quitionare .ant-collapse-item.ant-collapse-item-active {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.pre_quitionare .ant-collapse-item .ant-collapse-header {
  padding: 0 25px 0 0 !important;
  background-color: #1445b7;
}
.ant-collapse.pre_quitionare {
  border: none;
  background-color: transparent;
}
.prl-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.pre_quitionare .ant-collapse-item .ant-collapse-content-box {
  padding: 10px;
}
.pre_quitionare .ant-collapse-item .ant-collapse-header span.anticon {
  right: 10px;
  top: 8px;
}
.pre_quitionare .ant-collapse-item .ant-collapse-header span.anticon svg {
  fill: #ffffff;
}
.pre_quitionare .ant-collapse-item .ant-collapse-header span.anticon {
  transform: rotate(90deg);
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  background-size: 12px;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  padding: 0;
  background-position: 50%;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.test_investigation_content
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-arrow {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.pre_quitionare
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  span.anticon {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.pre_quitionare
  .ant-collapse-item
  .ant-collapse-header
  span.ant-collapse-arrow
  svg,
.test_investigation_content
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow
  svg {
  display: none;
}
.pre_quitionare
  .ant-collapse-item
  .ant-collapse-header
  span.ant-collapse-arrow {
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  padding: 0;
  background-position: 50%;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  background-size: 9px;
}
/* scrpt review css start */

/* walkin appt screenshort starts */
.walkin_detail_header .walkinheaderright {
  flex-grow: 1;
}
.patient_detail_header .patient_detail_right {
  /* width: calc(100% - 370px); */
}
.walkin_detail_header .walkinheaderleft {
  /* width: calc(100% - 400px); */
}
.screenshort_tabs .ant-tabs-tab-btn {
  font-size: 13px;
}
.screenshort_tabs .ant-tabs-tab {
  margin-right: 15px;
  padding-top: 0px;
}
.screenshort_tabs_wrapper {
  width: calc(20% - 10px);
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}
.screenshort_tabs_wrapper:nth-child(5n + 0) {
  margin-right: 0;
}
.screenshort_tabs_wrapper .screenshort_tabs_img img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-width: 100%;
  height: 150px;
}
.screenshort_tabs_wrapper:hover .hover-div {
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 4px !important;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.2s ease-in-out;
}
.screenshort_tabs_wrapper .hover-div {
  transition: 0.2s ease-in-out;
}
.screenshort_tabs_wrapper .hover-div .img_detail {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.screenshort_tabs_wrapper .hover-div:hover .img_detail {
  opacity: 1;
  transition: 0.2s ease-in-out;
}
/* walkin appt screenshort ends */

.ant-modal-body .ant-radio-group .ant-radio-button-wrapper {
  border: 1px solid #d0d0da !important;
  background-color: transparent;
  color: #808396;
  border-radius: 50px !important;
  padding: 6px 15px !important;
  margin-bottom: 0;
  line-height: 1;
  height: auto;
  min-width: 79px;
  text-align: center;
}
.ant-modal-body .ant-radio-group .ant-radio-button-wrapper:nth-child(3) {
  border-left: none !important;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  margin-left: 10px;
}
.ant-modal-body .ant-radio-group .ant-radio-button-wrapper::before {
  content: none;
}
.ant-modal-body .ant-radio-group .ant-radio-button-wrapper .ant-radio-button {
  box-shadow: none !important;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #1445b7;
  color: #ffffff;
  border-color: #1445b7 !important;
  box-shadow: none !important;
}
.padding-6-imp {
  padding: 6px !important;
}
.screenshot-container .ant-image {
  display: block;
}
.new_prescription .prescription-form {
  width: 100%;
}
.allergy_table th {
  background-color: #fed9dc;
  border: none !important;
  color: #e52c2c !important;
}
.coding_modal_table.allergy_table {
  border: 1px solid #e52c2c;
  border-radius: 2px;
}
.allergy_table td {
  border: none !important;
  padding: 4px 8px !important;
}
.coding_modal_table.vital_drug td {
  border: none !important;
}
.allergy_table td label.past {
  color: #e52c2c !important;
  background-color: rgba(229, 44, 44, 0.18);
}
.allergy_table td label.past,
.allergy_table td label.active {
  padding: 3px 6px;
  border-radius: 3px;
  margin-left: 10px;
  margin-bottom: 0;
  display: inline-block;
}
.allergy_table td label.active {
  color: #017922 !important;
  background-color: rgba(1, 121, 34, 0.18);
}
.coding_modal_table.vital_drug {
  border: 1px solid #1445b7;
}
.coding_modal_table.vital_drug th {
  background: #f0f9ff;
  border: none !important;
  color: #1445b7;
}
.table.live-talk2-table td {
  vertical-align: middle;
}
.fixed_headertable tbody {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 120px;
}
.fixed_headertable tr {
  display: table;
  width: 100%;
  box-sizing: border-box;
}
.fixed_headertable tbody tr td {
  border-collapse: collapse;
}
.my_profile_pc .ant-upload {
  font-family: "Gotham Book";
  color: #1445b7;
  font-size: 14px;
  text-decoration: underline;
}
.info_icon .anticon-info-circle {
  transform: none !important;
  font-size: 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
.scriptreview_bottomheader .anticon.anticon-plus.plus_blue {
  display: block !important;
  margin-left: auto;
  color: #1445b7;
}
.scriptreview_bottomheader
  .ant-collapse-item-active
  .anticon.anticon-minus.minus_blue {
  display: block !important;
  margin-left: auto;
  color: #1445b7;
}
.scriptreview_bottomheader
  .ant-collapse-item-active
  .anticon.anticon-plus.plus_blue {
  display: none !important;
}
.consultant_review .indication-dropdown,
.consultaiondetail-main .indication-dropdown {
  border: none !important;
  height: 32px !important;
  padding: 0 !important;
}
.consultant_review .ant-input-affix-wrapper .ant-input,
.consultant_review .ant-input-affix-wrapper .ant-input:hover,
.consultaiondetail-main .ant-input-affix-wrapper .ant-input,
.consultaiondetail-main .ant-input-affix-wrapper .ant-input:hover {
  border: none !important;
}

/* parent element of .updatingDeatil-loader must have position-relative class */
.updatingDeatil-loader {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: #ffffffa8;
}
.prescriptiondetailpopup .fixed_headertable tbody tr td,
.prescriptiondetailpopup .table thead th {
  font-size: 10px !important;
}
.newcodetable td.default-padding {
  padding: 8px 10px !important;
}
.radiogroupbtn span.ant-radio span.ant-radio-inner {
  position: absolute;
  opacity: 0;
  top: 0;
}
.radiogroupbtn label.ant-radio-wrapper {
  position: relative;
  margin-right: 0px !important;
  padding: 8px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  border-radius: 0p;
}
.radiogroupbtn label.ant-radio-button-wrapper.ant-radio-button-checked {
  position: relative;
  border-color: #1445b7;
  background: #1445b7;
  color: #fff;
}

.ant-modal-body
  .ant-radio-group.radiogroupbtn
  label.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0px 0 4px !important;
  border-right: none !important;
}
.ant-modal-body
  .ant-radio-group.radiogroupbtn
  label.ant-radio-button-wrapper:last-child {
  border-radius: 0px 4px 4px 0px !important;
  border-left: none !important;
}
.ant-modal-body .ant-radio-group.radiogroupbtn .ant-radio-button-wrapper {
  border-radius: 0px !important;
}
.radiogroupbtn {
  margin-bottom: 14px !important;
}
.ant-modal-body .full-radio .ant-radio-group label.ant-radio-button-wrapper {
  width: 100%;
  padding: 8px !important;
  text-align: center;
  font-size: 14px !important;
  border-color: #c6cbcd !important;
}
.ant-modal-body
  .full-radio
  .ant-radio-group
  label.ant-radio-button-wrapper:first-child {
  border-radius: 2px 0px 0px 2px !important;
}
.ant-modal-body
  .full-radio
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  margin-left: 0px !important;
  border-left: none !important;
  border-radius: 0px 2px 2px 0px !important;
}
.extendtimepopup .radiogroupbtn {
  margin-bottom: 30px !important;
}
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) .ant-radio-button.ant-radio-button-disabled:not(.ant-radio-button-wrapper-checked) {
  background-color: #ededed;
  z-index: 1;
  pointer-events: none;
}
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) span:not(.ant-radio-button):last-child {
  z-index: 1;
  position: relative;
  background-color: transparent;
}
.newpre_questionairepopup .ant-radio-group.radiogroupbtn label.ant-radio-button-wrapper:last-child .ant-radio-button {
  border-radius: 0 3px 3px 0;
}
.newpre_questionairepopup .ant-radio-group.radiogroupbtn label.ant-radio-button-wrapper:first-child .ant-radio-button {
  border-radius: 3px 0 0 3px;
}
.newpre_questionairepopup .ant-modal-body .ant-radio-group.radiogroupbtn label.ant-radio-button-wrapper:where(:first-child):last-child {
  border-radius: 4px !important;
  border-left: 0 !important;
}
.fc-col-header.fc-scrollgrid-sync-table {
    border-bottom: 1px solid #ddd !important;
}
