.custom-col-10px {
  padding-right: 10px;
  padding-left: 10px;
}
.ellipsis {
  position: relative;
}
.ellipsis .ellipsis-text {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 76%;
  left: 50px;
}
.ellipsis .ellipsis-text {
  padding: 6px 12px 6px 0;
  color: #757575;
  font-size: 12px;
  font-family: "Gotham Book";
  cursor: pointer;
}
.lb-p {
  display: inline-block;
  text-align: center;
  width: 30px;
}
.ellipsis .view_btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.view_popover {
  width: 246px;
}
.view_popover span {
  word-break: break-word;
}
.view_btn {
  line-height: 1 !important;
  height: auto;
}
.view_btn span {
  text-decoration: underline;
}
.card-head {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #e9e9e9;
  height: 45px;
}
.card-head .card-head-6 {
  width: 50%;
}
.payment_setting .card,
.payment_apointment .card {
  border-radius: 0;
  border: 1px solid #e9e9e9;
}
.payment_card-body {
  padding: 10px 15px 15px;
}
.payment_setting .ant-select,
.payment_apointment .ant-select {
  font-size: 12px;
  color: #404040;
  font-family: "Gotham Book";
}
.payment_setting .ant-select .ant-select-selector,
.payment_apointment .ant-select .ant-select-selector {
  height: 30px !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
}
.font-weight-500 span,
.weight-500 span {
  font-family: "Gotham Medium";
}
button.height-30,
.height-30 {
  height: 30px;
  line-height: 30px;
}
.payment_apointment .ant-select .ant-select-selector .ant-select-selection-item,
.payment_setting .ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
}
.payment_apointment .card-body {
  padding: 10px 10px 5px;
}
