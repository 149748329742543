:root {
  --primary: #1445b8;
}
.addpatientmodal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px !important;
  font-family: "Gotham Medium" !important;
}
.addpatientmodal .ant-modal-content .ant-modal-body {
  padding-top: 24px;
}
.full-radio .ant-radio-group {
  display: flex;
  justify-content: space-between;
}
.full-radio label.ant-radio-button-wrapper {
  width: 100%;
  padding: 8px !important;
  border-radius: 2px 0px 0px 2px !important;
}
.full-radio label .ant-radio-button-wrapper:first-child {
  border-radius: 2px 0px 0px 2px !important;
}
.addpatientmodal .ant-modal-content .ant-modal-body .form-control {
  padding: 8px 16px !important;
  height: 39px !important;
  line-height: 37px !important;
  font-size: 14px !important;
  border-color: #adadad !important;
  background: transparent !important;
  color: #474d50 !important;
}
.addpatientmodal .react-date-picker-wrapper fieldset {
  border-color: #adadad !important;
}
.push-b-16 {
  margin-bottom: 16px !important;
}
.addpatientmodal .react-date-picker-wrapper .MuiInputBase-input {
  min-height: 39px;
  padding: 0 16px;
}
.addpatientmodal .ant-modal-body label {
  font-size: 14px !important;
  color: #1c2745 !important;
  font-family: "Gotham Medium" !important;
  min-height: 19px;
}
.addpatientmodal .react-date-picker-wrapper .MuiFormControl-root {
  background: transparent !important;
}
.addpatientmodal .react-date-picker-wrapper input {
  font-size: 14px !important;
}

/* .addpatientmodal .react-date-picker-wrapper .MuiIconButton-root {
  background: url(../images/common/newcalendar_icon.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
} */
.addpatientmodal .react-date-picker-wrapper .MuiIconButton-root svg {
  display: none;
}
.push-r-6 {
  margin-right: 6px;
}
.title_icon h6 {
  font-size: 20px;
  font-family: "Gotham Medium";
  margin-bottom: 0px;
  color: #1445b8;
}
.push-b-16 {
  margin-bottom: 16px;
}
.addpatientmodal
  .ant-modal-body
  label.ant-checkbox-wrapper
  .ant-checkbox
  + span {
  font-size: 14px !important;
  font-family: "Gotham Medium";
  color: #727b7f;
}
.addpatientmodal span.ant-checkbox-inner {
  border-radius: 3px !important;
  border-width: 2px !important;
  width: 14px !important;
  height: 14px !important;
  border-color: #757575 !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #adb2b4 !important;
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #adb2b4 !important;
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #adb2b4 !important;
  font-size: 14px !important;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #adb2b4 !important;
  font-size: 14px !important;
}
.label_checkbox label {
  margin-bottom: 0px !important;
}
.label_checkbox {
  margin-bottom: 10px;
}
.addpatientmodal .ant-modal-content .ant-modal-body .form-group {
  margin-bottom: 16px;
}
.addpatientmodal .ant-divider-horizontal {
  border-color: #c6cbcd !important;
  margin: 20px 0 !important;
}
.addpatientmodal .ant-modal-content .ant-modal-body .ant-select.form-control {
  padding: 0px !important;
  /* border: none !important; */
}

.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder,
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 37px !important;
  font-size: 14px;
  color: #757575 !important;
}
.addpatientmodal .ant-modal-content .ant-modal-body .ant-btn.width-120 {
  min-width: 120px !important;
}
.addpatientmodal .backbtn {
  border-color: #1445b8 !important;
  color: #1445b8 !important;
  margin-right: 20px !important;
}
.secondrowdiv {
  position: relative;
  margin-bottom: 0 !important;
}
.secondrowdiv .dragbtn {
  border: none !important;
  border-top: 1px solid #c6cbcd !important;
  width: 100%;
  display: inline-block;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  top: 0 !important;
  border-radius: 0 !important;
}
.secondrowdiv .dragbtn a {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #e8ecf8;
  border-color: #e8ecf8;
  top: -11px;
  position: relative;
  color: #1445b8 !important;
  margin: 0 auto;
}
.secondrowdiv .dragbtn.textbtn a {
  min-width: 24px;
  width: auto;
  height: auto;
  min-height: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  top: -16px;
}
.addpatientmodal .form-row > .col,
.addpatientmodal .form-row > [class*="col-"] {
  padding-right: 12px;
  padding-left: 12px;
}
.addpatientmodal .form-row {
  margin-right: -12px;
  margin-left: -12px;
  justify-content: flex-start;
}
.push-t-19 {
  margin-top: 19px;
}
.push-t-11 {
  margin-top: 11px;
}
.addpatientmodal .validate-danger {
  font-size: 14px !important;
}
.addpatientmodal .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #1445b8 !important;
}
.addpatientmodal
  .ant-modal-body
  label.ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  + span {
  color: #1445b8 !important;
}
.addpatientmodal .ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 3px;
}
.addpatientmodal sup.text-danger.font-15.top-2 {
  font-size: 20px !important;
}
.addpatientmodal .ant-modal-content {
  padding: 9px 10px;
}
.addpatientmodal .ant-modal-content .ant-modal-close-x svg {
  display: none;
}
.addpatientmodal .ant-modal-body label.ant-radio-button-wrapper {
  font-family: "Gotham Book" !important;
}
.addpatientmodal
  .ant-modal-body
  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  font-family: "Gotham Medium" !important;
  color: #fff !important;
}
.addpatientmodal .ant-modal-content .ant-modal-close-x {
  position: relative !important;
  margin-right: 14px;
  margin-top: 8px;
}
.addpatientmodal .ant-modal-content button.ant-modal-close {
  position: relative;
  float: right;
  top: 0px;
}
.addpatientmodal
  .ant-modal-content
  .ant-modal-body
  .form-control.danger-border {
  border-color: rgb(169, 68, 66) !important;
  animation: pulse-animation 2s infinite;
}
.addpatientmodal .ant-modal-content .ant-modal-body .form-control:focus {
  border-color: #1445b7 !important;
}
.service_deliverybox {
  background: #e8ecf8;
  padding: 10px 15px;
}
.service_deliverybox ul {
  margin-top: 15px;
}
.service_deliverybox ul li {
  list-style: none;
  padding-left: 20px;
  position: relative;
  margin-bottom: 5px;
  line-height: 1.5;
  color: #000;
}
.consentradio {
  flex-direction: column;
  justify-content: flex-start;
}
.service_deliverybox ul li:before {
  content: "";
  background: #1445b7;
  width: 8px;
  height: 8px;
  left: 0;
  top: 4px;
  position: absolute;
  border-radius: 25px;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(169, 68, 66, 0.9);
  }
  50% {
    box-shadow: 0 0 0 3px rgba(169, 68, 66, 0);
  }
}
