.header_wrapper {
  background-color: #1445b7;
  /* height: 28px; 
    padding: 3px 15px 3px 30px; */
  /* height: 40px; */
  padding: 5px 15px 5px 15px;
  z-index: 4;
  background: #1445b7;
}
span.head_counter span {
  line-height: 20px;
}
span.head_counter span {
  height: 20px;
  vertical-align: middle;
  padding: 0 3px;
  min-width: 21px;
  /* -webkit-box-shadow: -1px 1px 4px 0px rgba(0,0,0,.7);
    box-shadow: -1px 1px 4px 0px rgba(0,0,0,.7); */
  background-color: #ff4a37;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  font-size: 9px;
  text-align: center;
  position: relative;
  color: #fff !important;
}
span.head_counter span::after {
  content: "";
  -webkit-box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 70%);
  box-shadow: -1px 1px 4px 0px rgb(0 0 0 / 70%);
  position: absolute;
  height: 20px;
  min-width: 21px;
  border-radius: 50%;
  display: inline-block;
  line-height: 20px;
  margin-left: 4px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/* .logo_w {
    position: absolute;
    width: 35px;
    left: 0;
} */
.userdropdown .anticon {
  margin-left: 5px;
  vertical-align: middle !important;
}
.userdropdown {
  padding-top: 2px;
  margin-right: 5px;
}
.navbar-fixed-top {
  position: fixed;
  width: 100%;
}
.bottom_bar {
  position: fixed;
  width: 100%;
  margin-top: 28px;
  height: 45px;
  background-color: #1445b7;
  padding: 0px 70px;
  display: flex;
  z-index: 1;
}
.bottom_bar + .ant-layout-sider,
.bottom_bar + .ant-layout-sider + .rightmainsection {
  top: 73px;
}
.ant-layout-sider + .rightmainsection,
.ant-layout-sider {
  /* top: 42px; */
  top: 56px;
}
/* .logo_w, .logo_w a {
    height: 40px;
    line-height: 1;
} */
.logo-menu-wrapper {
  display: flex;
  align-items: center;
}
.ant-layout-sider {
  position: fixed;
  /* top: 73px; */
  width: 200px;
  background-color: #f6f6f6 !important;
  left: 0px;
  height: 100%;
  /* padding-top: 40px; */
}
.ant-layout-sider + .rightmainsection {
  width: calc(100% - 221px);
  height: 100%;
  position: relative;
  right: 0;
  float: right;
  /* top: 73px; */
}
.ant-layout-sider-collapsed + .rightmainsection {
  /* width: calc(100% - 80px); */
  width: calc(100% - 50px);
}
.filter-actions {
  display: flex;
  align-items: center;
}
.filter-actions ul li {
  list-style: none;
  display: inline-block;
  margin-right: 26px;
  line-height: 1;
}
.filter-actions ul li button.ant-btn {
  border: 0;
  background-color: transparent;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  padding: 0;
}
.filter-actions ul li button.ant-btn span.anticon {
  vertical-align: middle;
  font-size: 20px;
}
.filter-actions ul {
  margin: 0;
  padding: 0;
}
.patient_form_wrapper,
.filterby_form_wrapper {
  min-width: 350px;
}
.patient_form_wrapper input.ant-input {
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  background-color: transparent;
}
.patient_form_wrapper .ant-input-prefix span {
  font-size: 16px;
}
.patient_form_wrapper span.ant-input-prefix {
  margin-right: 8px;
}
.patient_form_wrapper .ant-input-affix-wrapper,
.filterby_form_wrapper .ant-picker {
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7;
}
.filterby_form_wrapper .ant-picker {
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
}
.filterby_form_wrapper .ant-picker .ant-picker-suffix {
  font-size: 16px;
}
.filter-actions .ant-btn > .anticon + span {
  vertical-align: middle;
}
.bottom_bar + .ant-layout-sider {
  padding-top: 0;
}
.ant-layout-sider-collapsed + .rightmainsection .patient_detail_header {
  /* width: calc(100% - 100px); */
  width: calc(100% - 70px);
}
.header_wrapper .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent !important;
}
.logo_w img.AlldayDrlogo {
  /* height: 36px;
    position: relative;
    top: -4px; */
  height: 38px;
  top: -2px;
  position: relative;
}
.main_menu {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0 0 0px 10px;
  /* padding: 0 0 0px 40px; */
  font-family: "Gotham Book";
}
.main_menu li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 19px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main_menu li:last-child {
  margin-right: 0 !important;
}
@media screen and (max-width: 1150px) {
  .main_menu li {
    margin-right: 8px !important;
  }
  .main_menu li a {
    font-size: 8px !important
  }
}
.main_menu li:last-child {
  margin-right: 0;
}
.main_menu li a {
  font-size: 11.5px;
  color: #fff;
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
}
.main_menu li a::after {
  content: "";
  background-color: #ffffff;
  height: 16px;
  width: 1px;
  position: absolute;
  right: -10px;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main_menu li:last-child a::after {
  content: none;
}
.main_menu li a.active_menu::before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #1445b7;
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-top-color: #1445b7;
}
.logo_togglemenu .anticon svg {
  font-size: 20px;
  color: #ffffff;
}
.logo_togglemenu {
  margin-right: 10px;
}
.logo_togglemenu span.anticon {
  cursor: pointer;
}
.user_role {
  margin-right: 4px;
}
.ant-layout-sider .ant-menu-inline-collapsed .ant-menu-item {
  height: auto !important;
}
.ant-menu-inline-collapsed {
  width: auto !important;
}
.ant-layout-sider .ant-menu-item img {
  filter: grayscale(100%);
  transition: filter 0.6s ease-in-out;
  /* min-width: unset; */
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected img {
  filter: grayscale(0);
  transition: filter 0.6s ease-in-out;
}

/* notification dropdown starts*/
.notification {
  font-family: "Gotham Book";
}
/* .notification .infinite-scroll-component {
    overflow: hidden !important;
} */
.notification .dropdown-toggle img {
  height: 25px;
  margin: 0 0 0 5px;
}
.notification-badge:empty {
  min-width: 10px;
  height: 10px;
  right: 12px;
  top: 0;
}
.notification-badge {
  padding: 1px;
  right: 4px;
  width: auto;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  top: -4px;
  background-color: #e52c2c;
  line-height: 18px;
  text-align: center;
}
.notification .dropdown-menu {
  width: 410px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding: 0;
  border: 0;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  left: auto !important;
  right: 0 !important;
  -webkit-transform: translate(0px, 27px) !important;
  -ms-transform: translate(0px, 27px) !important;
  transform: translate(0px, 27px) !important;
}
.notification .dropdown-toggle {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: 0 !important;
}
.notification .dropdown-toggle::after {
  content: none;
}
.right-contact-detail .notification .dropdown-toggle img {
  margin-right: 0px;
  margin-left: 3px;
}
.notification .dropdown {
  min-width: 40px;
}
.notification .dropdown-toggle:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.notification-header {
  border-bottom: 1px solid #eceff1;
  padding: 12px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
}
.notification-footer {
  border-top: 1px solid #eceff1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 10px;
}
.notification-header a,
.notification-footer a {
  font-size: 13px;
  font-family: "Gotham Medium";
}
.notificationinnerlinks {
  max-height: 480px;
  /* overflow-y: auto; */
}
.notificationinnerlinks .notifications_text li {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: inherit;
  align-items: inherit;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 25px;
  cursor: pointer;
  padding-left: 0;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
}
.notificationinnerlinks .notifications_text li:last-child {
  border-bottom: 0;
}
.notificationinnerlinks .notifications_text {
  padding: 8px 0;
  margin: 0;
}
.notificationinnerlinks .notifications_text li a {
  display: inline-block;
  padding: 0;
  font-size: 13px;
  font-family: "Gotham Book";
  color: #1445b7;
  float: right;
}
.notificationinnerlinks .notifications_text li .notification_img {
  width: 40px;
}
.notification_img_sprite.schedule_consult {
  background-position: 0 -480px;
}
.notification_img_sprite.appointmnt_consult {
  background-position: 0 -150px;
}
.notificationinnerlinks .notifications_text li .notification_data {
  position: relative;
  width: 90%;
}
.notificationinnerlinks
  .notifications_text
  li
  .notification_data
  .notication-title {
  padding-right: 119px;
}
.notificationinnerlinks
  .notifications_text
  li
  .notification_data
  .notication-date-time {
  position: absolute;
  right: 0;
  top: 2px;
}
.notificationinnerlinks .notifications_text li .notification_data .dot_div {
  width: 4px;
  height: 4px;
  background-color: #1445b7;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  top: 5px;
}
.notificationinnerlinks {
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.notificationinnerlinks::-webkit-scrollbar {
  width: 4px;
}
.notificationinnerlinks::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.notificationinnerlinks::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.notificationinnerlinks .notifications_text li:hover {
  background-color: #ebf5fc !important;
}
.notification_loader_section {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
.notificationinnerlinks {
  height: auto !important;
}
.no-notification-sec {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.noti-loader-wrapper {
  height: 60px;
  width: 60px;
  z-index: 10;
  -webkit-transition: all 0.3s ease-in-out 0;
  -o-transition: all 0.3s ease-in-out 0;
  transition: all 0.3s ease-in-out 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1445b7;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
  z-index: 1;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.doctors-multi-dropdown span.anticon.anticon-close-circle {
  vertical-align: middle;
}
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* notification dropdown ends*/
/* notification dropdown starts*/
.notification {
  font-family: "Gotham Book";
}
/* .notification .infinite-scroll-component {
    overflow: hidden !important;
} */
.notification .dropdown-toggle img {
  height: 25px;
  margin: 0 0 0 5px;
}
.notification-badge:empty {
  min-width: 10px;
  height: 10px;
  right: 12px;
  top: 0;
}
.notification-badge {
  padding: 1px;
  right: 4px;
  width: auto;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  top: -4px;
  background-color: #e52c2c;
  line-height: 18px;
  text-align: center;
}
.notification .dropdown-menu {
  width: 410px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding: 0;
  border: 0;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  left: auto !important;
  right: 0 !important;
  -webkit-transform: translate(0px, 27px) !important;
  -ms-transform: translate(0px, 27px) !important;
  transform: translate(0px, 27px) !important;
}
.notification .dropdown-toggle {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: 0 !important;
}
.notification .dropdown-toggle::after {
  content: none;
}
.right-contact-detail .notification .dropdown-toggle img {
  margin-right: 0px;
  margin-left: 3px;
}
.notification .dropdown {
  min-width: 40px;
}
.notification .dropdown-toggle:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.notification-header {
  border-bottom: 1px solid #eceff1;
  padding: 12px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
}
.notification-footer {
  border-top: 1px solid #eceff1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 10px;
}
.notification-header a,
.notification-footer a {
  font-size: 13px;
  font-family: "Gotham Medium";
}
.notificationinnerlinks {
  max-height: 480px;
  /* overflow-y: auto; */
}
.notificationinnerlinks .notifications_text li {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: inherit;
  align-items: inherit;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 25px;
  cursor: pointer;
  padding-left: 0;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
}
.notificationinnerlinks .notifications_text li:last-child {
  border-bottom: 0;
}
.notificationinnerlinks .notifications_text {
  padding: 8px 0;
  margin: 0;
}
.notificationinnerlinks .notifications_text li a {
  display: inline-block;
  padding: 0;
  font-size: 13px;
  font-family: "Gotham Book";
  color: #1445b7;
  float: right;
}
.notificationinnerlinks .notifications_text li .notification_img {
  width: 40px;
}
.notification_img_sprite.schedule_consult {
  background-position: 0 -480px;
}
.notification_img_sprite.appointmnt_consult {
  background-position: 0 -150px;
}
.notificationinnerlinks .notifications_text li .notification_data {
  position: relative;
  width: 90%;
}
.notificationinnerlinks
  .notifications_text
  li
  .notification_data
  .notication-title {
  padding-right: 119px;
}
.notificationinnerlinks
  .notifications_text
  li
  .notification_data
  .notication-date-time {
  position: absolute;
  right: 0;
  top: 2px;
}
.notificationinnerlinks .notifications_text li .notification_data .dot_div {
  width: 4px;
  height: 4px;
  background-color: #1445b7;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  top: 5px;
}
.notificationinnerlinks {
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.notificationinnerlinks::-webkit-scrollbar {
  width: 4px;
}
.notificationinnerlinks::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.notificationinnerlinks::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.notificationinnerlinks .notifications_text li:hover {
  background-color: #ebf5fc !important;
}
.notification_loader_section {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
.notificationinnerlinks {
  height: auto !important;
}
.no-notification-sec {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.noti-loader-wrapper {
  height: 60px;
  width: 60px;
  z-index: 10;
  -webkit-transition: all 0.3s ease-in-out 0;
  -o-transition: all 0.3s ease-in-out 0;
  transition: all 0.3s ease-in-out 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1445b7;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
  z-index: 1;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.doctors-multi-dropdown span.anticon.anticon-close-circle {
  vertical-align: middle;
}
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* notification dropdown ends*/

/* 5-1-2023 css start */
.ant-layout-sider-collapsed .calender_box {
  display: none;
}
/* 5-1-2023 css end */

/* patient detail menu css start */

._renderQRItems_Wrapper {
  width: 350px;
  max-height: 70vh;
  overflow-y: auto;
}