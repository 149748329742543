@media only screen and (min-width: 1367px) {
  .lg-td-2 {
    width: 2%;
  }
  .lg-td-4 {
    width: 4%;
  }
  .lg-td-6 {
    width: 6%;
  }
  .lg-td-7 {
    width: 7%;
  }
  .lg-td-9 {
    width: 9%;
  }
  .lg-td-10 {
    width: 10%;
  }
  .lg-td-11 {
    width: 11%;
  }
  .lg-td-12 {
    width: 12%;
  }
  .lg-td-13 {
    width: 13%;
  }
  .lg-td-14 {
    width: 14%;
  }
  .lg-td-15 {
    width: 15%;
  }
  .lg-td-16 {
    width: 16%;
  }
  .lg-td-18 {
    width: 18%;
  }
  .lg-td-17 {
    width: 17%;
  }
  .lg-td-20 {
    width: 20%;
  }
  .lg-td-22 {
    width: 22%;
  }
  .lg-td-23 {
    width: 23%;
  }
  .lg-td-25 {
    width: 25%;
  }
  .lg-td-28 {
    width: 28%;
  }
  .lg-td-29 {
    width: 29%;
  }
  .lg-td-21 {
    width: 21%;
  }
  .lg-td-31 {
    width: 31%;
  }
  .lg-td-37 {
    width: 37%;
  }
  .lg-td-36 {
    width: 36%;
  }
  .lg-td-30 {
    width: 30%;
  }
  .lg-td-34 {
    width: 34%;
  }
  .lg-td-36 {
    width: 36%;
  }
  .lg-td-8 {
    width: 8%;
  }
  .lg-td-9 {
    width: 9%;
  }
  .lg-td-10 {
    width: 10%;
  }
  .lg-td-7 {
    width: 7%;
  }
  .lg-td-5 {
    width: 5%;
  }
  .lg-td-33 {
    width: 33%;
  }
  .lg-td-38 {
    width: 38%;
  }
}
@media only screen and (max-width: 1440px) {
  .patient_dropdown_data .patient_dropdown_inner {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 5px;
  }
  .main_menu li a {
    font-size: 9px !important;
  }
  .main_menu li {
    margin-right: 15px !important;
  }
  .main_menu li a::after {
    right: -8px !important;
  }
  .header_wrapper_right {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .md-td-2 {
    width: 2%;
  }
  .md-td-4 {
    width: 4%;
  }
  .md-td-6 {
    width: 6%;
  }
  .md-td-7 {
    width: 7%;
  }
  .md-td-8 {
    width: 8%;
  }
  .md-td-9 {
    width: 9%;
  }
  .md-td-11 {
    width: 11%;
  }
  .md-td-10 {
    width: 10%;
  }
  .md-td-13 {
    width: 13%;
  }
  .md-td-15 {
    width: 15%;
  }
  .md-td-16 {
    width: 16%;
  }
  .md-td-17 {
    width: 17%;
  }
  .md-td-18 {
    width: 18%;
  }
  .md-td-19 {
    width: 19%;
  }
  .md-td-21 {
    width: 21%;
  }
  .md-td-23 {
    width: 23%;
  }
  .md-td-27 {
    width: 27%;
  }
  .md-td-28 {
    width: 28%;
  }
  .md-td-20 {
    width: 20%;
  }
  .md-td-22 {
    width: 22%;
  }
  .md-td-14 {
    width: 14%;
  }
  .md-td-16 {
    width: 16%;
  }
  .md-td-10 {
    width: 10%;
  }
  .md-td-12 {
    width: 12%;
  }
  .md-td-33 {
    width: 33%;
  }
  .md-td-13 {
    width: 13%;
  }
  .md-td-24 {
    width: 24%;
  }
  .header_wrapper_left {
    /* flex: 0 0 29.333333%;
		max-width: 29.333333%; */
    flex: 0 0 46.333333%;
    max-width: 46.333333%;
  }
  .header_wrapper_right {
    /* flex: 0 0 70.666667%;
		max-width: 70.666667%; */
    flex: 0 0 54.666667%;
    max-width: 54.666667%;
  }
  .main_menu li {
    margin-right: 14px !important;
  }
  span.head_counter span {
    height: 18px !important;
    min-width: 18px !important;
    font-size: 10px !important;
  }
  span.head_counter span {
    line-height: 18px !important;
  }
  span.head_counter span::after {
    height: 18px !important;
    min-width: 19px !important;
  }

  .text-ellipsis-46 {
    max-width: 46%;
  }
  .logo_togglemenu {
    margin-right: 10px;
  }
  .main_menu li {
    margin-right: 23px;
  }
  .main_menu {
    padding: 0 0 0px 10px;
  }
}
@media (max-width: 1299px) {
}
@media only screen and (max-width: 1280px) {
  span.head_counter span {
    height: 17px !important;
    min-width: 17px !important;
    font-size: 9px !important;
  }
  span.head_counter span::after {
    height: 17px !important;
    min-width: 18px !important;
  }
  .main_menu li a {
    font-size: 10px !important;
  }
  .main_menu li a::after {
    right: -6px !important;
  }
  .main_menu li {
    margin-right: 12px !important;
  }
  .logo_w img.AlldayDrlogo {
    height: 32px !important;
  }
  .logo_togglemenu .anticon svg {
    font-size: 16px !important;
  }
  .logo_togglemenu {
    margin-right: 8px;
  }
  .header_wrapper_left * {
    font-size: 10px !important;
  }
  .header_wrapper_left {
    padding-left: 5px;
    /* flex: 0 0 28.333333%;
    max-width: 28.333333%; */
  }
  .header_wrapper_right {
    /* flex: 0 0 71.666667%;
    max-width: 71.666667%; */
    padding-right: 5px !important;
  }
  .header_wrapper_left {
    /* flex: 0 0 43.333333%;
    max-width: 43.333333%; */
  }
  .header_wrapper_right {
    /* flex: 0 0 56.666667%;
    max-width: 56.666667%; */
  }
  .main_menu {
    padding-left: 7px;
  }
  .user_role .ant-tag {
    margin: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .tablet-font-9 {
    font-size: 9px !important;
  }
  .tablet-font-10 {
    font-size: 10px;
  }
}

@media handheld, only screen and (min-width: 1600px) and (max-width: 1920px) {
  .messages {
    height: 607px;
  }
  .chat_window {
    height: 88%;
    top: 56%;
  }
  .header-height .bg-header-sky {
    padding-top: 25px;
  }
  .chat-screen-patient .messages {
    height: 215px;
  }
  .form-group.custom-margin {
    margin-bottom: 20px;
  }
}
@media handheld, only screen and (max-width: 1315px) {
}
@media handheld, only screen and (max-width: 1300px) {
  .banner-text h1 {
    font-size: 60px;
  }
  .section3-bg .push-custom {
    padding-right: 5%;
    padding-left: 8%;
  }
  footer .validation-icon {
    padding: 3px;
  }
}
@media handheld, only screen and (max-width: 1199px) {
  .search-section input.form-control {
    width: 77%;
    padding-right: 9%;
  }
  .search-section i.fa-close {
    right: 25%;
  }
  .banner-text {
    top: 170px;
  }
  .section3-bg .push-custom {
    padding-right: 5%;
    padding-left: 4%;
    margin-top: 8%;
  }
  .max-width {
    max-width: 250px;
  }
  .formradio label {
    padding: 10px 10px 4px;
  }
  .header.inner-header1 .navbar-header {
    width: auto;
  }
  .header.inner-header1 .search-input {
    margin-top: 3%;
    padding-left: 8%;
  }
  .header.inner-header1 .search-section-div i {
    top: 52%;
  }
  .header.inner-header1 .navbar #navbar:after {
    display: none;
  }
  .custom-header-margin {
    margin: 120px 0 0 0;
  }
  .custom-tabfullwidth .md-tab {
    padding: 23px 15px !important;
    min-width: 0;
  }
}
@media handheld, only screen and (max-width: 1024px) {
  .messages {
    height: 457px;
  }
  .header-height .bg-header-sky {
    padding-top: 16px;
  }
  .chat-screen-patient .messages {
    height: 265px;
  }
  .header.inner-header1 .navbar-header {
    width: 20%;
  }
  .header.inner-header1 .search-section-div {
    width: 30%;
  }
  .header.inner-header1 .navbar #navbar p.font-16 {
    font-size: 14px;
  }
  .max-width {
    max-width: 170px;
    min-width: 170px;
  }
  .radio-section .btn-group .btn {
    padding: 9px 7px 8px;
  }
}
@media handheld, only screen and (max-width: 991px) {
  .overright-row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .header.inner-header1 .navbar-header {
    width: 23%;
    margin-left: -2%;
  }
  .header.inner-header1 .search-section-div {
    width: 33%;
    margin-left: -2%;
  }
  .header.inner-header1 .navbar #navbar {
    position: relative;
    margin-right: -4%;
  }
  .header.inner-header1 .navbar #navbar p.font-16 {
    font-size: 12px;
  }
  .max-width {
    max-width: 140px;
    min-width: 140px;
  }

  .td-width-170 {
    max-width: 140px;
    min-width: 140px;
  }
  .td-width-270 {
    max-width: 200px;
    min-width: 200px;
  }
  .td-width-130 {
    max-width: 50px;
    min-width: 50px;
  }
  .btn-common {
    min-width: 180px;
  }
  .btn-edit.min-width {
    min-width: 180px;
  }

  .navbar > .container-fluid .navbar-brand {
    margin-left: 10px;
  }
  .banner-text {
    top: 140px;
  }
  .bg-light-green .col-md-10.in-block {
    padding-top: 10px;
  }
  /*.section3-bg .push-custom{background: rgba(255,255,255,0.8); padding-top: 5%;}*/
  img.section-icon {
    margin-top: 2%;
  }
  footer a.push-20-r {
    margin-right: 10px;
  }
  .section-2 .push-custom h4.font-36 br {
    display: none;
  }
  img.section-icon {
    display: block;
    float: none;
  }
  .banner.home {
    margin: 0px 0 0 0;
  }
  .bg-light-green p.font-18 {
    font-size: 16px;
  }
  #JsResendMobile .common-input {
    width: 80%;
  }

  .table.custom-table .btn-common {
    padding: 0px 10px;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 10px;
    vertical-align: middle;
  }
  .section-height .height-100 .height-100-mobile {
    height: 100%;
  }

  .messages {
    height: 627px;
  }
  .chat-screen-patient .messages {
    height: 245px;
  }
  .header-section-tab {
    height: 113px;
  }
  .custom-tabs .slimScrollDiv {
    height: 100% !important;
  }
  .header-section-tab.custom-tabfullwidth {
    height: 70px;
  }
  .custom-tabs .slimScrollDiv div {
    height: 100% !important;
  }
  .header.inner-header1 .search-section-div i {
    top: 48%;
    left: 4%;
  }
  .header.inner-header1 .search-input {
    margin-top: 3%;
    padding-left: 5%;
    font-size: 14px;
  }
  .dis-center-mobile {
    display: inline-block;
    float: none;
  }
  .search-result-parent {
    box-shadow: inset -5px -14px 18px 2px #f5f7f9 !important;
    background: #fff;
  }
  .NHS-mobile-img {
    display: block;
  }
}
@media handheld, only screen and (max-width: 767px) {
  .column-count {
    column-count: 1;
  }
  .header.inner-header .navbar > .container-fluid .navbar-brand {
    background-size: 130px auto !important;
    margin: 10px 7px 0 15% !important;
  }
  .table-responsive {
    border: none;
  }
  .custom-header-margin {
    margin: 113px 0 0 0 !important;
  }
  .overright-add-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
  .height-100 {
    height: auto !important;
  }
  #doctor-div,
  .mobile-height-420 {
    height: 430px !important;
  }
  .messages.mobile-scroll-height {
    height: 270px !important;
  }
  #stickyheader .bg-light-green .after-line p.font-16 {
    font-size: 17px;
  }
  .header.inner-header1 .navbar-header {
    width: 100%;
  }
  .header.inner-header {
    height: 118px;
  }
  .custom-inner-loader {
    left: 30%;
  }
  .width-50 {
    width: 50%;
  }
  .width-100 {
    width: 100%;
  }
  .mobile-clear-both {
    clear: both;
  }
  .header.inner-header1 .search-input {
    margin-top: 2% !important;
  }
  /*.delete-section{float: right; right: 10px; bottom: 10px;}*/
  .banner-heading {
    margin: 115px 0 0 0;
  }
  /*.table-responsive.custom>.table>tbody>tr>td, .table-responsive.custom>.table>tbody>tr>th, .table-responsive.custom>.table>tfoot>tr>td, .table-responsive.custom>.table>tfoot>tr>th, .table-responsive.custom>.table>thead>tr>td, .table-responsive.custom>.table>thead>tr>th{display: block; width: auto; min-width: 0; border: none;}*/
  /*.table-responsive.custom{border: none; overflow: hidden;}*/
  .table.custom-table .btn-common {
    width: 80%;
    height: 40px;
  }
  .float-right-button-mobile .btn-edit.min-width {
    width: 80%;
    float: left;
    margin-bottom: 10px;
  }
  .remove-padding {
    padding-right: 0;
    padding-left: 0;
  }
  .remove-margin {
    margin-right: 0;
    margin-left: 0;
  }
  /*.delete-section{bottom: 0;}*/

  .mobile-float-left {
    position: absolute;
    right: 6%;
  }
  .mobile-pull-float-left {
    float: left;
  }
  .mobile-pull-float-right {
    float: right;
  }
  .overright-center {
    text-align: center;
  }
  .banner.home {
    margin: 65px 0 0 0;
  }
  .banner.home .banner-padding {
    padding-bottom: 124%;
  }
  .banner.home .placeholder img.loaded {
    margin: 0 0 0 -40%;
    width: 250%;
  }
  .banner.home .btn-common {
    float: right;
    margin-right: 1%;
    height: 40px;
  }
  .search-section i.fa-close {
    right: 20%;
  }

  .navbar > .container-fluid .navbar-brand {
    background-size: 130px auto;
    margin: 10px 7px 0 15%;
  }
  .navbar-default .navbar-toggle {
    float: left;
    margin-top: 2%;
  }
  .navbar-default {
    background: #fff; /*height: 120px;*/
    padding-bottom: 5px;
    padding-right: 0;
    padding-left: 0;
  }
  .header .navbar #navbar {
    margin: 15px 0 10px 0; /*padding-top: 10px;*/ /* border-bottom: 3px solid #ddd;*/
  }
  .banner-text h1 {
    font-size: 47px;
    line-height: 1;
  }
  .search-hide {
    display: none;
  }
  .search-section i.fa-close {
    right: 20%;
  }
  .search-section input.form-control {
    width: 82%;
    padding-right: 9%;
  }
  .banner-text {
    top: 80px;
  }
  .bg-light-green,
  .bg-light-green .col-md-10.in-block.no-float {
    padding: 0;
    line-height: 1;
  }
  .bg-light-green .col-md-10.in-block.no-float .col-sm-3.after-line i {
    display: block;
    font-size: 36px;
    margin-bottom: 5%;
  }
  .after-line:nth-child(1),
  .after-line:nth-child(2) {
    border-bottom: 1px solid #8ee1b8;
  }
  .after-line {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .push-custom {
    margin: 0% 0 0 0;
  }
  .push-custom h4.font-36 {
    text-align: center;
    margin-top: 10px;
  }
  .push-custom p.font-18 {
    text-align: center;
  }
  .btn-common {
    width: 100%;
    height: 50px;
    font-size: 18px;
  }
  .section-2 .btn-common {
    margin-bottom: 40px;
  }
  .section3-bg,
  .section3-bg .push-custom {
    background: #f9f8fd;
  }
  .search-section .btn-common {
    width: initial;
    height: 36px;
    font-size: 16px;
  }
  img.section-icon {
    float: none;
    display: block;
    margin: 0 auto;
  }
  .NHS-mobile-img {
    display: block;
  }
  .section4-bg {
    padding-top: 30%;
    padding-bottom: 10%;
  }
  .section5 img.left-arrow,
  .section5 img.right-arrow {
    display: none;
  }
  .section5 #quote-carousel,
  .section5 #quote-carousel1 {
    padding: 0 0px 30px 0px;
  }
  .section3-bg .NHS-mobile-img img.img-responsive {
    width: 100%;
  }
  .section-2 img.img-responsive {
    float: none;
    display: inline;
  }
  .navbar-default .navbar-nav > li.bg-sky > a,
  #navbar > ul > li.bg-sky > a:hover {
    color: #fff;
    background-color: #29abe1;
  }
  .section5 .carousel-indicators {
    right: 0%;
    top: auto;
    bottom: 0px;
    margin-right: 0px;
    position: relative;
    width: 100%;
    left: 0;
    margin: 0;
  }
  .overright-auto {
    margin: 0 auto;
  }
  .medical-logo img {
    width: 30%;
    display: inline-block;
    float: none;
  }
  .medical-logo {
    margin: 30px auto;
  }
  footer .panel-links-mobile {
    display: block;
    margin-right: -30px;
    margin-left: -30px;
  }
  footer .panel-links-web {
    display: none;
  }
  footer .panel-default {
    background: transparent;
    border: none;
  }
  footer .panel-default > .panel-heading {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
  }
  footer .panel-default:nth-child(1) > .panel-heading {
    border-top: 1px solid #ddd;
  }
  footer .panel-group .panel + .panel {
    margin-top: 0px;
  }
  footer .panel-title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    color: #4d5257;
  }
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: none;
    border-bottom: 1px solid #ddd;
  }
  footer .border-top {
    border-top: none;
    margin-top: 0;
    padding-top: 20px;
  }
  footer .card-section.margin-auto {
    margin: 0 auto;
  }
  footer .col-sm-2.col-xs-12.text-center {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bg-light-green p.font-18 {
    font-size: 18px;
  }
  td.max-width p.font-14 {
    width: auto;
    display: table;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  /*td.radio-section{max-width: 270px; min-width: 270px;}*/
  footer .overright-auto img {
    display: inline-block;
    float: none;
    width: 47%;
  }
  footer.ftr .payment-bg {
    display: inline-block;
    float: none;
  }
  .overright-center {
    text-align: center;
  }
  .add-date-btn {
    width: 100%;
  }
  .header.inner-header1 .search-section-div {
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 0px !important;
    margin-bottom: 0;
  }
  .padding-0-mobile {
    padding: 0;
  }
  .header.inner-header1.nav-up .search-section-div {
    margin-top: 15px;
  }
  .header.inner-header1 .search-section-div i {
    top: 42%;
  }
  .overright-float {
    display: inline-block;
    float: none;
  }
  .section-border-right {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .header-height .bg-header-sky {
    padding-top: 10px;
  }
  .bg-header-sky .float-left .float-left.push-20-l {
    margin-left: 10px;
  }
  .bg-header-sky .float-right .push-20-r,
  .bg-header-sky .float-right.push-20-r {
    margin-right: 10px;
    font-size: 24px;
    padding-top: 2px;
  }
  .height-100 {
    height: auto;
  }
  .height-100 img.height-100-mobile {
    height: 400px;
    width: 100%;
  }
  .chat_window {
    width: 100%;
    left: 0;
    top: 0;
    transform: inherit;
    background-color: #fff;
    bottom: 0;
    overflow: scroll;
    position: inherit;
  }
  .messages {
    height: 460px;
    padding: 20px 0px 0 0px;
  }
  .bottom_wrapper {
    position: relative;
    padding: 20px 0;
  }
  .message_input_wrapper.chat-footer {
    padding-right: 0;
    padding-left: 0;
  }
  .bottom_wrapper .message_input_wrapper .message_input {
    padding-left: 19%;
  }
  .chat-footer i {
    left: 5%;
  }

  .custom-tabs .tab {
    width: 100%;
  }
  .custom-tabs .tabcontent {
    width: 100%;
  }
  .tabcontent.video-screen {
    width: 100%;
  }
  .chat-screen-patient .chat_window {
    width: 90%;
    left: 5%;
    right: 0;
    position: relative;
  }
  .addtocalendar {
    width: 100%;
  }
  .atc-style-blue .atcb-link {
    margin-right: 0;
  }
  .custom-checkbox label.cell {
    float: left;
  }
  .custom-checkbox span.cell {
    display: inline;
  }
  .mobile-button-right {
    float: right;
    display: inline-block;
    margin-top: 4%;
  }
  .mobile-view-h4 {
    float: left;
    display: inline-block;
    width: 50%;
  }
  img.loadingimg {
    width: 13%;
  }
  .overright-float-right {
    float: right;
  }
  .custom .overright-float {
    float: left;
    clear: both;
    margin-top: 2%;
    font-size: 18px;
  }
  .margin-top-custom {
    margin-top: 22%;
  }
  p.font-16.margin-top-custom {
    margin-top: 0;
  }
  .height-150 {
    height: auto;
  }
  .table.custom-table.Upcoming_app_dr .btn-common {
    float: left;
    margin-bottom: 10px;
  }
  .border-right {
    border: none;
  }
  #stickyheader {
    position: static !important;
  }
  .radio-section i.fa-question-circle {
    position: absolute;
    top: 22%;
  }
  .radio-section {
    position: relative;
  }
  .custom-checkbox {
    min-width: 100%;
    max-width: 100%;
  }
  /*.checkbox-section-row .checkbox-section-data{width: 100%;}*/
  /*.checkbox-section-row .checkbox-section-data .width{min-width: auto; max-width: 100%; display: block; margin-bottom: 7px;}*/
  .overright-padding-mobile-0 {
    padding-top: 0;
  }
  .radio-section .btn-group .btn {
    padding: 12px 20px 7px !important;
  }
  .overright-margin-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-xs-10.overright-padding-mobile-0 {
    float: none;
    display: inline-block;
  }
  .scroll-height {
    height: 100px !important;
  }
  .navbar-default .navbar-nav > li > a {
    color: #454545 !important;
  }
  .navbar-default .navbar-nav > li.bg-sky > a {
    color: #fff !important;
    margin-right: 15px;
  }
  ul.custom-ul li.checked-li .remove-btn {
    width: auto;
  }
  .header.header-home {
    padding-bottom: 0px !important;
  }
  .loader-mobile {
    left: 30% !important;
  }
  .section-height.OT_widget-container {
    height: 80% !important;
  }
  .patient-div {
    bottom: 3%;
    height: 125px;
    width: 200px;
  }
  .search-result-parent .search-footer .btn-common {
    min-width: 0;
    padding: 7px 4px;
    height: 35px;
    font-size: 14px;
  }
  .search-result-parent .search-footer {
    padding-right: 15px !important;
  }

  /* Responsive table*/
  .jim-table-responsive table thead {
    display: none;
  }

  /* treat rows like divs */
  .jim-table-responsive table tr {
    display: block;
    border-top: 2px solid lightgray; /* separate row data with thicker line */
    margin-top: 5px;
  }

  /* treat columns like divs */
  .jim-table-responsive table td {
    display: block;
    text-align: right; /* text to right */
  }

  /* this part is ugly, but necessary to show label on left */
  .jim-table-responsive table td:before {
    content: attr(data-label);
    float: left; /* label to left */
    font-weight: 700;
  }
}
@media handheld, only screen and (max-width: 430px) {
}
@media handheld, only screen and (max-width: 335px) {
  .call-end-div {
    margin-right: 7px;
  }
  .bg-light-green p.font-18 {
    font-size: 16px;
  }
  .radio-section .btn-group .btn {
    padding: 7px 17px 7px !important;
  }
  .radio-section i.fa-question-circle {
    right: 0px;
  }
  #stickyheader .bg-light-green .after-line p.font-16 {
    font-size: 16px;
  }
  .banner-text {
    top: 50px;
  }
}
