.ant-menu-dark.ant-menu-horizontal {
  background: transparent !important;
  line-height: 1 !important;
}
.ant-menu.ant-menu-dark {
  background: transparent;
  padding-left: 40px;
  width: 100%;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu.ant-menu-dark .ant-menu-item {
  position: relative;
  color: #ffffff;
  padding: 0 4px 0 2px !important;
  font-size: 11.5px;
  margin-right: 16px;
  vertical-align: middle;
  line-height: 22px;
  font-family: "Gotham Book";
}
.ant-menu.ant-menu-dark .ant-menu-item::after {
  content: "";
  background-color: #ffffff;
  height: 16px;
  width: 1px;
  position: absolute;
  right: -8px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected::before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #1445b7;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.ant-menu.ant-menu-dark .ant-menu-item:last-child::after {
  content: none;
}
.ant-layout-sider {
  /* padding-bottom: 76px !important;
  height: calc(100vh - 42px) !important; */
  height: calc(100vh - 56px) !important;
}
.ant-layout-sider .ant-menu-item {
  font-size: 12px;
  font-weight: normal;
  line-height: 2.49 !important;
  height: 34px !important;
  padding: 2px 13px !important;
  margin: 0 !important;
  font-family: "Gotham Book";
  color: #757575;
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  background-color: #ffffff !important;
  font-family: "Gotham Medium";
  color: #1445b7;
}
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
  padding-left: 30px !important;
  font-size: 12px;
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected::after {
  left: 0;
  right: auto;
  border-width: 4px;
  border-color: #1445b7;
}
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
  padding-left: 30px !important;
  font-size: 11px;
}
.ant-menu-submenu-inline .ant-menu-submenu-title img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title img,
.ant-menu-submenu-inline .ant-menu-submenu-title img {
  -webkit-transition: -webkit-filter 0.6s ease-in-out;
  transition: -webkit-filter 0.6s ease-in-out;
  -o-transition: filter 0.6s ease-in-out;
  transition: filter 0.6s ease-in-out;
  transition: filter 0.6s ease-in-out, -webkit-filter 0.6s ease-in-out;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-sub {
  border-top: 1px solid #e9e9e9 !important;
}
.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title {
  color: #1445b7;
  font-family: "Gotham Medium";
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}
.ant-layout-sider .ant-menu-item .ant-menu-inline-collapsed-noicon {
  font-size: 12px !important;
}
.ant-layout-sider .ant-menu {
  background: transparent;
}
.ant-form-item:last-child {
  margin-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:nth-last-child(2) {
  margin: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:nth-last-child(2)::after {
  content: none;
}
.ant-layout-sider-children ul.ant-menu:not(:first-child) {
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
}
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  img
  + span,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  svg
  + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-tooltip-inner .sidebar-notification-badge {
  position: relative;
  display: inline-block;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 10px;
  right: 0;
}

/* dropdown css */
.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 6px 15px;
  font-size: 12px;
  color: #404040;
  font-family: "Gotham Book";
  line-height: 1.42857143;
}
.ant-dropdown-menu .ant-dropdown-menu-item span {
  font-size: 12px;
}
.ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu .ant-dropdown-menu-item a:hover {
  background-color: #e8efff;
  color: #1445b7;
}
.ant-dropdown-menu {
  padding: 0 !important;
}
.ant-dropdown-menu.small_dropdown .ant-dropdown-menu-item {
  padding: 4px 10px;
}
.ant-dropdown-menu {
  box-shadow: none !important;
  border: solid 1px #e9e9e9;
}
.ant-dropdown-menu .ant-dropdown-menu-item span .ant-upload {
  line-height: 1;
}

/* table css override */
.ant-table .ant-table-content .ant-table-thead > tr > th {
  font-size: 12px;
  text-transform: capitalize;
  font-family: "Gotham Medium";
  background: #f6f6f6;
  padding: 8px 12px;
  color: #404040;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td,
.ant-table .ant-table-content .ant-table-thead > tr > th {
  line-height: 1.42857143;
  color: #5d5d5d;
}
.ant-table table {
  border: 1px solid #eeeeee;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 12px;
  font-family: "Gotham Book";
}
.ant-table .ant-table-content .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td .anticon {
  color: #9e9e9e;
}
.ant-table .ant-table-content .ant-table-thead > tr > th {
  border-bottom: 1px solid #eeeeee;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td p {
  margin: 0;
}
.font-12 .ant-table .ant-table-content .ant-table-tbody > tr > td,
.font-12 .ant-table .ant-table-content .ant-table-thead > tr > th {
  color: #757575;
  font-weight: 400;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 22px;
}
.no_table_header thead.ant-table-thead {
  display: none;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-20,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-20 {
  padding-left: 20px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-10,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-10 {
  padding-left: 10px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-bottom-4,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-bottom-4 {
  padding-bottom: 4px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-top-4,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-top-4 {
  padding-top: 4px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-0,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-0 {
  padding-right: 0;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-20,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-20 {
  padding-right: 20px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-10,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-10 {
  padding-right: 10px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-0,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-0 {
  padding-left: 0;
}
.ant-table .ant-table-content .ant-table-thead > tr > th.min-height-30 {
  height: 30px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.text-light-black {
  color: #404040;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.text-dark-gray {
  color: #757575;
}
.sort-pad-0 .ant-table-column-sorters {
  padding: 0;
}
.doctor_profile_text {
  width: calc(100% - 34px);
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.doctor_profile_text span.ant-tag-has-color {
  background: #1445b7 !important;
}
.border-spacing-5 table {
  border-spacing: 0 5px !important;
  border: 0 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell {
  border: 1px solid #e9e9e9 !important;
  background-color: #f9f9f9 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell:first-child,
.border-spacing-5
  table
  tr.ant-table-row
  td.ant-table-cell:not(:first-child):not(:last-child) {
  border-right: 0 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell:last-child,
.border-spacing-5
  table
  tr.ant-table-row
  td.ant-table-cell:not(:first-child):not(:last-child) {
  border-left: 0 !important;
}

/* button css */
.ant-btn-primary {
  background: #1445b7 !important;
  border: solid 1px #1445b7 !important;
}
.ant-btn-primary:hover {
  background-color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.ant-btn-dangerous,
.ant-btn-primary {
  font-size: 14px !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 2px !important;
}
.ant-btn-dangerous {
  background: #ff5964 !important;
  border: solid 1px #ff5964 !important;
}
.ant-btn-dangerous:hover {
  background: #ed2b2d !important;
  border: solid 1px #ed2b2d !important;
}

/* ant modal css */
.ellipsis .lb-container .lb-canvas {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ellipsis .lb-container .lb-canvas .lb-img {
  height: 100%;
  width: auto;
}
.ant-modal-content .ant-modal-title {
  font-size: 11px;
  color: #1445b7;
  font-family: "Gotham Book";
  font-weight: 400;
}
.ant-modal-content .ant-modal-header {
  padding: 15px 6px 6px 15px;
  border: 0;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.ant-modal-content {
  border-radius: 4px !important;
}
.ant-modal-content button.ant-modal-close {
  top: 6px;
}
.ant-modal-content .ant-modal-body {
  padding: 5px 15px 15px 15px;
  line-height: 1;
}
.ant-modal-content .ant-modal-close-x {
  width: 42px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
}
.anticon {
  vertical-align: middle !important;
}
.btn-item-wrapper button {
  margin-right: 10px;
}
.ant-modal-body label {
  font-size: 10px;
  font-weight: normal;
  color: #404040;
  margin-bottom: 6px;
  font-family: "Gotham Book";
  display: block;
  /* color: #757575;
  padding: 0px 8px;
  text-align: center;
  background-color: #e9e9e9;
  height: 18px;
  margin-bottom: 0px;
  line-height: 19px; */
}
.ant-modal-content .ant-modal-body .form-group {
  margin-bottom: 14px;
}
.ant-modal-content .ant-modal-body .form-control {
  height: 28px;
  line-height: 27px !important;
  padding: 5px 11px;
  font-size: 12px;
  border-radius: 2px;
  /* padding-left: 6px; */
  width: 100%;
  border: solid 1px #e9e9e9;
  background-color: #f9f9f9;
}
.searchsympotmspopup .ant-modal-content .ant-modal-body .ant-select {
  border: solid 1px #e9e9e9;
  background-color: #f9f9f9;
  font-size: 12px;
  border-radius: 2px;
  width: 100%;
}
.searchsympotmspopup
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f9f9f9;
}
.ant-modal-content .ant-modal-body .ant-btn {
  min-width: 100px;
}
.ant-radio-group label.ant-radio-wrapper {
  background-color: transparent;
  margin-top: 8px;
}
.ant-radio-group label.ant-radio-wrappe .ant-radio-inner {
  width: 18px;
  height: 18px;
}
.ant-radio-group label.ant-radio-wrappe .ant-radio-inner::after {
  width: 10px;
  height: 10px;
}
.ant-radio-group label.ant-radio-wrapper {
  background-color: transparent;
  margin-top: 8px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 28px;
  font-size: 11px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control input {
  font-size: 12px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control .ant-picker-suffix {
  font-size: 14px;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select-show-search
  .ant-select-selector {
  height: 0;
  min-height: 28px;
  border: solid 1px #e9e9e9;
}
.ant-modal-body
  .ant-radio-button-wrapper
  > .ant-radio-button.ant-radio-button-checked {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ant-modal-body .ant-radio-group .ant-radio-button-wrapper {
  padding: 6px 12px;
  height: auto;
  line-height: 1.42857143;
  background-color: #fff;
  border-color: #1445b7;
  border-radius: 4px;
}
.ant-modal-content .ant-modal-body .weight_wrapper select.form-control,
.ant-modal-content .ant-modal-body .height_wrapper select.form-control {
  width: calc(50% - 14px);
  float: left;
  margin: 0 5px;
}
.ant-modal-content .ant-modal-body .weight_wrapper div,
.ant-modal-content .ant-modal-body .height_wrapper div {
  margin: 15px -7px 0;
}
.weight_wrapper::after,
.weight_wrapper::before,
.weight_wrapper div::after,
.weight_wrapper div::before,
.height_wrapper::after,
.height_wrapper::before,
.height_wrapper div::after,
.height_wrapper div::before {
  content: "";
  display: table;
  clear: both;
}
.ant-modal-content .ant-modal-body span.file_upload_input {
  display: block;
  margin-top: 6px;
}
.ant-modal-content .ant-modal-body + .ant-modal-footer {
  padding-top: 0;
  border-top: 0;
  text-align: left;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  min-width: 90px;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
}
.ant-modal-content .ant-modal-footer .ant-btn span {
  font-family: "Gotham Medium" !important;
}
.ant-modal-content .ant-modal-body .form-control.ant-select,
.ant-modal-content .ant-modal-body .form-control.ant-select-show-search {
  padding: 0;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector {
  border: 0;
  padding-right: 28px;
  height: 26px;
  min-height: auto;
  background-color: transparent;
}
.ant-modal-body label.ant-checkbox-wrapper {
  background-color: transparent;
  padding-left: 0;
  display: inline-block;
}
.ant-modal-body label.ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 12px;
  color: #404040;
}
.sicknotepopup .ant-modal-body label.ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 10px;
}
.ant-checkbox {
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  top: 1px;
}
.ant-modal-content .ant-modal-body .ant-btn span,
.ant-modal-content .ant-modal-body .ant-btn {
  font-size: 12px;
  font-family: "Gotham Medium";
}
.ant-modal-content .ant-modal-body .ant-btn.custom_cancel_btn,
.ant-modal-content .ant-modal-body .ant-btn.cancel_btn {
  background-color: #e9e9e9 !important;
  border: solid 1px #e9e9e9 !important;
  color: #404040 !important;
}
.ant-modal-content .ant-modal-body .dlt_prescp .cancel_btn {
  background-color: rgba(255, 89, 100, 0.08) !important;
  border: 1px solid #ff5964 !important;
  color: #ff5964 !important;
}
.width_850px {
  width: 850px !important;
}
.ant-modal-content .ant-timeline .ant-timeline-item-head {
  position: absolute;
  height: 17px;
  width: 17px;
  background: #e9e9e9;
  border-radius: 50%;
  border: 4px solid #fff;
  top: 12px;
  left: -5px;
}
.ant-modal-content .ant-timeline-item {
  padding: 0;
  margin-bottom: 10px;
}
.ant-modal-content .ant-timeline-item:last-child {
  margin-bottom: 0px;
}
.ant-modal-content .ant-timeline-item .ant-timeline-item-tail {
  display: block;
  top: 0;
  height: calc(100% - 8px);
  left: 3px;
  background-color: #f0f0f0;
  width: 1.2px;
  border: none;
}
.ant-modal-content .ant-timeline-item .ant-timeline-item-content {
  margin-left: 20px;
}
.ant-radio-group.dis-bock {
  display: block;
}
.ant-radio-group.dis-bock .ant-radio-wrapper {
  margin-bottom: 8px;
}
.ant-modal-content .ant-modal-body .react-tel-input input {
  padding-left: 52px;
}
.ant-modal-content .ant-modal-body .flag-dropdown,
.ant-modal-content
  .ant-modal-body
  .react-tel-input
  .flag-dropdown.open
  .selected-flag {
  background-color: transparent;
  border: none;
}
.ant-modal-content .ant-modal-body .flag-dropdown .flag .arrow {
  left: 25px;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #1445b7;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-modal-content .ant-modal-body .file-upload-control span.file_upload_input {
  margin-top: 0;
}
.ant-modal-content .ant-modal-body .ant-picker-suffix {
  color: #1445b7;
}
.ant-modal-content button.ant-modal-close {
  outline: none;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow {
  right: 16px;
  color: #5d5d5d;
}
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow {
  display: none;
}*/
.ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow .anticon
/* .ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow .anticon */ {
  display: none;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow, */
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow {
  background-size: cover;
  width: 14px;
  height: 14px;
  top: 45%;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow,
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow */
.ant-modal-content .ant-modal-body select.form-control,
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow {
  background-image: url(../images/common/ic-dropdown.svg);
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  background-image: none;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow {
  background-repeat: no-repeat;
  background-position: 97.5%;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow
  span.ant-select-arrow
  .anticon-down {
  display: none;
}
.ant-modal-content .ant-modal-body select.form-control {
  background-repeat: no-repeat;
  background-position: 98% center;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  line-height: 1;
}
.ant-select-item-option-content {
  font-size: 12px;
  font-family: "Gotham Book" !important;
}
.ant-modal-content .ant-modal-body .ant-select-clear {
  background: transparent;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-item {
  white-space: break-spaces;
}

/* tags css */
.ant-tag-green,
.ant-tag-red,
.ant-tag-blue {
  font-size: 10px !important;
  border-radius: 2px !important;
  padding: 0px 5px !important;
  /* height: 20px !important; */
  line-height: 18px !important;
  font-family: "Gotham Book" !important;
}
.ant-tag-green {
  background-color: rgba(67, 220, 145, 0.1) !important;
  border: solid 1px #43dc91 !important;
  color: #43dc91 !important;
}
.ant-tag-red {
  background-color: rgba(255, 89, 100, 0.08) !important;
  border: solid 1px #ff5964 !important;
  color: #ff5964 !important;
}
.ant-tag-blue {
  color: #1445b7 !important;
  background: #ebf5fc !important;
  border-color: #1445b7 !important;
}
.ant-tag.private {
  border: 1px solid #5EBB5B !important;
  background: #5EBB5B !important;
  color: #fff !important;
  font-weight: 700 !important;
}
.ant-tag {
  padding-block: 1px 0;
}
.ant-tag.nhs {
  border: 1px solid #1445b7 !important;
  background: #1445b7 !important;
  color: #fff !important;
  font-weight: 700 !important;
}
.ant-alert-error.form-alert {
  background-color: rgba(255, 89, 100, 0.08);
  border: 1px solid #ff5964;
  color: #ff5964;
  padding: 2px 8px !important;
}
.ant-alert-error.form-alert .ant-alert-description {
  font-size: 10px;
  text-align: center;
  font-family: "Gotham Bold";
  line-height: 1.42857143;
}

/* ant form css */
.ant-input:hover {
  outline: none !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-input:focus,
.ant-input-focused {
  outline: none !important;
  border: solid 1px #1445b7 !important;
  box-shadow: none !important;
}
.indication-input-wrapper .ant-input-affix-wrapper .ant-input:focus,
.indication-input-wrapper .ant-input-affix-wrapper .ant-input:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.ant-modal-body .ant-upload.ant-upload-drag {
  border: 2px dotted #5d5d5d;
  background: #ffffff;
  border-radius: 0;
}
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-text {
  color: #5d5d5d;
  font-size: 14px;
}
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}
.ant-picker-input > input,
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-text {
  font-family: "Gotham Book";
}
.ant-modal-body .ant-picker-input > input {
  font-size: 10px;
}
.ant-modal-body .ant-picker.d-block.w-100 {
  height: 28px;
  line-height: 1;
  background: #f9f9f9;
}
.ant-modal-body label.font-12 {
  font-size: 12px;
}
.ant-modal-content .ant-modal-body .form-control.height-30 {
  height: 30px;
}
.ant-modal-content .ant-modal-body textarea.form-control.height-auto {
  height: auto;
  line-height: 14px;
  min-height: 26px;
  resize: vertical;
  overflow: hidden;
}
.ant-modal-mask {
  background: rgba(0, 0, 0, 0.8) !important;
}
.ant-btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-empty-description span {
  font-size: 12px;
  color: #1445b7;
}
.ant-empty-image {
  margin: 0;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  height: 26px;
  line-height: 26px;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
  /* font-size: 10px; */
  font-size: 12px;
}
/* confirm popup starts*/
.ant-modal.ant-modal-confirm {
  width: 478px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body {
  padding: 15px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body span.anticon {
  display: none;
}
/* .ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title ,
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content{
  font-size: 11px;
  color: #404040!important;
  font-weight: 400;
} */
/* .ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title {
  margin: 5px 0 10px!important;
} */
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  margin-top: 15px;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  width: 104px !important;
  color: #404040 !important;
  font-size: 10px !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn-primary {
  background-color: #ff5964 !important;
  border: 1px solid #ff5964 !important;
  color: #fff !important;
  font-size: 10px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content {
  margin-bottom: 5px;
  margin-left: 0 !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button,
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button
  span {
  font-size: 10px;
  text-transform: capitalize;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn.rota-ok-btn {
  color: #fff !important;
  background-color: #ff5964 !important;
  border: 1px solid #ff5964 !important;
}
/* confirm popup ends*/

/* checkbox css starts */
span.ant-checkbox-inner {
  border: 2px solid #1445b7 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  width: 14px !important;
  height: 14px !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1445b7 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 6.714286px !important;
  height: 13.142857px !important;
  top: 2px !important;
  left: 3px !important;
  -webkit-box-shadow: 1.4px 0px 0px 1px #ffffff !important;
  box-shadow: 1.4px 0px 0px 1px #ffffff !important;
}
/* checkbox css ends */

.custom-arrow .ant-select-arrow .anticon {
  height: 15px;
  width: 15px;
  background-position: 50%;
}
.custom-arrow .ant-select-arrow .anticon {
  background-image: url(../images/common/ic-dropdown.svg);
  background-repeat: no-repeat;
}
.custom-arrow .ant-select-arrow .anticon {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.custom-arrow .ant-select-arrow .anticon svg {
  display: none;
}
.custom-arrow span.ant-select-arrow {
  top: 50%;
}
textarea.form-control-textarea {
  min-height: 54px !important;
}
.filter_with_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.drop_name .ant-select-item-option-content {
  text-overflow: initial;
  overflow: auto;
  white-space: break-spaces;
}
/* 5-1-2023 css start */
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  padding-left: 15px !important;
}
/* patient detail menu css start */
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-item-icon {
  margin-right: 0 !important;
  min-width: 14px;
}
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-item-icon
  + span {
  margin-left: 0px;
  position: absolute;
}
.ant-layout-sider-collapsed
  .ant-layout-sider-children
  .ant-menu.ant-menu-inline-collapsed {
  text-align: center;
}
.ant-modal .ant-modal-content .ant-modal-title {
  font-size: 14px;
  font-weight: 500;
}
.ant-input {
  font-size: 12px !important;
}
.ant-modal-body label {
  font-size: 12px;
  margin-bottom: 10px;
}
.ant-modal-content .ant-modal-body select.form-control {
  line-height: 1 !important;
}

/* consultantion-info-table-wrapper */
.consultantion-info-table-wrapper .ant-select {
  line-height: 1 !important;
  width: calc(100% - 35px);
  min-width: 40px;
  margin-right: 3px;
}
.consultantion-info-table-wrapper .ant-select-selection-search {
  left: 3px;
  min-width: 20px;
  margin-left: -5px;
}
.consultantion-info-table-wrapper .ant-select-selector {
  border-radius: 4px !important;
}
.consultantion-info-table-wrapper
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 28px;
  padding: 0 0 0 4px !important;
  width: 100%;
}
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  min-width: 20px !important;
}
.consultantion-info-table-wrapper .ant-select-arrow {
  right: 4px !important;
}
.consultantion-info-table-wrapper .ant-select-selection-item {
  padding-right: 14px !important;
}
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 28px !important;
  font-size: 12px !important;
}
.prescriptiondetailpopup
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 10px !important;
}
.prescriptiondetailpopup .ant-modal-content .ant-modal-body .form-control,
.prescriptiondetailpopup
  .ant-modal-content
  .ant-modal-body
  .form-control::-webkit-input-placeholder {
  font-size: 10px !important;
}
.prescriptiondetailpopup
  .ant-modal-body
  label.ant-checkbox-wrapper
  .ant-checkbox
  + span {
  font-size: 10px !important;
}
/* consultantion-info-table-wrapper */
.ant-menu-dark.ant-menu-horizontal {
  background: transparent !important;
  line-height: 1 !important;
}
.ant-menu.ant-menu-dark {
  background: transparent;
  padding-left: 40px;
  width: 100%;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}
.ant-menu.ant-menu-dark .ant-menu-item {
  position: relative;
  color: #ffffff;
  padding: 0 4px 0 2px !important;
  font-size: 11.5px;
  margin-right: 16px;
  vertical-align: middle;
  line-height: 22px;
  font-family: "Gotham Book";
}
.ant-menu.ant-menu-dark .ant-menu-item::after {
  content: "";
  background-color: #ffffff;
  height: 16px;
  width: 1px;
  position: absolute;
  right: -8px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected::before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #1445b7;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}
.ant-menu.ant-menu-dark .ant-menu-item:last-child::after {
  content: none;
}
.ant-layout-sider {
  /* padding-bottom: 76px !important;
  height: calc(100vh - 42px) !important; */
  height: calc(100vh - 56px) !important;
}
.ant-layout-sider .ant-menu-item {
  font-size: 12px;
  font-weight: normal;
  line-height: 2.49 !important;
  height: 34px !important;
  padding: 2px 13px !important;
  margin: 0 !important;
  font-family: "Gotham Book";
  color: #757575;
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  background-color: #ffffff !important;
  font-family: "Gotham Medium";
  color: #1445b7;
}
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
  padding-left: 30px !important;
  font-size: 12px;
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.ant-layout-sider .ant-menu-item.ant-menu-item-selected::after {
  left: 0;
  right: auto;
  border-width: 4px;
  border-color: #1445b7;
}
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
  padding-left: 30px !important;
  font-size: 11px;
}
.ant-menu-submenu-inline .ant-menu-submenu-title img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title img,
.ant-menu-submenu-inline .ant-menu-submenu-title img {
  -webkit-transition: -webkit-filter 0.6s ease-in-out;
  transition: -webkit-filter 0.6s ease-in-out;
  -o-transition: filter 0.6s ease-in-out;
  transition: filter 0.6s ease-in-out;
  transition: filter 0.6s ease-in-out, -webkit-filter 0.6s ease-in-out;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-sub {
  border-top: 1px solid #e9e9e9 !important;
}
.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title {
  color: #1445b7;
  font-family: "Gotham Medium";
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}
.ant-layout-sider .ant-menu-item .ant-menu-inline-collapsed-noicon {
  font-size: 12px !important;
}
.ant-layout-sider .ant-menu {
  background: transparent;
}
.ant-form-item:last-child {
  margin-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:nth-last-child(2) {
  margin: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:nth-last-child(2)::after {
  content: none;
}
.ant-layout-sider-children ul.ant-menu:not(:first-child) {
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
}
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  img
  + span,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  svg
  + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-tooltip-inner .sidebar-notification-badge {
  position: relative;
  display: inline-block;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 10px;
  right: 0;
}

/* dropdown css */
.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 6px 15px;
  font-size: 12px;
  color: #404040;
  font-family: "Gotham Book";
  line-height: 1.42857143;
  word-break: break-word;
  white-space: pre-line;
}
.ant-dropdown-menu .ant-dropdown-menu-item span {
  font-size: 12px;
}
.ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu .ant-dropdown-menu-item a:hover,
.ant-dropdown-menu:not(.dropdown_appointment-walkin_wrapper) .ant-dropdown-menu-item-active {
  background-color: #e8efff;
  color: #1445b7;
}
.ant-dropdown-menu {
  padding: 0 !important;
}
.ant-dropdown-menu.small_dropdown .ant-dropdown-menu-item {
  padding: 4px 10px;
}
.ant-dropdown-menu {
  box-shadow: none !important;
  border: solid 1px #e9e9e9;
}
.ant-dropdown-menu .ant-dropdown-menu-item span .ant-upload {
  line-height: 1;
}

/* table css override */
.ant-table .ant-table-content .ant-table-thead > tr > th {
  font-size: 12px;
  text-transform: capitalize;
  font-family: "Gotham Medium";
  background: #f6f6f6;
  padding: 8px 12px;
  color: #404040;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td,
.ant-table .ant-table-content .ant-table-thead > tr > th {
  line-height: 1.42857143;
  color: #5d5d5d;
}
.ant-table table {
  border: 1px solid #eeeeee;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 12px;
  font-family: "Gotham Book";
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: #1445b7;
  color: #1445b7;
}
.ant-table .ant-table-content .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td .anticon {
  color: #9e9e9e;
}
.ant-table .ant-table-content .ant-table-thead > tr > th {
  border-bottom: 1px solid #eeeeee;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td p {
  margin: 0;
}
.font-12 .ant-table .ant-table-content .ant-table-tbody > tr > td,
.font-12 .ant-table .ant-table-content .ant-table-thead > tr > th {
  color: #757575;
  font-weight: 400;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 22px;
}
.no_table_header thead.ant-table-thead {
  display: none;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-20,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-20 {
  padding-left: 20px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-10,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-10 {
  padding-left: 10px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-bottom-4,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-bottom-4 {
  padding-bottom: 4px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-top-4,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-top-4 {
  padding-top: 4px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-0,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-0 {
  padding-right: 0;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-20,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-20 {
  padding-right: 20px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-right-10,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-right-10 {
  padding-right: 10px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.padding-left-0,
.ant-table .ant-table-content .ant-table-thead > tr > th.padding-left-0 {
  padding-left: 0;
}
.ant-table .ant-table-content .ant-table-thead > tr > th.min-height-30 {
  height: 30px;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.text-light-black {
  color: #404040;
}
.ant-table .ant-table-content .ant-table-tbody > tr > td.text-dark-gray {
  color: #757575;
}
.sort-pad-0 .ant-table-column-sorters {
  padding: 0;
}
.doctor_profile_text {
  width: calc(100% - 34px);
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.doctor_profile_text span.ant-tag-has-color {
  background: #1445b7 !important;
}
.border-spacing-5 table {
  border-spacing: 0 5px !important;
  border: 0 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell {
  border: 1px solid #e9e9e9 !important;
  background-color: #f9f9f9 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell:first-child,
.border-spacing-5
  table
  tr.ant-table-row
  td.ant-table-cell:not(:first-child):not(:last-child) {
  border-right: 0 !important;
}
.border-spacing-5 table tr.ant-table-row td.ant-table-cell:last-child,
.border-spacing-5
  table
  tr.ant-table-row
  td.ant-table-cell:not(:first-child):not(:last-child) {
  border-left: 0 !important;
}

/* button css */
.ant-btn-primary {
  background: #1445b7 !important;
  border: solid 1px #1445b7 !important;
}
.ant-btn-primary:hover {
  background-color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.ant-btn-dangerous,
.ant-btn-primary {
  font-size: 14px !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 2px !important;
}
.ant-btn-dangerous {
  background: #ff5964 !important;
  border: solid 1px #ff5964 !important;
}
.ant-btn-dangerous:hover {
  background: #ed2b2d !important;
  border: solid 1px #ed2b2d !important;
}

/* ant modal css */
.ellipsis .lb-container .lb-canvas {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ellipsis .lb-container .lb-canvas .lb-img {
  height: 100%;
  width: auto;
}
.ant-modal-content .ant-modal-title {
  font-size: 11px;
  color: #1445b7;
  font-family: "Gotham Book";
  font-weight: 400;
}
.ant-modal-content .ant-modal-header {
  padding: 15px 6px 6px 15px;
  border: 0;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.ant-modal-content {
  border-radius: 4px !important;
}
.ant-modal-content button.ant-modal-close {
  top: 6px;
}
.ant-modal-content .ant-modal-body {
  padding: 5px 15px 15px 15px;
  line-height: 1;
}
.ant-modal-content .ant-modal-close-x {
  width: 42px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
}
.anticon {
  vertical-align: middle !important;
}
.btn-item-wrapper button {
  margin-right: 10px;
}
.ant-modal-body label {
  font-size: 10px;
  font-weight: normal;
  color: #404040;
  margin-bottom: 6px;
  font-family: "Gotham Book";
  display: block;
  /* color: #757575;
  padding: 0px 8px;
  text-align: center;
  background-color: #e9e9e9;
  height: 18px;
  margin-bottom: 0px;
  line-height: 19px; */
}
.ant-modal-content .ant-modal-body .form-group {
  margin-bottom: 14px;
}
.ant-modal-content .ant-modal-body .form-control {
  height: 28px;
  line-height: 27px !important;
  padding: 5px 11px;
  font-size: 12px;
  border-radius: 2px;
  /* padding-left: 6px; */
  width: 100%;
  border: solid 1px #e9e9e9;
  background-color: #f9f9f9;
}
.searchsympotmspopup .ant-modal-content .ant-modal-body .ant-select {
  border: solid 1px #e9e9e9;
  background-color: #f9f9f9;
  font-size: 12px;
  border-radius: 2px;
  width: 100%;
}
.searchsympotmspopup
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f9f9f9;
}
.ant-modal-content .ant-modal-body .ant-btn {
  min-width: 100px;
}
.ant-radio-group label.ant-radio-wrapper {
  background-color: transparent;
  margin-top: 8px;
}
.ant-radio-group label.ant-radio-wrappe .ant-radio-inner {
  width: 18px;
  height: 18px;
}
.ant-radio-group label.ant-radio-wrappe .ant-radio-inner::after {
  width: 10px;
  height: 10px;
}
.ant-radio-group label.ant-radio-wrapper {
  background-color: transparent;
  margin-top: 8px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 28px;
  font-size: 11px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control input {
  font-size: 12px;
}
.ant-modal-content .ant-modal-body .ant-picker.form-control .ant-picker-suffix {
  font-size: 14px;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select-show-search
  .ant-select-selector {
  height: 0;
  min-height: 28px;
  border: solid 1px #e9e9e9;
}
.ant-modal-body
  .ant-radio-button-wrapper
  > .ant-radio-button.ant-radio-button-checked {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.ant-modal-body .ant-radio-group .ant-radio-button-wrapper {
  padding: 6px 12px;
  height: auto;
  line-height: 1.42857143;
  background-color: #fff;
  border-color: #1445b7;
  border-radius: 4px;
}
.ant-modal-content .ant-modal-body .weight_wrapper select.form-control,
.ant-modal-content .ant-modal-body .height_wrapper select.form-control {
  width: calc(50% - 14px);
  float: left;
  margin: 0 5px;
}
.ant-modal-content .ant-modal-body .weight_wrapper div,
.ant-modal-content .ant-modal-body .height_wrapper div {
  margin: 15px -7px 0;
}
.weight_wrapper::after,
.weight_wrapper::before,
.weight_wrapper div::after,
.weight_wrapper div::before,
.height_wrapper::after,
.height_wrapper::before,
.height_wrapper div::after,
.height_wrapper div::before {
  content: "";
  display: table;
  clear: both;
}
.ant-modal-content .ant-modal-body span.file_upload_input {
  display: block;
  margin-top: 6px;
}
.ant-modal-content .ant-modal-body + .ant-modal-footer {
  padding-top: 0;
  border-top: 0;
  text-align: left;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  min-width: 90px;
  border-radius: 2px !important;
  font-size: 12px !important;
  padding-block: 5px 3px;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
}
.ant-modal-content .ant-modal-footer .ant-btn span {
  font-family: "Gotham Medium" !important;
}
.ant-modal-content .ant-modal-body .form-control.ant-select,
.ant-modal-content .ant-modal-body .form-control.ant-select-show-search {
  padding: 0;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector {
  border: 0;
  padding-right: 28px;
  height: 26px;
  min-height: auto;
  background-color: transparent;
}
.ant-modal-body label.ant-checkbox-wrapper {
  background-color: transparent;
  padding-left: 0;
  display: inline-block;
}
.ant-modal-body label.ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 12px;
  color: #404040;
}
.sicknotepopup .ant-modal-body label.ant-checkbox-wrapper .ant-checkbox + span {
  font-size: 10px;
}
.ant-checkbox {
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}
.ant-modal-content .ant-modal-body .ant-btn.custom_cancel_btn,
.ant-modal-content .ant-modal-body .ant-btn.cancel_btn {
  background-color: #e9e9e9 !important;
  border: solid 1px #e9e9e9 !important;
  color: #404040 !important;
}
.ant-modal-content .ant-modal-body .dlt_prescp .cancel_btn {
  background-color: rgba(255, 89, 100, 0.08) !important;
  border: 1px solid #ff5964 !important;
  color: #ff5964 !important;
}
.width_850px {
  width: 850px !important;
}
.ant-modal-content .ant-timeline .ant-timeline-item-head {
  position: absolute;
  height: 17px;
  width: 17px;
  background: #e9e9e9;
  border-radius: 50%;
  border: 4px solid #fff;
  top: 12px;
  left: -5px;
}
.ant-modal-content .ant-timeline-item {
  padding: 0;
  margin-bottom: 10px;
}
.ant-modal-content .ant-timeline-item:last-child {
  margin-bottom: 0px;
}
.ant-modal-content .ant-timeline-item .ant-timeline-item-tail {
  display: block;
  top: 0;
  height: calc(100% - 8px);
  left: 3px;
  background-color: #f0f0f0;
  width: 1.2px;
  border: none;
}
.ant-modal-content .ant-timeline-item .ant-timeline-item-content {
  margin-left: 20px;
}
.ant-radio-group.dis-bock {
  display: block;
}
.ant-radio-group.dis-bock .ant-radio-wrapper {
  margin-bottom: 8px;
}
.ant-modal-content .ant-modal-body .react-tel-input input {
  padding-left: 52px;
}
.ant-modal-content .ant-modal-body .flag-dropdown,
.ant-modal-content
  .ant-modal-body
  .react-tel-input
  .flag-dropdown.open
  .selected-flag {
  background-color: transparent;
  border: none;
}
.ant-modal-content .ant-modal-body .flag-dropdown .flag .arrow {
  left: 25px;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #1445b7;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-modal-body
  .ant-radio-group
  .ant-radio-button-wrapper:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-modal-content .ant-modal-body .file-upload-control span.file_upload_input {
  margin-top: 0;
}
.ant-modal-content .ant-modal-body .ant-picker-suffix {
  color: #1445b7;
}
.ant-modal-content button.ant-modal-close {
  outline: none;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow {
  right: 16px;
  color: #5d5d5d;
}
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow {
  display: none;
}*/
.ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow .anticon
/* .ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow .anticon */ {
  display: none;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow, */
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow {
  background-size: cover;
  width: 14px;
  height: 14px;
  top: 45%;
}
/* .ant-modal-content .ant-modal-body .form-control.ant-select-show-search .ant-select-arrow,
.ant-modal-content .ant-modal-body .form-control.ant-select .ant-select-arrow */
.ant-modal-content .ant-modal-body select.form-control,
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow {
  background-image: url(../images/common/ic-dropdown.svg);
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  background-image: none;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow {
  background-repeat: no-repeat;
  background-position: 97.5%;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select.ant-select-single.ant-select-show-arrow
  span.ant-select-arrow
  .anticon-down {
  display: none;
}
.ant-modal-content .ant-modal-body select.form-control {
  background-repeat: no-repeat;
  background-position: 98% center;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  line-height: 1;
}
.ant-select-item-option-content {
  font-size: 12px;
  font-family: "Gotham Book" !important;
}
.ant-modal-content .ant-modal-body .ant-select-clear {
  background: transparent;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-item {
  white-space: break-spaces;
}

/* tags css */
.ant-tag-green,
.ant-tag-red,
.ant-tag-blue {
  font-size: 10px !important;
  border-radius: 2px !important;
  padding: 0px 5px !important;
  /* height: 20px !important; */
  line-height: 18px !important;
  font-family: "Gotham Book" !important;
}
.ant-tag-green {
  background-color: rgba(67, 220, 145, 0.1) !important;
  border: solid 1px #43dc91 !important;
  color: #43dc91 !important;
}
.ant-tag-red {
  background-color: rgba(255, 89, 100, 0.08) !important;
  border: solid 1px #ff5964 !important;
  color: #ff5964 !important;
}
.ant-tag-blue {
  color: #1445b7 !important;
  background: #e8efff !important;
  border-color: #1445b7 !important;
  padding-top: 1px !important;
}
.ant-alert-error.form-alert {
  background-color: rgba(255, 89, 100, 0.08);
  border: 1px solid #ff5964;
  color: #ff5964;
  padding: 2px 8px !important;
}
.ant-alert-error.form-alert .ant-alert-description {
  font-size: 10px;
  text-align: center;
  font-family: "Gotham Bold";
  line-height: 1.42857143;
}

/* ant form css */
.ant-input:hover {
  outline: none !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-input:focus,
.ant-input-focused {
  outline: none !important;
  border: solid 1px #1445b7 !important;
  box-shadow: none !important;
}
.indication-input-wrapper .ant-input-affix-wrapper .ant-input:focus,
.indication-input-wrapper .ant-input-affix-wrapper .ant-input:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.ant-modal-body .ant-upload.ant-upload-drag {
  border: 2px dotted #5d5d5d;
  background: #ffffff;
  border-radius: 0;
}
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-text {
  color: #5d5d5d;
  font-size: 14px;
}
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}
.ant-picker-input > input,
.ant-modal-body .ant-upload.ant-upload-drag p.ant-upload-text {
  font-family: "Gotham Book";
}
.ant-modal-body .ant-picker-input > input {
  font-size: 10px;
}
.ant-modal-body .ant-picker.d-block.w-100 {
  height: 28px;
  line-height: 1;
  background: #f9f9f9;
}
.ant-modal-body label.font-12 {
  font-size: 12px;
}
.ant-modal-content .ant-modal-body .form-control.height-30 {
  height: 30px;
}
.ant-modal-content .ant-modal-body textarea.form-control.height-auto {
  height: auto;
  line-height: 14px;
  min-height: 26px;
  resize: vertical;
  overflow: hidden;
}
.ant-modal-mask {
  background: rgba(0, 0, 0, 0.8) !important;
}
.ant-btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-empty-description span {
  font-size: 12px;
  color: #1445b7;
}
.ant-empty-image {
  margin: 0;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  height: 26px;
  line-height: 26px;
}
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
  /* font-size: 10px; */
  font-size: 12px;
}
/* confirm popup starts*/
.ant-modal.ant-modal-confirm {
  width: 478px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body {
  padding: 15px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body span.anticon {
  display: none;
}
/* .ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title ,
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content{
  font-size: 11px;
  color: #404040!important;
  font-weight: 400;
} */
/* .ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title {
  margin: 5px 0 10px!important;
} */
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  margin-top: 15px;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  width: 104px !important;
  color: #404040 !important;
  font-size: 10px !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn-primary {
  background-color: #ff5964 !important;
  border: 1px solid #ff5964 !important;
  color: #fff !important;
  font-size: 10px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content {
  margin-bottom: 5px;
  margin-left: 0 !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button,
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button
  span {
  font-size: 10px;
  text-transform: capitalize;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn.rota-ok-btn {
  color: #fff !important;
  background-color: #ff5964 !important;
  border: 1px solid #ff5964 !important;
}
/* confirm popup ends*/

/* checkbox css starts */
span.ant-checkbox-inner {
  border: 2px solid #1445b7 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  width: 14px !important;
  height: 14px !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1445b7 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 6.714286px !important;
  height: 13.142857px !important;
  top: 2px !important;
  left: 3px !important;
  -webkit-box-shadow: 1.4px 0px 0px 1px #ffffff !important;
  box-shadow: 1.4px 0px 0px 1px #ffffff !important;
}
/* checkbox css ends */

.custom-arrow .ant-select-arrow .anticon {
  height: 15px;
  width: 15px;
  background-position: 50%;
}
.custom-arrow .ant-select-arrow .anticon {
  background-image: url(../images/common/ic-dropdown.svg);
  background-repeat: no-repeat;
}
.custom-arrow .ant-select-arrow .anticon {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.custom-arrow .ant-select-arrow .anticon svg {
  display: none;
}
.custom-arrow span.ant-select-arrow {
  top: 50%;
}
textarea.form-control-textarea {
  min-height: 54px !important;
}
.filter_with_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.drop_name .ant-select-item-option-content {
  text-overflow: initial;
  overflow: auto;
  white-space: break-spaces;
}
/* 5-1-2023 css start */
.ant-menu-submenu .ant-menu-sub li.ant-menu-item {
  padding-left: 15px !important;
}
/* patient detail menu css start */
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-item-icon {
  margin-right: 0 !important;
  min-width: 14px;
}
.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-item-icon
  + span {
  margin-left: 0px;
  position: absolute;
}
.ant-layout-sider-collapsed
  .ant-layout-sider-children
  .ant-menu.ant-menu-inline-collapsed {
  text-align: center;
}
.ant-modal .ant-modal-content .ant-modal-title {
  font-size: 14px;
  font-weight: 500;
}
.ant-input {
  font-size: 12px !important;
}
.ant-modal-body label {
  font-size: 12px;
  margin-bottom: 10px;
}
.ant-modal-content .ant-modal-body select.form-control {
  line-height: 1 !important;
}

/* consultantion-info-table-wrapper */
.consultantion-info-table-wrapper .ant-select {
  line-height: 1 !important;
  width: calc(100% - 35px);
  min-width: 40px;
  margin-right: 3px;
}
.consultantion-info-table-wrapper .ant-select-selection-search {
  left: 3px;
  min-width: 20px;
  margin-left: -5px;
}
.consultantion-info-table-wrapper .ant-select-selector {
  border-radius: 4px !important;
}
.consultantion-info-table-wrapper
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 28px;
  padding: 0 0 0 4px !important;
  width: 100%;
}
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  min-width: 20px !important;
}
.consultantion-info-table-wrapper .ant-select-arrow {
  right: 4px !important;
}
.consultantion-info-table-wrapper .ant-select-selection-item {
  padding-right: 14px !important;
}
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 28px !important;
  font-size: 12px !important;
}
.prescriptiondetailpopup
  .ant-modal-content
  .ant-modal-body
  .form-control.ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 10px !important;
}
.prescriptiondetailpopup .ant-modal-content .ant-modal-body .form-control,
.prescriptiondetailpopup
  .ant-modal-content
  .ant-modal-body
  .form-control::-webkit-input-placeholder {
  font-size: 10px !important;
}
.prescriptiondetailpopup
  .ant-modal-body
  label.ant-checkbox-wrapper
  .ant-checkbox
  + span {
  font-size: 10px !important;
}
.ant-select-item-option-content {
  font-size: 14px !important;
}
.consultantion-info-table-wrapper
  .religon_drop
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px !important;
  height: 32px !important;
}
.consultantion-info-table-wrapper .religon_drop .ant-select-arrow {
  right: 10px !important;
}
.consultantion-info-table-wrapper
  .religon_drop
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.consultantion-info-table-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 32px !important;
  font-size: 12px !important;
}
/* consultantion-info-table-wrapper */
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-active {
  background: #1445b7 !important;
  color: #fff !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) b,
.ant-select-item-option-active b,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) span:not(.ant-tag),
.ant-select-item-option-active span:not(.ant-tag) {
  color: #fff !important;
}

.ant-select-item {
  padding: 6px 16px !important;
}
.preconsulttabwrap.borderfix .ant-tabs-top > .ant-tabs-nav::before {
  bottom: 2px !important;
}
.antpickerinput .ant-picker {
  padding: 6px 11px !important;
}

.demo-loadmore-list {
  overflow-x: hidden !important;
  .ant-list-item {
    padding: 6px 6px;
    .ant-checkbox-wrapper {
      line-height: unset;
      margin: 0;
    }
    .ant-list-item-meta-title {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}


/* ant-select */
.CustomDropDownItem_Wrapper .ant-select ,
.CustomDropDownItem_Wrapper .ant-select-selector {
  height: auto !important;
}
.CustomDropDownItem_Wrapper .ant-select-multiple .ant-select-selection-item {
  background: none !important;
  border: none !important;
}
.CustomDropDownItem_Wrapper .ant-select-selection-item-content {
  color: #ffffff !important;
}
.CustomDropDownItem_Wrapper .ant-select-selection-overflow {
  gap: 4px;
}
.CustomDropDownItem_Wrapper .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
  background: #1445b7 !important;
  border-radius: 4px !important;
}
.CustomDropDownItem_Wrapper .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix):first-child {
  margin-top: 4px;
}
.CustomDropDownItem_Wrapper .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix):nth-last-child(2) {
  margin-bottom: 4px;
}
.CustomDropDownItem_Wrapper .ant-select-multiple .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}
/* booked appointment */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ffffff !important;
}
.selected_doctor_for_appointment .ant-select-selection-overflow {
  gap: 4px;
  padding: 4px 0;
}
.selected_doctor_for_appointment .ant-select-selection-overflow .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix){
  background: #1445b7 !important;
  border-radius: 4px !important;
}
.selected_doctor_for_appointment .ant-select-selection-overflow .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) .ant-select-selection-item {
  background: none !important;
  border: none;
  color:#ffffff;
}
.selected_doctor_for_appointment .ant-select-selection-item-remove {
  color:#ffffff;
}
.selected_doctor_for_appointment .ant-select-selection-overflow .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  padding: 0 4px ;
}
.selected_doctor_for_appointment .ant-select-selection-search {
  margin: 0;
}
.ant-btn.ant-btn-danger:hover, .ant-btn.ant-btn-danger:active, .ant-btn.ant-btn-danger:focus {
  border-color: #ed3336;
  color: #fff;
  background-color: #ed3336;
}
/* booked appointment */
/* ant-select */
.labelcheckbox .ant-checkbox {
  top: 0px;
}
.patientdetailsmain .anticon.anticon-info-circle {
  color: #1445b7;
}
.ant-select-dropdown .ant-select-item.multi-data:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
}
.ant-modal-content .ant-modal-body .patient-search-tag .form-control.ant-select .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{height:20px;line-height:20px;}
.patient-search-tag .ant-select-multiple .ant-select-selection-search{margin-top:0;margin-bottom:0;display:flex;}
.patient-search-tag .ant-select-selection-overflow{gap:4px}
.ant-modal-content .ant-modal-body .patient-search-tag .form-control.ant-select .ant-select-selector .ant-select-selection-item{line-height:20px !important;margin:0 !important;background-color:#1445b7;border-radius:2px;border:1px solid #1445b7;color:#fff;height:auto;}
.ant-modal-content .ant-select-multiple .ant-select-selector{padding:0px 4px;}
.ant-modal-content .ant-modal-body .patient-search-tag .form-control.ant-select .ant-select-selector{height:26px;line-height:26px;}
.patient-search-tag .ant-select-multiple .ant-select-selector::after{line-height:22px;}
.patient-search-tag-dropdown .ant-select-item-option:not(.patient-search-tag-items){display:none;}
.patient-search-tag-dropdown .ant-select-item-option.patient-search-tag-items:first-child {margin-top: 4px;}
.patient-search-tag-dropdown .ant-select-item-option.patient-search-tag-items:last-child {margin-bottom: 4px;}
.ant-select-dropdown.patient-search-tag-dropdown{padding:0;}
.patient-search-tag .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {height: 28px;}
.patient-search-tag .ant-select-selection-item .gender {margin-top: 2px;}
.patient-search-tag .ant-select-single .ant-select-selector .ant-select-selection-item,.patient-search-tag .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height:28px;font-size: 11px;color: #404040;}
.ant-picker-range .ant-picker-clear {right: 30px;}
.child-question_single_choice .radiopbtn {margin-top: 0px;}
.child-question_single_choice .radiopbtn .ant-radio {top: 3px !important;}
.ant-modal-content .ant-radio,
.ant-modal-content .ant-checkbox {top: 4px;}
.ant-modal-content .live-talk2-table .ant-radio,
.ant-modal-content .live-talk2-table th .ant-checkbox {top: 2px;}
.live-talk2-table th .ant-checkbox .ant-checkbox-inner::after {box-shadow: 1.4px 0px 0px 1px #e9e9e9 !important;z-index: 1;}
.live-talk2-table th .ant-checkbox .ant-checkbox-inner {background-color: #e9e9e9 !important;}
.ant-modal-content .live-talk2-table td .ant-checkbox {top: 1px;}
.informgpmodal .ant-checkbox {top: 3px;}
