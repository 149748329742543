.ant-picker-body {
  z-index: 0 !important;
}
.rota-list-items
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.rota-list-items
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-list-items
  > .ant-list-item {
  width: 43% !important;
  margin-left: 3%;
}
.rota_modal .Calendar {
  width: 33em !important;
}
.rota_modal .ant-modal-body .Calendar__footer .ant-btn {
  background: #1445b7 !important;
  color: #fff;
}
.rota_modal .ant-modal-body abbr[title],
.rota_modal .ant-modal-body abbr[data-original-title] {
  text-decoration: none;
  cursor: pointer;
}
.rota_modal .Calendar__day.-ltr {
  height: 3.2em;
}
.rota_modal .ant-modal-body .react-daterange-picker {
  width: 100%;
  border: solid 1px #e9e9e9;
  background-color: #f9f9f9;
}
.rota_modal
  .ant-modal-body
  .react-daterange-picker
  .react-daterange-picker__wrapper {
  border: none;
  height: 28px;
  padding: 5px 6px;
  font-size: 13px;
}
.rota_modal
  .ant-modal-body
  .react-daterange-picker
  .react-daterange-picker__wrapper
  .react-daterange-picker__button
  svg {
  stroke: #1445b7;
  width: 13px !important;
}
/* .rota_modal
	.ant-modal-body
	.react-daterange-picker
	.react-daterange-picker__wrapper
	.react-daterange-picker__inputGroup__day,
.rota_modal
	.ant-modal-body
	.react-daterange-picker
	.react-daterange-picker__wrapper
	.react-daterange-picker__inputGroup__month {
	min-width: 25px;
}
.react-daterange-picker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup__year {
	min-width: 40px;
}
.search-filter-downdown-content .react-daterange-picker__wrapper .react-daterange-picker__inputGroup .react-daterange-picker__inputGroup__month.react-daterange-picker__inputGroup__input--hasLeadingZero, 
.search-filter-downdown-content .react-daterange-picker__wrapper .react-daterange-picker__inputGroup .react-daterange-picker__inputGroup__day.react-daterange-picker__inputGroup__input--hasLeadingZero {
    min-width: 18px !important;
} */

.react-daterange-picker__wrapper
  .react-daterange-picker__range-divider
  + .react-daterange-picker__inputGroup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 10px;
  align-items: center;
}

/** Sidemenu CSS, taken from PRG DP **/
.ant-layout-sider-children ul.ant-menu:not(:first-child) {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.ant-menu-submenu-title {
  font-family: "Gotham Medium";
  font-size: 12px;
  border-top: 1px solid #e9e9e9;
  font-weight: normal;
  line-height: 2.49 !important;
  height: 34px !important;
  padding: 2px 13px !important;
  margin: 0 !important;
}
.ant-menu-submenu-title > img,
.ant-menu-item .ant-menu-item-icon,
.ant-menu-inline .ant-menu-submenu-title svg,
.ant-menu-submenu-title > svg {
  margin-right: 10px !important;
  width: 14px;
}
.ant-menu-item.ant-menu-item-selected svg g,
.ant-menu-item:hover svg g,
.ant-menu-inline .ant-menu-submenu.ant-menu-item-selected svg g,
.ant-menu-inline .ant-menu-submenu:hover svg g {
  fill: #1445b7;
}
.ant-menu-item svg g,
.ant-menu-submenu svg g {
  fill: #1d2943;
}

.sidebar-notification-badge {
  position: absolute;
  right: 15px;
  background-color: #e52c2c;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  color: #fff;
}
/* confirm popup starts*/
.ant-modal.ant-modal-confirm {
  width: 478px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body {
  padding: 42px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body span.anticon {
  display: none;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title,
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content {
  font-size: 16px;
  color: #1d2943 !important;
  font-family: "Gotham Medium";
  line-height: 1.5;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-title {
  margin: 22px auto 22px !important;
  max-width: 277px;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  margin-top: 15px;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  background-color: #1445b7 !important;
  border: 1px solid #1445b7 !important;
  width: 124px !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn-primary {
  background-color: #e52c2c !important;
  border: 1px solid #e52c2c !important;
  color: #fff !important;
  font-size: 14px !important;
}
.ant-modal.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content {
  margin-bottom: 5px;
  margin-left: 0 !important;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button,
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-body-wrapper
  .ant-modal-confirm-btns
  button
  span {
  font-size: 14px;
  text-transform: capitalize;
}
.ant-modal.ant-modal-confirm
  .ant-modal-body
  .ant-modal-confirm-btns
  .ant-btn.rota-ok-btn {
  color: #fff !important;
  background-color: #ff5964 !important;
  border: 1px solid #ff5964 !important;
}
.ant-modal-confirm-body-wrapper {
  text-align: center;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: unset !important;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-left: 24px !important;
}
/* confirm popup ends*/
/* .signup-form-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none;
} */
.messages-wrapper .ant-result {
  padding: 30px 32px;
}

/* prescription modal css starts */
.prescription_drugs.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}
.prescription_drugs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.prescription_drugs.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  color: #1445b7;
}
.prescription_drugs .ant-tabs-tab + .ant-tabs-tab {
  padding: 0 12px 0 12px;
  margin: 0;
}
.prescription_drugs .ant-tabs-nav-list .ant-tabs-tab {
  border-bottom: 1px solid #e9e9e9;
  padding-top: 0;
  padding-bottom: 0;
}
.prescription_drugs_inner .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: none;
  background-color: #ecf8ff;
}
.prescription_drugs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #757575;
  font-size: 12px;
}
.prescription_drugs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  padding-bottom: 5px;
}

.prescription_drugs > .ant-tabs-content-holder {
  border-radius: 2px;
  border: solid 1px #1445b7;
}
.prescription_drugs > .ant-tabs-content-holder .prescription_drugs_header {
  background-color: #1445b7;
  padding: 6px;
}
.prescription_drugs
  > .ant-tabs-content-holder
  .prescription_drugs_header
  .prescription_drugs_title,
.prescription_drugs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Gotham Medium";
}
.prescription_drugs
  > .ant-tabs-content-holder
  .prescription_drugs_header
  .prescription_drugs_title {
  color: #fff;
  font-size: 12px;
  margin: 0;
  line-height: 1;
}
.prescription_drugs .prescription_drugs_inner .ant-tabs-tab {
  border-bottom: none;
}
.prescription_drugs_inner.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.prescription_drugs.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 1px;
}
.prescription_drugs_inner .ant-tabs-nav-list .ant-tabs-tab {
  padding: 5px;
  margin: 0 0;
}
.prescription_drugs_inner .ant-tabs-nav-list .ant-tabs-tab {
  border-right: 1px solid #f0f0f0;
}
.prescription_drugs_inner .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.prescription_drugs_inner .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 10px;
}
.prescription_drugs_inner .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.prescription_drugs_inner .ant-tabs-tab .ant-tabs-tab-btn,
.prescription_drugs .ant-tabs-tab .ant-tabs-tab-btn,
.prescription_drugs_inner .ant-tabs-content-holder {
  font-family: "Gotham Book";
}
.prescription_drugs_inner .ant-tabs-nav {
  margin: 0 !important;
}
.prescription_drugs_inner .ant-tabs-content-holder {
  padding: 6px;
  color: #757575;
  font-size: 12px;
  line-height: 1;
}
.prescription_drugs_inner .ant-tabs-content-holder p {
  margin-bottom: 7px;
  line-height: 1;
}
.prescription_drugs_inner .ant-tabs-content-holder p:last-child {
  margin-bottom: 0;
}
.prescription_drugs > .ant-tabs-nav {
  margin-bottom: 10px !important;
}
.prescription_drugs_inner .ant-tabs-tabpane {
  font-family: "Gotham Book";
  line-height: 1.4;
}
.prescription_drugs_inner .ant-tabs-tab .ant-tabs-tab-btn {
  text-transform: uppercase !important;
  font-size: 9px !important;
}
.prescription_drugs_inner .ant-tabs-nav-operations .ant-tabs-nav-more {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
span.no-prescription-data {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prescription_drugs_inner .ant-tabs-tabpane {
  position: relative;
  min-height: 80px;
}
.prescription_drugs_inner .ant-tabs-tab-btn {
  padding-bottom: 0 !important;
}
.prescription_drugs_inner .ant-tabs-nav-operations {
  line-height: 1;
}
/* prescription modal css ends */

.user_role .ant-tag-processing {
  color: #1445b7;
  border-color: #1445b7;
}
.ant-menu-item:hover .questinairepasticon g,
.ant-menu-item:hover .questinairepasticon path,
.ant-menu-item.ant-menu-item-selected .questinairepasticon g,
.ant-menu-item-selected .questinairepasticon path {
  stroke: #1445b7;
  color: #1445b7;
  fill: #1445b7;
}
.ant-layout-sider-collapsed .ant-menu-submenu-title > svg {
  margin-right: 0px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #e8efff !important;
}
.ant-menu-item-selected {
  color: #1445b7 !important;
}
.ant-menu-item-selected svg path {
  fill: #1445b7;
}

/* .ant-btn:hover,
.ant-btn:focus {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
} */

.sidebarmenuwrap .ant-menu-submenu-title {
  border-top: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.sidebarmenuwrap .ant-menu-inline .ant-menu-submenu .ant-menu-sub {
  border-bottom: 1px solid #d9d9d9 !important;
  border-top: none !important;
}

/* .sidebarmenuwrap .ant-menu-item:last-child{
  border-bottom: none !important;
}
.sidebarmenuwrap .ant-menu-item{
  border-bottom:1px solid #d9d9d9 !important;
}
.sidebarmenuwrap .ant-menu-submenu .ant-menu-item:last-child{
  border-bottom:1px solid #d9d9d9 !important;
} */
.ant-tag-processing {
  color: #1445b7 !important;
  border-color: #1445b7 !important;
}
.selectcorporate
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 25px;
}
.selectcorporate
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}
.selectcorporate .ant-select.form-control {
  padding: 0px !important;
}

/* multiselectContainerReact */
#multiselectContainerReact .chip {
  background: #1445b7 !important;
  border-radius: 4px !important;
}
