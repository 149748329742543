/* PreConsultNotification_Wrapper => Booking Section In Pre Consult Review Modal*/
/* PreConsultationReviewContainer_Wrapper => Booking Modal */
.PreConsultationReviewContainer_Wrapper {
  padding-top: 10px;
}
.BookAppointment_Flow .bookingapthead {
  margin-bottom: 16px;
}
.PreConsultNotification_Wrapper .bookaptcalenderwrap {
  padding-top: 16px;
}

/* back btn */
.BookAppointment_Flow .backbtn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #404040 !important;
  text-align: left !important;
}
.BookAppointment_Flow .backbtn span {
  font-size: 16px !important;
}
/* back btn */

.BookAppointment_Flow .ant-select-selector,
.BookAppointment_Flow .ant-select-multiple {
  height: auto !important;
  min-height: 28px !important;
}

/* radio group  */
.BookAppointment_Flow .change_calendar_view_btns,
.BookAppointment_Flow .bookingoptionradiogrup .ant-radio-group {
  display: flex;
  gap: 8px;
}
.BookAppointment_Flow .change_calendar_view_btns > label,
.BookAppointment_Flow .bookingoptionradiogrup .ant-radio-group > label {
  margin: 0 !important;
  border: 1px solid #808396 !important;
}
/* radio group  */

/* rota list */
.BookAppointment_Flow .available_rota_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 13px;
}
.BookAppointment_Flow
  .available_rota_list
  .ant-radio-button-wrapper:nth-child(n),
.BookAppointment_Flow .available_rota_list .ant-radio-button-wrapper {
  border-radius: 6px !important   ;
  border: 1px solid #808396 !important;
  color: #142d38 !important;
}
.BookAppointment_Flow .ant-radio-group .ant-radio-button-wrapper:nth-child(3) {
  border-left-width: 1px !important;
}
.BookAppointment_Flow .available_rota_list .ant-radio-button-wrapper-checked {
  color: #fff !important;
}
/* rota list */

.BookAppointment_Flow .fc .fc-header-toolbar {
  margin: 16px 0 !important;
}
.BookAppointment_Flow .fc .fc-toolbar-title {
  color: #142d38;
  font-size: 20px !important;
}
